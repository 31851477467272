export const BoardsIcon = ({
  width,
  height,
  color,
}: {
  width?: string
  height?: string
  color?: string
}) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.19624 10.1322L9.20255 10.1288C9.68974 9.87341 10.0826 9.46835 10.3237 8.97292L10.3271 8.96589C10.3535 8.91143 10.3781 8.85628 10.3999 8.80236L8.19482 8.28906L8.54966 10.3668C8.77463 10.3178 8.99208 10.2389 9.19624 10.1322Z"
        fill={color}
      />
      <path
        d="M10.5853 7.78719C10.583 7.65832 10.5711 7.5298 10.5494 7.40274L10.5464 7.38501C10.5237 7.25649 10.4914 7.12985 10.4499 7.00613L10.4429 6.98689C10.3981 6.85625 10.343 6.72939 10.2782 6.6075L10.2681 6.58775C10.194 6.45194 10.1081 6.32295 10.0115 6.20228L10.0019 6.19114C9.71132 5.83263 9.33015 5.55887 8.89812 5.39844L8.19849 7.79378L10.5373 8.33778C10.5721 8.16065 10.5884 7.98037 10.5858 7.79986L10.5853 7.78719ZM10.2554 7.93459L8.61119 7.55166L9.10624 5.85734C9.19494 5.9074 9.28016 5.96341 9.36133 6.025C9.50453 6.13355 9.63412 6.25906 9.74726 6.39881L9.75888 6.41249C9.84155 6.51614 9.91508 6.62681 9.97862 6.74325L9.98873 6.76199C10.0453 6.8688 10.0935 6.9799 10.1327 7.09427L10.1403 7.11503C10.1753 7.22117 10.2029 7.32967 10.2226 7.43971L10.2256 7.45744C10.2442 7.5683 10.2547 7.68037 10.257 7.79276L10.2575 7.80795C10.258 7.85 10.2575 7.89255 10.2554 7.93459Z"
        fill={color}
      />
      <path
        d="M7.6577 8.00037C7.65659 7.99146 7.65595 7.98577 7.65565 7.98018C7.65547 7.97657 7.65545 7.97331 7.65552 7.96579L7.65574 7.9558C7.65596 7.95144 7.65628 7.94719 7.65683 7.94254C7.65715 7.93903 7.65791 7.93423 7.65909 7.92751L7.66487 7.90025L8.4331 5.27133C8.28853 5.24679 8.14217 5.23443 7.99554 5.23438C7.65303 5.23514 7.31408 5.30409 6.99835 5.4372C6.68261 5.57032 6.39637 5.76496 6.15622 6.00984C5.91607 6.25472 5.72679 6.54497 5.59936 6.86375C5.47192 7.18254 5.40887 7.52352 5.41385 7.86693C5.41883 8.21034 5.49175 8.54934 5.62838 8.86427C5.76501 9.17921 5.96263 9.46381 6.20978 9.70158C6.45693 9.93936 6.7487 10.1256 7.06816 10.2494C7.38763 10.3733 7.72844 10.4323 8.07082 10.4231L7.6577 8.00037Z"
        fill={color}
      />
      <path
        d="M15.2317 1.44344H8.59371V0.595326C8.59371 0.437435 8.53116 0.286012 8.41982 0.174367C8.30847 0.0627216 8.15746 0 8 0C7.84254 0 7.69153 0.0627216 7.58019 0.174367C7.46884 0.286012 7.40629 0.437435 7.40629 0.595326V1.44344H0.768296C0.564532 1.44344 0.369113 1.52461 0.225029 1.66908C0.0809457 1.81356 3.40367e-07 2.00951 0 2.21383V2.35799C3.40367e-07 2.56231 0.0809457 2.75827 0.225029 2.90274C0.369113 3.04722 0.564532 3.12838 0.768296 3.12838V12.3762C0.768297 12.5473 0.836055 12.7113 0.956665 12.8322C1.07728 12.9532 1.24086 13.0211 1.41143 13.0211H4.31419L3.71662 15.2077C3.67283 15.368 3.69433 15.5392 3.7764 15.6836C3.85846 15.8279 3.99436 15.9337 4.15421 15.9776C4.31405 16.0215 4.48475 16 4.62874 15.9177C4.77273 15.8354 4.87823 15.6991 4.92202 15.5389L5.20282 14.5104L5.33472 14.0277L5.60975 13.0211H10.3902L10.6653 14.0277L10.7972 14.5104L11.078 15.5389C11.1141 15.6712 11.1925 15.788 11.3012 15.8713C11.4099 15.9546 11.5429 15.9998 11.6797 16C11.7358 15.9999 11.7916 15.9923 11.8456 15.9775C11.9248 15.9558 11.9989 15.9187 12.0638 15.8682C12.1286 15.8178 12.183 15.7551 12.2236 15.6836C12.2643 15.6121 12.2905 15.5333 12.3007 15.4516C12.311 15.37 12.3051 15.2871 12.2834 15.2077L11.6858 13.0211H14.5889C14.7594 13.0211 14.9229 12.9532 15.0434 12.8323C15.164 12.7114 15.2317 12.5475 15.2317 12.3765V3.12838C15.4355 3.12838 15.6309 3.04722 15.775 2.90274C15.9191 2.75826 16 2.56231 16 2.35799V2.21383C16 2.11266 15.9801 2.01248 15.9415 1.91901C15.9029 1.82555 15.8463 1.74062 15.775 1.66908C15.7036 1.59754 15.6189 1.5408 15.5257 1.50208C15.4325 1.46337 15.3326 1.44344 15.2317 1.44344ZM14.1727 11.9592H1.82735V3.70151H14.1727V11.9592Z"
        fill={color}
      />
    </svg>
  )
}
