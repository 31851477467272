import { Link, Paper, Typography, useMediaQuery } from "@mui/material"
import React, { useState } from "react"

import LoadingOverlay from "../../Components/LoadOverlay/LoadOverlay"
import { createPortalSession } from "../../../api/plans"
import { MOBILE_SCREEN_SIZE, openLink } from "../../../utils"

export default function V1Billing(): React.ReactElement {
  const isMobileDevice = useMediaQuery(MOBILE_SCREEN_SIZE)
  const [loading, setLoading] = useState(false)

  const getPaymentDetails = async () => {
    setLoading(true)
    const response = await createPortalSession()
    setLoading(false)
    if (response) {
      openLink(isMobileDevice, response.portal_url)
    }
  }

  return (
    <div className="Billing">
      <LoadingOverlay loading={loading} />
      <Paper>
        <Typography variant="h5">Customer Portal</Typography>
        <button className="btn" onClick={getPaymentDetails}>View now</button>
      </Paper>
      <Paper>
        <Typography variant="h5">Upgrade Plan</Typography>
      </Paper>
      <Paper>
        <Typography variant="h5">Access the Customer Portal where you’ll be able to:</Typography>
        <ul>
          <li>View your current plan</li>
          <li>Update or cancel your subscription</li>
          <li>Add or Update your payment methods</li>
          <li>Update billing information</li>
          <li>Access invoices</li>
        </ul>
      </Paper>
      <div className="billingFooter">
        Can’t find the answer you’re looking for? Reach out to our
        <Link href="mailto:support@trendrocket.io?subject=Billing%20Question"> customer support</Link> team.
      </div>
    </div>
  )
}
