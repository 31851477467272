import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { ThemeProvider } from "@mui/material/styles"

import { Change } from "./constants"
import { CountryTargets } from "../../../../api/countryTargets"
import { CountryTargetTheme, IconTheme } from "../../../../assets/themes/themes"
import { CountrySpan } from "./styles"

export const getGrowthIcon = (change: string) => {
  if (change === Change.INCREASE) {
    return (
      <ThemeProvider theme={IconTheme}>
        <ArrowUpwardIcon fontSize="small" color="success" />
      </ThemeProvider>
    )
  } else if (change === Change.DECREASE) {
    return (
      <ArrowDownwardIcon fontSize="small" color="error" />
    )
  } else {
    return null
  }
}

const getSpanTag = (country: CountryTargets, color: string) => {
  return (
    <CountrySpan color={color}>
      {getGrowthIcon(country.change?.toUpperCase())}{country.growth}
    </CountrySpan>
  )
}

export const renderSpanTag = (country: CountryTargets) => {
  const change = country.change?.toUpperCase()
  if (change === Change.INCREASE) {
    return getSpanTag(country, CountryTargetTheme.success)
  } else if (change === Change.DECREASE) {
    return getSpanTag(country, CountryTargetTheme.error)
  } else {
    return getSpanTag(country, CountryTargetTheme.primary)
  }
}

export const getPrecisedValue = (traffic: string) => {
  const value = traffic?.replace("%", "")
  const precisedValue = parseFloat(value).toPrecision(2)
  // converting to int format as geomap doesn't accepts float values.
  return parseInt(precisedValue)
}

export const getRegionsData = (data: CountryTargets[]) => {
  return data?.map((detail) => (
    {
      id: detail.code,
      value: getPrecisedValue(detail.traffic)
    }
  ))
}