// hooks/useUser.ts
import * as React from "react"
import { AuthContext, IAuthContext } from "../../contexts/AuthContext"

export const useAuth = (): IAuthContext => {
  const userContext = React.useContext(AuthContext)

  if (!userContext) {
    throw new Error("useAuth must be used within a AuthProvider")
  }

  return userContext
}
