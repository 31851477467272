import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import {
  Button,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { fetchAutomatedLogisticsData, fetchLogistics } from "../../../api/report"

import AirFreight from "../../../assets/images/v1-icons/airfreight.svg"
import SeaFreight from "../../../assets/images/v1-icons/sea-freight.svg"
import SeaFreight40 from "../../../assets/images/v1-icons/seafreight40ft.svg"
import LoadingOverlay from "../../Components/LoadOverlay/LoadOverlay"
import { AutomatedLogistics, Logistics } from "../../../types/productReportTypes"
import { getAbsoluteURL } from "../../../utils"
import Modal from "../../../components/Modal"

type Props = {
  productId: number
  productName: string
  supplierId: number
}

export default function LogisticsTable(props: Props): React.ReactElement {

  const tableColumns: string[] = [
    "no_of_units_20_ft_container",
    "cost_per_unit_20ft_container_cost",
    "no_of_units_40_ft_container",
    "cost_per_unit_40ft_container_cost",
  ]
  const initialState: Logistics = {
    product_id: props.productId,
    product_name: props.productName,
    country: undefined,
    cost_per_unit_container_cost: undefined,
    no_of_units_20_ft_container: null,
    cost_per_unit_20ft_container_cost: undefined,
    no_of_units_40_ft_container: null,
    cost_per_unit_40ft_container_cost: undefined,
    cost_per_unit_air_freight: undefined,
  }

  const initialAutomatedLogistics: AutomatedLogistics = {
    container_rates: [],
    units_per_container: { "20_ft": 0, "40_ft": 0 },
  }

  const token = localStorage.getItem("token")
  const isSuperUser = localStorage.getItem("is_superuser")

  const [currentLogistics, setCurrentLogistics] = useState<Logistics[]>(
    []
  )
  const [formData, setFormData] = useState<Logistics>(initialState)
  const [automatedLogistics, setAutomatedLogistics] =
    useState<AutomatedLogistics>(initialAutomatedLogistics)
  const [logisticUpdateCounter, setLogisticUpdateCounter] = useState(0)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [errors, setErrors] = useState<Record<string, unknown>>({})
  const [loading, setLoading] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = formData
    data[e.target.name] = e.target.value
    setFormData(data)
  }

  const handleCountrySelect = (e: SelectChangeEvent) => {
    const data = formData
    const automatedData = automatedLogistics.container_rates.filter(
      (data) => data.country_id === parseInt(e.target.value)
    )
    setFormData({
      ...data,
      ...{
        country: automatedData[0]?.country_id,
        cost_per_unit_20ft_container_cost:
          automatedData[0]?.cost_per_unit_20_ft,
        cost_per_unit_40ft_container_cost:
          automatedData[0]?.cost_per_unit_40_ft,
      },
    })
  }

  useEffect(() => {
    const loadLogistics = async () => {
      setLoading(true)
      const response = await fetchLogistics(props.productId, props.supplierId)
      setLoading(false)
      if (response) {
        setCurrentLogistics(response)
      }
    }

    loadLogistics()
  }, [props.productId, props.supplierId, logisticUpdateCounter])

  useEffect(() => {
    const loadAutomatedLogistics = async () => {
      setLoading(true)
      const response = await fetchAutomatedLogisticsData(props.supplierId, props.productId)
      setLoading(false)
      if (response) {
        setFormData({
          ...formData,
          ...{
            no_of_units_20_ft_container: response.units_per_container["20_ft"],
            no_of_units_40_ft_container: response.units_per_container["40_ft"],
          },
        })
        setAutomatedLogistics(response)
      }
    }

    loadAutomatedLogistics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.productId, props.supplierId])

  const closeEditModal = () => {
    setEditModalOpen(false)
    setFormData(initialState)
  }

  const handleEdit = (logistics: Logistics) => {
    const country = automatedLogistics.container_rates.filter(
      (data) => data.country === logistics.country?.toString()
    )
    setFormData({ ...logistics, ...{ country: country[0].country_id } })
    setEditModalOpen(true)
  }

  const addLogistics = () => {
    const data = formData
    data.product_supplier = props.supplierId
    axios
      .post(getAbsoluteURL("api/report/logistics/"), data, {
        headers: { Authorization: `Token ${token as string}` },
      })
      .then((response) => {
        setCurrentLogistics([
          ...currentLogistics,
          ...[response.data as Logistics],
        ])
        closeEditModal()
        setMessage("Logistics added successfully.")
        setModalOpen(true)
      })
      .catch((error: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setErrors(error.response.data)
      })
  }

  const updateLogistics = () => {
    const data = formData
    data.product_supplier = props.supplierId
    axios
      .patch(
        getAbsoluteURL(`api/report/logistics/${formData.id as number}/`),
        data,
        { headers: { Authorization: `Token ${token as string}` } }
      )
      .then(() => {
        setLogisticUpdateCounter(logisticUpdateCounter + 1)
        closeEditModal()
        setMessage("Logistics updated successfully.")
        setModalOpen(true)
      })
      .catch((error: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setErrors(error.response.data)
      })
  }

  const handleDelete = (logisticData: Logistics) => {
    axios
      .delete(
        getAbsoluteURL(`api/report/logistics/${logisticData.id as number}/`),
        { headers: { Authorization: `Token ${token as string}` } }
      )
      .then(() => {
        const logistics = currentLogistics.filter(
          (logistic) => logistic.id !== logisticData.id
        )
        setCurrentLogistics(logistics)
        setMessage("Logistic is deleted successfully.")
        setModalOpen(true)
      })
      .catch(() => {
        setMessage("Couldn't delete Logistic. Please try again later.")
        setModalOpen(true)
      })
  }

  const handleFormSubmit = () => {
    if (formData.id) {
      updateLogistics()
    } else {
      addLogistics()
    }
  }

  const renderErrors = (fieldName: string) => {
    let msgs: string[] = []
    let error = false
    Object.keys(errors).forEach((key) => {
      if (key === fieldName) {
        msgs = errors[key] as string[]
        error = true
      }
    })
    return { error, msg: msgs.join(".") }
  }

  const editModal = (
    <Modal
      title={"Logistics"}
      open={editModalOpen}
      handleClose={closeEditModal}
    >
      <Box
        sx={{
          "& .MuiFormControl-root": {
            width: "100%",
            borderRadius: "5px",
            pb: "8px",
            pt: "8px",
          },
          "& .MuiInputBase-root": {
            width: "100%",
            borderRadius: "5px",
            pb: "8px",
            pt: "8px",
          }
        }}
      >
        {Object.keys(formData).map((key, index) => (
          <>
            {key === "country" ? (
              <FormControl>
                <InputLabel id="country">Country</InputLabel>
                <Select
                  labelId="country-label"
                  id="country"
                  value={formData.country?.toString()}
                  label="Country"
                  onChange={handleCountrySelect}
                >
                  {automatedLogistics.container_rates.map((data, index) => (
                    <MenuItem key={index} value={data.country_id}>
                      {data.country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              ""
            )}

            {key === "cost_per_unit_20ft_container_cost" ? (
              <TextField
                type="number"
                key={formData.cost_per_unit_20ft_container_cost}
                name={key}
                label={key.replaceAll("_", " ")}
                defaultValue={formData.cost_per_unit_20ft_container_cost}
                onChange={handleChange}
                error={renderErrors(key).error}
                helperText={renderErrors(key).msg}
              />
            ) : (
              ""
            )}

            {key === "cost_per_unit_40ft_container_cost" ? (
              <TextField
                type="number"
                key={formData.cost_per_unit_40ft_container_cost}
                name={key}
                label={key.replaceAll("_", " ")}
                defaultValue={formData.cost_per_unit_40ft_container_cost}
                onChange={handleChange}
                error={renderErrors(key).error}
                helperText={renderErrors(key).msg}
              />
            ) : (
              ""
            )}

            {![
              "id",
              "product_name",
              "product_id",
              "product_supplier",
              "cost_per_unit_40ft_container_cost",
              "cost_per_unit_20ft_container_cost",
              "country",
            ].includes(key) ? (
              <TextField
                type="number"
                key={index}
                name={key}
                label={key.replaceAll("_", " ")}
                defaultValue={formData[key] as number}
                onChange={handleChange}
                error={renderErrors(key).error}
                helperText={renderErrors(key).msg}
              />
            ) : (
              ""
            )}
          </>
        ))}
        <Button
          sx={{
            position: "relative",
            mt: "20px",
          }}
          color="primary"
          variant="contained"
          onClick={handleFormSubmit}
        >
          Save
        </Button>
      </Box>
    </Modal>
  )

  const modal = (
    <Modal
      open={modalOpen}
      handleClose={() => setModalOpen(false)}
    >
      <Box>
        <Typography variant="h6" component="h2">
          {message}
        </Typography>
        <Button
          onClick={() => setModalOpen(false)}
          variant="contained"
          color="primary"
        >
          OK
        </Button>
      </Box>
    </Modal>
  )

  return (
    <>
      {isSuperUser ? (
        <button
          onClick={() => setEditModalOpen(true)}
          className="addlogisticBtn"
        >
          Add
        </button>
      ) : (
        ""
      )}
      {currentLogistics.length > 0 ? (
        <div>
          <Grid className="editTextRow">
            <div className="col-33 iconFields">
              <div>
                <TextField label="Sea Freight - 20 FT" />
                <img src={SeaFreight} />
              </div>
              <div>
                <TextField label="Sea Freight - 40 FT" />
                <img src={SeaFreight40} />
              </div>
              <div>
                <TextField label="Air Freight (Coming soon)" />
                <img src={AirFreight} />
              </div>
            </div>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Country</TableCell>
                  <TableCell>No. of Unit - 20" Container</TableCell>
                  <TableCell>Cost Per Unit - 20" Container Cost</TableCell>
                  <TableCell>No. of Unit - 40" Container</TableCell>
                  <TableCell>Cost Per Unit - 40" Container Cost</TableCell>
                  {/* <TableCell>Cost Per Unit - LCL Cost</TableCell> */}
                  {/* <TableCell>Cost Per Unit - Air Freight</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentLogistics.map((logisticData, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {isSuperUser && (
                        <div>
                          <IconButton onClick={() => handleEdit(logisticData)}>
                            <EditIcon fontSize="small" color="primary" />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDelete(logisticData)}
                          >
                            <DeleteIcon fontSize="small" color="error" />
                          </IconButton>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{logisticData.country}</TableCell>
                    {tableColumns.map((column, clIndex) => (
                      <TableCell key={clIndex}>
                        {[
                          "cost_per_unit_container_cost",
                          "cost_per_unit_20ft_container_cost",
                          "cost_per_unit_40ft_container_cost",
                          "cost_per_unit_air_freight",
                        ].includes(column)
                          ? "$"
                          : ""}
                        {logisticData[column]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <Typography variant="h6">
          No logistics have been added for this Supplier!
        </Typography>
      )}
      {editModal}
      {modal}
      <LoadingOverlay loading={loading} />
    </>
  )
}
