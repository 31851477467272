import { Grid } from "@mui/material"
import React from "react"

import GoogleAds from "../components/GoogleAds"
import Keywords from "../components/Keywords"
import Authorization from "../../Authorization/Authorization"
import { getToken } from "../../../utils/localStorage"
//------------------------------------------------------------------------------------------------------------

export default function GoogleAdsTab(): React.ReactElement {
  const token = getToken()

  return (
    <Grid container sx={{ mt: (theme) => theme.spacing(2) }}>
      {!token
        ? <Authorization />
        : <>
          <Keywords />
          <GoogleAds />
        </>
      }
    </Grid>
  )
}
