import InfoIcon from "@mui/icons-material/Info"
import { Grid, Tooltip, Typography } from "@mui/material"

type FilterRowHeaderAndSelectProps = {
  header: string
}

export default function FilterHeaderAndSelect({
  header,
}: FilterRowHeaderAndSelectProps) {

  const helpText = (name: string) => {
    if (name === "Scaling Score") {
      return "The Scaling Score tracks brand growth based on their Social channels month over month. Each brand receives a score between 1-100 in comparison with other tracked brands"
    } else {
      return "The Revenue Score uses Trend Rocket’s bespoke algorithm taking in consideration Traffic, Product metrics, Industry conversion data. Each brand receives a score between 1-100 in comparison with other tracked brands"
    }
  }

  return (
    <Grid
      item
      xs={2}
      alignItems="center"
      sx={(theme) => ({
        maxWidth: "16.66%",
        flexBasis: "16.66%",
        display: "flex",
        "& .MuiSvgIcon-root": {
          ml: theme.spacing(1),
          color: theme.palette.text.secondary,
        },
      })}
    >
      <Typography variant="subtitle1">
        <strong>{header}</strong>
      </Typography>
      {header.includes("Score") && (
        <Tooltip title={helpText(header)} arrow>
          <InfoIcon fontSize="small" />
        </Tooltip>
      )}
    </Grid>
  )
}