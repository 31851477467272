import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import React, { ReactElement } from "react"

import { getScore } from "../actions"
import { BrandTableRowData } from "../types"
import ScoreTableCell from "../../Components/ScoreTableCell"

type Props = {
  brandList: BrandTableRowData[]
}

type DetailShowing = {
  index: number
  open: boolean
}

export default function TableMobileView(props: Props): ReactElement {
  const [detailShowing, setDetailShowing] = React.useState<DetailShowing>({
    index: 0,
    open: false,
  })

  const toggleDetail = (index: number) => {
    setDetailShowing({
      index,
      open: !detailShowing.open,
    })
  }

  const handleClick = (name: string, id: number) => {
    if (name) {
      Object.assign(document.createElement("a"), {
        target: "_blank",
        href: `/brand/${name.replace(/ /g, "-")}/${id}`,
      }).click()
    }
  }

  const showBrandDetail = (brand: BrandTableRowData, index: number) => {
    return (
      index === detailShowing.index &&
      detailShowing.open && (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Scaling Score</TableCell>
              <TableCell>
                {brand.scaling_score_ranking !== null ? (
                  <ScoreTableCell
                    score={getScore(
                      brand.scaling_score_ranking,
                      brand.scaling_score_percentage
                    )}
                  />
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Revenue Score</TableCell>
              <TableCell>
                {brand.revenue_score_ranking !== null ? (
                  <ScoreTableCell
                    score={getScore(
                      brand.revenue_score_ranking,
                      brand.revenue_score_percentage
                    )}
                  />
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Brand Description</TableCell>
              <TableCell>{brand.brand_description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>FB Followers</TableCell>
              <TableCell>{brand.latest_fbfollowers}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>IG Followers</TableCell>
              <TableCell>{brand.igfollowers}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>FB Ads</TableCell>
              <TableCell>{brand.latest_numberfbads}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    )
  }

  const brandNames = props.brandList.map((brand, index) => (
    <li key={index} onClick={() => toggleDetail(index)}>
      <Avatar variant="circular" src={brand.profileimage} />
      <label>{brand.brandname}</label>
      {index === detailShowing.index && detailShowing.open && (
        <button onClick={() => handleClick(brand.brandname, brand.brand_id)}>
          Learn more
        </button>
      )}
      {showBrandDetail(brand, index)}
    </li>
  ))

  return (
    <div className="MobileViewSearchBrand">
      <Typography variant="h4">Brand</Typography>
      <ul>{brandNames}</ul>
    </div>
  )
}
