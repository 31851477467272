import CancelIcon from "@mui/icons-material/Cancel"
import CalenderIcon from "@mui/icons-material/InsertInvitation"
import FilterIcon from "@mui/icons-material/FilterAlt"
import {
  Autocomplete,
  Backdrop,
  Box,
  Fade,
  Grid,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers-pro"
import { DateRangePicker, DateRange as DateType } from "@mui/x-date-pickers-pro/DateRangePicker"
import dayjs from "dayjs"
import { Fragment, useEffect, useState } from "react"

import { BrandList, fetchBrandList } from "../../../api/search"
import {
  COUNTRIES_LIST,
  MEDIA_TYPES,
  ACTIVE_STATUS,
  PLATFORMS,
} from "./Constants"
import {
  BoxStyle,
  ButtonContainer,
  ClearButton,
  CloseButton,
  CustomSelect,
  DateInfo,
  DateLabel,
  DateRange,
  FilterButton,
  Hr,
  Label,
  ModalHeader,
  AutoCompleteStyles,
  Popup,
  DateGrid,
  DateContainer,
} from "./TrendingAdStyles"

type Props = {
  activeStatus: number
  brandsSelected: BrandList[]
  country: string
  dateRange: DateType<dayjs.Dayjs>
  isFilterApplied: boolean
  mediaType: string
  open: boolean
  platform: string
  clearFilter: VoidFunction
  handleClose: VoidFunction
  onBrandChange: (brands: BrandList[]) => void
  onCountryChange: (newCountry: string) => void
  onDateChange: (newDate: DateType<dayjs.Dayjs>) => void
  onFilterButtonClick: VoidFunction
  onMediaTypeChange: (newType: string) => void
  onPlatformChange: (newPlatform: string) => void
  onStatusChange: (newStatus: number) => void
}

enum FilterButtonTypes {
  applyFilter = "Apply Filters",
  changeFilter = "Change Filters",
}

export default function AdFilterPopup({
  activeStatus,
  brandsSelected,
  country,
  dateRange,
  isFilterApplied,
  mediaType,
  open,
  platform,
  clearFilter,
  handleClose,
  onBrandChange,
  onCountryChange,
  onDateChange,
  onFilterButtonClick,
  onMediaTypeChange,
  onPlatformChange,
  onStatusChange,
}: Props) {
  const [brands, setBrands] = useState<BrandList[]>([])

  useEffect(() => {
    const loadBrandNames = async () => {
      const response = await fetchBrandList()
      if (response) {
        setBrands(response)
      }
    }

    loadBrandNames()
  }, [])

  const handleCountryChange = (e: SelectChangeEvent<unknown>) => {
    onCountryChange(e.target.value as string)
  }

  const handleBrandsChange = (_event: React.ChangeEvent<unknown>, ...rest: any[]) => {
    onBrandChange(rest[0])
  }

  const handlePlatformChange = (e: SelectChangeEvent<unknown>) => {
    onPlatformChange(e.target.value as string)
  }

  const handleMediaTypeChange = (e: SelectChangeEvent<unknown>) => {
    onMediaTypeChange(e.target.value as string)
  }

  const handleActiveStatusChange = (e: SelectChangeEvent<unknown>) => {
    onStatusChange(e.target.value as number)
  }

  const handleClearButtonClick = () => {
    clearFilter()
    handleClose()
  }

  const handleFilterButtonClick = () => {
    onFilterButtonClick()
    handleClose()
  }

  return (
    <Popup
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={BoxStyle}>
          <CloseButton onClick={handleClose}>
            <CancelIcon />
          </CloseButton>
          <ModalHeader>
            <FilterIcon />
            Filter Trending Ads
          </ModalHeader>
          <Hr />
          <Label>Country</Label>
          <CustomSelect
            value={country}
            onChange={handleCountryChange}
          >
            {COUNTRIES_LIST.map((country, index) => (
              <MenuItem key={index} value={country.value}>{country.label}</MenuItem>
            ))}
          </CustomSelect>
          <Label>Brands</Label>
            <Autocomplete
              disablePortal
              sx={AutoCompleteStyles}
              onChange={handleBrandsChange}
              multiple
              options={brands}
              getOptionLabel={(option: BrandList) => option.brandname}
              renderOption={(props, option: BrandList) => (
                <Typography {...props}>{option.brandname}</Typography>
              )}
              defaultValue={brandsSelected}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder={brandsSelected.length === 0 ? "All Brands" : ""}
                />
              )}
            />
          <Label>Platform</Label>
          <CustomSelect
            value={platform}
            onChange={handlePlatformChange}
          >
            {PLATFORMS.map((platform, index) => (
              <MenuItem key={index} value={platform.value}>
                {platform.label}
              </MenuItem>
            ))}
          </CustomSelect>
          <Label>Media type</Label>
          <CustomSelect
            value={mediaType}
            onChange={handleMediaTypeChange}
          >
            {MEDIA_TYPES.map((mediaType, index) => (
              <MenuItem key={index} value={mediaType.value}>
                {mediaType.label}
              </MenuItem>
            ))}
          </CustomSelect>
          <Label>Active status</Label>
          <CustomSelect
            value={activeStatus}
            onChange={handleActiveStatusChange}
          >
            {ACTIVE_STATUS.map((status, index) => (
              <MenuItem key={index} value={status.value}>
                {status.label}
              </MenuItem>
            ))}
          </CustomSelect>
          <DateLabel>Ads running by date</DateLabel>
          <DateInfo>Choose an exact date or select a date range</DateInfo>
          <Grid container spacing={12} sx={DateGrid}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
            >
              <DateRangePicker
                disableFuture
                value={dateRange}
                onChange={(newValue) => {
                  onDateChange(newValue)
                }}
                renderInput={(startProps, endProps) => (
                  <Fragment>
                    <DateContainer sx={{ marginRight: "48px" }}>
                      <DateRange>From</DateRange>
                      <input
                        ref={startProps.inputRef as React.Ref<HTMLInputElement>}
                        {...startProps.inputProps}
                      />
                      <CalenderIcon fontSize="small" />
                    </DateContainer>
                    <DateContainer sx={{ marginLeft: "48px" }}>
                      <DateRange>To</DateRange>
                      <input
                        ref={endProps.inputRef as React.Ref<HTMLInputElement>}
                        {...endProps.inputProps}
                      />
                      <CalenderIcon fontSize="small" />
                    </DateContainer>
                  </Fragment>
                )}
              />
            </LocalizationProvider>
          </Grid>
          <ButtonContainer>
            <ClearButton color="secondary" onClick={handleClearButtonClick}>Clear All</ClearButton>
            <FilterButton onClick={handleFilterButtonClick}>
              {isFilterApplied ? FilterButtonTypes.changeFilter : FilterButtonTypes.applyFilter}
            </FilterButton>
          </ButtonContainer>
        </Box>
      </Fade>
    </Popup>
  )
}