import { Link } from "react-router-dom"

import ErrorIcon from "../assets/images/error.png"
import { SidebarRoute } from "../V1/Components/Appbar/appbarRoutes"


export default function ErrorPage() {
  return (
    <div className="error">
      <div>
        <span>
          <img src={ErrorIcon} />
        </span>
        <h2>Oops! </h2>
        <p>Something went wrong. Please try again later.
          <br />Go back to</p><Link to={SidebarRoute.TrendingBrands}>TrendRocket</Link>
      </div>
    </div>
  )
}