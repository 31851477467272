import { Autocomplete, Box, FormControl, TextField } from "@mui/material"
import { styled } from "@mui/system"
import { ChangeEvent, useState } from "react"
import ReactCountryFlag from "react-country-flag"

import { getCountryRegionOptions } from "./actions"
import { groupBy } from "../constants"
import { CountryRegion } from "../types"
import { Regions } from "./regions"
import { Countries } from "./countries"

type countryRegionProps = {
  value: CountryRegion[]
  reset: string
  countrySelect(e: ChangeEvent<unknown>, ...rest: any[]): void
}

const GroupHeader = styled("div")(() => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  fontFamily: "Poppins",
  fontWeight: "bolder",
  color: "#6E788A",
  backgroundColor: "rgba(59, 78, 223, 0.1)",
}))

const GroupItems = styled("ul")({
  padding: 0,
  fontFamily: "Poppins !important",
})

export default function CountryRegions({
  countrySelect,
  reset,
  value,
}: countryRegionProps) {
  const [options] = useState<CountryRegion[]>(
    getCountryRegionOptions(Regions, Countries)
  )

  return (
    <FormControl className="countryAutoComplete">
      <Autocomplete
        multiple
        sx={{
          width: "100%",
          minHeight: "40px",
        }}
        options={options}
        key={reset}
        onChange={countrySelect}
        autoHighlight
        size="small"
        groupBy={(option) => option.groupBy}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box
            sx={{
              "& span": {
                marginRight: 1.5,
                fontSize: 18,
              },
            }}
            {...props}
            component={"li"}
          >
            {option.groupBy === groupBy.country && (
              <span>
                <ReactCountryFlag countryCode={option.code} svg />
              </span>
            )}
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={value.length === 0 && "ALL"}
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>
              <strong>{params.group}</strong>
            </GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
      />
    </FormControl>
  )
}
