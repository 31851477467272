import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material"
import { DateTime } from "luxon"
import React from "react"
import { useSelector } from "react-redux"

import { RootStore } from "../../../Redux/store"
import { StoreTabSummary } from "../common"


export default function ProductAnalysis(): React.ReactElement {
  const storeTabSummary: StoreTabSummary = useSelector(((state: RootStore) => state.PageReduser["storeTabSummary"]))

  const rightFooterList: { label: string, val: string }[] = [
    { label: "Total number of products", val: storeTabSummary.product_count ? storeTabSummary.product_count.toString() : "-" },
    { label: "First Published Product", val: storeTabSummary.first_published_product ? DateTime.fromSQL(storeTabSummary.first_published_product).toFormat("DDD") : "-" },
    { label: "Last Published Product", val: storeTabSummary.last_published_product ? DateTime.fromSQL(storeTabSummary.last_published_product).toFormat("DDD") : "-" },
    { label: "Lowest Price", val: storeTabSummary.lowest_price ? `$${storeTabSummary.lowest_price.toString()}` : "-" },
    { label: "Highest Price", val: storeTabSummary.highest_price ? `$${storeTabSummary.highest_price.toString()}` : "-" },
    { label: "Avg. Price", val: storeTabSummary.average_price ? `$${storeTabSummary.average_price.toString()}` : "-" },
    { label: "Platform", val: "Shopify" }
  ]

  return (
    <Grid item xs={6}>
      <TableContainer className="ChartTable tracker">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel className="sortStyle">STATS</TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel className="sortStyle">VALUE</TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rightFooterList.map((list, index) => (
              <TableRow key={index} className="StoreDetails">
                <TableCell>
                  <span>{list.label}</span></TableCell>
                <TableCell>{list.val}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}
