export const Countries = [
  "AE",
  "AI",
  "AR",
  "AT",
  "AU",
  "BE",
  "BR",
  "BY",
  "CA",
  "CC",
  "CH",
  "CL",
  "CZ",
  "DE",
  "DK",
  "DO",
  "ES",
  "EU",
  "FI",
  "FM",
  "FR",
  "GB",
  "GG",
  "GR",
  "HK",
  "ID",
  "IE",
  "IM",
  "IN",
  "IO",
  "IS",
  "IT",
  "JO",
  "JP",
  "KE",
  "KR",
  "LA",
  "LT",
  "LU",
  "LV",
  "MC",
  "ME",
  "MX",
  "MY",
  "NL",
  "NO",
  "NZ",
  "PA",
  "PH",
  "PK",
  "PL",
  "PT",
  "QA",
  "RE",
  "RO",
  "SA",
  "SE",
  "SG",
  "SI",
  "SK",
  "ST",
  "TR",
  "TV",
  "TW",
  "US",
  "ZA",
]
