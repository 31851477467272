import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import LockIcon from "@mui/icons-material/Lock"
import LoginIcon from "@mui/icons-material/Login"
import { Alert, Button, FormControl, Link } from "@mui/material"
import axios from "axios"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { RootStore } from "../Redux/store"
import v1logo from "../assets/images/Logo/LogoFull.svg"
import { getAbsoluteURL } from "../utils"
import ReCaptcha, { getToken } from "../utils/Recaptcha"
import LoadingOverlay from "./Components/LoadOverlay/LoadOverlay"

export default function V1EmailVerify(): React.ReactElement {
  const dispatch = useDispatch()
  const [email, setEmail] = useState("")
  const [alertOpen, setAlertOpen] = useState(false)
  const [error, setError] = useState(false)
  const loading: boolean = useSelector(
    (state: RootStore) => state.PageReduser["loading"]
  )

  const handleReset = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const recaptchaToken = (await getToken()) as string
    const url = getAbsoluteURL("api/user/reset-password/")
    const formData = {
      email,
      recaptchaToken,
    }
    dispatch({ type: "Loading", payload: true })
    axios
      .post(url, formData, { headers: { "Content-type": "application/json" } })
      .then(() => {
        dispatch({ type: "Loading", payload: false })
        setAlertOpen(true)
        setTimeout(() => {
          setAlertOpen(false)
        }, 3000)
      })
      .catch(() => {
        dispatch({ type: "Loading", payload: false })
        setError(true)
        setAlertOpen(true)
        setTimeout(() => {
          setAlertOpen(false)
          setError(false)
        }, 3000)
      })
  }

  const alert = error ? (
    <Alert severity="error">Incorrect email! Please try again.</Alert>
  ) : (
    <Alert severity="info">
      Reset link has been successfully sent to your registered email. If this
      email is registered with Trend Rocket you should get an email from us
      within the next few minutes. Make sure to check your <strong>Junk</strong>{" "}
      or <strong>Spam</strong> folder.
    </Alert>
  )

  return (
    <div className="v1-loginBg">
      <div className="auth__left">
        <div className="auth__info">
          <ul>
            <li>Forgotten password - no problem</li>
          </ul>
          <p className="mt-2">
            {" "}
            We'll get you back on board in no time, just fill in the details.{" "}
          </p>
        </div>
      </div>
      <LoadingOverlay loading={loading} />
      <div className="auth__right">
        <div className="createAcc">
          <Link
            sx={{
              textDecoration: "none",
              ":hover": {
                textDecoration: "underline",
              },
            }}
            href="https://www.trendrocket.io/#pricing"
          >
            <AccountCircleIcon fontSize="small" />
            <span>Create New Account</span>
          </Link>
          <Link
            sx={{
              textDecoration: "none",
              ":hover": {
                textDecoration: "underline",
              },
            }}
            href="/login"
          >
            <LoginIcon fontSize="small" />
            <span>Login to My Account</span>
          </Link>
        </div>
        <div className="v1-loginBox">
          <img src={v1logo} alt="MOAS" />
          <div className="v1-login">
            {alertOpen && alert}
            <form onSubmit={handleReset}>
              <FormControl>
                <span>
                  <AlternateEmailIcon fontSize="small" />
                </span>
                <input
                  required
                  id="email"
                  type="email"
                  aria-describedby="email-helper-text"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </FormControl>
              <Button
                sx={{
                  textAlign: "left",
                  fontWeight: 700,
                  justifyContent: "space-between",
                  height: "36px !important",
                }}
                type="submit"
                fullWidth
                endIcon={<LockIcon />}
              >
                Reset Password
              </Button>
              <ReCaptcha action="submit" />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
