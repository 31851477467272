import { Box, IconButton, Stack, Typography } from "@mui/material"
import { DateTime } from "luxon"

import Iconify from "../../../components/Iconify"
import { FbAdPublisherPlatformGrayscale } from "../../../components/PublisherPlatforms"
import { CtaType, TrendingAd, ctaTitle } from "../../../api/trendingAds"
import SaveAdToTrendRocket from "../../../components/SaveAdToTrendRocket"
import { UserRoles, getUserRole } from "../../../utils/localStorage"
import { useState } from "react"
import { Board } from "../../../api/creativeCenter/boards"
//------------------------------------------------------------------------------------------------

type AdMetaViewProps = {
  ad: TrendingAd
  focusedCtaUrl?: string | null
  handleClose?: VoidFunction
}

export default function AdMetaView({
  ad,
  focusedCtaUrl,
  handleClose,
}: AdMetaViewProps) {
  const userRole = getUserRole()
  const isAdminOrTester =
    userRole === UserRoles.ADMIN || userRole === UserRoles.TESTER
  const [boards] = useState<Board[]>([])
  const [boardsLoading] = useState(false)

  return (
    <Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {handleClose && (
          <IconButton onClick={handleClose}>
            <Iconify icon={"material-symbols:close"} width={28} height={28} />
          </IconButton>
        )}
      </Box>
      {isAdminOrTester && (
        <SaveAdToTrendRocket
          adId={ad.ad_id}
          boards={boards}
          boardsLoading={boardsLoading}
          sx={{
            mb: 2,
            "& .MuiFormControl-root": {
              width: "100%",
            },
            "& .MuiLoadingButton-root": {
              width: "110px",
              mt: "12px",
              ml: "10px",
            },
          }}
        />
      )}
      <Typography variant="h6" pb={1}>
        Ad Details
      </Typography>
      <AdDetailsView ad={ad} focusedCtaUrl={focusedCtaUrl} />
    </Stack>
  )
}

const AdDetailsView = ({
  ad,
  focusedCtaUrl,
}: {
  ad: TrendingAd
  focusedCtaUrl?: string | null
}) => {
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={4}>
        <DetailItem
          title={"Started running on"}
          value={DateTime.fromISO(ad.start_date).toFormat("dd MMM yyyy")}
        />
        <DetailItem title={"ID"} value={ad.ad_id || "-"} />
      </Stack>
      <Stack direction="row" spacing={4}>
        {ad.cta && (
          <DetailItem title={"CTA Type"} value={ctaTitle(ad.cta as CtaType)} />
        )}
        <DetailItem
          title={"Display Type"}
          value={ad.ad_video ? "VIDEO" : "IMAGE"}
        />
        {ad.page_likes !== undefined && (
          <DetailItem
            title={"Page Likes"}
            value={new Intl.NumberFormat("en-AU").format(ad.page_likes)}
          />
        )}
      </Stack>

      <Typography
        color="rgba(61, 68, 101, 0.4);"
        fontFamily="Poppins"
        fontSize="14px"
      >
        Platforms
      </Typography>
      <FbAdPublisherPlatformGrayscale
        publisherPlatformString={ad.platform || ""}
      />

      {ad.page_categories && (
        <DetailItem
          title={"Categories"}
          value={parsePageCategories(ad.page_categories)}
        />
      )}

      {focusedCtaUrl && <DetailItem title={"CTA url"} value={focusedCtaUrl} />}
    </Stack>
  )
}

const DetailItem = ({ title, value }: { title: string; value: string }) => {
  return (
    <Stack>
      <Typography
        color="rgba(61, 68, 101, 0.4);"
        fontFamily="Poppins"
        fontSize="14px"
      >
        {title}
      </Typography>
      <Typography
        color="rgba(61, 68, 101, 0.8);"
        fontWeight={500}
        fontFamily="Poppins"
        fontSize="16px"
      >
        {value}
      </Typography>
    </Stack>
  )
}

const parsePageCategories = (publisherPlatforms: string) =>
  publisherPlatforms.replaceAll("'", "").replace("[", "").replace("]", "")
