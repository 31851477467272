import { MenuItem, SelectChangeEvent } from "@mui/material"
import { ReactElement } from "react"

import { useLayout } from "../../Hooks/useLayoutContext"
import { AdCategories } from "./Constants"
import {
  AdCategoryMenuItem,
  AdCategorySelect,
  Container,
  CustomFormControl,
  Header,
  SearchBar,
  SearchWrapper,
  Wrapper,
} from "./TrendingAdStyles"

type Props = {
  ads: ReactElement
  adCategory: AdCategories
  handleAdCategoryChange: (event: SelectChangeEvent<unknown>) => void
  handleSearch: VoidFunction
  search: string | null
  setSearch: (value: string) => void
}

export default function TrendingAdsLayout({
  ads,
  adCategory,
  handleAdCategoryChange,
  handleSearch,
  search,
  setSearch,
}: Props) {
  const { menuIsExpanded } = useLayout()

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch()
    }
  }

  return (
    <Container
      className={menuIsExpanded === true ? "slider v1-trending" : "v1-trending"}
    >
      <Wrapper>
        <Header>Ads search</Header>
        <SearchWrapper>
          <CustomFormControl variant="outlined">
            <AdCategorySelect
              value={adCategory}
              onChange={handleAdCategoryChange}
            >
              <MenuItem value={AdCategories.Default} disabled>
                Select ad category
              </MenuItem>
              <AdCategoryMenuItem value={AdCategories.AllAds}>
                All Ads
              </AdCategoryMenuItem>
              <AdCategoryMenuItem isFeaturedAd value={AdCategories.FeaturedAds}>
                Featured Ads
              </AdCategoryMenuItem>
            </AdCategorySelect>
          </CustomFormControl>
          <SearchBar
            placeholder="Search by keyword or brand"
            defaultValue={search as string}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </SearchWrapper>
        {ads}
      </Wrapper>
    </Container>
  )
}
