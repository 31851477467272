import { Box, Chip, Typography } from "@mui/material"

import { OverviewCharts } from "../../../api/brand"
import ApexChart from "../../../components/Chart/ApexChart"
import {
  ChartData,
  FbReviews,
  SocialCharts,
} from "../common"
import { getChipColor } from "../../../utils/statusHelpers"

type ApexChartData = {
  name: string
  series: { x: string, y: number }[]
}

export type LineChartContainerProps = {
  change: string
  headerText: string
  headerNumber: string | undefined
  data?: SocialCharts
  overviewCharts?: OverviewCharts
  fbReviews?: any[]
  reviewTracker?: boolean
  reviewTrackerValue?: ApexChartData
}

export default function LineChartContainer({
  change,
  headerText,
  overviewCharts,
  data,
  reviewTracker,
  reviewTrackerValue,
  fbReviews,
}: LineChartContainerProps) {

  function generateChartData(): ApexChartData {
    let chartData: ApexChartData | undefined = undefined
    switch (headerText) {
      case "Facebook likes":
        if (overviewCharts) {
          chartData = {
            name: "Facebook likes",
            series: overviewCharts.facebook_likes?.map((value) => ({
              x: new Date(value.date).toUTCString(),
              y: value.data,
            })),
          }
          return chartData
        }
        break
      case "Instagram followers":
        if (overviewCharts) {
          chartData = {
            name: "Instagram followers",
            series: overviewCharts.instagram_followers?.map(
              (value) => ({
                x: new Date(value.date).toUTCString(),
                y: value.data,
              })
            ),
          }
          return chartData
        }
        break
      case "TrustPilot reviews":
        if (data) {
          chartData = {
            name: "TrustPilot reviews",
            series: data.numberreviews?.map((value: ChartData) => ({
              x: new Date(value.timestamp).toUTCString(),
              y: value.count,
            })),
          }
          return chartData
        }
        break
      case "Facebook reviews":
        if (fbReviews) {
          chartData = {
            name: "Facebook reviews",
            series: fbReviews?.map((value: FbReviews) => ({
              x: new Date(value.x).toUTCString(),
              y: value.y,
            })),
          }
          return chartData
        }
        break
      case "Reddit mentions":
        if (data) {
          chartData = {
            name: "Reddit mentions",
            series: data.reditmentions?.map((value: ChartData) => ({
              x: new Date(value.timestamp).toUTCString(),
              y: value.count,
            })),
          }
          return chartData
        }
        break
      case "Ad counts":
        if (overviewCharts) {
          chartData = {
            name: "Ad counts",
            series: overviewCharts.facebook_ad_count?.map((value) => ({
              x: new Date(value.date).toUTCString(),
              y: value.data,
            })),
          }
          return chartData
        }
        break
      case "Website traffic":
        if (overviewCharts) {
          chartData = {
            name: "Website Traffic",
            series: overviewCharts.website_traffic?.map((value) => ({
              x: new Date(value.date).toUTCString(),
              y: value.data,
            })),
          }
          return chartData
        }
        break
    }
    return chartData as unknown as ApexChartData
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pl: "0px",
          mt: "0px",
        }}
      >
        <Typography
          sx={{
            color: "#3D4465 !important",
            fontSize: "16px !important",
            mt: "30px !important",
            pl: "30px !important",
          }}
          variant="subtitle2"
        >
          {headerText}
        </Typography>
        <Chip
          sx={{
            borderRadius: 4,
            marginRight: "10px",
            "& span": {
              padding: 0,
              fontFamily: "Poppins,sans-serif !important",
              fontWeight: 500,
              fontSize: "24px",
              lineHeight: "36px",
            },
            backgroundColor: "transparent !important",
            color: getChipColor(change)
          }}
          label={change}
        />
      </Box>
      <ApexChart
        type="area"
        chartSeries={
          reviewTracker
            ? (reviewTrackerValue as ApexChartData)
            : generateChartData()
        }
      />
    </>
  )
}
