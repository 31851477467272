import React from "react"
import Chart from "react-apexcharts"
import { intToString } from "./actions"
import { ApexBarChartData, BorderRadius, CurveType } from "./types"

type Props = {
  chartSeries: ApexBarChartData
}

export default function ApexBarChart({ chartSeries }: Props): React.ReactElement {
  const chartOptions =
  {

    dataLabels: {
      enabled: false,
    },
    xaxis:
    {
      categories: chartSeries ? chartSeries.xAxis : [],
      labels: {
        format: "dd/MM",
      },
      lines: {
        show: true,
      }
    },
    yaxis:
    {
      labels: {
        formatter: (value: number) => intToString(value).toString()
      }
    },
    chart:
    {
      id: "bar",
      height: 470,
      toolbar: {
        show: false
      }
    },
    colors: ["#3B4EDF", "#069D6D"],
    stroke: {
      width: [0, 4],
      curve: "smooth" as CurveType
    },
    legend: {
      show: false
    },
    plotOptions: {
      bar: {
        borderRadius: 25,
        borderRadiusApplication: "end" as BorderRadius,
        horizontal: false,
      }
    },
  }
  const seriesData = [
    {
      name: chartSeries ? chartSeries.name : "",
      type: "column",
      data: chartSeries ? chartSeries.yAxis : []
    },
    {
      name: "",
      type: "line",
      data: chartSeries ? chartSeries.yAxis.map(value => value - 3000) : []
    }
  ]

  return (
    <div className="apexbarchart" >
      <Chart
        options={chartOptions}
        series={seriesData}
        width={770}
        height={470}
      />
    </div >
  )
}
