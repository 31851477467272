import CancelIcon from "@mui/icons-material/Cancel"
import { Grid, IconButton } from "@mui/material"
import { MouseEvent, ReactElement } from "react"
import FilterHeaderAndSelect from "./filterHeader"

type FilterRowProps = {
  header: string
  thirdColumn: ReactElement
  onCancelIconClick(event: MouseEvent<unknown>): void
}

export default function FilterRow({
  header,
  thirdColumn,
  onCancelIconClick,
}: FilterRowProps) {

  return (
    <Grid container columnSpacing={1} alignItems="center" className="addFilters">
      <FilterHeaderAndSelect header={header} />
      <Grid
        item
        xs={9}
        lg={6}
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
        alignItems="center"
        className="countryRow"
      >
        {thirdColumn}
        <IconButton
          size="medium"
          title="Remove filter criteria"
          sx={{ ml: (theme) => theme.spacing(1) }}
          className="closeBtn"
          onClick={onCancelIconClick}
        >
          <CancelIcon color="error" />
        </IconButton>
      </Grid>
    </Grid>
  )
}