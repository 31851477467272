import { Button, Link } from "@mui/material"
import { ctaTitle, CtaType } from "../api/trendingAds"
import { colors } from "../utils/theme"

export const AdCardCallToActionButton = ({
  ctaType,
  link,
  maxWidth,
}: {
  ctaType: CtaType
  link: string
  maxWidth?: string
}) => {
  return (
    <Button
      component={Link}
      href={link}
      fullWidth={maxWidth === undefined}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        p: 2,
        height: "40px",
        border: "solid",
        BorderColor: colors.Primary,
        borderWidth: 1,
        borderRadius: "5px",
        color: colors.Primary,
        minWidth: "120px",
        maxWidth,
      }}
    >
      {ctaTitle(ctaType)}
    </Button>
  )
}
