import { fetchApiCall, postApiCall } from "."
import { CollectionResult } from "./common"

export type Product = {
  imageurl: string
  name: string
  previousprice: unknown
  price: number
  price_usd: number
  url: string
  shopify_product_id: number
  is_featured: boolean
}

export const fetchProducts = async (
  brandname: string,
  page: number,
  page_size: number,
  order_by: string | null
) => {
  const endpoint = "search/shopify-products/"

  return fetchApiCall<CollectionResult<Product>>(endpoint, {
    brandname__wildcard: brandname,
    page,
    page_size,
    order_by,
  })
}

export const featureProduct = async (brand_id: number, product_id: number) => {
  const endpoint = "/api/brand/feature-product/"
  const payload = {
    brand_id,
    product_id,
  }

  return postApiCall<{ message: string }>(endpoint, payload)
}
