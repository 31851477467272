import { Typography } from "@mui/material"
import { styled } from "@mui/system"

export const Header = styled(Typography)({
  fontSize: "25px !important",
  fontFamily: "Poppins !important",
  marginBottom: "2px"
})

export const Description = styled(Typography)({
  position: "relative",
  fontFamily: "Poppins !important",
})

export const Image = styled("img")({
  position: "relative",
  top: "-12px",
  minWidth: "50px",
  minHeight: "50px",
  left: "2px",
})