import { useMediaQuery } from "@mui/material"

import { MOBILE_SCREEN_SIZE } from "../../../utils"
//-------------------------------------------------------------------------------------------------

type VideoPlayerProps = {
  sourceUrl: string
}

export default function VideoPlayer({ sourceUrl }: VideoPlayerProps) {
  const isMobileView = useMediaQuery(MOBILE_SCREEN_SIZE)

  const getSourceURL = () => {
    if (isMobileView) {
      return `${sourceUrl}#t=0.001`
    }
    return sourceUrl
  }

  return (
    <video
      key={sourceUrl}
      loop
      controls
      muted
      style={{
        width: "100%",
        height: "100%",
        display: "block",
      }}
    >
      <source src={getSourceURL()} type="video/mp4" />
    </video>
  )
}
