import { Box, Button, Grid, MenuItem, Typography } from "@mui/material"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { DateTime } from "luxon"
import React from "react"

import CalenderIcon from "../../../assets/images/v1-icons/calendar.svg"
import { colors } from "../../../utils/theme"

type Props = {
  dateChoice: string
  handleDateChoice: (e: SelectChangeEvent) => void
}

export default function ReportsOverviewHeader(
  { dateChoice, handleDateChoice }: Props
): React.ReactElement {

  const getChoiceDateRange = () => {
    const today = DateTime.now().toFormat("LLL dd, yyyy")
    const choiceDate = DateTime.now()
      .minus({ days: parseInt(dateChoice) })
      .toFormat("LLL dd, yyyy")
    return `${choiceDate} - ${today}`
  }

  return (
    <Grid item xs={12} sx={{ pt: "30px !important", mb: "15px" }}>
      <Typography
        sx={{
          position: "relative",
          top: { xs: 0, md: "25px" }
        }}
        variant="h5">Reports Overview</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        <Select
          sx={{
            "& .MuiSelect-select": {
              fontSize: "15px",
              border: "1px solid rgba(61, 68, 101, 0.2)",
              borderRadius: "4px 0px 0px 4px",
              height: "32px !important",
              lineHeight: "27px",
              pt: "5px",
              pb: "0px",
              pl: "10px",
              pr: "25px",
              background: colors.White,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              opacity: 0,
            }
          }}
          value={dateChoice} onChange={handleDateChoice} displayEmpty>
          <MenuItem value={"30"}>Last 30 days</MenuItem>
          <MenuItem value={"60"}>Last 60 days</MenuItem>
          <MenuItem value={"90"}>Last 90 days</MenuItem>
        </Select>
        <Button
          sx={{
            fontSize: { xs: "12px !important", md: "15px" },
            fontWeight: 500,
            border: "1px solid rgba(61, 68, 101, 0.2) !important",
            borderRadius: "0px 4px 4px 0px !important",
            height: "37px !important",
            pt: "5px !important",
            pb: "0px !important",
            pl: "10px !important",
            pr: "25px !important",
            background: `${colors.White} !important`,
            borderLeft: "none !important",
            color: `${colors.Text} !important`,
            "&:hover": {
              backgroundColor: "transparent !important",
            }
          }}
          size="small"
          startIcon={<img src={CalenderIcon} alt="Calendar" />}>
          {getChoiceDateRange()}
        </Button>
      </Box>
    </Grid>
  )
}
