import {
  InputLabel,
  MenuItem,
  Pagination as MUIPagination,
  Select as PageSelect,
  SelectChangeEvent,
} from "@mui/material"
import { useMemo } from "react"

export type PaginationData = {
  page: number
  pageSize: number
}

type Props = {
  resultCount: number
  currentPage: number
  pageSize: number
  onPageChange: (newPage: number) => void
  onPageSizeChange: (newPageSize: number) => void
  isSearchBrandPage?: boolean
  label: string
}

export const PAGINATION_SIZE_OPTIONS = [20, 50, 100, 150]

export default function Pagination({
  resultCount,
  currentPage,
  pageSize,
  onPageChange,
  onPageSizeChange,
  isSearchBrandPage,
  label,
}: Props) {
  const usePageChange = (_e: React.ChangeEvent<unknown>, page: number) => {
    onPageChange(page)
  }

  const pageCount = useMemo(
    () => Math.ceil(resultCount / pageSize) || 0,
    [resultCount, pageSize]
  )

  const usePageSelect = (
    e: SelectChangeEvent<unknown>
  ) => {
    onPageChange(e.target.value as number)
  }

  const usePageSizeSelect = (
    e: SelectChangeEvent<unknown>
  ) => {
    onPageChange(1)
    onPageSizeChange(e.target.value as number)
  }

  return (
    <div className="BrandPagination">
      <MUIPagination
        color="primary"
        page={currentPage}
        count={pageCount}
        onChange={usePageChange}
      />
      <InputLabel sx={{ top: "5px" }} id="page-select">Jump to:</InputLabel>
      <PageSelect
        labelId="page-select"
        variant="standard"
        value={currentPage}
        onChange={usePageSelect}
      >
        {new Array(pageCount).fill(0).map((_, index) => {
          return (
            <MenuItem key={index} value={index + 1}>
              {index + 1}
            </MenuItem>
          )
        })}
      </PageSelect>
      {!isSearchBrandPage && (
        <div className="pageNumber">
          <InputLabel sx={{ top: "5px" }}>{`${label} per page`}</InputLabel>
          <PageSelect
            labelId="page-select"
            variant="standard"
            value={pageSize}
            onChange={usePageSizeSelect}
          >
            {PAGINATION_SIZE_OPTIONS.map((val, index) => {
              return (
                <MenuItem key={index} value={val}>
                  {val}
                </MenuItem>
              )
            })}
          </PageSelect>
        </div>
      )}
    </div>
  )
}
