import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import {
  Alert,
  Button,
  FormControl,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material"
import React from "react"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"

import LoadingOverlay from "../../Components/LoadOverlay/LoadOverlay"
import { getAbsoluteURL } from "../../../utils"
import { RootStore } from "../../../Redux/store"


export default function Security(): React.ReactElement {
  const dispatch = useDispatch()
  const token = localStorage.getItem("token")

  const loading: boolean = useSelector(((state: RootStore) => state.PageReduser["loading"]))
  const [oldPwd, setOldPwd] = React.useState<string>("")
  const [pwd, setPwd] = React.useState<string>("")
  const [confirmPwd, setConfirmPwd] = React.useState<string>("")
  const [error, setError] = React.useState<boolean>(false)
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
  const [showIcon, setShowIcon] = React.useState<boolean>(false)
  const [pwdVisible, setPwdVisible] = React.useState<boolean>(false)

  function handlePwdChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.id === "newPassword") {
      setPwd(e.target.value)
    } else {
      setConfirmPwd(e.target.value)
    }
    if (e.target.id === "confirmPassword" && e.target.value.length > 0) {
      setShowIcon(true)
    } else if (e.target.id === "confirmPassword" && e.target.value.length === 0) {
      setShowIcon(false)
    }
  }

  function changePassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const url = getAbsoluteURL("api/user/change-password/")
    const formData = { old_password: oldPwd, password: pwd, confirm_password: confirmPwd }
    dispatch({ type: "Loading", payload: true })
    axios.post(url, JSON.stringify(formData), { headers: { "Content-Type": "application/json", "Authorization": `Token ${token as string}` } })
      .then(() => {
        dispatch({ type: "Loading", payload: false })
        setAlertOpen(true)
        setTimeout(() => {
          setAlertOpen(false)
        }, 3000)
      })
      .catch(() => {
        dispatch({ type: "Loading", payload: false })
        setError(true)
        setAlertOpen(true)
        setTimeout(() => {
          setAlertOpen(false)
          setError(false)
        }, 3000)
      })
  }

  const alert = (
    error
      ? <Alert severity="error">Error occured! Please try again.</Alert>
      : <Alert severity="success">Your password has been successfully changed.</Alert>
  )

  const validationIcon = (
    pwd === confirmPwd ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />
  )

  return (
    <div className="security">
      <LoadingOverlay loading={loading} />
      <h4> Change your password</h4>
      <Link
        sx={{
          textDecoration: "none",
          ":hover": {
            textDecoration: "underline"
          }
        }}
        href="/email-verify"
      >
        Forgotten password?
      </Link>
      <Grid container>
        {alertOpen ? alert : ""}
        <Grid item xs={6}>
          <form onSubmit={changePassword}>
            <FormControl>
              <label>Current password</label>
              <input required id="oldPassword" type={"password"} aria-describedby="pwd-helper-text" onChange={e => setOldPwd(e.target.value)} placeholder="Enter your current password..." />
            </FormControl>
            <FormControl>
              <label>New password</label>
              <input required id="newPassword" type={pwdVisible ? "text" : "password"} aria-describedby="pwd-helper-text" onChange={handlePwdChange} placeholder="Enter your new password..." />
              <span onClick={() => setPwdVisible(!pwdVisible)}>{pwdVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}</span>
            </FormControl>
            <FormControl>
              <label>Re-enter new password</label>
              <input required id="confirmPassword" type={"password"} aria-describedby="pwd-helper-text" onChange={handlePwdChange} placeholder="Re-enter your new password..." />
              <span>{showIcon ? validationIcon : ""}</span>
            </FormControl>
            <Button
              sx={{
                background: "#775ddc !important",
                borderRadius: "5px",
                padding: "6px 15px",
                border: "none",
              }}
              type="submit"
            >
              Update Password
            </Button>
          </form>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <Typography variant="h4">Password requirements</Typography>
            <p>To create a new password, you have to meet all of the following requirements:</p>
            <ul>
              <li>Minimum 6 characters</li>
              <li>At least one number</li>
              <li>At least one symbol</li>
              <li>Can't be the same as a previous password</li>
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
