import ReactCountryFlag from "react-country-flag"

import { renderSpanTag } from "./actions"
import {
  Container,
  CountryName,
  CountryTraffic,
  ImageWrapper
} from "./styles"
import { CountryTargets } from "../../../../api/countryTargets"

type TopCountryProps = {
  countries: CountryTargets[]
}

export default function TopCountries({ countries }: TopCountryProps) {

  return (
    <>
      {countries.map((country) => (
        <Container>
          <ImageWrapper>
            <ReactCountryFlag countryCode={country.code} svg />
          </ImageWrapper>
          <CountryName>{country.country}</CountryName>
          <CountryTraffic>{country.traffic}</CountryTraffic>
          {renderSpanTag(country)}
        </Container>
      ))}
    </>
  )
}