import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker"
import { Dayjs } from "dayjs"
import { useState } from "react"
import { BrandList } from "../../api/search"

import {
  COUNTRIES_LIST,
  MEDIA_TYPES,
  ACTIVE_STATUS,
  PLATFORMS,
  defaultFromDate,
  defaultToDate,
} from "../Trending/TrendingAds/Constants"

export default function UseAdFilter() {
  const [brandSelected, setBrandSelected] = useState<BrandList[]>([])
  const [country, setCountry] = useState(COUNTRIES_LIST[0].value)
  const [mediaType, setMediaType] = useState(MEDIA_TYPES[0].value)
  const [activeStatus, setActiveStatus] = useState(ACTIVE_STATUS[0].value)
  const [platform, setPlatform] = useState(PLATFORMS[0].value)
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([defaultFromDate, defaultToDate])
  const [applyFilters, setApplyFilters] = useState<boolean>(false)

  const clearFilter = () => {
    setActiveStatus(ACTIVE_STATUS[0].value)
    setBrandSelected([])
    setCountry(COUNTRIES_LIST[0].value)
    setDateRange([defaultFromDate, defaultToDate])
    setMediaType(MEDIA_TYPES[0].value)
    setPlatform(PLATFORMS[0].value)
    setApplyFilters(false)
  }

  const toggleApplyFilters = () => {
    setApplyFilters(!applyFilters)
  }

  return {
    activeStatus,
    applyFilters,
    brandSelected,
    country,
    dateRange,
    mediaType,
    platform,
    clearFilter,
    setActiveStatus,
    setBrandSelected,
    setCountry,
    setDateRange,
    setMediaType,
    setPlatform,
    toggleApplyFilters,
  }
}