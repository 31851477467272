import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import TuneIcon from "@mui/icons-material/FilterAlt"
import { styled } from "@mui/system"
import React, { useEffect, useState } from "react"

import {
  fetchTrendingAds,
  TrendingAd,
  TrendingAdResponse,
  TrendingAdsSortOption,
} from "../../../api/trendingAds"
import EmptyComponent from "../../../components/EmptyComponent/EmptyComponent"
import {
  DetailTypes,
  TitleTypes,
} from "../../../components/EmptyComponent/types"
import { getToken, getUserRole, UserRoles } from "../../../utils/localStorage"
import Pagination from "../../Components/Pagination/pagination"
import useAdFilter from "../../Hooks/useAdFilter"
import usePagination from "../../Hooks/usePagination"
import AdFilterPopup from "./AdFilter"
import TrendingAdCard from "./TrendingAdCard"
import TrendingAdFeedLoader from "./TrendingAdFeedLoader"
import { Count, Description } from "./TrendingAdStyles"
import AdDetailPreviewModal from "../../Components/AdDetail"
import { scrollToTop } from "../../../utils"

const CustomSort = styled(FormControl)({
  height: 30,
  lineHeight: 30,
  "& label": {
    fontSize: 12,
    paddingBottom: 6,
    paddingTop: 6,
  },
})

const Wrapper = styled(Paper)({
  padding: 30,
  marginTop: 30,
})

type AdsProps = {
  adCategory: string
  search: string
  searchCounter: number
}

export default function Ads({
  adCategory,
  searchCounter,
  search,
}: AdsProps): React.ReactElement {
  const token = getToken()
  const userRole = getUserRole()

  const {
    currentPage,
    pageSize,
    resultCount,
    setResultCount,
    setCurrentPage,
    setPageSize,
  } = usePagination()

  const {
    activeStatus,
    applyFilters,
    brandSelected,
    country,
    dateRange,
    mediaType,
    platform,
    clearFilter,
    setActiveStatus,
    setBrandSelected,
    setCountry,
    setDateRange,
    setMediaType,
    setPlatform,
    toggleApplyFilters,
  } = useAdFilter()

  const [sortAdsBy, setSortAdsBy] = useState(TrendingAdsSortOption.random)
  const [loading, setLoading] = useState(false)
  const [adsData, setAdsData] = useState<TrendingAdResponse | undefined>({
    count: 0,
    next: "",
    previous: "",
    results: [],
  })

  const [filtersPopperOpened, setFiltersPopperOpened] = useState(false)
  const [selectedAd, setSelectedAd] = useState<TrendingAd>()

  useEffect(() => {
    const adsDateRange = `${dateRange[0]?.format("YYYY-MM-DD") as string}__${
      dateRange[1]?.format("YYYY-MM-DD") as string
    }`
    const brandIds = brandSelected.map((brand) => brand.brand_id).join(",")

    const getTrendingAds = async () => {
      setLoading(true)
      setResultCount(0)
      const response = await fetchTrendingAds(
        currentPage,
        pageSize,
        sortAdsBy,
        adCategory,
        country,
        activeStatus,
        brandIds,
        adsDateRange,
        mediaType,
        applyFilters,
        searchCounter > 0 ? search : undefined
      )
      setResultCount(response?.count || 0)
      setAdsData(response)
      setLoading(false)
    }

    getTrendingAds()
    scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adCategory,
    applyFilters,
    searchCounter,
    currentPage,
    pageSize,
    setResultCount,
    sortAdsBy,
    token,
  ])

  const viewNextAd = () => {
    const ads = adsData?.results
    if (ads) {
      const index = ads.findIndex((ad) => ad.id === selectedAd?.id)
      if (index === ads.length) {
        return
      }
      setSelectedAd(ads[index + 1])
    }
  }
  const viewPreviousAd = () => {
    const ads = adsData?.results
    if (ads) {
      const index = ads.findIndex((a) => a.id === selectedAd?.id)
      if (index < 1) {
        return
      }
      setSelectedAd(ads[index - 1])
    }
  }

  function handleFiltersButtonClicked() {
    setFiltersPopperOpened(!filtersPopperOpened)
  }

  function closeFilterPopper() {
    setFiltersPopperOpened(false)
  }

  function handleSortAdsBySelectChanged(e: SelectChangeEvent<string>) {
    setSortAdsBy(e.target.value as unknown as TrendingAdsSortOption)
  }

  return (
    <>
      <Count>{resultCount} Results</Count>
      <Description>
        {searchCounter === 0 ? (
          <>
            This is just a small sample of our ads library - perform a search to
            discover more ads matching your needs
          </>
        ) : (
          <>
            These results includes ads that match your <b>keyword search.</b>
          </>
        )}
      </Description>
      <Wrapper>
        <div className="searchHead">
          <div>
            <Typography variant="h4">
              {userRole === UserRoles.ADMIN ? "Ads list" : "Trending Ads"}
            </Typography>
            <div className="TrendingAdsSort">
              <div>
                <Button
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                  color="inherit"
                  variant="outlined"
                  startIcon={<TuneIcon />}
                  size="medium"
                  onClick={handleFiltersButtonClicked}
                >
                  Filters
                </Button>
              </div>
              <CustomSort variant="outlined">
                <InputLabel id="label-sort-by">Sort by : </InputLabel>
                <Select
                  labelId="label-sort-by"
                  label="Sort by"
                  id="dropdown-sort-by"
                  value={sortAdsBy}
                  onChange={handleSortAdsBySelectChanged}
                  inputProps={{
                    style: {
                      height: "38px",
                    },
                  }}
                >
                  <MenuItem value={TrendingAdsSortOption.random} disabled>
                    Choose an option
                  </MenuItem>
                  <MenuItem value={TrendingAdsSortOption.StartDateDesc}>
                    Latest Ads
                  </MenuItem>
                  <MenuItem value={TrendingAdsSortOption.StartDateAsc}>
                    Start date
                  </MenuItem>
                  <MenuItem value={TrendingAdsSortOption.LongestRunning}>
                    Long running ads
                  </MenuItem>
                </Select>
              </CustomSort>
            </div>
          </div>
          <Divider />
        </div>
        {loading && <TrendingAdFeedLoader />}
        {!loading && adsData ? (
          <Grid container className="featuredProducts adsTab">
            {adsData.results.length > 0 ? (
              adsData?.results.map((ad, index) => (
                <TrendingAdCard
                  isAdmin={userRole === UserRoles.ADMIN}
                  trendingAd={ad}
                  viewDetail={setSelectedAd}
                  key={index}
                />
              ))
            ) : (
              <EmptyComponent
                title={TitleTypes.noResults}
                detail={DetailTypes.adsWithFilter}
              />
            )}
            {adsData?.results.length > 0 && (
              <Pagination
                currentPage={currentPage}
                resultCount={resultCount}
                pageSize={pageSize}
                onPageChange={setCurrentPage}
                onPageSizeChange={setPageSize}
                label={"Ads"}
              />
            )}
          </Grid>
        ) : (
          <Box>
            <EmptyComponent
              title={TitleTypes.noResults}
              detail={DetailTypes.adsWithoutFilter}
            />
          </Box>
        )}
      </Wrapper>
      <AdFilterPopup
        activeStatus={activeStatus}
        brandsSelected={brandSelected}
        country={country}
        dateRange={dateRange}
        isFilterApplied={applyFilters}
        mediaType={mediaType}
        open={filtersPopperOpened}
        platform={platform}
        clearFilter={clearFilter}
        handleClose={closeFilterPopper}
        onBrandChange={setBrandSelected}
        onCountryChange={setCountry}
        onDateChange={setDateRange}
        onFilterButtonClick={toggleApplyFilters}
        onMediaTypeChange={setMediaType}
        onPlatformChange={setPlatform}
        onStatusChange={setActiveStatus}
      />
      <AdDetailPreviewModal
        handleClose={() => setSelectedAd(undefined)}
        ad={selectedAd}
        onNext={viewNextAd}
        onPrevious={viewPreviousAd}
      />
    </>
  )
}
