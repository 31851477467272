import { AdsIcon } from "../../../assets/images/Navigation/AdsIcon"
import { BoardsIcon } from "../../../assets/images/Navigation/BoardsIcon"
import { BrandsIcon } from "../../../assets/images/Navigation/BrandsIcon"
import { FaqIcon } from "../../../assets/images/Navigation/FaqIcon"
import { ProductsIcon } from "../../../assets/images/Navigation/ProductsIcon"
import { SettingsIcon } from "../../../assets/images/Navigation/SettingsIcon"
import { colors } from "../../../utils/theme"

function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

export enum SidebarLabel {
  TrendingAds = "Ads",
  TrendingBrands = "Brands",
  FeaturedProducts = "Products",
  SearchBrands = "Search Brands",
  MyBoards = "My Boards",
  Settings = "Settings",
  Faq = "FAQ",
}

export enum SidebarRoute {
  TrendingAds = "/trending/ads",
  TrendingBrands = "/trending/brands",
  FeaturedProducts = "/trending/products",
  MyBoards = "/creative/my-boards",
  SearchBrands = "/search-brand",
  Settings = "/settings",
  Faq = "https://www.trendrocket.io/product-faq",
}

export const PATH_CREATIVE_CENTER = {
  root: SidebarRoute.MyBoards,
  adDetail: (id: string) => path(SidebarRoute.MyBoards, `/ad/${id}`),
}

export type RouteItem = {
  title: SidebarLabel
  route: SidebarRoute
  newTab?: boolean
}

export type RouteSection = {
  id: string
  title: string
  items: RouteItem[]
}

export const appBarRoutes = (adminOrTester = false): RouteSection[] => {
  const routes: RouteSection[] = [
    {
      id: "trending",
      title: "Trending",
      items: [
        {
          title: SidebarLabel.TrendingAds,
          route: SidebarRoute.TrendingAds,
        },
        {
          title: SidebarLabel.TrendingBrands,
          route: SidebarRoute.TrendingBrands,
        },
        {
          title: SidebarLabel.FeaturedProducts,
          route: SidebarRoute.FeaturedProducts,
        },
      ],
    },
  ]

  if (adminOrTester) {
    routes.push({
      id: "creativeCenter",
      title: "Creative Center",
      items: [
        {
          title: SidebarLabel.MyBoards,
          route: SidebarRoute.MyBoards,
        },
      ],
    })
  }

  routes.push({
    id: "discover",
    title: "Discover",
    items: [
      {
        title: SidebarLabel.SearchBrands,
        route: SidebarRoute.SearchBrands,
      },
    ],
  })
  routes.push({
    id: "help",
    title: "Help",
    items: [
      {
        title: SidebarLabel.Settings,
        route: SidebarRoute.Settings,
      },
      {
        title: SidebarLabel.Faq,
        route: SidebarRoute.Faq,
        newTab: true,
      },
    ],
  })

  return routes
}

export const iconForSidebarRoute = (
  sidebarRoute: SidebarRoute,
  isSelected: boolean
) => {
  const color = isSelected ? colors.White : colors.Icon
  switch (sidebarRoute) {
    case SidebarRoute.TrendingAds:
      return <AdsIcon color={color} />
    case SidebarRoute.FeaturedProducts:
      return <ProductsIcon color={color} />
    case SidebarRoute.TrendingBrands:
    case SidebarRoute.SearchBrands:
      return <BrandsIcon color={color} />
    case SidebarRoute.MyBoards:
      return <BoardsIcon color={color} />
    case SidebarRoute.Faq:
      return <FaqIcon color={color} />
    case SidebarRoute.Settings:
      return <SettingsIcon color={color} />
    default:
      return <AdsIcon color={color} />
  }
}
