import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { Autocomplete, Checkbox, FormControl, TextField } from "@mui/material"
import { styled } from "@mui/system"
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react"

import {
  extractSubCategories,
  getCategoryIds,
  getUniqueCategoryOptions
} from "./actions"
import { fetchCategories } from "../../../api/searchBrand/searchBrand"
import { Category, SubCategory } from "../../../api/searchBrand/types"

const CheckboxContainer = styled("span")({
  position: "absolute",
  top: "6px",
  left: "calc(100% - 62px)"
})

const GroupHeader = styled("div")(() => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  fontFamily: "Poppins",
  fontWeight: "bolder",
  color: "#6E788A",
  backgroundColor: "rgba(59, 78, 223, 0.1)",
}))

const GroupItems = styled("ul")({
  padding: 0,
  fontFamily: "Poppins !important"
})

type CategoryProps = {
  resetCounter: number
  updateCategory: (categoryIDs: number[]) => void
  updateSubCategory: (subCategoryIds: number[]) => void
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function Categories({ resetCounter, updateCategory, updateSubCategory }: CategoryProps) {
  const [categories, setCategories] = useState<Category[]>([])
  const [subCategories, setSubCategories] = useState<SubCategory[]>([])
  const [categoryChecked, setCategoryChecked] = useState<Record<string, unknown>>({})
  const [dropDownValue, setdropDownValue] = useState<SubCategory[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const loadCategories = async () => {
      setLoading(true)
      const response = await fetchCategories()
      setLoading(false)
      if (response) {
        setCategories(response)
        const categoryOptions = extractSubCategories(response)
        setSubCategories(categoryOptions)
      }
    }
    loadCategories()
  }, [])

  useEffect(() => {
    setdropDownValue([])
    setCategoryChecked({})
  }, [resetCounter])

  const handleDropdownSelect = (e: SyntheticEvent, value: SubCategory[]) => {
    const subCategoryIds = value.map((subCategories) => subCategories.id)
    setdropDownValue(value)
    updateSubCategory(subCategoryIds)
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, categoryName: string) => {
    const prevState = categoryChecked
    const prevOptions = dropDownValue
    prevState[categoryName] = e.target.checked
    const category = categories.filter((item) => item.title == categoryName)
    if (e.target.checked === true) {
      const newChoices = [...prevOptions, ...category[0].sub_categories]
      setdropDownValue(newChoices)
      updateCategory(getCategoryIds(newChoices))
    } else {
      const newChoices = getUniqueCategoryOptions(category[0].sub_categories, prevOptions)
      setdropDownValue(newChoices)
      updateCategory(getCategoryIds(newChoices))
    }
    setCategoryChecked(prevState)
  }

  return (
    <FormControl className="countryAutoComplete">
      <Autocomplete
        multiple
        disableCloseOnSelect
        loading={loading}
        options={subCategories}
        onChange={handleDropdownSelect}
        value={dropDownValue}
        limitTags={3}
        groupBy={(option) => option.category as string}
        getOptionLabel={(option) => option.title}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label={dropDownValue.length === 0 && "ALL"} inputProps={{
            ...params.inputProps,
          }} />
        )}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>
              <strong>{params.group.toUpperCase()}</strong>
              <CheckboxContainer>
                <Checkbox
                  checked={categoryChecked[params.group] as boolean}
                  onChange={(e) => handleCheckboxChange(e, params.group)}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                />
              </CheckboxContainer>
            </GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
      />
    </FormControl>
  )
}