import { Table, TableBody, TableRow, useMediaQuery } from "@mui/material"
import { useState } from "react"

import {
  HeadCap,
  MonthlyPlanButton,
  PlanBox,
  PlanDescription,
  PlanFeature,
  PlanName,
  PriceTag,
  TickIcon,
  YearlyPlanButton,
} from "./planStyles"
import LoadingOverlay from "../LoadOverlay/LoadOverlay"
import { createCheckout, createPortalSession, PlanDetails } from "../../../api/plans"
import { MOBILE_SCREEN_SIZE, openLink } from "../../../utils"
//----------------------------------------------------------------------------------

enum Plan {
  grow = "Grow",
  launch = "Launch",
  scale = "Scale",
}

enum PlanType {
  monthly = "month",
  yearly = "year"
}

type Props = {
  buttonType: string
  plan: PlanDetails
  currentPlan: string
}

export default function PlanCard({ buttonType, currentPlan, plan }: Props) {
  const isMobileDevice = useMediaQuery(MOBILE_SCREEN_SIZE)
  const [loading, setLoading] = useState<boolean>(false)

  const getPriceId = (planType: string) => {
    const priceId = plan.prices.filter((type) => type.recurring.interval === planType)
    return priceId[0].id
  }

  const createCheckoutSession = async (planType: string) => {
    const priceId = getPriceId(planType)
    const payload = { price_id: priceId }
    setLoading(true)
    const response = await createCheckout(payload)
    setLoading(false)
    if (response) {
      openLink(isMobileDevice, response.payment_url)
    }
  }

  const openPortalSession = async () => {
    setLoading(true)
    const response = await createPortalSession()
    setLoading(false)
    if (response) {
      openLink(isMobileDevice, response.portal_url)
    }
  }

  const handleButtonClick = (planType: string) => {
    if (currentPlan) {
      openPortalSession()
    } else {
      createCheckoutSession(planType)
    }
  }

  const getAmountInDollars = (planType: string) => {
    const priceItem = plan.prices.filter((type) => type.recurring.interval === planType)
    return priceItem[0] && `$ ${priceItem[0].unit_amount / 100}`
  }

  const yearlyAmount = getAmountInDollars(PlanType.yearly)

  return (
    <PlanBox>
      <PlanName>{plan.name}</PlanName>
      {plan.name === Plan.grow &&
        <HeadCap>BEST VALUE</HeadCap>
      }
      <PlanDescription>{plan.metadata.product_desc}</PlanDescription>
      {currentPlan === plan.id ? (
        <MonthlyPlanButton
          currentplan="true"
          onClick={() => handleButtonClick(PlanType.monthly)}
        >
          Current Plan
        </MonthlyPlanButton>
      ) : (
        <MonthlyPlanButton
          onClick={() => handleButtonClick(PlanType.monthly)}
        >
          {buttonType}
        </MonthlyPlanButton>
      )}
      <PriceTag>
        <b>{getAmountInDollars(PlanType.monthly)}</b> {plan.metadata?.pricing_offer?.substring(4)}
      </PriceTag>
      {yearlyAmount
        && (
          <YearlyPlanButton onClick={() => handleButtonClick(PlanType.yearly)}>
            {yearlyAmount} Billed Annually
          </YearlyPlanButton>
        )}
      <hr />
      <Table>
        <TableBody>
          {plan.features.map((feature, index) => (
            <TableRow key={index}>
              <PlanFeature>
                <TickIcon />{feature}
              </PlanFeature>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <LoadingOverlay loading={loading} />
    </PlanBox>
  )
}