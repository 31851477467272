import SearchIcon from "@mui/icons-material/Search"
import {
  Autocomplete,
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { BrandList, fetchBrandList } from "../../../api/search"
import { MOBILE_SCREEN_SIZE, openLinkInNewTab } from "../../../utils"
import { colors } from "../../../utils/theme"

type Props = {
  onClose?: () => void
}

export default function Search({ onClose }: Props) {
  const history = useHistory()
  const isMobileView = useMediaQuery(MOBILE_SCREEN_SIZE)
  const [options, setOptions] = useState<BrandList[]>([])
  const [optionsLoading, setOptionsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")

  const filterOptions = (choices: BrandList[]) => {
    const uniqueSet: BrandList[] = []
    choices.forEach((value) => {
      if (!uniqueSet.find((v) => v.brandsite === value.brandsite)) {
        uniqueSet.push(value)
      }
    })
    return uniqueSet
  }

  useEffect(() => {
    const loadBrandList = async () => {
      setOptionsLoading(true)
      const response = await fetchBrandList()
      if (response) {
        const choice = filterOptions(response)
        setOptions(choice)
        setOptionsLoading(false)
      }
    }

    loadBrandList()
  }, [])

  const getBrandDomain = (url: string) => {
    return url.split("/")[2]?.replace("www.", "")
  }

  const isBrandMatching = (brand: BrandList, query: string) => {
    const lCaseQuery = query.toLowerCase()
    if (brand?.brandname?.toLowerCase()?.includes(lCaseQuery)
      || brand?.brandsite?.toLowerCase()?.includes(lCaseQuery)) {
      return true
    }
    return false
  }

  const handelEnterKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && searchTerm && searchTerm.length > 2) {
      history.push(
        "/search?" + new URLSearchParams({ q: searchTerm }).toString()
      )
    }
  }

  const handleSubmit = (_e: React.SyntheticEvent, value: BrandList | null) => {
    if (value) {
      const formattedValue = value.brandname.replace("-", "_")
      const url = `/brand/${formattedValue.replace(/ /g, "-")}/${value.brand_id
        }`
      openLinkInNewTab(url)
    }
  }

  const handleSearchTermChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as string
    setSearchTerm(value)
  }

  const getIconStyle = () => {
    if (isMobileView) {
      return { top: "-17px" }
    } else {
      return { top: "16px" }
    }
  }

  const getInputStyle = () => {
    if (isMobileView) {
      return { top: 15 }
    }
  }

  return (
    <Autocomplete
      options={options}
      loading={optionsLoading}
      onChange={handleSubmit}
      onClose={onClose}
      getOptionLabel={(option) =>
        option?.brandsite?.toLowerCase()?.includes(searchTerm.toLowerCase())
          ? option?.brandsite
          : option?.brandname
      }
      id="brand-autocomplete"
      isOptionEqualToValue={(option, value) => option?.brandname === value?.brandname}
      filterOptions={(options, state) => options.filter((option) => isBrandMatching(option, state.inputValue))}
      renderOption={(props, option) => (
        <Box
          sx={{
            "& svg": {
              mr: "10px",
              ml: "5px",
              position: "relative"
            },
            "& span": {
              width: "calc(100% - 50px)",
              verticalAlign: "top",
              fontFamily: "Poppins, sans-serif !important",
              fontWeight: "bold",
              color: colors.Text,
            },
            "& p": {
              width: "calc(100% - 50px)",
              ml: "30px",
              mt: "0px",
              fontFamily: "Poppins, sans-serif !important",
              fontWeight: "normal",
              color: colors.Text,
            },
          }}
          key={option?.brand_id}
          component="li"
          {...props}
        >
          <SearchIcon color="disabled" fontSize="small" />
          <span>{option?.brandname}</span>
          <p>{option?.brandsite ? getBrandDomain(option.brandsite) : ""}</p>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none"
            },
          }}
          value={searchTerm}
          onChange={handleSearchTermChange}
          onKeyDown={handelEnterKeyPress}
          label="Search Brands or Products"
          InputProps={{
            ...params.InputProps,
            autoComplete: "new-password",
            startAdornment: <InputAdornment position="end" />,
            endAdornment: (
              <>
                {optionsLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
                <SearchIcon className="searchBtn" style={getIconStyle()} />
              </>
            ),
          }}
          size="small"
          style={getInputStyle()}
        />
      )}
    />
  )
}
