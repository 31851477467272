import { Box, Grid, Paper, Typography } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"

import { RootStore } from "../../../Redux/store"
import { FbAdsReview } from "../common"
import GeoMap from "./GeoMap/GeoMap"
import { colors } from "../../../utils/theme"

export default function BrandAdsCountry(): React.ReactElement {
  const FbAdsReview: FbAdsReview = useSelector(
    (state: RootStore) => state.PageReduser["FbAdsReview"]
  )
  const [adCounts, setAdCounts] = React.useState<number[]>([])

  React.useEffect(() => {
    if (FbAdsReview.fb_ads_country) {
      const adCountList = FbAdsReview.fb_ads_country.map((country) =>
        country.ad_country !== "Others" ? country.ads_count : 0
      )
      adCountList.sort((a, b) => a - b).shift()
      setAdCounts(adCountList)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid item xs={12} md={3} className="shbox">
        {FbAdsReview.fb_ads_country ? (
          <Paper
            sx={(theme) => ({
              pt: theme.spacing(1),
              pb: theme.spacing(1),
              pl: theme.spacing(2),
              pr: theme.spacing(2),
              backgroundColor: "#F8FAFC",
              boxShadow: "none",
              mt: theme.spacing(0),
            })}
          >
            <Typography variant="h6">Ads - Country Targeting</Typography>
            {FbAdsReview.fb_ads_country.map((data, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  "& > :last-child": {
                    color: colors.Text,
                  },
                  "& > h6": {
                    fontSize: "16px",
                    lineHeight: "2",
                  },
                }}
              >
                <Typography variant="h6">{data.ad_country}</Typography>
                <Typography variant="h6">{data.ads_count}</Typography>
              </Box>
            ))}
          </Paper>
        ) : (
          ""
        )}
      </Grid>
      {FbAdsReview.fb_ads_country ? (
        <Grid
          item
          xs={12}
          md={5.5}
          sx={{
            height: "294px",
            pl: "16px!important",
            pt: "0px!important",
            maxWidth: "calc(66.66% - 30px)",
            background: " #FFFFFF!important",
            boxShadow: " 0px 5px 20px 2px rgba(23, 3, 89, 0.08)!important",
            borderRadius: "10px!important",
          }}
        >
          <GeoMap adCounts={adCounts} countries={FbAdsReview.fb_ads_country} />
        </Grid>
      ) : (
        ""
      )}
    </>
  )
}
