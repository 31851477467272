import { Box, Stack } from "@mui/material"

import VideoPlayer from "../VideoPlayer/videoPlayer"
import { TrendingAd } from "../../../api/trendingAds"

const DEFAULT_BODY_HEIGHT = "258px"

export const AdBody = ({
  ad,
  bodyHeight,
}: {
  ad: TrendingAd
  bodyHeight?: string
}) => {
  return (
    <AdCardBody
      adId={ad.id}
      bodyHeight={bodyHeight}
      imageUrl={ad.ad_image}
      videoUrl={ad.ad_video}
    />
  )
}

const AdCardBody = ({
  adId,
  imageUrl,
  videoUrl,
  bodyHeight,
}: {
  adId: string
  imageUrl?: string | null
  videoUrl?: string | null
  bodyHeight?: string
}) => {
  if (imageUrl && imageUrl != "N/A") {
    return (
      <Stack spacing={2}>
        <AdImage
          adId={adId}
          imageUrlString={imageUrl}
          bodyHeight={bodyHeight}
        />
      </Stack>
    )
  }

  if (videoUrl) {
    return (
      <Stack spacing={2}>
        <AdVideo videoUrlString={videoUrl} bodyHeight={bodyHeight} />
      </Stack>
    )
  }

  return null
}

const AdImage = ({
  adId,
  imageUrlString,
  bodyHeight,
}: {
  adId: string
  imageUrlString?: string | null
  bodyHeight?: string
}) => {
  if (!imageUrlString || imageUrlString === "N/A") {
    return null
  }
  return (
    <img
      src={imageUrlString}
      alt={`image for ${adId}`}
      style={{
        backgroundColor: "#F2F2F2",
        marginTop: "12px",
        width: "100%",
        height: bodyHeight || DEFAULT_BODY_HEIGHT,
        display: "inline-block",
        objectFit: "contain",
      }}
    />
  )
}

const AdVideo = ({
  videoUrlString,
  bodyHeight,
}: {
  videoUrlString: string
  bodyHeight?: string
}) => {
  return (
    <Box
      sx={{
        marginTop: "12px",
        width: "100%",
        height: bodyHeight || DEFAULT_BODY_HEIGHT,
        display: "inline-block",
      }}
    >
      <VideoPlayer sourceUrl={videoUrlString} />
    </Box>
  )
}