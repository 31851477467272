import { fetchApiCall, postApiCall } from ".."
import { CollectionResult } from "../common"

export type Board = {
  id: number
  user_id: string
  title: string
  description?: string | null
}

export const fetchMyBoards = async (page = 1, pageSize = 20) => {
  const endpoint = `api/creative/board?page=${page}&pageSize=${pageSize}`

  const result = await fetchApiCall(endpoint)
  return result as CollectionResult<Board>
}

export type AddBoardInput = {
  title: string
  description?: string
}

export const addNewBoard = async (input: AddBoardInput) => {
  const endpoint = "api/creative/board/"

  const result = await postApiCall(endpoint, input)
  return result as Board
}
