import InfoIcon from "@mui/icons-material/Info"
import { List, ListItem, ListItemText, Tooltip } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { TrafficBriefData } from "../common"
import { fetchRevenueData, RevenueData } from "../../../api/brand"
import { LockButton } from "../../../components/Buttons/lockButton"
import { RootStore } from "../../../Redux/store"
import { getExactBrandName } from "../../../utils/textHelpers"


type Params = {
  name: string
  brandId: string
}

export default function RevenueCard(): React.ReactElement {
  const params: Params = useParams()
  const trafficBriefData: TrafficBriefData = useSelector(((state: RootStore) => state.PageReduser["trafficBriefData"]))

  const [revenueData, setRevenueData] = useState<RevenueData>()

  useEffect(() => {
    const loadRevenue = async () => {
      const brandName = getExactBrandName(params.name)
      const response = await fetchRevenueData(brandName)

      if (response) {
        setRevenueData(response)
      }
    }

    loadRevenue()
  }, [params.name])

  const helpText = (name: string) => {
    return (
      `We are currently working on a process to model data to estimate ${name} for stores
        - this statistic is currently an approximation.`
    )
  }
  return (
    <>
      <List className="StoreDataList">
        <ListItem>
          Estimated Monthly Traffic
          <Tooltip title={helpText("traffic")} arrow><InfoIcon color="info" /></Tooltip>
          <ListItemText>{trafficBriefData.estimated_monthly_traffic?.toLocaleString() || "-"}</ListItemText>
        </ListItem>
        <ListItem>
          Average Product Price
          <ListItemText>{trafficBriefData.average_product_price?.toLocaleString() || "-"}</ListItemText>
        </ListItem>
        <ListItem>
          Estimated Monthly Orders
          <Tooltip style={{ marginLeft: "5px" }} title={helpText("orders")} arrow><InfoIcon color="info" /></Tooltip>
          <ListItemText>{trafficBriefData.estimated_monthly_orders?.toLocaleString() || "-"}</ListItemText>
        </ListItem>
        <ListItem>
          Estimated Monthly Revenue
          <Tooltip style={{ marginLeft: "5px" }} title={helpText("revenue")} arrow><InfoIcon color="info" /></Tooltip>

          {revenueData ? (
            <ListItemText>{revenueData.estimated_monthly_revenue}</ListItemText>
          ) : (
            <LockButton
              name="revenue estimates"
              sx={{
                mt: "18px",
              }}
            />
          )}
        </ListItem>
      </List>
    </>
  )
}
