import { fetchApiCall } from "."
import { BrandTableRowData } from "../V1/search-brand/types"

export type FeaturedBrandResult = {
  count: number
  next: string | null
  previous: string | null
  results: BrandTableRowData[]
}

export const fetchTrendingBrands = async (page = 1, pageSize = 20) => {
  const endpoint = `api/featured-brands?page=${page}&page_size=${pageSize}`

  return fetchApiCall<FeaturedBrandResult>(endpoint)
}
