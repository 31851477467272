import { Box, Button, Paper, Stack, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { PinnedAd, getPublicAd } from "../../../api/creativeCenter/pinnedAds"
import v1logo from "../../../assets/images/Logo/LogoFull.svg"
import { colors } from "../../../utils/theme"
import LoadingOverlay from "../../Components/LoadOverlay/LoadOverlay"
import { DesktopPinnedAdDetail } from "./DesktopView"
import { MobilePinnedAdDetail } from "./MobileView"

export default function PublicAd(): React.ReactElement {
  const { token } = useParams<{ token?: string }>()

  const [pinnedAd, setPinnedAd] = useState<PinnedAd>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchPinedAd = async () => {
      if (!token) {
        return
      }
      const result = await getPublicAd(token)
      setPinnedAd(result)
      setLoading(false)
    }
    fetchPinedAd()
  }, [token])

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.Primary100,
      }}
    >
      <TopBar />
      {loading && <LoadingOverlay loading={loading} />}
      <Box
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {pinnedAd && <DesktopPinnedAdDetail pinnedAd={pinnedAd} />}
      </Box>
      <Box
        sx={{
          display: {
            xs: "flex",
            md: "none",
          },
          p: 2,
          justifyContent: "center",
        }}
      >
        {pinnedAd && <MobilePinnedAdDetail pinnedAd={pinnedAd} />}
      </Box>
    </Paper>
  )
}

const TopBar = () => {
  return (
    <Box
      sx={{
        backgroundColor: colors.White,
        height: "100px",
        py: { xs: 2, md: 0 },
        paddingX: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <img
        src={v1logo}
        alt="Trend-Rocket-Logo"
        style={{
          width: "158px",
        }}
      />
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography
          variant="body1"
          sx={{ display: { xs: "none", md: "block" } }}
        >
          Save ad references in seconds!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            paddingY: "18px",
            minWidth: "164px",
          }}
          onClick={() => {
            window.open("https://www.trendrocket.io/#pricing", "_blank")
          }}
        >
          Try Trend Rocket
        </Button>
      </Stack>
    </Box>
  )
}
