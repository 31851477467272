export const Regions = [
  "North America",
  "Europe",
  "Australia, Asia & Pacific",
  "South/Latin America",
  "Africa",
  "Middle east",
  "Arab States",
  "South/Central America",
]
