import { useSelector } from "react-redux"

import { RootStore } from "../../../Redux/store"
import { BrandItem, TrafficBriefData, ReviewTracker } from "../common"


export default function GetChartLabel(name: string): string { // function to get the chart label(change and percentage).
  const brandItem: BrandItem = useSelector(((state: RootStore) => state.PageReduser["brandItem"]))
  const trafficBriefData: TrafficBriefData = useSelector(((state: RootStore) => state.PageReduser["trafficBriefData"]))
  const ReviewTracker: ReviewTracker = useSelector(((state: RootStore) => state.PageReduser["ReviewTracker"]))
    
  function getLabelChange(value: number): string { // determines whether value is positive or negative.
    if (value > 0) {
      return "+"
    } else if (value < 0) {
      return "-"
    } else {
      return ""
    }
  }

  const label: { percentage: string, change: string } = { percentage: "", change: "" }

  switch (name) { // returns corresponding change and percentage based on param.
    case "Website traffic": {
      label.percentage = trafficBriefData?.relative_change ? trafficBriefData.relative_change.toString() : ""
      label.change = trafficBriefData?.relative_change ? (trafficBriefData.relative_change > 0 ? "+" : "") : ""
    }
      break
    case "Facebook likes": {
      label.percentage = brandItem?.scaling_score_percentage != null ? brandItem.scaling_score_percentage.toString().replace("-", "") : "0"
      label.change = brandItem?.scaling_score_percentage != null ? getLabelChange(brandItem.scaling_score_percentage) : ""
    }
      break
    case "Instagram followers": {
      label.percentage = brandItem?.growth_percentage?.instafollowers != null ? brandItem.growth_percentage.instafollowers.toString().replace("-", "") : "0"
      label.change = brandItem?.growth_percentage?.instafollowers != null ? getLabelChange(brandItem.growth_percentage.instafollowers) : ""
    }
      break
    case "TrustPilot reviews": {
      label.percentage = brandItem?.growth_percentage?.numberreviews != null ? brandItem.growth_percentage.numberreviews.toString().replace("-", "") : "0"
      label.change = brandItem?.growth_percentage?.numberreviews != null ? getLabelChange(brandItem.growth_percentage.numberreviews) : ""
    }
      break
    case "Reddit mentions": {
      label.percentage = brandItem?.growth_percentage?.reditmentions != null ? brandItem.growth_percentage.reditmentions.toString().replace("-", "") : "0"
      label.change = brandItem?.growth_percentage?.reditmentions != null ? getLabelChange(brandItem.growth_percentage.reditmentions) : ""
    }
      break
    case "Ad counts": {
      label.percentage = brandItem?.growth_percentage?.fb_ads != null ? brandItem.growth_percentage.fb_ads.toString().replace("-", "") : "0"
      label.change = brandItem?.growth_percentage?.fb_ads != null ? getLabelChange(brandItem.growth_percentage.fb_ads) : ""
    }
      break
    case "Facebook reviews": {
      label.percentage = ReviewTracker?.velocity_tracker?.facebook?.review_count != null ? ReviewTracker?.velocity_tracker?.facebook?.review_count.toString().replace("-", "") : "0"
      label.change = ReviewTracker?.velocity_tracker?.facebook?.review_count != null ? getLabelChange(ReviewTracker?.velocity_tracker?.facebook?.review_count) : ""
    }
  }
  const changePercent = (name === "Facebook likes" || name === "Instagram followers")
    ? `${label.change}${label.percentage}%`
    : `${label.change}${label.percentage}`

  return changePercent
}