import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import AlibabaIcon from "../../../assets/images/icon_alibaba.png"
import AliExpressIcon from "../../../assets/images/v1-icons/aliexpress.png"
import { RootStore } from "../../../Redux/store"
import ErrorMessage from "../../Components/ErrorMessage/ErrorMessage"
import LoadingOverlay from "../../Components/LoadOverlay/LoadOverlay"
import { BrandItem } from "../common"
import Pagination from "../../Components/Pagination/pagination"
import PriceRange from "../components/PriceRange"
import ProductAnalysis from "../components/ProductAnalysis"
import { featureProduct, fetchProducts, Product } from "../../../api/products"
import usePagination from "../../Hooks/usePagination"
import {
  handleAlibabaIconClick,
  handleAliExpressIconClick,
} from "../../search-results/actions"
import { colors } from "../../../utils/theme"
import { useLayout } from "../../Hooks/useLayoutContext"
import { checkIsSuperUser } from "../../../utils/localStorage"
import { scrollToTop } from "../../../utils"
//-----------------------------------------------------------------------------------------------------------

type Params = {
  name: string
  brandId: string
}

const ProductName = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
})

export default function ProductsTab() {
  const params: Params = useParams()
  const isSuperUser = checkIsSuperUser()
  const { menuIsExpanded } = useLayout()

  const brandItem: BrandItem = useSelector(
    (state: RootStore) => state.PageReduser["brandItem"]
  )

  const {
    currentPage,
    pageSize,
    resultCount,
    setResultCount,
    setCurrentPage,
    setPageSize,
  } = usePagination()

  const [productsDropdownValue, setProductsDropdownValue] =
    useState<string>("all")
  const [hoveredProductIndex, setHoveredProductIndex] = useState<number | null>(
    null
  )
  const [products, setProducts] = useState<Product[]>([])
  const [featuredProductCounter, setFeaturedProductCounter] = useState(0)
  const [message, setMessage] = useState<string>("")
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const loadProducts = async () => {
      setLoading(true)
      const response = await fetchProducts(
        brandItem.brandname,
        currentPage,
        pageSize,
        productsDropdownValue === "all" ? null : productsDropdownValue
      )
      setLoading(false)
      if (response) {
        setProducts(response.results)
        setResultCount(response.count)
      }
    }

    loadProducts()
    if (resultCount !== 0) {
      scrollToTop(1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productsDropdownValue,
    brandItem.brandname,
    featuredProductCounter,
    currentPage,
    pageSize,
    setResultCount,
  ])

  const handleFeatureProduct = async (product: Product) => {
    const response = await featureProduct(
      parseInt(params.brandId),
      product.shopify_product_id
    )
    if (response) {
      setFeaturedProductCounter(featuredProductCounter + 1)
      setMessage(response.message)
      setModalOpen(true)
    }
  }

  function handleMouseEnteredProduct(index: number) {
    setHoveredProductIndex(index)
  }

  function handleMouseLeftProduct() {
    setHoveredProductIndex(null)
  }

  function handleProductsDropdownChanged(event: SelectChangeEvent<string>) {
    setProductsDropdownValue(event.target.value)
  }

  return (
    <>
      <Grid
        container
        columnSpacing={2}
        rowSpacing={2}
        sx={{
          mt: (theme) => theme.spacing(2),
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{
              color: colors.Text,
              fontWeight: 500,
              fontSize: "30px",
              lineHeight: "45px",
              fontFamily: "Poppins, sans-serif !important",
              mb: "5px",
              "& span": {
                color: (theme) => theme.palette.text.secondary,
              },
            }}
          >
            Product and Prices
          </Typography>
        </Grid>
        <ProductAnalysis />
        <PriceRange />
        <Grid
          item
          xs={12}
          sx={{
            mt: "30px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: colors.Text,
              fontWeight: 500,
              fontSize: "30px",
              lineHeight: "45px",
              fontFamily: "Poppins, sans-serif !important",
              mb: "5px",
              "& span": {
                color: (theme) => theme.palette.text.secondary,
              },
            }}
          >
            Products in Store <span>{resultCount}</span>
          </Typography>
          <FormControl
            variant="outlined"
            size="small"
            sx={{
              float: "right",
              position: "relative",
              top: "-40px",
            }}
          >
            <Select
              value={productsDropdownValue}
              onChange={handleProductsDropdownChanged}
              className="productDpdwn"
            >
              <MenuItem value="all">All products</MenuItem>
              <MenuItem value="-productrank">Best seller</MenuItem>
              <MenuItem value="-timestamp">Recently Added</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <LoadingOverlay loading={loading} />
        {products &&
          products.map((product, index) => (
            <Grid
              item
              key={product.shopify_product_id}
              xs={12}
              md={menuIsExpanded ? 6 : 4}
              lg={menuIsExpanded ? 4 : 3}
              xl={3}
              sx={{
                position: "relative",
                alignItems: "center",
              }}
            >
              <Paper
                elevation={4}
                onMouseEnter={() => handleMouseEnteredProduct(index)}
                onMouseLeave={handleMouseLeftProduct}
                sx={(theme) => ({
                  boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08)",
                  borderRadius: "10px",
                  display: "block",
                  pl: theme.spacing(2),
                  pr: theme.spacing(2),
                  pt: theme.spacing(1.25),
                  pb: theme.spacing(1.25),
                  minHeight: "460px",
                  "& .MuiAvatar-root": {
                    width: "100%",
                    height: "100%",
                  },
                })}
              >
                {hoveredProductIndex === index && (
                  <>
                    <Avatar
                      sx={{
                        height: "30px !important",
                        right: "-6px",
                        top: "-4px",
                        cursor: "pointer",
                        "& .MuiAvatar-img": {
                          position: "absolute",
                          right: "30px",
                          width: "40px !important",
                          height: "auto !important",
                        },
                      }}
                      src={AlibabaIcon}
                      onClick={() => handleAlibabaIconClick(product.name)}
                    />
                    <Avatar
                      sx={{
                        position: "absolute",
                        width: "30px !important",
                        height: "30px !important",
                        right: "10px",
                        top: "21px",
                        cursor: "pointer",
                        "& .MuiAvatar-img": {
                          width: "28px !important",
                          right: "0px",
                          position: "absolute",
                          height: "auto !important",
                        },
                      }}
                      src={AliExpressIcon}
                      onClick={() => handleAliExpressIconClick(product.name)}
                    />
                  </>
                )}
                <Avatar
                  sx={{
                    height: "240px",
                    borderRadius: "5px",
                    mt: "20px",
                  }}
                  variant="square"
                  src={product.imageurl || "/images/product_placeholder.jpg"}
                />
                <Box
                  sx={{
                    display: "inline-block",
                    width: "65%",
                    mt: "20px",
                    "& .MuiTypography-root": {
                      fontWeight: 500,
                      fontSize: "18px",
                      lineHeight: "27px",
                      color: colors.Text,
                      fontFamily: "Poppins, sans-serif !important",
                      height: "auto",
                      mb: "5px !important",
                    },
                  }}
                >
                  <ProductName variant="subtitle2">{product.name}</ProductName>
                  <span>
                    {" "}
                    <Link
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 0,
                        textDecoration: "none",
                        ":hover": {
                          textDecoration: "underline",
                        },
                      }}
                      href={product.url}
                      referrerPolicy="no-referrer"
                      target="_blank"
                    >
                      {brandItem.brandname}
                    </Link>
                  </span>
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 500,
                    fontSize: "30px",
                    lineHeight: "45px",
                    fontFamily: "Poppins, sans-serif !important",
                    color: colors.Secondary,
                    display: "inline-block",
                    width: "35%",
                    textAlign: "right",
                    "& span": {
                      marginLeft: (theme) => theme.spacing(2),
                      textDecoration: "line-through",
                      fontSize: "90%",
                    },
                  }}
                >
                  ${product.price_usd || product.price}
                </Typography>
                {isSuperUser && hoveredProductIndex === index ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      sx={{
                        mb: "15px",
                        height: "25px !important",
                        fontSize: "14px !important",
                      }}
                      size="small"
                      color="primary"
                      onClick={() => {
                        handleFeatureProduct(product)
                      }}
                    >
                      {product.is_featured
                        ? "Featured Product"
                        : "Feature this Product"}
                    </Button>
                  </Box>
                ) : (
                  ""
                )}
              </Paper>
            </Grid>
          ))}
        <ErrorMessage
          open={modalOpen}
          close={() => setModalOpen(false)}
          apiMessage={message}
        />
      </Grid>

      {products.length > 0 && (
        <Box sx={{ mt: "30px" }}>
          <Pagination
            currentPage={currentPage}
            resultCount={resultCount}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onPageSizeChange={setPageSize}
            label={"Products"}
          />
        </Box>
      )}
      {resultCount === 0 && (
        <Typography variant="h6">
          Loading Products for the selected store - Please wait
        </Typography>
      )}
    </>
  )
}
