export const MessengerMono = ({
  width,
  height,
  color,
}: {
  width?: string
  height?: string
  color?: string
}) => {
  return (
    <svg
      width={width || 15}
      height={height || 15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.51611 0.234375C3.41367 0.234375 0.234375 3.23262 0.234375 7.28232C0.234375 9.40049 1.10479 11.231 2.52158 12.4954C2.76621 12.7154 2.71582 12.8429 2.75742 14.2014C2.7607 14.2965 2.78721 14.3894 2.83463 14.472C2.88206 14.5546 2.94897 14.6243 3.02952 14.675C3.11007 14.7258 3.20182 14.7561 3.29677 14.7632C3.39171 14.7704 3.48696 14.7542 3.57422 14.7161C5.12432 14.0335 5.14424 13.9796 5.40703 14.0511C9.89795 15.2871 14.7656 12.4131 14.7656 7.28232C14.7656 3.23262 11.6188 0.234375 7.51611 0.234375ZM11.8884 5.65811L9.74971 9.04395C9.66895 9.17126 9.56276 9.28053 9.4378 9.36489C9.31284 9.44925 9.1718 9.5069 9.02352 9.53421C8.87524 9.56152 8.72292 9.55791 8.57611 9.52361C8.42929 9.48931 8.29114 9.42505 8.17031 9.33486L6.46875 8.06133C6.39268 8.00428 6.30016 7.97344 6.20508 7.97344C6.10999 7.97344 6.01747 8.00428 5.94141 8.06133L3.64541 9.80273C3.33896 10.0351 2.9376 9.66797 3.14414 9.34365L5.28281 5.95781C5.36354 5.83046 5.46972 5.72116 5.59468 5.63677C5.71964 5.55239 5.86068 5.49473 6.00897 5.46741C6.15726 5.4401 6.3096 5.44372 6.45642 5.47804C6.60325 5.51237 6.7414 5.57666 6.86221 5.66689L8.56318 6.94014C8.63925 6.99719 8.73177 7.02803 8.82686 7.02803C8.92194 7.02803 9.01446 6.99719 9.09053 6.94014L11.3877 5.20049C11.6936 4.9667 12.0949 5.3335 11.8884 5.65811Z"
        fill={color || "#3D4465"}
      />
    </svg>
  )
}
