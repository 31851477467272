import { Box, Grid, styled, Typography } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import EditIcon from "@mui/icons-material/Edit"
import { fetchOverviewCharts, OverviewCharts } from "../../../api/brand"
import Actions from "../../../pages/Actions/PageActions"
import { RootStore } from "../../../Redux/store"
import { getAbsoluteURL } from "../../../utils"
import EditSection from "../../Components/EditSection/EditSection"
import LoadingOverlay from "../../Components/LoadOverlay/LoadOverlay"
import GetChartCount from "../chartValues/GetChartCount"
import GetChartLabel from "../chartValues/GetChartLabel"
import { BrandItem, ReviewTracker, SocialCharts, SocialData } from "../common"
import BrandAdsCountry from "../components/BrandAdsCountry"
import BrandInfo from "../components/BrandInfo"
import FacebookAds from "../components/FbAds"
import GoogleAds from "../components/GoogleAds"
import Keywords from "../components/Keywords"
import LineChartContainer from "../components/LineChartContainer"
import PaidAds from "../components/PaidAds"
import PriceRange from "../components/PriceRange"
import ProductAnalysis from "../components/ProductAnalysis"
import ProductsBestSeller from "../components/ProductsBestseller"
import RevenueCard from "../components/RevenueCard"
import TrafficChart from "../components/TrafficChart"
import { colors } from "../../../utils/theme"

const Heading = styled(Typography)({
  fontWeight: "bold",
  fontSize: "28px",
  width: "100%",
  paddingTop: "0px",
  marginTop: "0px",
  "& span": {
    color: colors.Text,
  },
  "& svg": {
    top: "2px",
    marginLeft: "3px",
    position: "relative",
    cursor: "pointer",
  },
})

const SubHeading = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
  marginTop: "22px",
  marginBottom: "12px",
})

type ReportHeaders = {
  viability_description: string
  hot_market_trend: string
  trend_viability_title: string | null
  trend_viability_text: string | null
  [index: string]: any
}

type Params = {
  name: string
  brandId: string
}

const initialReportHeaders = {
  viability_description: "",
  hot_market_trend: "",
  trend_viability_title: "",
  trend_viability_text: "",
}

export default function ReportsTab(): React.ReactElement {
  const brandItem: BrandItem = useSelector(
    (state: RootStore) => state.PageReduser["brandItem"]
  )
  const ReviewTracker: ReviewTracker = useSelector(
    (state: RootStore) => state.PageReduser["ReviewTracker"]
  )
  const socialChartsData: SocialCharts = useSelector(
    (state: RootStore) => state.PageReduser["socialCharts"]
  )
  const socialDataCount: SocialData = useSelector(
    (state: RootStore) => state.PageReduser["socialDataCount"]
  )
  const featureKey: number = useSelector(
    (state: RootStore) => state.PageReduser["featureKey"]
  )
  const dispatch = useDispatch()
  const params: Params = useParams()

  const token = localStorage.getItem("token")
  const isSuperUser = localStorage.getItem("is_superuser")

  const [modalOpen, setModalOpen] = useState(false)
  const [editSectionHeader, setEditSectionHeader] = useState("")
  const [publishButtonLabel, setPublishButtonLabel] = useState(
    brandItem.report_published ? "Published" : "Publish Report"
  )
  const [reportHeadersFormData, setReportHeadersFormData] =
    useState<ReportHeaders>(initialReportHeaders)
  const [charts, setCharts] = useState<OverviewCharts>()
  const [loading, setLoading] = useState(false)

  const fbLikes = {
    change: GetChartLabel("Facebook likes"),
    count: GetChartCount("Facebook likes", charts),
  }
  const fbReviews = {
    change: GetChartLabel("Facebook reviews"),
    count: socialDataCount?.fbreviews?.toString(),
  }
  const instaFollowers = {
    change: GetChartLabel("Instagram followers"),
    count: GetChartCount("Instagram followers", charts),
  }
  const trustpilotReviews = {
    change: GetChartLabel("TrustPilot reviews"),
    count: socialDataCount?.numberreviews?.toString(),
  }

  const createMarkup = (content: string) => {
    return { __html: content }
  }

  const getChartData = () => {
    const datalist: { x: string; y: number }[] = []
    ReviewTracker?.reviews?.facebook?.forEach((datePair) => {
      Object.keys(datePair).forEach((date) => {
        datalist.push({ x: new Date(date).toUTCString(), y: datePair[date] })
      })
    })
    return datalist
  }

  useEffect(() => {
    const loadCharts = async () => {
      setLoading(true)
      const response = await fetchOverviewCharts(params.brandId)
      setLoading(false)
      if (response) {
        setCharts(response)
      }
    }

    loadCharts()
  }, [params.brandId])

  useEffect(() => {
    dispatch(Actions.GetReviewTracker(params.brandId))
    const getViabilityData = () => {
      axios
        .get(getAbsoluteURL(`api/report/viability-report/${params.brandId}/`), {
          headers: { Authorization: `Token ${token as string}` },
        })
        .then((response) => {
          const data = response.data as { data: ReportHeaders }
          setReportHeadersFormData(data.data)
        })
        .catch((error) => console.log(error))
    }
    getViabilityData()
  }, [brandItem.brandname, dispatch, params.brandId, token])

  const handleEditPencilClick = (fieldName: string) => {
    setEditSectionHeader(fieldName)
    setModalOpen(true)
  }

  const getTextEditorContent = (content: string, name: string) => {
    const formData = reportHeadersFormData
    formData[name] = content
    setReportHeadersFormData(formData)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = reportHeadersFormData
    formData[event.target.name] = event.target.value
    setReportHeadersFormData(formData)
  }

  const handlePublish = () => {
    dispatch({ type: "Loading", payload: true })
    axios({
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token as string}`,
      },
      url: getAbsoluteURL(
        `api/report/toggle-report-published/${brandItem.brand_id}/`
      ),
    })
      .then((response) => {
        const data = response.data as { report_published: boolean }
        dispatch({ type: "BrandItem", payload: { ...brandItem, ...data } })
        setPublishButtonLabel(
          data.report_published ? "Published" : "Publish Report"
        )
        dispatch({ type: "Loading", payload: false })
      })
      .catch((error) => {
        console.log(error)
        dispatch({ type: "Loading", payload: false })
      })
  }

  const handleSave = () => {
    dispatch({ type: "Loading", payload: true })
    axios
      .patch(
        getAbsoluteURL(`api/update-brand/${brandItem.brand_id}/`),
        reportHeadersFormData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token as string}`,
          },
        }
      )
      .then((response) => {
        const data = response.data as { data: ReportHeaders; message: string }
        setReportHeadersFormData(data.data)
        setModalOpen(false)
        dispatch({ type: "Loading", payload: false })
      })
      .catch((error) => {
        console.log(error)
        dispatch({ type: "Loading", payload: false })
      })
  }

  return (
    <Box className="reportTab">
      <LoadingOverlay loading={loading} />

      {isSuperUser ? (
        <button className="pbRptBtn btn btn-primary" onClick={handlePublish}>
          {publishButtonLabel}
        </button>
      ) : (
        ""
      )}

      {/* the editable content modal. */}
      <EditSection
        modalOpen={modalOpen}
        modalClose={() => setModalOpen(false)}
        heading={editSectionHeader}
        defaultData={reportHeadersFormData}
        handleChange={handleChange}
        handleTextEditor={getTextEditorContent}
        handleSave={handleSave}
      />

      <Grid container columnGap={2} rowGap={2}>
        <BrandInfo isReport />
        <BrandAdsCountry />
      </Grid>

      <Box
        alignItems="center"
        justifyContent="space-between"
        className="ProductChartHead"
      >
        <SubHeading variant="h6">
          About the Brand
          {isSuperUser ? (
            <span className="editIcon">
              <EditIcon
                color="primary"
                onClick={() => handleEditPencilClick("About the Brand")}
              />
            </span>
          ) : (
            ""
          )}
        </SubHeading>
      </Box>
      <div
        dangerouslySetInnerHTML={createMarkup(
          reportHeadersFormData.viability_description
        )}
      />

      <Box
        alignItems="center"
        justifyContent="space-between"
        className="ProductChartHead"
      >
        <SubHeading variant="h6">
          Trend Viability - Hot Market trends
          {isSuperUser ? (
            <span className="editIcon">
              <EditIcon
                color="primary"
                onClick={() =>
                  handleEditPencilClick("Trend Viability - Hot Market trends")
                }
              />
            </span>
          ) : (
            ""
          )}
        </SubHeading>
      </Box>
      <div
        dangerouslySetInnerHTML={createMarkup(
          reportHeadersFormData.hot_market_trend
        )}
      />

      <Box
        alignItems="center"
        justifyContent="space-between"
        className="ProductChartHead"
      >
        <SubHeading variant="h6">
          {`Trend Viability - ${
            reportHeadersFormData.trend_viability_title as string
          }`}
          {isSuperUser ? (
            <span className="editIcon">
              <EditIcon
                color="primary"
                onClick={() => handleEditPencilClick("Trend Viability -")}
              />
            </span>
          ) : (
            ""
          )}
        </SubHeading>
      </Box>
      <div
        dangerouslySetInnerHTML={createMarkup(
          reportHeadersFormData.trend_viability_text as string
        )}
      />
      {/* social charts column */}
      <Box
        alignItems="center"
        justifyContent="space-between"
        className="ProductChartHead"
      >
        <Heading variant="h5">Social Metrics and Reviews</Heading>
      </Box>
      {((charts?.facebook_likes && charts.facebook_likes.length > 0) ||
        (charts?.instagram_followers &&
          charts.instagram_followers.length > 0)) && (
        <div style={{ display: "contents" }}>
          <Box
            alignItems="center"
            justifyContent="space-between"
            className="ProductChartHead"
          >
            <SubHeading variant="h6">Social Growth</SubHeading>
          </Box>
          <Grid
            container
            sx={{
              "& > *": {
                background: colors.White,
                boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08)",
                borderRadius: "10px",
                padding: "30px",
                marginRight: "30px",
              },
              "& div:nth-child(3n)": {
                borderRight: "none",
              },
            }}
          >
            {charts?.facebook_likes && charts.facebook_likes.length > 0 && (
              <Grid item xs={4}>
                <LineChartContainer
                  change={fbLikes.change}
                  headerText="Facebook likes"
                  headerNumber={fbLikes.count}
                  overviewCharts={charts}
                />
              </Grid>
            )}
            {charts?.instagram_followers &&
              charts.instagram_followers.length > 0 && (
                <Grid item xs={4}>
                  <LineChartContainer
                    change={instaFollowers.change}
                    headerText="Instagram followers"
                    headerNumber={instaFollowers.count}
                    overviewCharts={charts}
                  />
                </Grid>
              )}
          </Grid>
        </div>
      )}
      {((socialChartsData?.numberreviews &&
        socialChartsData.numberreviews.length > 0) ||
        ReviewTracker?.reviews?.facebook?.length > 0) && (
        <div style={{ display: "contents" }}>
          <Box
            alignItems="center"
            justifyContent="space-between"
            className="ProductChartHead"
          >
            <SubHeading variant="h6">Reviews</SubHeading>
          </Box>
          <Grid
            container
            sx={{
              "& > *": {
                background: colors.White,
                boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08)",
                borderRadius: "10px",
                padding: "30px",
                marginRight: "30px",
              },
              "& div:nth-child(3n)": {
                borderRight: "none",
              },
            }}
          >
            {socialChartsData?.numberreviews &&
              socialChartsData.numberreviews.length > 0 && (
                <Grid item xs={4}>
                  <LineChartContainer
                    change={trustpilotReviews.change}
                    headerText="TrustPilot reviews"
                    headerNumber={trustpilotReviews.count}
                    data={socialChartsData}
                  />
                </Grid>
              )}
            {ReviewTracker?.reviews?.facebook?.length > 0 && (
              <Grid item xs={4}>
                <LineChartContainer
                  change={fbReviews.change}
                  headerText="Facebook reviews"
                  headerNumber={fbReviews.count}
                  fbReviews={getChartData()}
                />
              </Grid>
            )}
          </Grid>
        </div>
      )}

      <Box
        sx={{
          mb: "30px",
          mt: "30px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            mb: "4px",
            color: colors.Text,
            fontWeight: 500,
            fontSize: "30px",
            lineHeight: "45px",
            fontFamily: "Poppins, sans-serif !important",
          }}
        >
          Traffic and Revenue
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        id="traffic-revenue"
        sx={{
          background: colors.White,
          boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08)",
          borderRadius: "10px",
          height: "auto",
          width: "100%",
          padding: "30px",
          ml: "1px",
          mb: "30px",
        }}
      >
        <Grid item xs={8}>
          <TrafficChart />
        </Grid>
        <Grid item xs={4}>
          <RevenueCard />
        </Grid>
      </Grid>
      {/* paid ads column */}
      <PaidAds />
      {/* keywords column */}
      <Keywords />
      {/* google ads column */}
      <GoogleAds />
      {/* fb ads column */}
      <Box
        alignItems="center"
        justifyContent="space-between"
        className="ProductChartHead"
      >
        <Heading variant="h5">Facebook Ads</Heading>
      </Box>
      <FacebookAds />

      {/* product analysis column */}
      <Box
        alignItems="center"
        justifyContent="space-between"
        className="ProductChartHead"
      >
        <Heading variant="h5">Products analysis</Heading>
      </Box>
      <Grid container>
        <ProductAnalysis />
        <PriceRange isViabilityPage />
      </Grid>
      {/* top rated products section */}
      <Box
        alignItems="center"
        justifyContent="space-between"
        className="ProductChartHead"
      >
        <Heading variant="h5">Featured Products</Heading>
      </Box>
      <div className="FeaturedSlider">
        <ProductsBestSeller key={featureKey} isFeatured />
      </div>
      {/* best sellers section */}
      <Box
        alignItems="center"
        justifyContent="space-between"
        className="ProductChartHead"
      >
        <Heading variant="h5">Best Sellers</Heading>
      </Box>
      <ProductsBestSeller />
    </Box>
  )
}
