import React from "react"

import loader from "../../../assets/images/loader.svg"

interface props {
  loading: boolean
}

export default function LoadingOverlay(props: props) {

  return (
    <div style={{ display: props.loading ? "block" : "none" }} className="loader">
      <img src={loader} style={{ height: "150px", top: "calc(50% - 50px)", position: "relative" }} alt="" />
    </div>
  )
}

