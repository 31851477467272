import { useEffect, useState } from "react"

import SucessIcon from "../assets/images/success.png"
import { SidebarRoute } from "../V1/Components/Appbar/appbarRoutes"
//----------------------------------------------------------------------------

export default function SuccessPage() {
  const [seconds, setSeconds] = useState(6)

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        clearInterval(interval)
        window.location.href = SidebarRoute.TrendingBrands
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  return (
    <div className="error">
      <div>
        <span className="paySxss">
          <img src={SucessIcon} />
        </span>
        <p>Your payment has been completed successfully.</p>
        <p>Please wait, You will be redirected to
          <strong> TrendRocket </strong>
          in {seconds} seconds...
        </p>
      </div>
    </div>
  )
}