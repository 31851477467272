import { AxiosError } from "axios"
import { useEffect, useState } from "react"


export default function useError() {
  const [error, setError] = useState<AxiosError>()
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [statusCode, setStatusCode] = useState<number>(200) // default status code for success requests

  useEffect(() => {
    const getErrorMessage = () => {
      if (error && error.response) {
        const { data } = error.response
        const messages = Object.keys(data).map((key) => (data[key][0]))
        setErrorMessage(messages.join(","))
      }
    }

    getErrorMessage()
  }, [error])

  const errorCallBack = (errorResponse: AxiosError | null) => {
    if (errorResponse) {
      setError(errorResponse)
      if (errorResponse.response) {
        setStatusCode(errorResponse.response.status)
      }
    }
  }

  return {
    statusCode,
    errorMessage,
    setErrorMessage,
    errorCallBack
  }
}