export type EmptyComponentProps = {
  title: string
  detail?: string
  image?: string
}

export const DetailTypes = {
  adsWithFilter: "We couldn't find any Ads with those filters. Try broadening your search",
  adsWithoutFilter: "We couldn't load any ads. Please check for any running Ad Blockers",
  countryTraffic: "We couldn't find any traffic growth for this brand."
}

export enum TitleTypes {
  noResults = "No Results",
}