import AudienceNetworkIcon from "../../../assets/images/Creative/Filters/AudienceNetwork.svg"
import CarouselIcon from "../../../assets/images/Creative/Filters/Carousel.svg"
import FacebookIcon from "../../../assets/images/Creative/Filters/Facebook.svg"
import ImageIcon from "../../../assets/images/Creative/Filters/Image.svg"
import InstagramIcon from "../../../assets/images/Creative/Filters/Instagram.svg"
import MessengerIcon from "../../../assets/images/Creative/Filters/Messenger.svg"
import VideoIcon from "../../../assets/images/Creative/Filters/Video.svg"

export type Option = {
  label: string
  value: string
  image?: string
}

export const PLATFORMS: Option[] = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Facebook",
    value: "facebook",
    image: FacebookIcon,
  },
  {
    label: "Instagram",
    value: "instagram",
    image: InstagramIcon,
  },
  {
    label: "Messenger",
    value: "messenger",
    image: MessengerIcon,
  },
  {
    label: "Audience Network",
    value: "audience_network",
    image: AudienceNetworkIcon,
  }
]

export const FORMAT: Option[] = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Image",
    value: "image",
    image: ImageIcon,
  },
  {
    label: "Video",
    value: "video",
    image: VideoIcon,
  },
  {
    label: "Carousel",
    value: "carousel",
    image: CarouselIcon,
  }
]

export const SORT_OPTIONS: Option[] = [
  {
    label: "Newest First",
    value: "newest",
  },
  {
    label: "Oldest First",
    value: "oldest"
  }
]