// @mui
import { Grid, Skeleton } from "@mui/material"
// components

// ----------------------------------------------------------------------

export default function TrendingAdFeedLoader() {
  return (
    <Grid container spacing={2} className="featuredProducts adsTab">
      <TrendingAdFeedLoadingCard />
      <TrendingAdFeedLoadingCard />
      <TrendingAdFeedLoadingCard />
      <TrendingAdFeedLoadingCard />
      <TrendingAdFeedLoadingCard />
      <TrendingAdFeedLoadingCard />
      <TrendingAdFeedLoadingCard />
      <TrendingAdFeedLoadingCard />
      <TrendingAdFeedLoadingCard />
    </Grid>
  )
}

const TrendingAdFeedLoadingCard = () => {
  return (
    <Grid item xs={12} md={4}>
      <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 2 }} />

      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" height={60} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" height={60} />
    </Grid>
  )
}
