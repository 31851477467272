import { TableCell } from "@mui/material"
import { styled } from "@mui/system"
import { BrandTableRowData } from "../types"
import { BrandFields, SortType } from "../constants"

const BrandTableCell = styled(TableCell)({
  background: "rgba(59, 78, 223, 0.1)",
  borderBottom: "1px solid rgba(224, 224, 224, 1)"
})

const NoIconCell = styled("div")({
  display: "inline-flex",
  alignItems: "center",
  paddingLeft: "15px",
  cursor: "pointer",
})

export const showTableHead = (
  tabFilterValue: string,
  createSortHandler: (sort: SortType, column: keyof BrandTableRowData) => () => void,
) => {

  switch (tabFilterValue) {
    case "Social":
      return (
        <>
          <BrandTableCell className="sortHead">
            <NoIconCell>
              <label className="sortStyle">IG FOLLOWERS </label>
              <span
                className="sortUp"
                onClick={createSortHandler(SortType.Descending, BrandFields.InstaFollowers)}
              />
              <span
                className="sortDown"
                onClick={createSortHandler(SortType.Ascending, BrandFields.InstaFollowers)}
              />
            </NoIconCell>
          </BrandTableCell>
          <BrandTableCell className="sortHead">
            <NoIconCell>
              <label className="sortStyle">FB Ads </label>
              <span
                className="sortUp"
                onClick={createSortHandler(SortType.Descending, BrandFields.FbAds)}
              />
              <span
                className="sortDown"
                onClick={createSortHandler(SortType.Ascending, BrandFields.FbAds)}
              />
            </NoIconCell>
          </BrandTableCell>
        </>
      )
    case "Reviews":
      return (
        <>
          <BrandTableCell className="sortHead">
            <NoIconCell>
              <label className="sortStyle">Trust Pilot </label>
              <span
                className="sortUp"
                onClick={createSortHandler(SortType.Descending, BrandFields.NumberReviews)}
              />
              <span
                className="sortDown"
                onClick={createSortHandler(SortType.Ascending, BrandFields.NumberReviews)}
              />
            </NoIconCell>
          </BrandTableCell>
          <BrandTableCell className="sortHead">
            <NoIconCell>
              <label className="sortStyle">FB's </label>
              <span
                className="sortUp"
                onClick={createSortHandler(SortType.Descending, BrandFields.FbReviews)}
              />
              <span
                className="sortDown"
                onClick={createSortHandler(SortType.Ascending, BrandFields.FbReviews)}
              />
            </NoIconCell>
          </BrandTableCell>
          <BrandTableCell className="sortHead">
            <NoIconCell>
              <label className="sortStyle">Amazon's</label>
              <span
                className="sortUp"
                onClick={createSortHandler(SortType.Descending, BrandFields.AmazonReviews)}
              />
              <span
                className="sortDown"
                onClick={createSortHandler(SortType.Ascending, BrandFields.AmazonReviews)}
              />
            </NoIconCell>
          </BrandTableCell>
        </>
      )
    case "Reddit":
      return (
        <BrandTableCell className="sortHead">
          <NoIconCell>
            <label className="sortStyle">KEYWORD MENTIONS</label>
            <span
              className="sortUp"
              onClick={createSortHandler(SortType.Descending, BrandFields.ReditMentions)}
            />
            <span
              className="sortDown"
              onClick={createSortHandler(SortType.Ascending, BrandFields.ReditMentions)}
            />
          </NoIconCell>
        </BrandTableCell>
      )
    case "Product":
      return (
        <>
          <BrandTableCell className="sortHead">
            <NoIconCell>
              <label className="sortStyle">PRODUCT MINIMUM PRICE</label>
              <span
                className="sortUp"
                onClick={createSortHandler(SortType.Descending, BrandFields.MinPrice)}
              />
              <span
                className="sortDown"
                onClick={createSortHandler(SortType.Ascending, BrandFields.MinPrice)}
              />
            </NoIconCell>
          </BrandTableCell>
          <BrandTableCell className="sortHead">
            <NoIconCell>
              <label className="sortStyle">PRODUCT MAXIMUM PRICE</label>
              <span
                className="sortUp"
                onClick={createSortHandler(SortType.Descending, BrandFields.MaxPrice)}
              />
              <span
                className="sortDown"
                onClick={createSortHandler(SortType.Ascending, BrandFields.MaxPrice)}
              />
            </NoIconCell>
          </BrandTableCell>
          <BrandTableCell className="sortHead">
            <NoIconCell>
              <label className="sortStyle">PRODUCT AVERAGE PRICE</label>
              <span
                className="sortUp"
                onClick={createSortHandler(SortType.Descending, BrandFields.AvgPrice)}
              />
              <span
                className="sortDown"
                onClick={createSortHandler(SortType.Ascending, BrandFields.AvgPrice)}
              />
            </NoIconCell>
          </BrandTableCell>
          <BrandTableCell className="sortHead">
            <NoIconCell>
              <label className="sortStyle">NEW PRODUCTS LAST 30 DAYS</label>
              <span
                className="sortUp"
                onClick={createSortHandler(SortType.Descending, BrandFields.Last_30_Days)}
              />
              <span
                className="sortDown"
                onClick={createSortHandler(SortType.Ascending, BrandFields.Last_30_Days)}
              />
            </NoIconCell>
          </BrandTableCell>
        </>
      )
    case "Store":
      return (
        <>
          <BrandTableCell className="sortHead">
            <NoIconCell>
              <label className="sortStyle">Orders</label>
              <span
                className="sortUp"
                onClick={createSortHandler(
                  SortType.Descending,
                  BrandFields.MonthlyOrders
                )}
              />
              <span
                className="sortDown"
                onClick={createSortHandler(
                  SortType.Ascending,
                  BrandFields.MonthlyOrders
                )}
              />
            </NoIconCell>
          </BrandTableCell>
          <BrandTableCell className="sortHead">
            <NoIconCell>
              <label className="sortStyle">Revenue</label>
              <span
                className="sortUp"
                onClick={createSortHandler(
                  SortType.Descending,
                  BrandFields.MonthlyRevenue
                )}
              />
              <span
                className="sortDown"
                onClick={createSortHandler(
                  SortType.Ascending,
                  BrandFields.MonthlyRevenue
                )}
              />
            </NoIconCell>
          </BrandTableCell>
        </>
      )
    case "Traffic":
      return (
        <>
          <BrandTableCell className="sortHead">
            <NoIconCell>
              <label className="sortStyle">Last 3 Months</label>
              <span
                className="sortUp"
                onClick={createSortHandler(SortType.Descending, BrandFields.AvgLast_3_Months)}
              />
              <span
                className="sortDown"
                onClick={createSortHandler(SortType.Ascending, BrandFields.AvgLast_3_Months)}
              />
            </NoIconCell>
          </BrandTableCell>
          <BrandTableCell className="sortHead">
            <NoIconCell>
              <label className="sortStyle">Last 6 Months</label>
              <span
                className="sortUp"
                onClick={createSortHandler(SortType.Descending, BrandFields.AvgLast_6_Months)}
              />
              <span
                className="sortDown"
                onClick={createSortHandler(SortType.Ascending, BrandFields.AvgLast_6_Months)}
              />
            </NoIconCell>
          </BrandTableCell>
        </>
      )
  }
}