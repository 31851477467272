import {
  Grid,
  Button,
  Paper,
  Avatar,
  Typography,
  Link,
} from "@mui/material"
import axios from "axios"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import { RootStore } from "../../../Redux/store"
import { checkIsSuperUser, getToken } from "../../../utils/localStorage"
import AlibabaIcon from "../../../assets/images/icon_alibaba.png"
import { BrandItem } from "../common"
import { getAbsoluteURL } from "../../../utils"
import Ficon1 from "../../../assets/images/v1-icons/ficon-1.svg"
import Ficon2 from "../../../assets/images/v1-icons/ficon-2.svg"
import Ficon3 from "../../../assets/images/v1-icons/ficon-3.svg"
import Ficon4 from "../../../assets/images/v1-icons/ficon-4.svg"


interface Products {
  body: string
  brandname: string
  brandname_keyword: string
  code: string
  collection: string
  imageurl: string
  index: number
  instock: string
  is_featured: boolean
  name: string
  previousprice: number
  price: number
  productrank: number
  shopify_product_id: number
  timestamp: string
  timeupdated: string
  url: string
  variantname: string
}

interface FeaturedProduct {
  brand_id: number
  brand_name: string
  id: number
  product: Products
  product_id: number
  product_name: string
}

interface Response {
  count: number
  next: string | null
  previous: string | null
  results: FeaturedProduct[]
}

interface Params {
  name: string,
  brandId: string
}

interface Props {
  isFeatured?: boolean
}

export default function ProductsBestSeller(props: Props): React.ReactElement {
  const brandItem: BrandItem = useSelector(((state: RootStore) => state.PageReduser["brandItem"]))
  const featureKey: number = useSelector(((state: RootStore) => state.PageReduser["featureKey"]))

  const [products, setProducts] = React.useState<Products[]>([])
  const [hoveredProductIndex, setHoveredProductIndex] = React.useState<number | null>(null)

  const dispatch = useDispatch()
  const params: Params = useParams()
  const token = getToken()
  const isSuperUser = checkIsSuperUser()
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1367 },
      items: 4,
      slidesToSlide: 4
    },
    desktop: {
      breakpoint: { max: 1366, min: 1024 },
      items: 4,
      slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 640, min: 320 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }

  React.useEffect(() => {

    const fetchProducts = () => {
      const url = getAbsoluteURL(`api/report/brand-products/?brand_id=${params.brandId}&best_seller=true`)
      axios
        .get(url, {
          headers: { "Content-Type": "application/json", "Authorization": `Token ${token as string}` },
        })
        .then((response) => {
          const data = response.data as { products: Products[] }
          setProducts(data.products)
        })
        .catch(error => console.log(error))
    }

    const getFeaturedProducts = () => {
      const url = getAbsoluteURL(`api/brand/feature-product/?brand_id=${params.brandId}&page_size=50`)
      axios
        .get(url, {
          headers: { "Content-Type": "application/json", "Authorization": `Token ${token as string}` },
        })
        .then((response) => {
          const data = response.data as Response
          const featuredProducts = data.results.map((item) => item.product)
          setProducts(featuredProducts)
        })
        .catch(error => console.log(error))
    }

    if (props.isFeatured) {
      getFeaturedProducts()
    } else {
      fetchProducts()
    }
  }, [params.brandId, props.isFeatured, token])

  // passing the selected product as state to product detail page.
  const handleProductSelect = (selectedProduct: Products) => {
    if (props.isFeatured) {
      sessionStorage.setItem("pid", selectedProduct.shopify_product_id.toString())
      Object.assign(
        document.createElement("a"),
        {
          target: "_blank",
          href: `/brand/${brandItem.brandname.replaceAll(" ", "-")}/${params.brandId}/product/${selectedProduct.name}/${selectedProduct.shopify_product_id}/detail`
        }).click()
    }
  }

  const handleFeature = (selectedProduct: Products) => {
    dispatch({ type: "Loading", payload: true })
    const formData = { brand_id: parseInt(params.brandId), product_id: selectedProduct.shopify_product_id }
    const url = getAbsoluteURL("/api/brand/feature-product/")
    axios
      .post(url,
        formData,
        {
          headers: { "Content-Type": "application/json", "Authorization": `Token ${token as string}` },
        })
      .then(() => {
        dispatch({ type: "Loading", payload: false })
        dispatch({ type: "TriggerFeaturedKey", payload: featureKey + 1 })
        const productList = products
        productList.forEach((item) => {
          if (item.shopify_product_id === selectedProduct.shopify_product_id) {
            item.is_featured = true
          }
        })
        setProducts(productList)
      })
      .catch((error) => {
        dispatch({ type: "Loading", payload: false })
        console.log(error)
      })
  }

  function handleMouseEnteredProduct(index: number) {
    setHoveredProductIndex(index)
  }

  function handleMouseLeftProduct() {
    setHoveredProductIndex(null)
  }

  function handleAlibabaIconClick(productName: string) {
    if (productName) {
      Object.assign(
        document.createElement("a"),
        {
          target: "_blank",
          href: `https://www.alibaba.com/trade/search?fsb=y&IndexArea=product_en&CatId=&SearchText=${productName.replace(/ /g, "+")}`
        }).click()
    }
  }

  return (
    products?.length > 0 ?
      <Carousel
        swipeable={false}
        draggable={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={"desktop"}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {products.map((product, index) => (
          <Grid item key={index} xs={12} className="bestSeller">
            <Paper
              variant={hoveredProductIndex == index ? "elevation" : "elevation"}
              elevation={4}
              onMouseEnter={() => handleMouseEnteredProduct(index)}
              onMouseLeave={handleMouseLeftProduct}
              sx={(theme) => ({
                pl: theme.spacing(2),
                pr: theme.spacing(2),
                pt: theme.spacing(1.25),
                pb: theme.spacing(1.25),
                ml: theme.spacing(1.25),
                mr: theme.spacing(1.25),
                borderRadius: "15px",
                display: "flex",
                height: "400px",
                flexDirection: "column",
                "& > *:not(last-child)": {
                  marginBottom: theme.spacing(1)
                },
                "& .MuiAvatar-root": {
                  width: "100%",
                  height: "100%"
                }
              })}
            >
              {hoveredProductIndex === index
                && !props.isFeatured
                && <Avatar className="hoverIcon" src={AlibabaIcon} onClick={() => handleAlibabaIconClick(product.name)} />
              }
              <Typography variant="subtitle2">Featured Product</Typography>
              <Avatar variant="square" src={product.imageurl} />
              <div className="bstSellerDetails">
                <Typography variant="subtitle2">{product.name}</Typography>
                <span><Link href={product.url} target="_blank">{brandItem.brandname}</Link></span>
              </div>
              <Typography
                variant="subtitle1"
                sx={(theme) => ({
                  "& span": {
                    marginLeft: theme.spacing(2),
                    color: theme.palette.text.secondary,
                    textDecoration: "line-through",
                    fontSize: "90%"
                  }
                })}
              >${product.price}</Typography>
              {isSuperUser && !props.isFeatured && hoveredProductIndex === index
                && <Button
                  color="primary"
                  onClick={() => handleFeature(product)}>
                  {product.is_featured ? "Featured Product" : "Feature this Product"}
                </Button>
              }
              {props.isFeatured
                &&
                <>
                  <div className="feauredIcons">
                    <button><img src={Ficon1} /></button>
                    <button><img src={Ficon2} /></button>
                    <button><img src={Ficon3} /></button>
                    <button><img src={Ficon4} /></button>
                  </div>
                  <Button
                    color="primary"
                    onClick={() => handleProductSelect(product)}>
                    View Product Details
                  </Button>
                </>
              }
            </Paper>
          </Grid>
        ))}
      </Carousel> : <Typography variant="h6">{props.isFeatured ? "No Products Featured Yet." : "No Products Listed Yet."}</Typography>
  )
}
