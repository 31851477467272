import React from "react"
import NotFound from "../assets/images/404.png"


export default function PageNotFound(){
  return(
    <div className="NotFound">
    <img src={NotFound}/>
     <h6> Sorry, page you are trying to access is not available right now, Please try again later.</h6>
    </div>
  )
}