import { Box, Stack, Typography } from "@mui/material"

import * as DOMPurify from "dompurify"
import { DateTime } from "luxon"

import { AdBody } from "./AdBody"
import { TrendingAd } from "../../../api/trendingAds"


export default function AdDetailView({ ad }: { ad: TrendingAd }) {
  return (
    <Stack spacing={1} sx={{ mb: 2 }}>
      <AdHeader ad={ad} />
      <AdHtml ad={ad} />
      <AdBody
        ad={ad}
        bodyHeight={"425px"}
      />
    </Stack>
  )
}

type AdDetailViewProps = {
  ad: TrendingAd
}

const AdHeader = ({ ad }: AdDetailViewProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <img
        src={ad.profileimage || ""}
        style={{
          width: "61px",
          height: "61px",
          display: "inline-block",
          marginRight: "11px",
          borderRadius: "50%",
        }}
      />
      <Stack>
        <Typography variant="h6">{ad.brand_name}</Typography>
        <Typography
          fontWeight="400"
          fontSize="16px"
          color="rgba(59, 78, 223, 0.7);"
        >
          {`Saved on ${DateTime.fromISO(ad.start_date).toFormat("dd MMM yyyy")}`}
        </Typography>
      </Stack>
    </Box>
  )
}

const AdHtml = ({ ad }: AdDetailViewProps) => {
  const badHtml = ad.ad_content?.includes("&#123;")
  if (ad.ad_content && !badHtml) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(ad.ad_content),
        }}
      />
    )
  } else {
    return <Typography variant="body2">{ad.ad_content}</Typography>
  }
}