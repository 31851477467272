import { Box, IconButton, Stack, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { useSelector } from "react-redux"

import Iconify from "../../../../components/Iconify"
import { FbAdPublisherPlatformGrayscale } from "../../../../components/PublisherPlatforms"
import { CtaType, ctaTitle } from "../../../../api/trendingAds"
import SaveAdToTrendRocket from "../../../../components/SaveAdToTrendRocket"
import { UserRoles, getUserRole } from "../../../../utils/localStorage"
import { FbAd } from "../../../../api/ads"
import { BrandItem } from "../../common"
import { RootStore } from "../../../../Redux/store"
//------------------------------------------------------------------------------------------------

type AdMetaViewProps = {
  ad: FbAd
  focusedCtaUrl?: string | null
  handleClose?: VoidFunction
}

export default function AdMetaView({
  ad,
  focusedCtaUrl,
  handleClose,
}: AdMetaViewProps) {
  const userRole = getUserRole()
  const isAdminOrTester =
    userRole === UserRoles.ADMIN || userRole === UserRoles.TESTER

  return (
    <Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {handleClose && (
          <IconButton onClick={handleClose}>
            <Iconify icon={"material-symbols:close"} width={28} height={28} />
          </IconButton>
        )}
      </Box>
      {isAdminOrTester && (
        <SaveAdToTrendRocket
          adId={ad.adarchiveid}
          boards={[]}
          boardsLoading={false}
          sx={{
            mb: 2,
            "& .MuiFormControl-root": {
              width: "100%",
            },
            "& .MuiLoadingButton-root": {
              width: "110px",
              mt: "12px",
              ml: "10px",
            },
          }}
        />
      )}
      <Typography variant="h6" pb={1}>
        Ad Details
      </Typography>
      <AdDetailsView ad={ad} focusedCtaUrl={focusedCtaUrl} />
    </Stack>
  )
}

const AdDetailsView = ({
  ad,
  focusedCtaUrl,
}: {
  ad: FbAd
  focusedCtaUrl?: string | null
}) => {
  const brand: BrandItem = useSelector(
    (state: RootStore) => state.PageReduser["brandItem"]
  )

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={4}>
        <DetailItem
          title={"Started running on"}
          value={DateTime.fromISO(ad.startdate).toFormat("dd MMM yyyy")}
        />
        <DetailItem title={"ID"} value={ad.adarchiveid || "-"} />
      </Stack>
      <Stack direction="row" spacing={4}>
        {ad.cta_type && (
          <DetailItem
            title={"CTA Type"}
            value={ctaTitle(ad.cta_type as CtaType)}
          />
        )}
        <DetailItem
          title={"Display Type"}
          value={ad.video_url ? "VIDEO" : "IMAGE"}
        />
        {brand.fblikes !== undefined && (
          <DetailItem
            title={"Page Likes"}
            value={new Intl.NumberFormat("en-AU").format(brand.fblikes)}
          />
        )}
      </Stack>

      <Typography
        color="rgba(61, 68, 101, 0.4);"
        fontFamily="Poppins"
        fontSize="14px"
      >
        Platforms
      </Typography>
      <FbAdPublisherPlatformGrayscale
        publisherPlatformString={ad.publisherplatform || ""}
      />

      {ad.page_categories && (
        <DetailItem
          title={"Categories"}
          value={parsePageCategories(ad.page_categories)}
        />
      )}

      {focusedCtaUrl && <DetailItem title={"CTA url"} value={focusedCtaUrl} />}
    </Stack>
  )
}

const DetailItem = ({ title, value }: { title: string; value: string }) => {
  return (
    <Stack>
      <Typography
        color="rgba(61, 68, 101, 0.4);"
        fontFamily="Poppins"
        fontSize="14px"
      >
        {title}
      </Typography>
      <Typography
        color="rgba(61, 68, 101, 0.8);"
        fontWeight={500}
        fontFamily="Poppins"
        fontSize="16px"
      >
        {value}
      </Typography>
    </Stack>
  )
}

const parsePageCategories = (publisherPlatforms: string) =>
  publisherPlatforms.replaceAll("'", "").replace("[", "").replace("]", "")
