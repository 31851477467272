import { deleteApiCall, fetchApiCall, postApiCall } from "."
import { AdCategories } from "../V1/Trending/TrendingAds/Constants"

export interface TrendingAd {
  ad_content: string
  ad_id: string
  ad_image: string
  ad_link: string
  ad_video: string
  brand_name: string
  brand_id: number
  brandsite: string
  cta: string
  end_date: string
  id: string
  is_active: boolean
  manually_featured: boolean
  page_id: string
  platform: string
  profileimage: string
  start_date: string
  page_categories: string
  page_likes: number
}

export interface TrendingAdResponse {
  count: number
  next: string
  previous: string
  results: TrendingAd[]
}

export enum CtaType {
  instagram = "VIEW_INSTAGRAM_PROFILE",
  LearnMore = "LEARN_MORE",
  WhatsAppMessage = "WHATSAPP_MESSAGE",
  shopNow = "SHOP_NOW",
  signUp = "SIGN_UP",
}

export const ctaTitle = (ctaType: CtaType) => {
  switch (ctaType) {
    case CtaType.LearnMore:
      return "Learn More"
    case CtaType.instagram:
      return "Instagram"
    case CtaType.shopNow:
      return "Show Now"
    case CtaType.WhatsAppMessage:
      return "WhatsApp"
    case CtaType.signUp:
      return "Sign Up"
    default:
      return ctaType
  }
}

export enum Publishers {
  instagram = "instagram",
  facebook = "facebook",
  messenger = "messenger",
}

export enum TrendingAdsSortOption {
  random = "random",
  StartDateDesc = "-start_date",
  StartDateAsc = "start_date",
  LongestRunning = "long_running_ads",
}

export const fetchTrendingAds = async (
  page = 1,
  pageSize = 20,
  sortBy = TrendingAdsSortOption.random,
  adCategory: string,
  adCountry: string,
  adStatus: number,
  brandIds: string,
  dateRange: string,
  mediaType: string,
  isFilterApplied: boolean,
  searchValue?: string
) => {
  let apiUrl = isFilterApplied
    ? `api/ads/featured-ads/?page=${page}&page_size=${pageSize}&ad_country=${adCountry}&date_range=${dateRange}&ad_status=${adStatus}&media_type=${mediaType}&brands=${brandIds}`
    : `api/ads/featured-ads/?page=${page}&page_size=${pageSize}`

  if (adCategory === AdCategories.AllAds) {
    apiUrl = `${apiUrl}&all_ads=true`
  } else {
    apiUrl = `${apiUrl}&all_ads=false`
  }

  if (searchValue) {
    apiUrl = `${apiUrl}&q=${searchValue}`
  }

  const endpoint =
    sortBy === TrendingAdsSortOption.random
      ? apiUrl
      : `${apiUrl}&ordering=${sortBy}`

  return fetchApiCall<TrendingAdResponse>(endpoint)
}

export const featureAd = async (payload: any) => {
  const endpoint = "api/ads/feature/fbads/"

  return postApiCall(endpoint, payload)
}

export const removeTrendingAd = async (adId: string) => {
  const endpoint = `api/ads/feature/fbads/${adId}/`

  return deleteApiCall(endpoint)
}
