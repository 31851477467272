import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import FormHelperText from "@mui/material/FormHelperText"
import axios from "axios"
import React, { useState } from "react"

import LoadingOverlay from "../Components/LoadOverlay/LoadOverlay"
import { getAbsoluteURL } from "../../utils"
import { createBrand } from "../../api/brand"

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

interface FormData {
  brandname: string | null,
  brandsite: string | null,
  shopifysite: string | null,
  fburl: string | null,
  trustpilotsite: string | null,
  trustpilotaddress: string | null,
  aboutbrand: string | null,
  description: string | null,
  fb_about: string | null,
  brandcategory: string | null,
  is_featured: 0 | 1,
  [index: string]: any
}

export default function AddBrand(): React.ReactElement {
  const initialFormData: FormData = {
    brandname: "", brandsite: "", shopifysite: "", fburl: "", trustpilotsite: "", is_featured: 0,
    trustpilotaddress: "", aboutbrand: "", description: "", fb_about: "", brandcategory: ""
  }
  const [modalOpen, setModalOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [formData, setFormData] = useState(initialFormData)
  const [loading, setLoading] = useState(false)

  const token = localStorage.getItem("token")

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const data = formData
    Object.keys(data).forEach((val) => {
      if (val === e.target.name) {
        data[val] = e.target.value
      }
    })
    setFormData(data)
  }

  const runBrandScrapper = (brandId: number) => {
    const url = getAbsoluteURL(`api/brand/run-brand-scrapper/${brandId}/`)
    axios.post(url, null, { headers: { "Content-Type": "application/json", "Authorization": `Token ${token as string}` } })
      .then(() => {
        setMessage(`Successfully added brand ${formData.brandname as string} to MOAS. Scraper initialized.This might take a while.`)
        setModalOpen(true)
        setFormData(initialFormData)
      }).catch((error) => console.log(error))
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    const response = await createBrand(formData)
    setLoading(false)
    if (response) {
      runBrandScrapper(response.brand_id)
    } else {
      setMessage(`Sorry, couldn't add brand ${formData.brandname as string} to MOAS.`)
      setModalOpen(true)
    }
  }

  function handleModalClose() {
    setModalOpen(false)
  }

  const modal = (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      className="addBrandPopup"
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalOpen}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">{message}</Typography>
          <Button onClick={handleModalClose} variant="contained" color="primary">Ok</Button>
        </Box>
      </Fade>
    </Modal>
  )

  return (
    <div className="addBrand">
      <LoadingOverlay loading={loading} />
      <Paper className="BrandPopupBox">
        <Typography variant="h4">Add new brand</Typography>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <label htmlFor="brandname">Name*</label>
            <input required name="brandname" onChange={handleChange} placeholder="Enter name of the brand..." />
            <FormHelperText id="brandname-helper-text"></FormHelperText>
          </FormControl>
          <FormControl>
            <label htmlFor="brandsite">Brand website*</label>
            <input required name="brandsite" onChange={handleChange} placeholder="Enter URL of the brand..." />
            <FormHelperText id="brandsite-helper-text"></FormHelperText>
          </FormControl>
          <FormControl>
            <label htmlFor="shopifysite">Shopify Website*</label>
            <input required name="shopifysite" onChange={handleChange} placeholder="Enter shopify URL of the brand..." />
            <FormHelperText id="shopifysite-helper-text"></FormHelperText>
          </FormControl>
          <FormControl>
            <label htmlFor="fburl">Facebook URL</label>
            <input name="fburl" onChange={handleChange} placeholder="Enter facebook URL of the brand..." />
            <FormHelperText id="fburl-helper-text"></FormHelperText>
          </FormControl>
          <FormControl>
            <label htmlFor="trustpilotsite">Trustpilot site</label>
            <input name="trustpilotsite" onChange={handleChange} placeholder="Enter trustpilot URL of the brand..." />
            <FormHelperText id="trustpilotsite-helper-text"></FormHelperText>
          </FormControl>
          <FormControl>
            <label htmlFor="trustpilotaddress">Trustpilot address</label>
            <input name="trustpilotaddress" onChange={handleChange} placeholder="Enter trustpilot address of the brand..." />
            <FormHelperText id="trustpilotaddress-helper-text"></FormHelperText>
          </FormControl>
          <FormControl>
            <label htmlFor="aboutbrand">About brand</label>
            <input name="aboutbrand" onChange={handleChange} placeholder="Enter about brand..." />
            <FormHelperText id="aboutbrand-helper-text"></FormHelperText>
          </FormControl>
          <FormControl>
            <label htmlFor="description">Description</label>
            <input name="description" onChange={handleChange} placeholder="Enter brand description..." />
            <FormHelperText id="description-helper-text"></FormHelperText>
          </FormControl>
          <FormControl>
            <label htmlFor="fb_about">Facebook About</label>
            <input name="fb_about" onChange={handleChange} placeholder="Enter facebook about of the brand..." />
            <FormHelperText id="fb_about-helper-text"></FormHelperText>
          </FormControl>
          <FormControl>
            <label htmlFor="brandcategory">Brand Category</label>
            <input name="brandcategory" onChange={handleChange} placeholder="Enter category of the brand..." />
            <FormHelperText id="brandcategory-helper-text"></FormHelperText>
          </FormControl>
          <Button type="submit" variant="contained" color="primary">ADD</Button>
        </form>
      </Paper>
      {modal}
    </div>
  )
}