// @mui
import { Box, IconButton, Stack, Typography } from "@mui/material"

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import {
  PinnedAd,
  movePinnedAd,
} from "../../../../../api/creativeCenter/pinnedAds"
import { ctaTitle } from "../../../../../api/trendingAds"
import BoardSelect from "../../../../../components/BoardSelect"
import Iconify from "../../../../../components/Iconify"
import { FbAdPublisherPlatformGrayscale } from "../../../../../components/PublisherPlatforms"
import { Board, fetchMyBoards } from "../../../../../api/creativeCenter/boards"

// ----------------------------------------------------------------------

type AdMetaViewProps = {
  pinnedAd: PinnedAd
  focusedCtaUrl?: string | null
  handleClose?: VoidFunction
  hideMoveBoard?: boolean
}

export default function AdMetaView({
  pinnedAd,
  focusedCtaUrl,
  handleClose,
  hideMoveBoard,
}: AdMetaViewProps) {
  return (
    <Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {handleClose && (
          <IconButton onClick={handleClose}>
            <Iconify icon={"material-symbols:close"} width={28} height={28} />
          </IconButton>
        )}
      </Box>
      {!hideMoveBoard && <MoveBoardDropdown pinnedAd={pinnedAd} />}
      {hideMoveBoard && (
        <Typography variant="h6" pb={4}>
          Ad Details
        </Typography>
      )}
      <AdDetailsView pinnedAd={pinnedAd} focusedCtaUrl={focusedCtaUrl} />
    </Stack>
  )
}

const MoveBoardDropdown = ({ pinnedAd }: { pinnedAd: PinnedAd }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [boardsLoading, setBoardsLoading] = useState(false)
  const [boards, setBoards] = useState<Board[]>([])
  const [selectedBoardId, setSelectedBoardId] = useState<number>()

  useEffect(() => {
    const loadBoards = async () => {
      setBoardsLoading(true)
      const result = await fetchMyBoards()
      setBoards(result.results)
      setBoardsLoading(false)
    }
    loadBoards()
  }, [])

  const moveBoard = async () => {
    if (!selectedBoardId) {
      return
    }
    setLoading(true)

    await movePinnedAd(pinnedAd, selectedBoardId)

    enqueueSnackbar("Board Changed")
    setLoading(false)
  }

  return (
    <Stack
      spacing={2}
      direction="row"
      sx={{
        mb: 2,
        alignItems: "center",
      }}
    >
      <BoardSelect
        name="board"
        label="Move to Board"
        boards={boards}
        onChange={setSelectedBoardId}
        loading={boardsLoading}
        value={selectedBoardId || pinnedAd.board || -1}
      />
      <LoadingButton onClick={moveBoard} loading={loading}>
        Move
      </LoadingButton>
    </Stack>
  )
}

const AdDetailsView = ({
  pinnedAd,
  focusedCtaUrl,
}: {
  pinnedAd: PinnedAd
  focusedCtaUrl?: string | null
}) => {
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={4}>
        <DetailItem title={"Started running on"} value="27 Feb 2023" />
        <DetailItem title={"ID"} value={pinnedAd.fb_ad.adarchiveid} />
      </Stack>
      <Stack direction="row" spacing={4}>
        {pinnedAd.fb_ad.cta_type && (
          <DetailItem
            title={"CTA Type"}
            value={ctaTitle(pinnedAd.fb_ad.cta_type)}
          />
        )}
        <DetailItem
          title={"Display Type"}
          value={pinnedAd.fb_ad.video_url ? "VIDEO" : "IMAGE"}
        />
        {pinnedAd.fb_ad.brand.fblikes !== undefined && (
          <DetailItem
            title={"Page Likes"}
            value={new Intl.NumberFormat("en-AU").format(
              pinnedAd.fb_ad.brand.fblikes
            )}
          />
        )}
      </Stack>

      <Typography
        color="rgba(61, 68, 101, 0.4);"
        fontFamily="Poppins"
        fontSize="14px"
      >
        Platforms
      </Typography>
      <FbAdPublisherPlatformGrayscale
        publisherPlatformString={pinnedAd.fb_ad.publisherplatform || ""}
      />

      {pinnedAd.fb_ad.page_categories && (
        <DetailItem
          title={"Categories"}
          value={parsePageCategories(pinnedAd.fb_ad.page_categories)}
        />
      )}

      {focusedCtaUrl && <DetailItem title={"CTA url"} value={focusedCtaUrl} />}
    </Stack>
  )
}

const DetailItem = ({ title, value }: { title: string; value: string }) => {
  return (
    <Stack>
      <Typography
        color="rgba(61, 68, 101, 0.4);"
        fontFamily="Poppins"
        fontSize="14px"
      >
        {title}
      </Typography>
      <Typography
        color="rgba(61, 68, 101, 0.8);"
        fontWeight={500}
        fontFamily="Poppins"
        fontSize="16px"
      >
        {value}
      </Typography>
    </Stack>
  )
}

const parsePageCategories = (publisherPlatformString: string) =>
  publisherPlatformString.replaceAll("'", "").replace("[", "").replace("]", "")
