import CheckIcon from "@mui/icons-material/Check"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import {
  Box,
  Button,
  Grid,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import ApexChart from "../../../components/Chart/ApexChart"
import Actions from "../../../pages/Actions/PageActions"
import { RootStore } from "../../../Redux/store"
import { getToken } from "../../../utils/localStorage"
import Authorization from "../../Authorization/Authorization"
import {
  AdsCount,
  BrandItem,
  ChartData,
  FbAdsCount,
  FbLikesData,
  IGFollowersData,
  OverviewCharts,
  SocialCharts,
  SocialData,
} from "../common"
import { colors } from "../../../utils/theme"

type PaperButtonProps = {
  title: string
  content: string
  isActive: boolean
  onClick: () => void
}

function PaperButton({ title, content, isActive, onClick }: PaperButtonProps) {
  return (
    <Grid item xs={12}>
      <Paper
        variant={isActive ? "elevation" : "outlined"}
        sx={(theme) => ({
          mt: theme.spacing(2),
          padding: theme.spacing(2),
          minHeight: "55px",
          cursor: "pointer",
          border: isActive ? `2px solid ${theme.palette.primary.dark}` : "",
        })}
        onClick={onClick}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ color: colors.Text }}
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            color: (theme) => isActive ? theme.palette.primary.dark : "inherit",
          }}
        >
          {content}
        </Typography>
      </Paper>
    </Grid>
  )
}

interface MenuItemWithCheckmarkProps {
  text: string
  onClick: () => void
  checked: boolean
}

function MenuItemWithCheckmark({
  text,
  onClick,
  checked,
}: MenuItemWithCheckmarkProps) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemText primary={text} />
      {checked ? (
        <ListItemSecondaryAction>
          <CheckIcon fontSize="small" />
        </ListItemSecondaryAction>
      ) : (
        ""
      )}
    </MenuItem>
  )
}

interface PaperButtonData {
  title: string
  content: string
}

interface PaperButtonDataCollection {
  data: Array<PaperButtonData>
  onClick: (index: number) => void
}

function PaperButtonsCollection({ data, onClick }: PaperButtonDataCollection) {
  const [activePaperIndex, setActivePaperIndex] = React.useState(0)
  function handlePaperButtonClicked(index: number) {
    setActivePaperIndex(index)
    onClick(index)
  }
  return (
    <>
      {data.map((dataItem, index) => (
        <PaperButton
          key={index}
          title={dataItem.title}
          content={dataItem.content}
          isActive={activePaperIndex == index}
          onClick={() => handlePaperButtonClicked(index)}
        />
      ))}
    </>
  )
}

type ApexChartData = {
  name: string
  series: { x: string; y: number }[]
}

export default function SocialTab(): React.ReactElement {
  const dispatch = useDispatch()
  const [menuAnchorElem, setMenuAnchorElem] =
    React.useState<null | HTMLElement>(null)
  const [checkedMenuItemIndex, setCheckedMenuItemIndex] = React.useState(0)
  const [activePaperButtonIndex, setActivePaperButtonIndex] = React.useState(0)
  const [dateChoice, setDateChoice] = React.useState("30")

  const brandItem: BrandItem = useSelector(
    (state: RootStore) => state.PageReduser["brandItem"]
  )
  const socialChartsData: SocialCharts = useSelector(
    (state: RootStore) => state.PageReduser["socialCharts"]
  )
  const socialCount: SocialData = useSelector(
    (state: RootStore) => state.PageReduser["socialDataCount"]
  )
  const overviewCharts: OverviewCharts = useSelector(
    (state: RootStore) => state.PageReduser["overviewCharts"]
  )
  const FbAdsCountData: FbAdsCount = useSelector(
    (state: RootStore) => state.PageReduser["FbAdsCountData"]
  )

  const token = getToken()
  const menuItems = React.useMemo(() => ["Social", "Reviews", "Reddit"], [])
  const paperColumnButtonCounts = React.useMemo(() => [5, 6, 2], [])

  function handleMenuButtonClicked(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchorElem(event.currentTarget)
  }
  function handleMenuClosed() {
    setMenuAnchorElem(null)
  }
  function handleMenuItemSelected(index: number) {
    setCheckedMenuItemIndex(index)
    setActivePaperButtonIndex(0)
    handleMenuClosed()
  }

  const chartsData = React.useMemo(() => {
    function getChartData(
      menu: string,
      activePaperIndex: number
    ): ApexChartData | undefined {
      if (token) {
        let chartData: ApexChartData | undefined = undefined
        switch (`${menu}-${activePaperIndex}`) {
          case "Social-0":
            chartData = {
              name: "Facebook likes",
              series: overviewCharts?.fblikes_data?.map(
                (value: FbLikesData) => ({
                  x: new Date(value.date).toUTCString(),
                  y: value.fb_likes,
                })
              ),
            }
            return chartData
          case "Social-1":
            chartData = {
              name: "Instagram followers",
              series: overviewCharts?.instafollowers_data?.map(
                (value: IGFollowersData) => ({
                  x: new Date(value.date).toISOString(),
                  y: value.instafollowers,
                })
              ),
            }
            return chartData
          case "Social-2":
            chartData = {
              name: "Facebook ads",
              series: overviewCharts?.fb_ads_count?.map((value: AdsCount) => ({
                x: new Date(value.timestamp).toUTCString(),
                y: value.ads_count,
              })),
            }
            return chartData
          case "Reviews-1":
            chartData = {
              name: "Trustpilot reviews",
              series: socialChartsData?.numberreviews?.map(
                (value: ChartData) => ({
                  x: new Date(value.timestamp).toUTCString(),
                  y: value.count,
                })
              ),
            }
            return chartData
          case "Reviews-3":
            chartData = {
              name: "Facebook reviews",
              series: socialChartsData?.fbreviews?.map((value: ChartData) => ({
                x: new Date(value.timestamp).toUTCString(),
                y: value.count,
              })),
            }
            return chartData
          case "Reddit-0":
            chartData = {
              name: "Reddit mentions",
              series: socialChartsData?.reditmentions?.map(
                (value: ChartData) => ({
                  x: new Date(value.timestamp).toUTCString(),
                  y: value.count,
                })
              ),
            }
            return chartData
          case "Reddit-1":
            chartData = {
              name: "Reddit growth",
              series: socialChartsData?.redditgrowth?.map(
                (value: ChartData) => ({
                  x: new Date(value.timestamp).toUTCString(),
                  y: value.count,
                })
              ),
            }
            return chartData
        }
        return chartData as unknown as ApexChartData
      }
    }
    return menuItems.map((menuItem, index) =>
      new Array(paperColumnButtonCounts[index])
        .fill(0)
        .map((value, index) => getChartData(menuItem, index))
    )
  }, [
    menuItems,
    overviewCharts?.fb_ads_count,
    overviewCharts?.fblikes_data,
    overviewCharts?.instafollowers_data,
    paperColumnButtonCounts,
    socialChartsData?.fbreviews,
    socialChartsData?.numberreviews,
    socialChartsData?.redditgrowth,
    socialChartsData?.reditmentions,
    token,
  ])

  const handleDateChoice = (e: SelectChangeEvent) => {
    setDateChoice(e.target.value)
    dispatch(Actions.GetSocialData(brandItem.brandname, e.target.value))
  }

  const fbRenderData: any[] = [
    {
      title: "Facebook likes growth",
      content: brandItem?.fblikes?.toLocaleString() || "-",
    },
    {
      title: "Instagram followers growth",
      content: brandItem?.igfollowers?.toLocaleString() || "-",
    },
    {
      title: "Ads count",
      content: FbAdsCountData?.total_ads?.toLocaleString() || "-",
    },
  ]

  return (
    <Grid container className="SocialTab">
      {!token ? (
        <Authorization />
      ) : (
        <>
          <Grid item xs={12} md={4}>
            <Box justifyContent="space-between">
              <Button
                variant="outlined"
                size="small"
                endIcon={<KeyboardArrowDownIcon />}
                aria-haspopup="true"
                sx={{
                  "& .MuiButton-endIcon": {
                    position: "relative",
                    top: "3px"
                  }
                }}
                onClick={handleMenuButtonClicked}
              >
                {menuItems[checkedMenuItemIndex]}
              </Button>
              <Menu
                id="socials-menu"
                anchorEl={menuAnchorElem}
                sx={{ maxWidth: "207px" }}
                keepMounted
                open={Boolean(menuAnchorElem)}
                onClose={handleMenuClosed}
              >
                {menuItems.map((menuItem, index) => (
                  <MenuItemWithCheckmark
                    key={index}
                    text={menuItem}
                    onClick={() => handleMenuItemSelected(index)}
                    checked={checkedMenuItemIndex == index}
                  />
                ))}
              </Menu>
              <Select
                value={dateChoice}
                onChange={handleDateChoice}
                sx={{
                  height: "38px",
                  ml: "10px",
                }}
                displayEmpty
              >
                <MenuItem value={"30"}>Last 30 days</MenuItem>
                <MenuItem value={"60"}>Last 60 days</MenuItem>
                <MenuItem value={"90"}>Last 90 days</MenuItem>
              </Select>
            </Box>
            {checkedMenuItemIndex == 0 ? (
              <PaperButtonsCollection
                data={fbRenderData}
                onClick={setActivePaperButtonIndex}
              />
            ) : checkedMenuItemIndex == 1 ? (
              <PaperButtonsCollection
                data={[
                  { title: "Total reviews", content: "0" },
                  {
                    title: "TrustPilot reviews",
                    content: socialCount?.numberreviews?.toLocaleString(),
                  },
                  { title: "Product reviews", content: "0" },
                  {
                    title: "Facebook reviews",
                    content: socialCount?.fbreviews?.toLocaleString(),
                  },
                  { title: "Google reviews", content: "0" },
                ]}
                onClick={setActivePaperButtonIndex}
              />
            ) : (
              <PaperButtonsCollection
                data={[
                  {
                    title: "Total mentions",
                    content: socialCount?.reditmentions?.toLocaleString(),
                  },
                  {
                    title: "Reddit mentions growth",
                    content: socialCount?.redditgrowth?.toLocaleString(),
                  },
                ]}
                onClick={setActivePaperButtonIndex}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              height: "100%",
              width: "100%",
              pl: "30px"
            }}
          >
            <ApexChart
              type="line"
              chartSeries={
                chartsData[checkedMenuItemIndex][
                activePaperButtonIndex
                ] as ApexChartData
              }
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
