import { fetchApiCall, postApiCall, postApiCallWithFile} from "."


export type PaidAdsResponse = {
  id: number
  brand_id: number
  brand_name: string
  brand_site: string
  pixel_setup: {
    facebook: boolean
    google: boolean
    tiktok: boolean
    pinterest: boolean
    snapchat: boolean
    reddit: boolean
    native: boolean
  }
  run_ads_before: {
    facebook: boolean
    google: boolean
    tiktok: boolean
    pinterest: boolean
    snapchat: boolean
    reddit: boolean
    native: boolean
  }
  currently_running_ads: {
    facebook: boolean
    google: boolean
    tiktok: boolean
    pinterest: boolean
    snapchat: boolean
    reddit: boolean
    native: boolean
  }
  json_response: unknown[]
}

export const fetchPaidAds = async (brand_id: number) => {
  const endpoint = "api/report/paid-ads-platforms/"

  return fetchApiCall<PaidAdsResponse>(endpoint, { brand_id })
}

export const triggerWappalyzer = async (payload: FormData) => {
  const endpoint = "api/brand/wappalyzer-upload/"
  return postApiCallWithFile<any>(endpoint, payload)
}