import { TableCell } from "@mui/material"
import ScoreTableCell from "../../Components/ScoreTableCell"
import { getScore } from "../actions"
import { BrandTableRowData } from "../types"


export const showBrandTableRows = (rowData: BrandTableRowData, tabFilterValue: string) => {
  switch (tabFilterValue) {
    case "Social":
      return (
        <>
          <TableCell>{rowData.latest_fblikes || "-"}</TableCell>
          <TableCell>{rowData.latest_instafollowers || "-"}</TableCell>
          <TableCell>{rowData.fbads_count || "-"}</TableCell>
        </>
      )
    case "Reviews":
      return (
        <>
          <TableCell>{rowData.latest_shopifyreviews_count || "-"}</TableCell>
          <TableCell>{rowData.latest_numberreviews || "-"}</TableCell>
          <TableCell>{rowData.latest_fbreviews || "-"}</TableCell>
          <TableCell>{rowData.latest_amazonreviews || "-"}</TableCell>
        </>
      )
    case "Reddit":
      return (
        <>
          <TableCell>{rowData.latest_redditgrowth || "-"}</TableCell>
          <TableCell>{rowData.latest_reditmentions || "-"}</TableCell>
        </>
      )
    case "Product":
      return (
        <>
          <TableCell>{rowData.product_data.count || "-"}</TableCell>
          <TableCell>${rowData.product_data.min_price || "-"}</TableCell>
          <TableCell>${rowData.product_data.max_price || "-"}</TableCell>
          <TableCell>${rowData.product_data.avg_price || "-"}</TableCell>
          <TableCell>{rowData.product_data.last_30_days || "-"}</TableCell>
        </>
      )
    case "Store":
      return (
        <>
          <TableCell>
            {rowData.store_data.estimated_monthly_traffic || "-"}
          </TableCell>
          <TableCell>
            {rowData.store_data.estimated_monthly_orders || "-"}
          </TableCell>
          <TableCell>
            ${rowData.store_data.estimated_monthly_revenue || "-"}
          </TableCell>
        </>
      )
    case "Traffic":
      return (
        <>
          {rowData.web_traffic_growth.last_month_traffic &&
            rowData.web_traffic_growth.last_month ? (
            <ScoreTableCell
              score={getScore(
                parseInt(rowData.web_traffic_growth.last_month_traffic),
                parseInt(rowData.web_traffic_growth.last_month)
              )}
            />
          ) : (
            <TableCell>-</TableCell>
          )}
          {rowData.web_traffic_growth.avg_last_3_months &&
            rowData.web_traffic_growth.last_3_months ? (
            <ScoreTableCell
              score={getScore(
                parseInt(rowData.web_traffic_growth.avg_last_3_months),
                parseInt(rowData.web_traffic_growth.last_3_months)
              )}
            />
          ) : (
            <TableCell>-</TableCell>
          )}
          {rowData.web_traffic_growth.avg_last_6_months &&
            rowData.web_traffic_growth.last_6_months ? (
            <ScoreTableCell
              score={getScore(
                parseInt(rowData.web_traffic_growth.avg_last_6_months),
                parseInt(rowData.web_traffic_growth.last_6_months)
              )}
            />
          ) : (
            <TableCell>-</TableCell>
          )}
        </>
      )
  }
}