import axios, { AxiosError } from "axios"
import { getToken } from "../utils/localStorage"

const API_URL = process.env.REACT_APP_API_URL

if (!API_URL) {
  throw new Error("API URL environment variable not set")
}

export function getAbsoluteURL(relativePath: string): string {
  return new URL(relativePath, API_URL).toString()
}

type ApiHeaders = {
  "Content-Type": string
  Authorization?: string
}

export const defaultHeaders = (token: string | null) => {
  const headers: ApiHeaders = {
    "Content-Type": "application/json",
  }

  if (token) {
    headers.Authorization = `Token ${token}`
  }

  return headers
}

export const deleteApiCall = async <T>(endpoint: string) => {
  const token = getToken()
  const headers = defaultHeaders(token)

  try {
    const response = await axios.delete(getAbsoluteURL(endpoint), {
      headers,
    })
    return response.data as T
  } catch (e: any) {
    console.log(e)
  }
}

export const fetchApiCall = async <T>(
  endpoint: string,
  params?: any,
  errorCallback?: (response: AxiosError | null) => void,
  data?: any
) => {
  const token = getToken()
  const headers = defaultHeaders(token)

  try {
    const response = await axios.get(getAbsoluteURL(endpoint), {
      headers,
      data,
      params,
    })
    return response.data as T
  } catch (e: any) {
    if (errorCallback) {
      errorCallback(e as AxiosError)
    }
    console.log(e)
  }
}

export const postApiCall = async <T>(
  endpoint: string,
  payload: any,
  params?: any,
  errorCallback?: (response: AxiosError | null) => void
) => {
  const token = getToken()
  const headers = defaultHeaders(token)

  try {
    const response = await axios.post(getAbsoluteURL(endpoint), payload, {
      headers,
      params,
    })
    return response.data as T
  } catch (e: any) {
    if (errorCallback) {
      errorCallback(e as AxiosError)
    }
    console.log(e)
  }
}

export const postApiCallWithFile = async <T>(
  endpoint: string,
  payload: FormData,
  params?: any,
  errorCallback?: (response: AxiosError | null) => void
) => {
  const token = getToken()
  const headers = {
    ...defaultHeaders(token),
    "Content-Type": "multipart/form-data"  // this line is important for file uploads
  }

  try {
    const response = await axios.post(getAbsoluteURL(endpoint), payload, {
      headers,
      params,
    })
    return response.data as T
  } catch (e: any) {
    if (errorCallback) {
      errorCallback(e as AxiosError)
    }
    console.log(e)
  }
}

export const patchApiCall = async <T>(
  endpoint: string,
  payload: any,
  params?: any
) => {
  const token = getToken()
  const headers = defaultHeaders(token)

  try {
    const response = await axios.patch(getAbsoluteURL(endpoint), payload, {
      headers,
      params,
    })
    return response.data as T
  } catch (e: any) {
    console.log(e)
  }
}

export const putApiCall = async <T>(
  endpoint: string,
  payload: any,
  params?: any
) => {
  const token = getToken()
  const headers = defaultHeaders(token)

  try {
    const response = await axios.put(getAbsoluteURL(endpoint), payload, {
      headers,
      params,
    })
    return response.data as T
  } catch (e: any) {
    console.log(e)
  }
}
