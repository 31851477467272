import { Box, Typography } from "@mui/material"
import { DateTime } from "luxon"

import { PinnedAd } from "../../../../api/creativeCenter/pinnedAds"
import { PinnedAdActive } from "../../../../components/AdActiveStatus"
import { AdCardCallToActionButton } from "../../../../components/AdCardCallToActionButton"
import { FbAdPublisherPlatform } from "../../../../components/PublisherPlatforms"

export const PinnedAdFooter = ({
  pinnedAd,
  onSelect,
}: {
  pinnedAd: PinnedAd
  onSelect: VoidFunction
}) => {
  return (
    <Box
      onClick={onSelect}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        mt: "16px",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          width: "60%",
        }}
      >
        <PinnedAdActive endDateString={pinnedAd.fb_ad.enddate} />
        <Typography
          variant="subtitle2"
          fontWeight="normal"
          sx={{ mt: "18px", mb: "5px" }}
        >
          Started:
          {DateTime.fromSeconds(parseInt(pinnedAd.fb_ad.startdate)).toFormat(
            "dd MMM, yyyy"
          )}
        </Typography>
        <Typography variant="subtitle2" fontWeight="normal">
          ID: {pinnedAd.fb_ad.adarchiveid}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "40%",
        }}
      >
        <Box
          sx={{
            height: "40px",
          }}
        >
          {pinnedAd.fb_ad.cta_type && (
            <AdCardCallToActionButton
              ctaType={pinnedAd.fb_ad.cta_type}
              link={pinnedAd.fb_ad.link_url || ""}
            />
          )}
        </Box>
        <Box sx={{ mt: "18px" }} />
        <FbAdPublisherPlatform
          publisherPlatformString={pinnedAd.fb_ad.publisherplatform || ""}
        />
      </Box>
    </Box>
  )
}
