import React, { useState } from "react"
import { Button, Checkbox as MUICheckbox, Grid, Tab, Tabs, styled, Typography } from "@mui/material"

import TabPanel from "../brand/tabs/TabPanel"
import { FilterTypes } from "./types"
import Modal from "../../components/Modal"

interface FilterChoiceProps {
  onConfirm(e: React.MouseEvent<unknown>): void
  open: boolean
  choices: FilterTypes
  handleCheckbox(e: React.ChangeEvent<HTMLInputElement>, name: string): void
  onClose: () => void
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Wrapper = styled("div")({
  display: "flex"
})

const Checkbox = styled(MUICheckbox)({
  top: "-1px"
})

const Label = styled(Typography)({
  marginLeft: "5px"
})

export default function FilterChoices({ onConfirm, open, choices, handleCheckbox, onClose }: FilterChoiceProps): React.ReactElement {
  const [tabIndex, setTabIndex] = useState(0)
  const [trustpilotReviews, setTrustpilotReviews] = useState<boolean>(choices.Trustpilot_Reviews)
  const [trustpilotDays, setTrustpilotDays] = useState<boolean>(choices.Trustpilot_Reviews_30_Days)
  const [facebookReviews, setFacebookReviews] = useState<boolean>(choices.Facebook_Reviews)
  const [facebookDays, setFacebookDays] = useState<boolean>(choices.Facebook_Reviews_30_Days)
  const [shopifyReviews, setShopifyReviews] = useState<boolean>(choices.Shopify_Reviews)
  const [shopifyDays, setShopifyDays] = useState<boolean>(choices.Shopify_Reviews_30_Days)
  const [redditMentions, setRedditMentions] = useState<boolean>(choices.Reddit_Mentions)
  const [fbLikes, setFbLikes] = useState<boolean>(choices.Facebook_Likes)
  const [fbLikesGrowth, setFbLikesGrowth] = useState<boolean>(choices.Facebook_Likes_growth)
  const [IgFollowers, setIgFollowers] = useState<boolean>(choices.IG_Followers)
  const [IgFollowersGrowth, setIgFollowersGrowth] = useState<boolean>(choices.IG_Followers_Growth)
  const [fbAdsCount, setFbAdsCount] = useState<boolean>(choices.FB_Ads_Count)
  const [fbAdsGrowthDays, setFbGrowthDays] = useState<boolean>(choices.FB_Ads_growth_30_days)
  const [activeAds, setActiveAds] = useState<boolean>(choices.Active_ads)
  const [adsRunningDays, setAdsRunningDays] = useState<boolean>(choices.Ads_running_over_30_days)
  const [estimatedTraffic, setEstimatedTraffic] = useState<boolean>(choices.Estimated_monthly_traffic)
  const [estimatedOrders, setEstimatedOrders] = useState<boolean>(choices.Estimated_monthly_orders)
  const [estimatedRevenue, setEstimatedRevenue] = useState<boolean>(choices.Estimated_monthly_revenue)
  const [numberProducts, setNumberProducts] = useState<boolean>(choices.Number_of_products)
  const [productMinimum, setProductMinimum] = useState<boolean>(choices.Product_price_minimum)
  const [productAverage, setProductAverage] = useState<boolean>(choices.Product_price_average)
  const [productMaximum, setProductMaximum] = useState<boolean>(choices.Product_price_maximum)
  const [newProductsDays, setNewProductsDays] = useState<boolean>(choices.New_products_last_30_days)

  function handleTabChanged(event: React.ChangeEvent<unknown>, newTabIndex: number) {
    setTabIndex(newTabIndex)
  }

  function checkBox(e: React.ChangeEvent<HTMLInputElement>, name: string) {
    switch (name) {
      case "Trustpilot_Reviews": setTrustpilotReviews(e.target.checked)
        break
      case "Trustpilot_Reviews_30_Days": setTrustpilotDays(e.target.checked)
        break
      case "Facebook_Reviews": setFacebookReviews(e.target.checked)
        break
      case "Facebook_Reviews_30_Days": setFacebookDays(e.target.checked)
        break
      case "Shopify_Reviews": setShopifyReviews(e.target.checked)
        break
      case "Shopify_Reviews_30_Days": setShopifyDays(e.target.checked)
        break
      case "Reddit_Mentions": setRedditMentions(e.target.checked)
        break
      case "Facebook_Likes": setFbLikes(e.target.checked)
        break
      case "Facebook_Likes_growth": setFbLikesGrowth(e.target.checked)
        break
      case "IG_Followers": setIgFollowers(e.target.checked)
        break
      case "IG_Followers_Growth": setIgFollowersGrowth(e.target.checked)
        break
      case "FB_Ads_Count": setFbAdsCount(e.target.checked)
        break
      case "FB_Ads_growth_30_days": setFbGrowthDays(e.target.checked)
        break
      case "Active_ads": setActiveAds(e.target.checked)
        break
      case "Ads_running_over_30_days": setAdsRunningDays(e.target.checked)
        break
      case "Estimated_monthly_traffic": setEstimatedTraffic(e.target.checked)
        break
      case "Estimated_monthly_orders": setEstimatedOrders(e.target.checked)
        break
      case "Estimated_monthly_revenue": setEstimatedRevenue(e.target.checked)
        break
      case "Number_of_products": setNumberProducts(e.target.checked)
        break
      case "Product_price_minimum": setProductMinimum(e.target.checked)
        break
      case "Product_price_average": setProductAverage(e.target.checked)
        break
      case "Product_price_maximum": setProductMaximum(e.target.checked)
        break
      case "New_products_last_30_days": setNewProductsDays(e.target.checked)
    }
    handleCheckbox(e, name)
  }

  return (
    <Modal
      aria-labelledby="filter-modal-title"
      aria-describedby="filter-modal-description"
      open={open}
      handleClose={onClose}
      maxWidth="sm"
      hideHeader
    >
      <div className="v1 filterChoicePopup">
        <h5>Select filters</h5>
        <Grid item xs={12}>
          <Tabs textColor="inherit" value={tabIndex} onChange={handleTabChanged} aria-label="Filter tabs">
            <Tab label="SOCIAL" {...a11yProps(0)} />
            <Tab label="REVIEWS" {...a11yProps(1)} />
            <Tab label="FACEBOOK ADS" {...a11yProps(2)} />
            <Tab label="STORE" {...a11yProps(3)} />
            <Tab label="PRODUCTS" {...a11yProps(4)} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={tabIndex} index={0}>
            <div className="popSocial">
              <Typography variant="h5">Facebook</Typography>
              <Wrapper>
                <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={fbLikes} onChange={e => checkBox(e, "Facebook_Likes")} />
                <Label variant="subtitle2">Likes</Label>
              </Wrapper>
              <Wrapper>
                <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={fbLikesGrowth} onChange={e => checkBox(e, "Facebook_Likes_growth")} />
                <Label variant="subtitle2">Likes growth - 30 days</Label>
              </Wrapper>
            </div>
            <div className="popSocial">
              <Typography variant="h5">Instagram</Typography>
              <Wrapper>
                <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={IgFollowers} onChange={e => checkBox(e, "IG_Followers")} />
                <Label variant="subtitle2">Followers</Label>
              </Wrapper>
              <Wrapper>
                <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={IgFollowersGrowth} onChange={e => checkBox(e, "IG_Followers_Growth")} />
                <Label variant="subtitle2">Followers growth - 30 days</Label>
              </Wrapper>
            </div>
            <div className="popSocial">
              <Typography variant="h5">Reddit</Typography>
              <Wrapper>
                <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={redditMentions} onChange={e => checkBox(e, "Reddit_Mentions")} />
                <Label variant="subtitle2">Mentions</Label>
              </Wrapper>
            </div>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <div className="popSocial">
              <Typography variant="h5">Shopify Reviews</Typography>
              <Wrapper>
                <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={shopifyReviews} onChange={e => checkBox(e, "Shopify_Reviews")} />
                <Label variant="subtitle2">Number of reviews</Label>
              </Wrapper>
              <Wrapper>
                <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={shopifyDays} onChange={e => checkBox(e, "Shopify_Reviews_30_Days")} />
                <Label variant="subtitle2">Reviews - 30 days</Label>
              </Wrapper>
            </div>
            <div className="popSocial">
              <Typography variant="h5">Trustpilot Reviews</Typography>
              <Wrapper>
                <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={trustpilotReviews} onChange={e => checkBox(e, "Trustpilot_Reviews")} />
                <Label variant="subtitle2">Number of Reviews</Label>
              </Wrapper>
              <Wrapper>
                <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={trustpilotDays} onChange={e => checkBox(e, "Trustpilot_Reviews_30_Days")} />
                <Label variant="subtitle2">Reviews - 30 days</Label>
              </Wrapper>
            </div>
            <div className="popSocial">
              <Typography variant="h5">Facebook Reviews</Typography>
              <Wrapper>
                <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={facebookReviews} onChange={e => checkBox(e, "Facebook_Reviews")} />
                <Label variant="subtitle2">Number of Reviews</Label>
              </Wrapper>
              <Wrapper>
                <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={facebookDays} onChange={e => checkBox(e, "Facebook_Reviews_30_Days")} />
                <Label variant="subtitle2">Reviews - 30 days</Label>
              </Wrapper>
            </div>
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <Wrapper>
              <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={fbAdsCount} onChange={e => checkBox(e, "FB_Ads_Count")} />
              <Label variant="subtitle2">FB Ads count</Label>
            </Wrapper>
            <Wrapper>
              <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={fbAdsGrowthDays} onChange={e => checkBox(e, "FB_Ads_growth_30_days")} />
              <Label variant="subtitle2">FB Ads growth - 30 days</Label>
            </Wrapper>
            <Wrapper>
              <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={activeAds} onChange={e => checkBox(e, "Active_ads")} />
              <Label variant="subtitle2">Active ads</Label>
            </Wrapper>
            <Wrapper>
              <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={adsRunningDays} onChange={e => checkBox(e, "Ads_running_over_30_days")} />
              <Label variant="subtitle2">Ads running - over 30 days</Label>
            </Wrapper>
          </TabPanel>
          <TabPanel value={tabIndex} index={3}>
            <Wrapper>
              <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={estimatedTraffic} onChange={e => checkBox(e, "Estimated_monthly_traffic")} />
              <Label variant="subtitle2">Estimated monthly traffic</Label>
            </Wrapper>
            <Wrapper>
              <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={estimatedOrders} onChange={e => checkBox(e, "Estimated_monthly_orders")} />
              <Label variant="subtitle2">Estimated monthly orders</Label>
            </Wrapper>
            <Wrapper>
              <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={estimatedRevenue} onChange={e => checkBox(e, "Estimated_monthly_revenue")} />
              <Label variant="subtitle2">Estimated monthly revenue</Label>
            </Wrapper>
          </TabPanel>
          <TabPanel value={tabIndex} index={4}>
            <Wrapper>
              <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={numberProducts} onChange={e => checkBox(e, "Number_of_products")} />
              <Label variant="subtitle2">Number of products</Label>
            </Wrapper>
            <Wrapper>
              <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={productMinimum} onChange={e => checkBox(e, "Product_price_minimum")} />
              <Label variant="subtitle2">Product price minimum</Label>
            </Wrapper>
            <Wrapper>
              <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={productAverage} onChange={e => checkBox(e, "Product_price_average")} />
              <Label variant="subtitle2">Product price average</Label>
            </Wrapper>
            <Wrapper>
              <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={productMaximum} onChange={e => checkBox(e, "Product_price_maximum")} />
              <Label variant="subtitle2">Product price maximum</Label>
            </Wrapper>
            <Wrapper>
              <Checkbox color="default" inputProps={{ "aria-label": "checkbox with default color" }} checked={newProductsDays} onChange={e => checkBox(e, "New_products_last_30_days")} />
              <Label variant="subtitle2">New products - last 30 days</Label>
            </Wrapper>
          </TabPanel>
        </Grid>
        <Button variant="contained" color="primary" onClick={onConfirm}>Confirm</Button>
      </div>
    </Modal>
  )
}
