import { Box, Chip, Typography } from "@mui/material"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import ApexChart from "../../../components/Chart/ApexChart"
import { ApexChartData } from "../../../components/Chart/types"
import { RootStore } from "../../../Redux/store"
import { FbAdsCount } from "../common"
import { getChipColor } from "../../../utils/statusHelpers"

export const LifetimeUniqueBrandAdCountChart = () => {
  const fbAdsCount: FbAdsCount[] | undefined = useSelector(
    (state: RootStore) => state.PageReduser["FbAdsCount"] as FbAdsCount[]
  )
  const fbAdsCountData: FbAdsCount | undefined = useSelector(
    (state: RootStore) => state.PageReduser["FbAdsCountData"] as FbAdsCount
  )

  const chartData: ApexChartData = useMemo(() => {
    if (!fbAdsCount) {
      return {
        name: "Lifetime Ad Count",
        series: [],
      }
    }
    return {
      name: "Lifetime Ad Count",
      series: fbAdsCount.map((value: FbAdsCount) => {
        return {
          x: new Date(value.timestamp).toUTCString(),
          y: value.unique_ads,
        }
      }),
    }
  }, [fbAdsCount])

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pl: "0px",
          mt: "0px",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: "#3D4465 !important",
            fontSize: "16px !important",
            mt: "30px !important",
            pl: "30px !important",
          }}
        >
          Lifetime Unique Ad Count
        </Typography>
        <Chip
          label={fbAdsCountData?.unique_ads?.toString()}
          sx={{
            borderRadius: 4,
            marginRight: "10px",
            "& span": {
              padding: 0,
              fontFamily: "Poppins,sans-serif !important",
              fontWeight: 500,
              fontSize: "24px",
              lineHeight: "36px",
            },
            backgroundColor: "transparent !important",
            color: getChipColor(fbAdsCountData?.unique_ads?.toString() || "1")
          }}
        />
      </Box>
      <ApexChart type="area" chartSeries={chartData} />
    </>
  )
}
