import { fetchApiCall, postApiCall } from "."
import { getAbsoluteURL } from "../utils"

export type NewBrand = {
  brand_id: number
  brandname: string
  brandsite: string
  shopifysite: string
  [index: string]: any
}

type ChartValues = {
  date: string
  data: number
}

export type OverviewCharts = {
  "date-period": {
    end_date: string
    start_date: string
  }
  facebook_ad_count: ChartValues[]
  facebook_likes: ChartValues[]
  instagram_followers: ChartValues[]
  percentage_change: {
    facebook_ad_count: number
    facebook_likes: number
    instagram_followers: number
    reddit_mentions: number
    trustpilot_reviews: number
    website_traffic: number
  }
  reddit_mentions: ChartValues[]
  trustpilot_reviews: ChartValues[]
  website_traffic: ChartValues[]
  [key: string]: any
}

export type RevenueData = {
  estimated_monthly_revenue: string
}

export const fetchOverviewCharts = async (brandId: string, duration = "90") => {
  const endpoint = getAbsoluteURL(
    `api/brand/charts/${brandId}/?duration=${duration}`
  )

  return fetchApiCall<OverviewCharts>(endpoint)
}

export const fetchRevenueData = async (brandName: string) => {
  const endpoint = getAbsoluteURL(`api/revenue-data/${brandName}`)

  return fetchApiCall<RevenueData>(endpoint)
}

export const createBrand = async (payload: any) => {
  const endpoint = "api/create-brand/"

  return postApiCall<NewBrand>(endpoint, payload)
}
