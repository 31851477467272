import { Breakpoint } from "@mui/material"

export const headingStyle = {
  fontFamily: "Poppins !important",
  fontWeight: 500,
  fontSize: "18px",
  mt: "0px",
  lineHeight: "25px",
}

export const modalStyle = (maxWidth?: Breakpoint) => {
  return {
    display: "block",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "5%",
    zIndex: 99,
    maxWidth: maxWidth || "500px",
    height: "auto",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08)",
    padding: "32px",
  }
}
