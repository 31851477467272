import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import { Board } from "../api/creativeCenter/boards"

type Props = {
  name: string
  label?: string
  defaultValue?: number
  value?: number
  onChange: (boardId: number) => void
  placeholder?: string
  error?: string
  disabled?: boolean
  required?: boolean
  boards: Board[]
  loading: boolean
}

export default function BoardSelect(props: Props) {
  const {
    name,
    label,
    onChange,
    defaultValue,
    value,
    disabled,
    required,
    boards,
    loading,
  } = props

  if (loading) {
    return <CircularProgress />
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="board-select">{label || "Boards"}</InputLabel>
      <Select
        labelId="board-select"
        required={required}
        name={name}
        label={label || "Board"}
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        MenuProps={{ disablePortal: true }}
        onChange={(e) => onChange(e.target.value as number)}
      >
        {boards?.map((board) => (
          <MenuItem key={board.id} value={board.id}>
            {board.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
