import { Paper, Slider, styled } from "@mui/material"
import ValueLabel from "./tooltip"
import { colors } from "../../../utils/theme"
//---------------------------------------------------------------

const CustomSlider = styled(Slider)({
  height: 4,
  paddingTop: "8px",
  paddingBottom: "8px",
  marginTop: "9px",
  width: "100%",
  "& .MuiSlider-rail": {
    top: 5,
    height: 12,
  },
  "& .MuiSlider-track": {
    height: 12,
    borderRadius: 0,
    backgroundColor: "#6372E6",
    top: 5,
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    top: "5px",
    backgroundColor: colors.White,
    "&:focus, &:hover, &$active": {},
    borderRadius: 50,
  }
})

type RangeSliderProps = {
  values: number[]
  onRangeChange(event: any, newValue: number | number[]): void
}

export default function RangeSlider({ values, onRangeChange }: RangeSliderProps) {

  return (
    <Paper
      sx={{
        backgroundColor: "rgba(59, 78, 223, .1)",
        pt: "12px",
        pb: "12px",
        pl: "40px",
        pr: "58px",
        ml: "30px",
        boxShadow: "none",
        borderRadius: "5px",
        height: "26px",
        flexGrow: 1,
      }}
    >
      <CustomSlider
        value={values}
        onChange={onRangeChange}
        valueLabelDisplay="on"
        slots={{ valueLabel: ValueLabel }}
      />
    </Paper>
  )
}
