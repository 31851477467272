import { Box } from "@mui/material"

import {
  Description,
  Header,
  Image,
} from "./EmptyComponentStyles"
import { EmptyComponentProps } from "./types"
//--------------------------------------------------------------------------------------------

export default function EmptyComponent({ title, detail, image }: EmptyComponentProps) {

  return (
    <Box sx={{
      width: "700px"
    }}>
      <Header variant="h4">{title}</Header>
      {image && <Image src={image} alt={title} />}
      <Description variant="body1">{detail}</Description>
    </Box>
  )
}