import { postApiCall } from "."
import { getAbsoluteURL } from "../utils"

export enum ViewType {
  brandView = 0,
  reportView = 1,
}

export const trackBrand = async (brandId: number, viewType: number) => {
  const endpoint = getAbsoluteURL("fc/track/")
  const payload = {
    view_type: viewType,
    brand_id: brandId,
  }

  return postApiCall(endpoint, payload)
}