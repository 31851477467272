import { ScoreColumn, ScoreStatus } from "../V1/search-brand/types"
import { colors } from "./theme"

export const getChipColor = (label: string) => {
  if (!label.startsWith("-") && label !== "0") {
    return colors.Success
  } else if (label.startsWith("-")) {
    return colors.Error
  } else {
    return colors.Text
  }
}

export const getScoreIconColor = (score: ScoreColumn) => {
  if (score.percentage > 0) {
    return "#0B825D"
  } else if (score.percentage < 0) {
    return "#CD3D64"
  } else {
    return "default"
  }
}

export const getScoreChipBgColor = (score: ScoreColumn) => {
  if (score.status == ScoreStatus.Good) {
    return "#CBF4C9"
  } else if (score.status == ScoreStatus.Warn) {
    return "#F6E5B9"
  } else {
    return "#FEE2DD"
  }
}

export const getScoreChipColor = (score: ScoreColumn) => {
  if (score.status == ScoreStatus.Good) {
    return "#0B825D"
  } else if (score.status == ScoreStatus.Warn) {
    return "#C44C35"
  } else {
    return "#CD3D64"
  }
}