export const SettingsIcon = ({
  width,
  height,
  color,
}: {
  width?: string
  height?: string
  color?: string
}) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5198 9.16343L14.2088 8.98971C13.8386 8.78343 13.6174 8.41314 13.6174 8C13.6174 7.58686 13.8386 7.21657 14.2088 7.01029L14.5198 6.83657C15.369 6.36229 15.6594 5.31543 15.1691 4.49486L14.5778 3.50514C14.0887 2.68629 13.0013 2.40514 12.1545 2.87771L11.8434 3.05086C11.4732 3.25771 11.0303 3.25771 10.6607 3.05086C10.2905 2.844 10.0694 2.47429 10.0694 2.06114V1.71429C10.0694 0.769143 9.27343 0 8.29534 0H7.11266C6.13457 0 5.33862 0.769143 5.33862 1.71429V2.06171C5.33862 2.47486 5.11746 2.84457 4.74728 3.05143C4.3771 3.25771 3.93477 3.25829 3.56459 3.05143L3.25354 2.87771C2.40674 2.40514 1.31926 2.68629 0.829623 3.50514L0.238278 4.49486C-0.251946 5.31543 0.038404 6.36286 0.887574 6.83657L1.19921 7.01029C1.56939 7.21657 1.79056 7.58686 1.79056 8C1.79056 8.41314 1.56939 8.78343 1.19921 8.98971L0.888166 9.16343C0.0389953 9.63714 -0.251355 10.6846 0.23887 11.5051L0.830214 12.4949C1.31985 13.3137 2.40733 13.5949 3.25354 13.1223L3.56459 12.9491C3.93477 12.7417 4.3771 12.7429 4.74728 12.9491C5.11746 13.156 5.33862 13.5257 5.33862 13.9389V14.2857C5.33862 15.2309 6.13457 16 7.11266 16H8.29534C9.27343 16 10.0694 15.2309 10.0694 14.2857V13.9383C10.0694 13.5251 10.2905 13.1554 10.6607 12.9486C11.0303 12.7423 11.4732 12.7417 11.8434 12.9486L12.1545 13.1223C13.0013 13.5943 14.0887 13.3131 14.5778 12.4949L15.1691 11.5051C15.6594 10.6846 15.369 9.63714 14.5198 9.16343ZM7.704 10.8571C6.07366 10.8571 4.74728 9.57543 4.74728 8C4.74728 6.42457 6.07366 5.14286 7.704 5.14286C9.33434 5.14286 10.6607 6.42457 10.6607 8C10.6607 9.57543 9.33434 10.8571 7.704 10.8571Z"
        fill={color}
      />
    </svg>
  )
}
