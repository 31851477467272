/* eslint-disable */
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import Add from "@mui/icons-material/Add"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import Logout from "@mui/icons-material/Logout"
import IconButton from "@mui/material/IconButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { styled } from "@mui/material/styles"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"

import Settings from "../../../assets/images/v1-icons/setting.svg"
import Actions from "../../../pages/Actions/PageActions"
import { RootStore } from "../../../Redux/store"
import {
  ChromeExtensionEventType,
  sendChromeExtensionMessage,
} from "../../../utils/chromeExtension"
import { useAuth } from "../../Hooks/usAuthContext"
import { createBrand } from "../../../api/brand"

const Input = styled("input")({
  display: "none",
})

export const AvatarMenu = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const { setUser } = useAuth()

  const subscriptionLoaded: boolean = useSelector(
    (state: RootStore) => state.PageReduser["subscriptionLoaded"]
  )

  const token = localStorage.getItem("token")
  const isSuperUser = localStorage.getItem("is_superuser")

  React.useEffect(() => {
    if (!subscriptionLoaded) {
      dispatch(Actions.GetSubscriptionUsage())
    }
  }, [token])

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleBulkUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    const data = { file: files && files[0] }
    if (files) {
      await createBrand(data)
    }
  }

  const handleLogout = async () => {
    setUser(null)
    await localStorage.clear()
    await sendChromeExtensionMessage({
      event: ChromeExtensionEventType.LOG_OUT,
      data: {},
    })
    history.push("/login")
  }

  return (
    <div>
      <IconButton onClick={handleMenuClick}>
        <AccountCircleIcon fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClick={handleMenuClose}
        onClose={handleMenuClose}
        className="v1 avtarPopup"
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {isSuperUser ? (
          <div key="superuser-menu">
            <MenuItem
              key="add-brand"
              onClick={() => history.push("/add-brand")}
            >
              <ListItemIcon>
                <Add fontSize="small" />
              </ListItemIcon>
              Add Brand
            </MenuItem>
            <MenuItem key="upload-csv" title="Upload a csv or excel file.">
              <ListItemIcon>
                <FileUploadIcon fontSize="small" />
              </ListItemIcon>
              <label htmlFor="upload-file">
                <Input
                  id="upload-file"
                  type="file"
                  onChange={handleBulkUpload}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                Brand Bulk Upload
              </label>
            </MenuItem>
          </div>
        ) : (
          ""
        )}
        <MenuItem onClick={() => history.push("/settings")}>
          <ListItemIcon>
            <img src={Settings} />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  )
}