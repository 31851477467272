// @mui
import LockIcon from "@mui/icons-material/Lock"
import { styled, SxProps, Theme } from "@mui/system"
import { useState } from "react"
import SubscriptionModal from "../Modals/subscriptionModal"

type Props = {
  name: string
  sx: SxProps<Theme> | undefined
  [propName: string]: any
}

const IconButton = styled("button")({
  background: "#683BB7 !important",
  boxSizing: "border-box",
  color: "#FFFF",
  cursor: "pointer",
  lineHeight: "35px",
  width: "300px",
  height: "38px",
  border: "1px solid rgba(0, 40, 100, 0.12)",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.075)",
  borderRadius: "6px",
  "& svg": {
    fill: "#FFFF !important",
    left: "-5px !important",
  }
})

export const LockButton = ({ name, sx, ...rest }: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const toggleSubscriptionModal = () => {
    setModalOpen(!modalOpen)
  }

  return (
    <>
      <IconButton
        sx={sx}
        onClick={toggleSubscriptionModal}
        {...rest}
      >
        <LockIcon fontSize="small" />
        {`Upgrade to see ${name}`}
      </IconButton>
      <SubscriptionModal open={modalOpen} handleClose={toggleSubscriptionModal} />
    </>
  )
}