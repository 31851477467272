import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CancelIcon from "@mui/icons-material/Close"
import InfoIcon from "@mui/icons-material/Info"
import StarIcon from "@mui/icons-material/Star"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import { Avatar, Box, Grid, IconButton, Paper, Typography } from "@mui/material"
import { DateTime } from "luxon"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { Publishers, featureAd } from "../../../api/trendingAds"
import { Icon } from "../../../components/Icons/adFooterIcon"
import { IconWrapper } from "../../../components/IconWrapper/adIconWrapper"
import { CTALink } from "../../../components/Links/adCtaLink"
import { RootStore } from "../../../Redux/store"
import { colors } from "../../../utils/theme"
import VideoPlayer from "../../Components/VideoPlayer/videoPlayer"
import { getCtaType } from "../../Trending/TrendingAds/Constants"
import { BrandItem, FbAds } from "../common"
import Iconify from "../../../components/Iconify"
import { useLayout } from "../../Hooks/useLayoutContext"
import { truncateText } from "../../../utils/textHelpers"
import { UserRoles, getUserRole } from "../../../utils/localStorage"
import { FbAd } from "../../../api/ads"
import { Board } from "../../../api/creativeCenter/boards"

export default function FacebookAdCard({
  facebookAd,
  viewDetail,
}: {
  facebookAd: FbAd
  viewDetail: (ad: FbAd) => void
  boards: Board[]
  boardsLoading: boolean
}): React.ReactElement {
  const brandItem: BrandItem = useSelector(
    (state: RootStore) => state.PageReduser["brandItem"]
  )
  const FbAds: FbAds = useSelector(
    (state: RootStore) => state.PageReduser["FbAds"]
  )
  const { menuIsExpanded } = useLayout()
  const dispatch = useDispatch()
  const userRole = getUserRole()
  const isAdminOrTester =
    userRole === UserRoles.ADMIN || userRole === UserRoles.TESTER

  const getImage = (adImage: string | null) => {
    if (adImage === "N/A" || adImage === null) {
      return (
        <Iconify
          width={200}
          height={200}
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
          icon={"icons8:advertising"}
          color={colors.Icon}
        />
      )
    }

    return (
      <img
        style={{
          objectFit: "cover",
          height: "100%",
          width: "100%",
        }}
        src={adImage}
        alt="Ad-image"
      />
    )
  }

  const handleFeature = async (fbAd: FbAd) => {
    if (!fbAd.is_featured) {
      dispatch({ type: "Loading", payload: true })
      const payload = {
        ad: fbAd.adarchiveid,
        page_id: brandItem.page_id,
      }
      const response = await featureAd(payload)
      if (response) {
        const data = FbAds
        data.results.forEach((ad) => {
          if (ad.adarchiveid === fbAd.adarchiveid) {
            ad.is_featured = true
          }
        })
        dispatch({ type: "FbAds", payload: data })
        dispatch({ type: "Loading", payload: false })
      }
    }
  }

  function renderPublisherPlatform(data: string): string[] {
    const filter = data.replace(/[[\]' ]+/g, "")
    return filter.split(",")
  }

  return (
    <Grid
      item
      xs={12}
      md={menuIsExpanded ? 6 : 4}
      lg={menuIsExpanded ? 4 : 3}
      xl={3}
      sx={{
        padding: "8px",
        position: "relative",
      }}
    >
      {userRole === UserRoles.ADMIN && (
        <IconButton
          sx={{
            position: "absolute",
            right: "7px",
            top: "7px",
            border: "none",
          }}
          onClick={() => handleFeature(facebookAd)}
        >
          {facebookAd.is_featured ? (
            <StarIcon fontSize="small" titleAccess="Featured ad" />
          ) : (
            <StarBorderIcon fontSize="small" titleAccess="Feature this ad" />
          )}
        </IconButton>
      )}
      {isAdminOrTester && (
        <IconButton
          sx={{
            position: "absolute",
            right: "33px",
            top: "7px",
            border: "none",
          }}
          onClick={() => viewDetail(facebookAd)}
        >
          <InfoIcon
            sx={{ color: colors.Icon }}
            fontSize="small"
            titleAccess="View Details"
          />
        </IconButton>
      )}
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
          background: "#fff !important",
          boxShadow: "0 5px 20px 3px rgba(23,3,89,.08) !important",
          borderRadius: "10px !important",
          padding: "20px",
          border: "none",
        }}
        elevation={4}
      >
        <Box
          sx={{
            width: "100%",
            "& a": {
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "27px",
              color: colors.Text,
              fontFamily: "Poppins,sans-serif !important",
              textDecoration: "none",
            },
            "& p": {
              fontFamily: "Poppins,sans-serif !important",
              fontSize: "12px",
              lineHeight: "18px",
              color: colors.Text,
              height: "54px",
              mt: "10px",
              mb: "20px",
            },
          }}
        >
          <Avatar
            src={brandItem.profileimage}
            variant="circular"
            sx={{
              width: "28px",
              height: "28px",
              display: "inline-block",
              verticalAlign: "middle",
              mr: "10px",
            }}
          />
          <a
            href={`/brand/${brandItem.brandname.replaceAll(" ", "-")}/${
              brandItem.brand_id
            }`}
            target="_blank"
          >
            {brandItem.brandname}
          </a>

          <p>{truncateText(facebookAd.ad_content, 150)}</p>
          <Box
            sx={{
              margin: "0",
              width: "100%",
              height: "312px",
              background: "#d3d3d3",
              borderRadius: "5px",
              position: "relative",
              display: "inline-block",
              overflow: "hidden",
            }}
          >
            {facebookAd.video_url && facebookAd.video_url !== "N/A" ? (
              <VideoPlayer sourceUrl={facebookAd.video_url} />
            ) : (
              getImage(facebookAd.image)
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "inline-block",
            width: "100%",
            pl: 0,
            pr: 0,
            pt: "25px",
            "& span": {
              width: "calc(100% - 118px)",
              display: "inline-block",
              verticalAlign: "top",
              fontWeight: "normal",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "Poppins, sans-serif !important",
              color: colors.Text,
            },
          }}
        >
          {facebookAd.isactive === 1 ? (
            <span>
              <CheckBoxIcon
                sx={{
                  fill: colors.Success,
                  borderRadius: "3px",
                  overflow: "hidden",
                  border: `1px solid ${colors.Success}`,
                  width: "16px",
                  height: "16px",
                  mr: "10px",
                  display: "inline-block",
                  verticalAlign: "middle",
                  position: "relative",
                }}
              />{" "}
              Active{" "}
            </span>
          ) : (
            <span>
              <CancelIcon
                sx={{
                  fill: colors.White,
                  borderRadius: "3px",
                  overflow: "hidden",
                  border: "1px solid rgba(59, 78, 223, 0.1)",
                  width: "16px",
                  height: "16px",
                  mr: "10px",
                  background: "rgba(59, 78, 223, 0.1)",
                  display: "inline-block",
                  verticalAlign: "middle",
                  position: "relative",
                }}
              />
              Inactive
            </span>
          )}
          <CTALink href={facebookAd.link_url} target="_blank">
            {getCtaType(facebookAd.cta_type)}
          </CTALink>
          {facebookAd.startdate && (
            <Box
              sx={{
                mt: "10px",
                width: "100%",
                "& span": {
                  fontFamily: "Poppins, sans-serif !important",
                  fontSize: "16px",
                },
              }}
            >
              <Typography variant="caption" component="span">
                Start :{" "}
                {DateTime.fromISO(
                  new Date(facebookAd.startdate).toISOString()
                ).toFormat("dd MMM yyyy")}
              </Typography>
            </Box>
          )}
          <p
            style={{
              fontSize: "14px",
              lineHeight: "24px",
              fontFamily: "Poppins, sans-serif !important",
              display: "inline-block",
              marginBottom: "0px",
            }}
          >
            ID: {facebookAd.adarchiveid}
          </p>
          {renderPublisherPlatform(facebookAd.publisherplatform).map(
            (publisher, index) => (
              <IconWrapper key={index}>
                {publisher === Publishers.instagram && (
                  <Icon className="fab fa-instagram" />
                )}
                {publisher === Publishers.facebook && (
                  <Icon className="fab fa-facebook-f" />
                )}
                {publisher === Publishers.messenger && (
                  <Icon className="fab fa-facebook-messenger" />
                )}
              </IconWrapper>
            )
          )}
        </Box>
      </Paper>
    </Grid>
  )
}
