import CancelIcon from "@mui/icons-material/Cancel"
import { styled } from "@mui/system"

import SubscriptionPlans from "../../V1/Components/SubscriptionPlans/plans"
import Modal from "../Modal"


type Props = {
  open: boolean,
  handleClose: () => void
}

const CloseButton = styled("button")({
  background: "none",
  border: "none",
  color: "#3b4edf",
  marginLeft: "97%",
  cursor: "pointer",
})


export default function SubscriptionModal({ open, handleClose }: Props) {

  return (
    <Modal
      aria-labelledby="subscription-modal-title"
      aria-describedby="subscription-modal-description"
      open={open}
      handleClose={handleClose}
    >
      <>
        <CloseButton onClick={handleClose}>
          <CancelIcon />
        </CloseButton>
        <SubscriptionPlans />
      </>
    </Modal>
  )
}