export const FaqIcon = ({
  width,
  height,
  color,
}: {
  width?: string
  height?: string
  color?: string
}) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.53551 10.9927C8.19694 10.9688 8.26177 10.646 8.32887 10.3923L8.8962 8.24717C8.96133 8.00089 8.92613 7.73884 8.79833 7.51848C8.67052 7.29812 8.46055 7.13744 8.21444 7.07168C7.96834 7.00592 7.7062 7.04044 7.48551 7.16767C7.26482 7.2949 7.1036 7.50447 7.0372 7.7504C6.95436 8.05716 6.2915 10.7928 6.28742 10.81C6.19472 11.1986 6.08385 11.6954 6.25475 12.0785C6.51403 12.6596 7.30146 12.4909 7.77988 12.3525C8.1085 12.2589 8.42304 12.1215 8.71505 11.944C9.04991 11.7156 9.36815 11.4638 9.6674 11.1904L9.60391 10.5523C9.34755 10.757 8.87778 11.0168 8.53551 10.9927Z"
        fill={color}
      />
      <path
        d="M8.76401 3.51392C8.0509 3.46923 7.3416 4.1103 7.31386 4.84492C7.30518 5.06254 7.36151 5.27782 7.47566 5.46331C7.9184 6.17969 8.93555 6.02368 9.48544 5.52923C10.2112 4.87663 9.72514 3.57417 8.76401 3.51392Z"
        fill={color}
      />
      <path
        d="M8.00006 1.96117e-10C6.4178 -1.10763e-05 4.87108 0.469173 3.55548 1.34822C2.23987 2.22727 1.21449 3.4767 0.608978 4.93851C0.00347013 6.40032 -0.154961 8.00885 0.153719 9.56071C0.462399 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88744 15.5376 6.43929 15.8463C7.99115 16.155 9.59968 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7601 14.6518 12.4445C15.5308 11.1289 16 9.5822 16 7.99994C15.9976 5.87896 15.154 3.84554 13.6542 2.34578C12.1545 0.846025 10.121 0.00240405 8.00006 1.96117e-10ZM8.00006 14.2815C6.75769 14.2815 5.54322 13.9131 4.51022 13.2229C3.47722 12.5327 2.67209 11.5517 2.19665 10.4039C1.7212 9.25609 1.5968 7.99309 1.83916 6.77459C2.08152 5.55609 2.67977 4.43682 3.55825 3.55833C4.43673 2.67983 5.55599 2.08156 6.77448 1.83918C7.99298 1.59679 9.25598 1.72118 10.4038 2.1966C11.5516 2.67202 12.5326 3.47714 13.2229 4.51012C13.9131 5.54311 14.2815 6.75757 14.2815 7.99994C14.2796 9.66533 13.6173 11.262 12.4397 12.4396C11.2621 13.6172 9.66544 14.2796 8.00006 14.2815Z"
        fill={color}
      />
    </svg>
  )
}
