import { Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { RootStore } from "../../../Redux/store"
import { BrandItem } from "../../brand/common"
import AdsTab from "../../brand/tabs/AdsTab"
import GoogleAdsTab from "../../brand/tabs/GoogleAdsTab"
import OverviewTab from "../../brand/tabs/OverviewTab"
import ProductsTab from "../../brand/tabs/ProductsTab"
import ReviewTrackerTab from "../../brand/tabs/ReviewTrackerTab"
import SocialTab from "../../brand/tabs/SocialTab"
import StoreTab from "../../brand/tabs/StoreTab"

interface Props {
  renderViabilityTabScreen: () => JSX.Element
}

const dropdownMenu = [
  "Overview",
  "Trend Viability",
  "Review Tracker",
  "Social",
  "Fb Ads",
  "Google Ads",
  "Traffic and Revenue",
  "Products",
]

export default function DropDownMenu(props: Props): JSX.Element {
  const dispatch = useDispatch()
  const brandItem: BrandItem = useSelector(
    (state: RootStore) => state.PageReduser["brandItem"]
  )
  const forcingOverviewTab: boolean = useSelector(
    (state: RootStore) => state.PageReduser["forceOverviewTab"]
  )
  const forcingReportTab: boolean = useSelector(
    (state: RootStore) => state.PageReduser["forceReportTab"]
  )

  const [selected, setSelected] = React.useState<string>("Overview")

  React.useEffect(() => {
    if (forcingReportTab) {
      setSelected("Trend Viability")
    }
    if (forcingOverviewTab) {
      setSelected("Overview")
      dispatch({ type: "ForceOverviewTab", payload: false })
    }
  }, [forcingReportTab, forcingOverviewTab, dispatch])

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    setSelected(e.target.value as string)
  }

  const handleNavigation = () => {
    switch (selected) {
      case "Overview":
        return <OverviewTab />
      case "Trend Viability":
        return props.renderViabilityTabScreen()
      case "Review Tracker":
        return <ReviewTrackerTab brandId={brandItem.brand_id} />
      case "Social":
        return <SocialTab />
      case "Fb Ads":
        return <AdsTab />
      case "Google Ads":
        return <GoogleAdsTab />
      case "Traffic and Revenue":
        return <StoreTab />
      case "Products":
        return <ProductsTab />
    }
  }

  return (
    <>
      <div className="mobileDropDown">
        <Select value={selected} onChange={handleChange}>
          {dropdownMenu.map((menu) => (
            <MenuItem value={menu}>{menu}</MenuItem>
          ))}
        </Select>
      </div>
      <Grid className="mobileTabContent">{handleNavigation()}</Grid>
    </>
  )
}
