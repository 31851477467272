import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material"
import { MouseEventHandler, useState } from "react"

import { PinnedAdBrand } from "../../../../api/creativeCenter/pinnedAds"
import Iconify from "../../../../components/Iconify"
import { colors } from "../../../../utils/theme"

import AdDetails from "../../../../assets/images/Creative/Icons/AdDetails.png"
import Delete from "../../../../assets/images/Creative/Icons/Delete.png"
import MoveAd from "../../../../assets/images/Creative/Icons/MoveAd.png"

export const PinnedAdHeader = ({
  pinnedAdBrand,
  onDeleteClicked,
  onSelect,
  onMove,
}: {
  pinnedAdBrand: PinnedAdBrand
  onDeleteClicked: VoidFunction
  onSelect: VoidFunction
  onMove: VoidFunction
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box
        onClick={onSelect}
        sx={{
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
        }}
      >
        <img
          src={pinnedAdBrand?.profileimage || ""}
          style={{
            width: "28px",
            height: "28px",
            display: "inline-block",
            marginRight: "11px",
            borderRadius: "50%",
          }}
        />
        <Typography>{pinnedAdBrand?.brandname}</Typography>
      </Box>
      <IconButton onClick={onOpen}>
        <Iconify icon={"eva:more-horizontal-fill"} width={20} height={20} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <PinnedAdCardOptionsMenu
          onDeleteClicked={onDeleteClicked}
          onSelect={onSelect}
          onMove={onMove}
        />
      </Menu>
    </Box>
  )
}

const PinnedAdCardOptionsMenu = ({
  onDeleteClicked,
  onSelect,
  onMove,
}: {
  onDeleteClicked: VoidFunction
  onSelect: VoidFunction
  onMove: VoidFunction
}) => {
  return (
    <MenuList
      sx={{
        paddingTop: 0,
        paddingBottom: 0,
        minWidth: 180,
        borderRadius: "10px",
      }}
      disablePadding
    >
      <AdCardMenuItem
        icon={AdDetails}
        color={colors.Primary}
        text="Details"
        onClick={onSelect}
      />
      <AdCardMenuItem
        icon={MoveAd}
        color={colors.Primary}
        text="Move"
        onClick={onMove}
      />
      <AdCardMenuItem
        onClick={onDeleteClicked}
        icon={Delete}
        color={colors.Error}
        text="Delete"
      />
    </MenuList>
  )
}
const AdCardMenuItem = ({
  icon,
  color,
  text,
  onClick,
}: {
  icon: string
  color: string
  text: string
  onClick?: MouseEventHandler<HTMLLIElement> | undefined
}) => (
  <MenuItem
    sx={[{ "&:hover": { backgroundColor: "rgba(59, 78, 223, 0.1)" } }]}
    onClick={onClick}
  >
    <ListItemIcon>
      <img
        src={icon}
        style={{
          width: "16px",
          height: "16px",
        }}
      />
    </ListItemIcon>
    <ListItemText
      disableTypography
      primary={
        <Typography
          fontSize="16px"
          fontWeight="500"
          fontFamily={"'Poppins', sans-serif !important;"}
          color={color}
        >
          {text}
        </Typography>
      }
    />
  </MenuItem>
)
