// context/userContext.tsx
import * as React from "react"
import { useLocalStorage } from "../V1/Hooks/useLocalStorage"

export interface ILayoutContext {
  menuIsExpanded: boolean
  setMenuIsExpanded: (isOpen: boolean) => void
}

export const LayoutContext = React.createContext<ILayoutContext | undefined>(
  undefined
)

export const LayoutProvider: React.FC = ({ children }) => {
  const [menuIsExpanded, setMenuIsExpanded] = useLocalStorage<boolean>(
    "menuIsExpanded",
    true
  )

  return (
    <LayoutContext.Provider value={{ menuIsExpanded, setMenuIsExpanded }}>
      {children}
    </LayoutContext.Provider>
  )
}
