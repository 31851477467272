import { ResponsiveChoropleth } from "@nivo/geo"
import { useMediaQuery } from "@mui/material"

import countriesFeatures from "../../../../static/geo/world_countries.json"
import { MOBILE_SCREEN_SIZE } from "../../../../utils"
import { CountryData } from "../../common"

type GeoMapProps = {
  adCounts: number[]
  countries: CountryData[]
}

export default function GeoMap({ adCounts, countries }: GeoMapProps) {
  const isMobileView = useMediaQuery(MOBILE_SCREEN_SIZE)
  const countrydata = countries.map((country) => ({
    id: country.ad_country,
    value: country.ads_count,
  }))

  return (
    <ResponsiveChoropleth
      data={countrydata}
      features={countriesFeatures.features}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      colors="blues"
      domain={[adCounts[0], adCounts[adCounts.length - 1]]}
      unknownColor="#eaeef3"
      label="properties.name"
      valueFormat=".2s"
      projectionScale={isMobileView ? 50 : 150}
      projectionRotation={[0, 0, 0]}
      enableGraticule={false}
      borderWidth={0.5}
      borderColor="#3b4edf"
    />
  )
}