import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField as MUITextField,
  Typography,
  styled,
} from "@mui/material"
import axios from "axios"
import React from "react"
import { MultiSelect } from "react-multi-select-component"
import { useParams } from "react-router-dom"

import { fetchComparisonReport } from "../../../../api/report"
import { ComparisonReport } from "../../../../types/productReportTypes"
import { getAbsoluteURL } from "../../../../utils"
import Modal from "../../../../components/Modal"

type BrandList = {
  brandname: string
  brand_id: number
  brandsite: string
  shopifysite: string
}

type Option = {
  label: string
  value: string
}

type Params = {
  name: string
  brandId: string
  productId: string
  productName: string
}

type Props = {
  modalOpen: boolean
  modalClose: () => void
  brandId: number
  updateCompetitors: (reports: ComparisonReport[]) => void
}

const feedbackStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const TextField = styled(MUITextField)({
  marginBottom: "10px"
})

export default function AddCompetitors(props: Props): React.ReactElement {
  const params: Params = useParams()
  const ref = React.useRef(null)
  const label = { inputProps: { "aria-label": "check box" } }
  const token = localStorage.getItem("token")

  const [loading, setLoading] = React.useState(false)
  const [brandOptions, setBrandOptions] = React.useState<any[]>([])
  const [selected, setSelected] = React.useState<Option[]>([])
  const [formData, setFormData] = React.useState<Record<string, unknown>>({
    is_featured: 0,
  })
  const [errors, setErrors] = React.useState<Record<string, unknown>>({})
  const [message, setMessage] = React.useState<string>("")
  const [feedbackMessageOpen, setFeedbackMessageOpen] =
    React.useState<boolean>(false)

  React.useEffect(() => {
    void (async () => {
      setLoading(true)
      const response = await fetch(
        getAbsoluteURL("search/shopifyfull/brandname/"),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token as string}`,
          },
        }
      )
      const data = (await response.json()) as BrandList[]
      if (data) {
        const options: { label: string; value: string }[] = []
        data.forEach((brand) =>
          options.push({
            label: brand.brandname,
            value: brand.brand_id.toString(),
          })
        )
        setBrandOptions(options)
        setLoading(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadComparisonReport = async () => {
    const response = await fetchComparisonReport(params.brandId)
    if (response) {
      props.updateCompetitors(response)
    }
  }

  const handleChange = (options: Option[]) => {
    setSelected(options)
  }

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = formData
    setFormData({ ...data, ...{ [e.target.id]: e.target.checked ? 1 : 0 } })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = formData
    data[e.target.name] = e.target.value
    setFormData(data)
  }

  const handleAdd = () => {
    if (Object.values(formData).length > 1) {
      axios
        .post(
          getAbsoluteURL("api/report/brand-competitors/create-new-competitor/"),
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token as string}`,
            },
          }
        )
        .then(() => {
          setMessage(
            "Scraper initialized.This may take a while.Please check brand status"
          )
          setFeedbackMessageOpen(true)
        })
        .catch((error: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          setErrors(error.response.data)
        })
    } else {
      const brandIds: string[] = selected.map((brand) => brand.value)
      const payload = {
        competitor_brands_ids: brandIds,
        target_brand_id: props.brandId,
      }
      axios
        .post(getAbsoluteURL("api/report/brand-competitors/"), payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token as string}`,
          },
        })
        .then(() => {
          loadComparisonReport()
          props.modalClose()
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const renderErrors = (fieldName: string) => {
    let msgs: string[] = []
    let error = false
    Object.keys(errors).forEach((key) => {
      if (key === fieldName) {
        msgs = errors[key] as string[]
        error = true
      }
    })
    return { error, msg: msgs.join(".") }
  }

  const closeModal = () => {
    props.modalClose()
    setFormData({ is_featured: 0 })
    setErrors({})
    setFeedbackMessageOpen(false)
  }

  const feedbackContent = (
    <Box sx={feedbackStyle}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        {message}
      </Typography>
      <Button onClick={closeModal} variant="contained" color="primary">
        Ok
      </Button>
    </Box>
  )

  return (
    <Modal
      aria-labelledby="add-competitor-modal-title"
      aria-describedby="add-competitor-modal-description"
      title="Add Competitors"
      open={props.modalOpen}
      handleClose={closeModal}
    >
      <>
        {!feedbackMessageOpen ? (
          <Box>
            <Box
              ref={ref}
              sx={{
                "& .rmsc": {
                  "& .dropdown-container": {
                    "& .dropdown-heading": {
                      cursor: "pointer",
                    },
                    "& .dropdown-content": {
                      zIndex: 99,
                    }
                  }
                },
              }}
            >
              <Typography variant="subtitle1">
                Choose from listed Shopify brands
              </Typography>
              <MultiSelect
                isLoading={loading}
                options={brandOptions}
                value={selected}
                onChange={handleChange}
                labelledBy="Select"
              />
            </Box>
            <Typography variant="subtitle1" sx={{ mt: "10px" }}>Add a new brand</Typography>
            <form style={{ display: "grid" }}>
              <TextField
                type="text"
                name="brandname"
                required
                label="Name"
                onChange={handleInputChange}
                error={renderErrors("brandname").error}
                helperText={renderErrors("brandname").msg}
              />

              <TextField
                type="text"
                name="brandsite"
                required
                label="Brand Website"
                onChange={handleInputChange}
                error={renderErrors("brandsite").error}
                helperText={renderErrors("brandsite").msg}
              />

              <TextField
                type="text"
                name="shopifysite"
                required
                label="Shopify Website"
                onChange={handleInputChange}
                error={renderErrors("shopifysite").error}
                helperText={renderErrors("shopifysite").msg}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    id="is_featured"
                    {...label}
                    checked={formData["is_featured"] === 1 ? true : false}
                    onChange={handleCheckbox}
                  />
                }
                label="Feature this brand"
              />
            </form>
            <Button
              sx={{
                float: "right",
                top: "-12px"
              }}
              color="primary"
              variant="contained"
              onClick={handleAdd}
            >
              Save
            </Button>
          </Box>
        ) : (
          feedbackContent
        )}
      </>
    </Modal>
  )
}
