import { BrandList } from "../../api/search"
import { ProductsDetail } from "../../api/searchResults"
import { openLinkInNewTab } from "../../utils"
import { replaceText } from "../../utils/textHelpers"

export const getBrandId = (brandname: string, brandList: BrandList[]): string | undefined => {
  const brand = brandList.find((item) => item.brandname === brandname)

  return brand && brand.brand_id.toString()
}

export const handleAlibabaIconClick = (productName: string) => {
  const alibabaURL = `https://www.alibaba.com/trade/search?fsb=y&IndexArea=product_en&SearchText=${replaceText(productName, " ", "+")}`

  openLinkInNewTab(alibabaURL)
}

export const handleAliExpressIconClick = (productName: string) => {
  const aliExpressURL = `https://www.aliexpress.com/w/wholesale-${replaceText(productName, " ", "-")}.html?SearchText=${replaceText(productName, " ", "+")}`

  openLinkInNewTab(aliExpressURL)
}

const getProductsFromSelectedBrands = (selectedBrands: string[], productResults: ProductsDetail[]) => {
  let results: ProductsDetail[] = []
  selectedBrands.forEach((brand) => {
    results = productResults.filter(
      (product) => product.brandname === brand
    )
  })

  return results
}

const getFeaturedProducts = (products: ProductsDetail[]) => {
  return products.filter(
    (product) => product.is_featured === true
  )
}

export const loadFilteredProducts = (
  selectedBrands: string[],
  productResults: ProductsDetail[],
  featuredProduct: boolean
): ProductsDetail[] => {

  if (selectedBrands.length > 0) {
    if (featuredProduct) {
      return getFeaturedProducts(getProductsFromSelectedBrands(selectedBrands, productResults))
    } else {
      return getProductsFromSelectedBrands(selectedBrands, productResults)
    }
  } else if (featuredProduct) {
    return getFeaturedProducts(productResults)
  } else {
    return productResults
  }
}

export const resetWindow = () => {
  if (window.scrollY !== 0) {
    window.scrollTo(0, 40)
  }
}