export const truncateText = (text: string, length = 10) => {
  return text.length > length ? text.substring(0, length - 3) + "..." : text
}

export const toTitleCase = (text: string) => {
  return text.replace(/\w\S*/g, function (text) {
    return `${text.charAt(0).toUpperCase()}${text.substr(1).toLowerCase()}`
  })
}

export const replaceText = (text: string, searchValue: string, replacer: string) => {
  return text.replaceAll(searchValue, replacer)
}

export const getExactBrandName = (name: string) => {
  return name.replaceAll("-", " ").replaceAll("_", "-")
}

export const getBrandUrl = (name: string, id: number | string) => {
  return `/brand/${name.replaceAll("-", "_").replaceAll(" ", "-")}/${id}`
}
