import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import {
  Button,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  TextField,
} from "@mui/material"
import { styled } from "@mui/system"
import axios from "axios"
import React from "react"

import Shipping from "../../../assets/images/v1-icons/shipping.svg"
import SeaFreight from "../../../assets/images/v1-icons/sea-freight.svg"
import AirFreight from "../../../assets/images/v1-icons/airfreight.svg"
import VerifyImg from "../../../assets/images/v1-icons/verified-supplier.svg"
import NameIcon from "../../../assets/images/v1-icons/Name.svg"
import skypeIcon from "../../../assets/images/v1-icons/skype.svg"
import whatsAppIcon from "../../../assets/images/v1-icons/whatsapp.svg"
import WechatIcon from "../../../assets/images/v1-icons/wechat.svg"
import { getAbsoluteURL } from "../../../utils"
import { Supplier } from "../../../types/productReportTypes"
import Modal from "../../../components/Modal"
//--------------------------------------------------------------------------------------------------------------------

type Props = {
  productId: number
  productName: string
  updateSupplier: (value: Supplier) => void
  deleteSupplier: () => void
  supplier: Supplier
}

const Label = styled("label")({
  maxWidth: "210px",
  width: "60% !important"
})

const DropShipping = styled(TextField)({
  "& div": {
    width: "40% !important"
  },
  "& .MuiInputBase-input": {
    height: "15px !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none"
  }
})

const Field = styled(TextField)({
  "& .MuiInputBase-input": {
    height: "15px !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none"
  }
})

export default function ProductAnalysisTable(props: Props): React.ReactElement {

  const label = { inputProps: { "aria-label": "check box" } }
  const nonEditableFields = [
    "id",
    "is_deleted",
    "supplier_type",
    "incoterms",
    "sea_freight",
    "air_freight",
    "is_verified",
    "product_id",
    "product_name",
    "margin",
    "drop_shipping"
  ]

  const [formData, setFormData] = React.useState<Supplier>(props.supplier)
  const [message, setMessage] = React.useState<string>("")
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Record<string, unknown>>({})

  const token = localStorage.getItem("token")
  const isSuperUser = localStorage.getItem("is_superuser")

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = formData
    data[e.target.name] = e.target.value
    setFormData(data)
  }

  const handleSelect = (e: SelectChangeEvent, fieldName: string) => {
    const data = formData
    setFormData({ ...data, ...{ [fieldName]: e.target.value } })
  }

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = formData
    setFormData({ ...data, ...{ [e.target.id]: e.target.value as unknown as boolean } })
  }

  const handleEdit = (supplier: Supplier) => {
    setFormData(supplier)
    setEditModalOpen(true)
  }

  const closeEditModal = () => {
    setEditModalOpen(false)
  }

  const closeDeleteModal = () => {
    setIsDeleting(false)
    props.deleteSupplier()
    setModalOpen(false)
  }

  const updateSupplier = () => {
    axios.patch(getAbsoluteURL(`api/report/product-suppliers/${props.supplier.id as number}/`),
      formData, { headers: { Authorization: `Token ${token as string}` } })
      .then((response) => {
        const data = response.data as { message: string, data: Supplier }
        setMessage(data.message)
        props.updateSupplier(data.data)
        // setSuppliers([...suppliers, ...[data.data]])
        closeEditModal()
        setModalOpen(true)
      })
      .catch((error: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setErrors(error.response.data)
      })
  }

  const handleDelete = () => {
    axios.delete(getAbsoluteURL(`api/report/product-suppliers/${props.supplier.id as number}/`),
      { headers: { Authorization: `Token ${token as string}` } })
      .then(() => {
        setMessage("Supplier is deleted successfully.")
        setIsDeleting(true)
        setModalOpen(true)
      })
      .catch(() => {
        setMessage("Couldn't delete supplier. Please try again later.")
        setModalOpen(true)
      })
  }

  const renderErrors = (fieldName: string) => {
    let msgs: string[] = []
    let error = false
    Object.keys(errors).forEach((key) => {
      if (key === fieldName) {
        msgs = errors[key] as string[]
        error = true
      }
    })
    return { error, msg: msgs.join(".") }
  }

  const editModal = (
    <Modal
      open={editModalOpen}
      handleClose={closeEditModal}
      title={"Edit Supplier"}
    >
      <Box
        sx={{
          "& .MuiFormControl-root": {
            borderRadius: "5px",
            pb: "8px",
            pt: "8px",
            mb: "10px",
          },
          "& .MuiInputBase-root": {
            minHeight: "50px",
            borderRadius: "5px",
            pb: "8px",
            pt: "8px",
          }
        }}
      >
        <Box sx={{ display: "grid" }}>
          {Object.keys(formData).map((key, index) => (
            <>
              {(key === "sea_freight" || key === "air_freight" || key === "is_verified")
                ? <FormControlLabel
                  control={<Checkbox id={key} {...label} checked={formData[key]} onChange={handleCheckbox} />}
                  label={key.replaceAll("_", " ")} />
                : ""
              }
              {!nonEditableFields.includes(key)
                ? <TextField
                  type={["price_per_unit", "minimum_order_qty", "master_carton_units"].includes(key) ? "number" : "text"}
                  key={index}
                  name={key}
                  label={key.replaceAll("_", " ")}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  defaultValue={formData[key]}
                  onChange={handleChange}
                  multiline
                  error={renderErrors(key).error}
                  helperText={renderErrors(key).msg} />
                : ""
              }
              {key === "drop_shipping"
                ? <FormControl>
                  <InputLabel id="drop-shipping">Drop Shipping</InputLabel>
                  <Select
                    labelId="drop-shipping-label"
                    id="drop-shipping"
                    value={formData.drop_shipping}
                    label="Drop Shipping"
                    onChange={(e) => handleSelect(e, key)}
                    error={renderErrors(key).error}
                    sx={{
                      height: "50px"
                    }}
                  >
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </Select>
                  {renderErrors(key).msg}
                </FormControl>
                : ""
              }
              {key === "supplier_type"
                ? <FormControl>
                  <InputLabel id="supplier-type">Supplier Type</InputLabel>
                  <Select
                    labelId="supplier-type-label"
                    id="supplier-type"
                    value={formData.supplier_type.toString()}
                    label="Supplier Type"
                    onChange={(e) => handleSelect(e, key)}
                    error={renderErrors(key).error}
                    sx={{
                      height: "50px"
                    }}
                  >
                    <MenuItem value={0}>Manufacturer</MenuItem>
                    <MenuItem value={1}>Trading Company</MenuItem>
                    <MenuItem value={2}>Manufacturer/Trading Company</MenuItem>
                  </Select>
                  {renderErrors(key).msg}
                </FormControl>
                : ""
              }
              {key === "incoterms"
                ? <FormControl>
                  <InputLabel id="incoterms">Incoterms</InputLabel>
                  <Select
                    labelId="incoterms-label"
                    id="incoterms"
                    value={formData.incoterms}
                    label="Incoterms"
                    onChange={(e) => handleSelect(e, key)}
                    error={renderErrors(key).error}
                    sx={{
                      height: "50px"
                    }}
                  >
                    <MenuItem value="EXW">EXW</MenuItem>
                    <MenuItem value="FOB">FOB</MenuItem>
                    <MenuItem value="DDP">DDP</MenuItem>
                  </Select>
                  {renderErrors(key).msg}
                </FormControl>
                : ""
              }
            </>
          ))}
        </Box>
        <Button
          sx={{
            float:"right"
          }}
          color="primary" variant="contained" onClick={updateSupplier} className="editSaveBtn">Update</Button>
      </Box>
    </Modal>
  )

  const modal = (
    <Modal
      open={modalOpen}
      handleClose={() => setModalOpen(false)}
    >
      <>
        <Typography variant="h6">{message}</Typography>
        <Button onClick={() => isDeleting ? closeDeleteModal() : setModalOpen(false)} variant="contained" color="primary">Ok</Button>
      </>
    </Modal>
  )

  return (
    <>
      {isSuperUser
        ?
        <div>
          <IconButton onClick={() => handleEdit(props.supplier)} className="iconRowEdit">
            <EditIcon fontSize="small" color="error" />
          </IconButton>
          <IconButton onClick={handleDelete} className="iconRowDel">
            <DeleteIcon fontSize="small" color="error" />
          </IconButton>
        </div>
        : ""
      }
      <Box alignItems="center" className="ProductDetailHead">
        <Typography variant="h5">
          Product - Detailed analysis (Suppliers / Logistics / Margin)
        </Typography>
      </Box>
      <Typography variant="h5">Suppliers</Typography>
      <Grid container alignItems="center" className="editText">
        <Grid item xs={12} alignItems="center">
          <img src={VerifyImg} />
        </Grid>
        <Grid item xs={12} className="editTextRow">
          <div className="col-50">
            <Field label="Manufacturer:" defaultValue={props.supplier.supplier_name} disabled />
            {props.supplier.alibaba_url
              ? <div className="iconRow">
                <div>
                  <Field label="Product Details:" />
                  {/* <img src={DetailIcon} onClick={handleDetailIconClick} /> */}
                  <Link href={props.supplier.alibaba_url} target="_blank">Link to product</Link>
                </div>
              </div>
              : ""
            }
            <Field label="Price:" defaultValue={`$${props.supplier.price_per_unit as number}`} disabled />
            <Field label="Incoterms:" defaultValue={props.supplier.incoterms} disabled />
            <Field label="MOQ:" defaultValue={`${props.supplier.minimum_order_qty as number} Units`} disabled />
            <Field label="Lead Time:" defaultValue={`${props.supplier.lead_time as number} Days`} disabled />
            <div className="iconsRow">
              <div>
                <Label>Shipping:</Label>
                <img src={Shipping} />
              </div>
              <div>
                <Label>Sea Freight:</Label>
                <img src={SeaFreight} />
              </div>
            </div>
          </div>
          <div className="col-50">
            <Field label="Product box:" defaultValue={props.supplier.product_box_size} disabled />
            <Field label="Weight:" defaultValue={props.supplier.weight} disabled />
            <Field label="Master Carton:" defaultValue={props.supplier.master_carton_size} disabled />
            <Field label="Weight:" defaultValue={props.supplier.master_carton_weight} disabled />
            <div className="iconsRow">
              {props.supplier.air_freight
                ? <div>
                  <Label>Air Freight:</Label>
                  <img src={AirFreight} />
                </div>
                : ""
              }
              <div>
                <DropShipping label="DropShipping:" defaultValue={props.supplier.drop_shipping?.toUpperCase()} disabled />
              </div>
            </div>
          </div>
        </Grid>
        <Box
          sx={{
            position: "absolute",
            right: "100px",
            top: "200px",
          }}
          className="userCol">
          {props.supplier.contact_person ? <button><img src={NameIcon} />{props.supplier.contact_person}</button> : ""}
          {props.supplier.whatsapp ? <button><img src={whatsAppIcon} />{props.supplier.whatsapp}</button> : ""}
          {props.supplier.skype ? <button><img src={skypeIcon} /> {props.supplier.skype}</button> : ""}
          {props.supplier.wechat ? <button><img src={WechatIcon} /> {props.supplier.wechat}</button> : ""}
        </Box>
        {/* notes section ui */}
        {props.supplier.agent_notes
          ? <div className="shbox">
            <Typography variant="h5">Notes:</Typography>
            <p>{props.supplier.agent_notes}</p>
          </div>
          : ""
        }
      </Grid>
      {editModal}
      {modal}
    </>
  )
}
