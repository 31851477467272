import { colors } from "../../../../utils/theme"

export const dropDownStyle = {
  boxSizing: "border-box",
  width: "100%",
  height: "50px",
  mb: "20px",
  border: "1px solid rgba(61, 68, 101, 0.2)",
}

export const submitButtonStyle = {
  width: "100%",
  height: "50px !important",
  mt: "5px",
  fontFamily: "Poppins !important",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  ".MuiLoadingButton-loadingIndicator": {
    color: colors.White,
  },
}

export const textFieldStyle = {
  width: "100%",
  height: "50px",
  border: "1px solid rgba(61, 68, 101, 0.2)",
  borderRadius: "5px",
  mb: "15px",
  boxSizing: "border-box",
  ".MuiInputLabel-root": {
    background: colors.White,
  },
  ".MuiOutlinedInput-root": {
    height: "50px",
    width: "100%",
    margin: "auto",
  },
}
