import { Button, Grid, Tab, Tabs } from "@mui/material"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"

import SubscriptionPlans from "../Components/SubscriptionPlans/plans"
import { useAuth } from "../Hooks/usAuthContext"
import { useLayout } from "../Hooks/useLayoutContext"
import TabPanel from "./TabPanel"
import V1Billing from "./Tabs/Billing"
import V1General from "./Tabs/General"
import Security from "./Tabs/Security"

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export default function V1Settings(): React.ReactElement {
  const history = useHistory()
  const { setUser } = useAuth()

  const { menuIsExpanded } = useLayout()

  const [tabIndex, setTabIndex] = useState(0)

  function handleTabChanged(
    event: React.ChangeEvent<unknown>,
    newTabIndex: number
  ) {
    setTabIndex(newTabIndex)
  }

  const handleLogout = () => {
    localStorage.clear()
    setUser(null)
    history.push("/login")
  }

  return (
    <div
      className={menuIsExpanded === true ? "slider v1-trending" : "v1-trending"}
    >
      <Grid item xs={12} className="ActSettings">
        <h3>Account Settings</h3>
        <Button className="btn" onClick={handleLogout}>
          Logout
        </Button>
        <Grid item xs={12}>
          <Tabs
            variant="scrollable"
            value={tabIndex}
            onChange={handleTabChanged}
            aria-label="Brand tabs"
            sx={{
              borderBottom: "1px solid #E3E8EE",
              textAlign: "right",
              "& .MuiTab-root": {
                minWidth: "inherit",
                background: "#ebedfc !important",
                color: "#6e788a !important",
                ":hover": {
                  background: "#ebedfc !important",
                  color: "#6e788a !important",
                },
              },
              "& .Mui-selected": {
                background: "#683bb7 !important",
                color: "#fff !important",
                ":hover": {
                  background: "#683bb7 !important",
                  color: "#fff !important",
                },
              },
            }}
          >
            <Tab label="General" {...a11yProps(0)} />
            <Tab label="Plans" {...a11yProps(1)} />
            <Tab label="Billing" {...a11yProps(2)} />
            <Tab label="Security" {...a11yProps(3)} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={tabIndex} index={0}>
            <V1General />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <SubscriptionPlans />
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <V1Billing />
          </TabPanel>
          <TabPanel value={tabIndex} index={3}>
            <Security />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  )
}
