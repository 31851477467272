import CircularProgress from "@mui/material/CircularProgress"

import {
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"
import { useEffect, useState } from "react"
import { Board, fetchMyBoards } from "../api/creativeCenter/boards"
import Iconify from "./Iconify"

type Props = {
  value?: number[]
  error?: string
  label?: string
  onChange: (boardIds: number[]) => void
  onNewBoard?: VoidFunction
  newBoard: Board | undefined
  placeholder?: string
  disabled?: boolean
  showNewBoard?: boolean
}

const NEW_BOARD_ID = -99

export default function MultiBoardSelect(props: Props) {
  const { value, onChange, newBoard, label, placeholder, error, onNewBoard } =
    props

  const [loading, setLoading] = useState(false)
  const [boards, setBoards] = useState<Board[]>([])

  useEffect(() => {
    const loadBoards = async () => {
      setLoading(true)
      const result = await fetchMyBoards()
      setBoards(result.results)
      setLoading(false)
    }
    loadBoards()
  }, [])

  useEffect(() => {
    if (newBoard) {
      setBoards([...boards, ...[newBoard]])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBoard])

  if (loading) {
    return <CircularProgress />
  }

  const handleOnChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as unknown as number[]
    const newIsChecked = value.includes(NEW_BOARD_ID)
    if (newIsChecked) {
      if (onNewBoard) {
        onNewBoard()
      }
    } else {
      onChange(value)
    }
  }

  const displayValue = () => {
    const foundItems = value?.map((v) => {
      const foundItem = boards.find((board) => board.id === v)
      return foundItem?.title || ""
    })
    return foundItems?.join(", ")
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="board-select">{label || "Boards"}</InputLabel>
      <Select
        labelId="board-select"
        name="boardSelectSelect"
        label={label || "Staff"}
        multiple
        // @ts-ignore
        value={value || []}
        error={!!error}
        placeholder={placeholder}
        onChange={handleOnChange}
        disabled={props.disabled}
        renderValue={() => <>{displayValue()}</>}
      >
        {boards.map((board) => (
          <MenuItem
            key={board.id}
            value={board?.id}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: "body2",
            }}
          >
            <Checkbox checked={value?.includes(board.id) || false} />
            <ListItemText primary={board.title} />
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          value={NEW_BOARD_ID}
          sx={{
            mx: 1,
            my: 0.5,
            borderRadius: 0.75,
            typography: "body2",
          }}
        >
          <ListItemIcon>
            <Iconify icon={"material-symbols:add"} />
          </ListItemIcon>
          <ListItemText primary="New Board" />
        </MenuItem>
      </Select>
      {!!error && (
        <FormHelperText error sx={{ px: 2 }}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}
