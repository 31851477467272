import React from "react"
import { Redirect, Route } from "react-router-dom"


interface Props {
  exact?: boolean;
  path: string;
  children: React.ReactElement
}

export default function PrivateRoute({ children, ...rest }: Props) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem("token") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}