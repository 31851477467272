import { Box, Stack } from "@mui/material"
import { useMemo } from "react"

import { AudienceNetworkMono } from "../assets/images/Creative/Icons/AudienceNetworkMono"
import Facebook from "../assets/images/Creative/Icons/Facebook.png"
import { FacebookMono } from "../assets/images/Creative/Icons/FacebookMono"
import Instagram from "../assets/images/Creative/Icons/Instagram.png"
import { InstagramMono } from "../assets/images/Creative/Icons/InstagramMono"
import Messenger from "../assets/images/Creative/Icons/Messenger.png"
import { MessengerMono } from "../assets/images/Creative/Icons/MessengerMono"

export enum FacebookAdPublisherPlatform {
  Messenger = "messenger",
  Facebook = "facebook",
  Instagram = "instagram",
  AudienceNetwork = "audience_network",
}

const ICON_SIZE = "30px"

export const FbAdPublisherPlatform = ({
  publisherPlatformString,
}: {
  publisherPlatformString: string
}) => {
  const publisherPlatforms = useMemo(() => {
    return parsePublisherPlatform(publisherPlatformString)
  }, [publisherPlatformString])

  return (
    <Box
      sx={{
        display: "flex",
        direction: "row",
      }}
    >
      {publisherPlatforms.map((platform, index) => {
        const icon = publisherPlatformIcon(platform)
        if (icon) {
          return (
            <img
              key={platform}
              src={icon}
              style={{
                width: ICON_SIZE,
                height: ICON_SIZE,
                marginLeft: index > 0 ? "10px" : undefined,
              }}
            />
          )
        }
        return null
      })}
    </Box>
  )
}

const publisherPlatformIcon = (
  publisherPlatform: FacebookAdPublisherPlatform
) => {
  switch (publisherPlatform) {
    case FacebookAdPublisherPlatform.Facebook:
      return Facebook
    case FacebookAdPublisherPlatform.Instagram:
      return Instagram
    case FacebookAdPublisherPlatform.Messenger:
      return Messenger
    default:
      return null
  }
}

export const FbAdPublisherPlatformGrayscale = ({
  publisherPlatformString,
}: {
  publisherPlatformString: string
}) => {
  const publisherPlatforms = useMemo(() => {
    return parsePublisherPlatform(publisherPlatformString)
  }, [publisherPlatformString])

  return (
    <Stack direction="row" spacing={1}>
      {publisherPlatforms.map((platform, index) => {
        const icon = publisherPlatformGrayIcon(platform)
        if (icon) {
          return <Box key={index}>{icon}</Box>
        }
        return null
      })}
    </Stack>
  )
}

const publisherPlatformGrayIcon = (
  publisherPlatform: FacebookAdPublisherPlatform
) => {
  switch (publisherPlatform) {
    case FacebookAdPublisherPlatform.Facebook:
      return <FacebookMono />
    case FacebookAdPublisherPlatform.Instagram:
      return <InstagramMono />
    case FacebookAdPublisherPlatform.Messenger:
      return <MessengerMono />
    case FacebookAdPublisherPlatform.AudienceNetwork:
      return <AudienceNetworkMono />
    default:
      return null
  }
}

const parsePublisherPlatform = (publisherPlatformString: string) =>
  publisherPlatformString
    .replaceAll("'", "")
    .replace("[", "")
    .replace("]", "")
    .split(",")
    .map((m) => m.trim()) as FacebookAdPublisherPlatform[]
