import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import AdsLibrary from "@mui/icons-material/DynamicFeed"
import FacebookIcon from "@mui/icons-material/Facebook"
import InfoIcon from "@mui/icons-material/Info"
import InstagramIcon from "@mui/icons-material/Instagram"
import Shopify from "@mui/icons-material/ShoppingCart"
import StarsIcon from "@mui/icons-material/StarRate"
import { Box, Grid, Paper, Rating, Tooltip, Typography } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"

import { BrandItem } from "../common"
import NewGaugeChart from "../components/GaugeChart"
import OverviewChartsSection from "../components/OverviewCharts/overviewCharts"
import Authorization from "../../Authorization/Authorization"
import LoadingOverlay from "../../Components/LoadOverlay/LoadOverlay"
import { RootStore } from "../../../Redux/store"
import { getToken } from "../../../utils/localStorage"

interface GaugeChartProps {
  topLabel: string
  value: number
  percentage: number | null
}

function GaugeChartContainer({ topLabel, value, percentage }: GaugeChartProps) {
  let colors
  if (value >= 90) {
    colors = ["#0B825D", "#5bf1c1"]
  } else if (value >= 50) {
    colors = ["#FAB000", "#fab0001a"]
  } else {
    colors = ["#E25950", "#fd4e5d1a"]
  }
  let icon
  if (percentage) {
    if (percentage > 0) {
      icon = <ArrowUpwardIcon color="primary" />
    } else {
      icon = <ArrowDownwardIcon color="error" />
    }
  }

  const helpText = (name: string) => {
    if (name === "Scaling Score") {
      return "The Scaling Score tracks brand growth based on their Social channels month over month. Each brand receives a score between 1-100 in comparison with other tracked brands"
    } else {
      return "The Revenue Score uses Trend Rocket’s bespoke algorithm taking in consideration Traffic, Product metrics, Industry conversion data. Each brand receives a score between 1-100 in comparison with other tracked brands"
    }
  }

  return (
    <Grid item xs={6} className="gaugeChart">
      <Grid spacing={3}>
        <Paper className="shbox">
          <Typography
            variant="caption"
            sx={{
              display: "flex",
              alignItems: "center",
              textTransform: "uppercase",
              "& > .MuiIconButton-root": {
                ml: (theme) => theme.spacing(0),
              },
            }}
          >
            {topLabel}
            <Tooltip title={helpText(topLabel)} arrow>
              <InfoIcon color="disabled" />
            </Tooltip>
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <NewGaugeChart value={value} color={colors} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom align="center">
                {value}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "inline-block",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                {percentage && (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: "inline-block",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <span>{percentage}%</span>
                  </Typography>
                )}
                {icon}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default function OverviewTab(): React.ReactElement {
  const brandItem: BrandItem = useSelector(
    (state: RootStore) => state.PageReduser["brandItem"]
  )
  const loading: boolean = useSelector(
    (state: RootStore) => state.PageReduser["loading"]
  )
  const [readMore, setReadMore] = React.useState(false)
  const [forceRead, setForceRead] = React.useState(false)

  const token = getToken()

  function toggleReadMore() {
    setReadMore(!readMore)
    setForceRead(!forceRead)
  }

  function stringTruncate(str: string) {
    if (str.length > 125) {
      if (readMore === false) {
        setReadMore(true)
      }
      return str.slice(0, 125) + "..."
    } else {
      return str
    }
  }

  return (
    <Grid container spacing={2} className="OverviewTab">
      {!token ? (
        <Authorization />
      ) : (
        <>
          <LoadingOverlay loading={loading} />
          {/* the info/ad column */}

          {/* the charts column */}
          <Grid item xs={4} className="infoSocial">
            {Object.keys(brandItem).length !== 0 && (
              <Paper className="shbox">
                {!brandItem.brand_description ? (
                  ""
                ) : (
                  <>
                    <InfoIcon />
                    <span>
                      {forceRead
                        ? brandItem.brand_description
                        : stringTruncate(brandItem.brand_description)}
                    </span>
                    {brandItem.brand_description.length > 125 &&
                      (forceRead ? (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => toggleReadMore()}
                          className="readmore"
                        >
                          Read less...
                        </a>
                      ) : (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => toggleReadMore()}
                          className="readmore"
                        >
                          Read more...
                        </a>
                      ))}
                  </>
                )}
                <br />
                <br />
                <div className="socialIcons">
                  {!brandItem.fburl ? (
                    ""
                  ) : (
                    <>
                      <FacebookIcon />
                      <a target="_blank" href={brandItem.fburl}>
                        {"Facebook"}
                      </a>
                    </>
                  )}
                  {!brandItem.fb_ads_library_url ? (
                    ""
                  ) : (
                    <>
                      <AdsLibrary />
                      <a target="_blank" href={brandItem.fb_ads_library_url}>
                        Ads Library
                      </a>
                    </>
                  )}

                  {!brandItem.shopifysite ? (
                    ""
                  ) : (
                    <>
                      <Shopify />
                      <a target="_blank" href={brandItem.shopifysite}>
                        Shopify
                      </a>
                    </>
                  )}

                  {!brandItem.instagramurl ? (
                    ""
                  ) : (
                    <>
                      <InstagramIcon />
                      <a target="_blank" href={brandItem.instagramurl}>
                        Instagram
                      </a>
                    </>
                  )}

                  {!brandItem.trustpilotsite ? (
                    ""
                  ) : (
                    <>
                      <div className="starRate">
                        <StarsIcon />
                        <a target="_blank" href={brandItem.trustpilotsite}>
                          Trustpilot
                        </a>
                        <p>
                          <Rating
                            name="half-rating-read"
                            readOnly
                            precision={0.5}
                            className="starRating"
                            value={brandItem.latest_trustpilotrating || 0}
                          />
                        </p>
                        {`Reviews ${brandItem.latest_numberreviews} |  ${
                          brandItem.latest_trustpilotratingtext || "No rating"
                        }`}{" "}
                        {brandItem.latest_trustpilotrating}
                      </div>
                    </>
                  )}
                </div>
              </Paper>
            )}
          </Grid>
          {/* The gauge charts row */}
          <Grid item xs={8} className="scoreCards">
            {brandItem.scaling_score_ranking != null && (
              <GaugeChartContainer
                topLabel="Scaling Score"
                value={brandItem.scaling_score_ranking}
                percentage={brandItem.scaling_score_percentage}
              />
            )}
            {brandItem.revenue_score_ranking != null && (
              <GaugeChartContainer
                topLabel="Revenue Score"
                value={brandItem.revenue_score_ranking}
                percentage={brandItem.revenue_score_percentage}
              />
            )}
          </Grid>
          <OverviewChartsSection />
        </>
      )}
    </Grid>
  )
}
