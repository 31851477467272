/* eslint-disable */
import React from "react"
import { SweepDirection } from "igniteui-react-core"
import { IgrRadialGauge } from "igniteui-react-gauges"
import { IgrRadialGaugeModule } from "igniteui-react-gauges"
import { IgrRadialGaugeRange } from "igniteui-react-gauges"
import { RadialGaugeBackingShape } from "igniteui-react-gauges"
import { RadialGaugeNeedleShape } from "igniteui-react-gauges"
import { RadialGaugePivotShape } from "igniteui-react-gauges"
import { RadialGaugeScaleOversweepShape } from "igniteui-react-gauges"

interface Props {
  value: number
  color:string[]
}

IgrRadialGaugeModule.register()

export default class RadialGaugeAnimation extends React.Component<Props> {
  public gauge: IgrRadialGauge | undefined;

  constructor(props: Props) {
    super(props)
    this.onGaugeRef = this.onGaugeRef.bind(this)
    this.onAnimateToGauge4 = this.onAnimateToGauge4.bind(this)
  }

  public onGaugeRef(component: IgrRadialGauge) {
    if (!component) { return }

    this.gauge = component
    this.onAnimateToGauge4(null)
  }

  public render(): JSX.Element {
    return (
      <div className="container sample">
        <IgrRadialGauge
          ref={this.onGaugeRef}
          transitionDuration={1000}
          width="100%"
        />
      </div>
    )
  }

  // custom radial gauge
  public onAnimateToGauge4 = (e: any) => {
    if (!this.gauge) { return }

    this.gauge.minimumValue = 0
    this.gauge.maximumValue = 100
    this.gauge.value = this.props.value
    this.gauge.interval = 100

    // setting appearance of labels
    this.gauge.labelInterval = 0
    this.gauge.labelExtent = 0
    this.gauge.font = "15px Verdana,Arial"

    // setting custom needle
    this.gauge.isNeedleDraggingEnabled = false
    this.gauge.needleEndExtent = 0.4
    this.gauge.needleShape = RadialGaugeNeedleShape.Triangle
    this.gauge.needleEndWidthRatio = 0.002;
    this.gauge.needleStartWidthRatio = 0.1;
    this.gauge.needlePivotShape = RadialGaugePivotShape.CircleOverlay;
    this.gauge.needlePivotWidthRatio = 0.0001;
    this.gauge.needleBaseFeatureWidthRatio = 0.1;
        this.gauge.needleBrush = "#3D4465";
        this.gauge.needleOutline = "#3D4465";
        this.gauge.needlePivotBrush = "#ffffff";
        this.gauge.needlePivotOutline = "#3D4465";

    // setting appearance of major/minor ticks
    this.gauge.minorTickCount = 0
    // this.gauge.minorTickEndExtent = 0.625;
    this.gauge.minorTickStartExtent = 0;
    this.gauge.minorTickStrokeThickness = 0;
    // this.gauge.minorTickBrush = "#79797a";
    this.gauge.tickStartExtent = 0.6
    this.gauge.tickEndExtent = 0.65
    this.gauge.tickStrokeThickness = 0;
    // this.gauge.tickBrush = "#79797a";

    // setting extent of gauge scale
    this.gauge.scaleStartAngle = 140
    this.gauge.scaleEndAngle = 40
    this.gauge.scaleBrush = "#fd4e5d1a"
    this.gauge.scaleOversweepShape = RadialGaugeScaleOversweepShape.Fitted
    this.gauge.scaleSweepDirection = SweepDirection.Clockwise
    this.gauge.scaleEndExtent = 0.61
    this.gauge.scaleStartExtent = .5

    // setting appearance of backing dial
    this.gauge.backingBrush = "white"
    this.gauge.backingOutline = "white"
    this.gauge.backingStrokeThickness = 0
    this.gauge.backingShape = RadialGaugeBackingShape.Fitted

    // setting custom gauge ranges
    const range1 = new IgrRadialGaugeRange({})
    range1.startValue = -1
    range1.endValue = this.props.value
    // const range3 = new IgrRadialGaugeRange({});
    // range3.startValue = 35;
    // range3.endValue = 45;
    this.gauge.rangeBrushes = this.props.color
    this.gauge.rangeOutlines = ["white", "white"]
    this.gauge.ranges.clear()
    this.gauge.ranges.add(range1)
    // this.gauge.ranges.add(range3);
    // setting extent of all gauge ranges
    for (let i = 0; i < this.gauge.ranges.count; i++) {
      const range = this.gauge.ranges.item(i)
      range.innerStartExtent = 0.61
      range.innerEndExtent = 0.61
      range.outerStartExtent = 0.5
      range.outerEndExtent = 0.5
    }
  }
}
