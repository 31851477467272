import { Typography } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { RootStore } from "../../../Redux/store"

interface SubscriptionUsage {
  current_product: string
  trialing: boolean
  days_to_reset: number
  brand_views_usage: number
  brand_views_limit: number
  viability_report_usage: number
  viability_report_limit: number
  brands_visited_on_current_cycle: any[]
  viability_report_unlocked_brands: any[]
  message?: string
}

export default function SubscriptionCard(): React.ReactElement {
  const subscription: SubscriptionUsage = useSelector(((state: RootStore) => state.PageReduser["subscriptionUsage"]))

  return (
    <div>
      {subscription.message
        ? <div className="subCard">
          <Typography variant="h4">{subscription.message}</Typography>
        </div>
        : <div className="subCard">
          <button>
            {subscription.current_product}
          </button>
          <Typography variant="h4">{`${subscription.brand_views_usage} / ${subscription.brand_views_limit} Brand Views`}</Typography>
          <Typography variant="h4">{`${subscription.viability_report_usage} / ${subscription.viability_report_limit} Trend Viability Reports`}</Typography>
          <b>{subscription.trialing ? `Trial Period ends in ${subscription.days_to_reset} days` : `Resets in ${subscription.days_to_reset} days`}</b>
        </div>
      }
    </div>
  )
}