import * as htmlToImage from "html-to-image"

export const TakeSnapshot = (
  id: string,
  brandName: string,
) => {
  const dateTime = new Date().toLocaleDateString().replaceAll("/", "-")
  
  htmlToImage.toJpeg(document.getElementById(id) as HTMLElement)
    .then((dataUrl) => {
      const link = document.createElement("a")
      link.download = `${brandName}-${id}-${dateTime}.jpeg`
      link.href = dataUrl
      link.click()
    })
    .catch((error) => console.log(error))
}