import { useState } from "react"
import { PAGINATION_SIZE_OPTIONS } from "../Components/Pagination/pagination"

// ----------------------------------------------------------------------

export default function usePagination(
  defaultCurrentPage?: number,
  defaultPageSize?: number,
  defaultResultCount?: number
) {
  const [currentPage, setCurrentPage] = useState<number>(
    defaultCurrentPage || 1
  )
  const [pageSize, setPageSize] = useState(
    defaultPageSize || PAGINATION_SIZE_OPTIONS[0]
  )
  const [resultCount, setResultCount] = useState<number>(
    defaultResultCount || 0
  )

  return {
    currentPage,
    pageSize,
    resultCount,
    setCurrentPage,
    setPageSize,
    setResultCount,
  }
}
