// @mui
import { Stack, Typography } from "@mui/material"

import { CtaType } from "../../../../../api/trendingAds"
import { AdCardCallToActionButton } from "../../../../../components/AdCardCallToActionButton"

// ----------------------------------------------------------------------

export interface AdCtaData {
  caption?: string | null
  title?: string | null
  link_description?: string | null
  cta_type?: CtaType | string | null
  link_url?: string | null
}

type PinnedAdCtaProps = {
  adCtaData: AdCtaData
}

export const PinnedAdCTA = ({ adCtaData }: PinnedAdCtaProps) => {
  return (
    <Stack
      justifyContent="space-between"
      flexDirection={"row"}
      alignItems="center"
    >
      <Stack sx={{ width: "80%" }}>
        <Typography fontSize={"10px"} color={"rgba(61, 68, 101, 0.5);"}>
          {adCtaData.caption}
        </Typography>
        <Typography fontSize={"16px"}>{adCtaData.title}</Typography>
        <Typography fontSize={"10px"} color={"rgba(61, 68, 101, 0.5);"}>
          {adCtaData.link_description}
        </Typography>
      </Stack>
      {adCtaData.cta_type && (
        <AdCardCallToActionButton
          ctaType={adCtaData.cta_type as CtaType}
          link={adCtaData.link_url || ""}
          maxWidth={"240px"}
        />
      )}
    </Stack>
  )
}
