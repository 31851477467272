export enum UserRoles {
  ADMIN = "admin",
  BRANDANALYST = "brand_analyst",
  USER = "user",
  BETAUSER = "beta_user",
  TESTER = "tester",
}
export const FULLNAME = "name"
export const IS_SUPER_USER_KEY = "is_superuser"
export const ROLE_KEY = "role"
export const TOKEN = "token"
export const USERNAME = "username"

export const checkIsSuperUser = () => {
  return localStorage.getItem(IS_SUPER_USER_KEY)
}

export const getFullName = () => {
  return localStorage.getItem(FULLNAME)
}

export const getUserName = () => {
  return localStorage.getItem(USERNAME)
}

export const getUserRole = (): UserRoles => {
  return localStorage.getItem(ROLE_KEY) as UserRoles
}

export const getToken = () => {
  return localStorage.getItem(TOKEN)
}
