import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import { useParams } from "react-router-dom"

import { getToken, getUserRole, UserRoles } from "../../../utils/localStorage"
import { colors } from "../../../utils/theme"
import Authorization from "../../Authorization/Authorization"
import CountryTrafficGrowth from "../components/CountryTargets"
import RevenueCard from "../components/RevenueCard"
import TrafficChart from "../components/TrafficChart"

export default function StoreTab(): React.ReactElement {
  const token = getToken()
  const userRole = getUserRole()
  const params: { name: string } = useParams()

  return (
    <Grid container sx={{ mt: (theme) => theme.spacing(2) }}>
      {!token ? (
        <Authorization />
      ) : (
        <>
          <Box
            sx={{
              mb: "30px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                fontSize: "28px",
                width: "100%",
                paddingTop: "0px",
                marginTop: "0px",
                "& span": {
                  color: (theme) => theme.palette.text.secondary,
                },
                "& svg": {
                  top: "2px",
                  marginLeft: "3px",
                  position: "relative",
                  cursor: "pointer",
                },
              }}
            >
              Traffic and Revenue
            </Typography>
          </Box>
          <Grid
            container
            spacing={2}
            id="traffic-revenue"
            sx={{
              background: colors.White,
              boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08)",
              borderRadius: "10px",
              height: "auto",
              width: "100%",
              display: "inherit",
              padding: "30px",
              mb: "30px",
            }}
          >
            <Grid item xs={8}>
              <TrafficChart />
            </Grid>
            <Grid item xs={4}>
              <RevenueCard />
            </Grid>
          </Grid>
          {userRole === UserRoles.ADMIN && (
            <CountryTrafficGrowth brandName={params.name} />
          )}
        </>
      )}
    </Grid>
  )
}
