import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"
import { Option } from "../V1/CreativeCenter/PinnedAds/FilterChoices"
import { toTitleCase } from "../utils/textHelpers"

type Props = {
  id: string
  label: string
  options: Option[]
  onChange: (choice: string[] | string | undefined) => void
  value?: string[] | string
  error?: string
  placeholder?: string
  disabled?: boolean
  multiple?: boolean
}

enum Label {
  Sort = "Sort",
}

enum Choice {
  ALL = "all"
}

export default function BoardFilter(props: Props) {
  const { id, value, error, label, onChange, options, placeholder, multiple } =
    props

  const filterValues = (values: string[]) => {
    const allSelected = values.find((v) => v === Choice.ALL)
    if (allSelected) {
      return [] as string[]
    }
    return values
  }

  const handleOnChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value as unknown
    if (label === Label.Sort) {
      onChange(value as string)
    } else {
      onChange(filterValues(value as string[]))
    }
  }

  const getValue = () => {
    if (label === Label.Sort) {
      return value
    }
    return value || []
  }

  const displayValue = () => {
    if (typeof value === "string") {
      return toTitleCase(value)
    }
    if (Array.isArray(value)) {
      const foundItems = value?.map((value) => {
        const foundItem = options.find((option) => option.value === value)
        return foundItem?.label
      })
      return foundItems?.join(", ")
    }
    return undefined
  }

  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-select`}>{label}</InputLabel>
      <Select
        labelId={`${id}-select`}
        name={`${id}Select`}
        label={label}
        multiple={multiple || false}
        // @ts-ignore
        value={getValue()}
        error={!!error}
        placeholder={placeholder}
        onChange={handleOnChange}
        disabled={props.disabled}
        renderValue={() => <>{displayValue()}</>}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: "body2",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                pb: "5px",
                pt: "5px",
              }}
            >
              {option.image && (
                <ListItemIcon>
                  <img src={option.image} alt={`${option.label}-icon`} />
                </ListItemIcon>
              )}
              <ListItemText primary={option.label} />
            </Box>
          </MenuItem>
        ))}
      </Select>
      {!!error && (
        <FormHelperText error sx={{ px: 2 }}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}
