import { Box, Grid } from "@mui/material"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import {
  PinnedAd,
  PinnedAdsFilters,
  getPinnedAds,
} from "../../../api/creativeCenter/pinnedAds"
import EmptyComponent from "../../../components/EmptyComponent/EmptyComponent"
import { colors } from "../../../utils/theme"
import BottomScrollListener from "../../Components/BottomScrollListener"
import { useLayout } from "../../Hooks/useLayoutContext"
import usePagination from "../../Hooks/usePagination"
import TrendingAdFeedLoader from "../../Trending/TrendingAds/TrendingAdFeedLoader"
import MoveBoardModal from "./MoveBoardModal"
import { PinnedAdCard } from "./PinnedAdCard"
import PinnedAdDetailModal from "./PinnedAdModal"

type PinnedAdsListProps = {
  filters: PinnedAdsFilters
}

export const PinnedAdsList = ({ filters }: PinnedAdsListProps) => {
  const { currentPage, setCurrentPage, setResultCount } = usePagination()
  const [selectedPinnedFbAd, setSelectedPinnedFbAd] = useState<
    PinnedAd | undefined
  >()
  const [pinnedAdToMove, setPinnedAdToMove] = useState<PinnedAd | undefined>()
  const { menuIsExpanded } = useLayout()

  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [pinnedAds, setPinnedAds] = useState<PinnedAd[]>([])
  const [hasReachedEnd, setHasReachedEnd] = useState(false)

  const loadNextPage = () => {
    if (hasReachedEnd || loading) {
      return
    }
    const fetchData = async (page: number) => {
      setLoading(true)
      const response = await getPinnedAds(filters, page)
      if (response) {
        const _pinnedAds =
          page === 1 ? response.results : [...pinnedAds, ...response.results]
        setPinnedAds(_pinnedAds)
        setLoading(false)
        setResultCount(response.count)
        setHasReachedEnd(response.count <= _pinnedAds.length)
      } else {
        enqueueSnackbar("Something went wrong")
      }
    }

    setLoading(true)
    const nextPage = currentPage + 1
    setCurrentPage(nextPage)
    fetchData(nextPage)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const response = await getPinnedAds(filters, 1)
      if (response) {
        const _pinnedAds = response.results
        setPinnedAds(_pinnedAds)
        setLoading(false)
        setResultCount(response.count)
        setHasReachedEnd(response.count <= _pinnedAds.length)
      } else {
        enqueueSnackbar("Something went wrong")
      }
    }
    setCurrentPage(1)
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const onDeleteSuccess = (index: number) => {
    enqueueSnackbar("Pin removed from board")
    setPinnedAds([...pinnedAds.slice(0, index), ...pinnedAds.slice(index + 1)])
  }

  const onMoveSuccess = (pinnedAdToMove: PinnedAd, newBoardId: number) => {
    const shouldBeHidden =
      filters.boardIds && filters.boardIds.includes(newBoardId) === false
    if (shouldBeHidden) {
      const index = pinnedAds.findIndex((v) => v.id === pinnedAdToMove.id)
      setPinnedAds([
        ...pinnedAds.slice(0, index),
        ...pinnedAds.slice(index + 1),
      ])
    }
  }

  const selectNextAd = () => {
    const index = pinnedAds.findIndex((a) => a.id === selectedPinnedFbAd?.id)
    if (index === pinnedAds.length) {
      return
    }
    setSelectedPinnedFbAd(pinnedAds[index + 1])
  }
  const selectPreviousAd = () => {
    const index = pinnedAds.findIndex((a) => a.id === selectedPinnedFbAd?.id)
    if (index < 1) {
      return
    }
    setSelectedPinnedFbAd(pinnedAds[index - 1])
  }

  return (
    <>
      <Box bgcolor={colors.Background} sx={{ padding: "30px" }}>
        <Grid container rowSpacing={"20px"} columnSpacing={"20px"}>
          {pinnedAds.map((pinnedAd, index) => {
            return (
              <Grid
                item
                key={pinnedAd.id}
                xs={12}
                md={menuIsExpanded ? 12 : 6}
                lg={menuIsExpanded ? 6 : 4}
                xl={menuIsExpanded ? 4 : 3}
              >
                <PinnedAdCard
                  pinnedAd={pinnedAd}
                  onDeleteSuccess={() => onDeleteSuccess(index)}
                  onSelect={() => setSelectedPinnedFbAd(pinnedAd)}
                  onMove={() => setPinnedAdToMove(pinnedAd)}
                />
              </Grid>
            )
          })}
          {loading && <TrendingAdFeedLoader />}
          {!loading && pinnedAds.length === 0 && (
            <EmptyComponent title="No Pinned Ads" />
          )}
        </Grid>
        <BottomScrollListener onBottom={loadNextPage} />
      </Box>
      <PinnedAdDetailModal
        handleClose={() => setSelectedPinnedFbAd(undefined)}
        pinnedAd={selectedPinnedFbAd}
        onNext={selectNextAd}
        onPrevious={selectPreviousAd}
      />
      <MoveBoardModal
        handleClose={() => setPinnedAdToMove(undefined)}
        onMoveSuccess={(pinnedAd, newBoardId) =>
          onMoveSuccess(pinnedAd, newBoardId)
        }
        pinnedAd={pinnedAdToMove}
      />
    </>
  )
}
