import { Grid } from "@mui/material"
import AdDetailView from "./AdDetailView"
import AdMetaView from "./AdMetaView"
import { FbAd } from "../../../../api/ads"


type AdContentProps = {
  ad: FbAd
  focusedCtaUrl?: string | null
  handleClose?: VoidFunction
}

export default function AdContent({
  ad,
  focusedCtaUrl,
  handleClose,
}: AdContentProps) {

  return (
    <Grid
      container
      sx={{
        borderBottomWidth: "1px",
        borderBottomColor: "rgba(61, 68, 101, 0.1)",
        borderBottomStyle: "solid",
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          borderRightWidth: { md: "1px" },
          borderRightColor: { md: "rgba(61, 68, 101, 0.1)" },
          borderRightStyle: { md: "solid" },
          pr: { md: 2 },
        }}
      >
        <AdDetailView ad={ad} />
      </Grid>
      <Grid item xs={12} md={6} sx={{ pl: 2 }}>
        <AdMetaView
          ad={ad}
          handleClose={handleClose}
          focusedCtaUrl={focusedCtaUrl}
        />
      </Grid>
    </Grid>
  )
}