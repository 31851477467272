import LaunchIcon from "@mui/icons-material/Launch"
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link as RouterLink, useLocation } from "react-router-dom"

import { BrandList } from "../../api/search"
import {
  BrandsSearch,
  fetchBrandNames,
  fetchBrandsResults,
  fetchProductsResults,
  ProductsSearch,
} from "../../api/searchResults"
import AlibabaIcon from "../../assets/images/icon_alibaba.png"
import AliExpressIcon from "../../assets/images/v1-icons/aliexpress.png"
import { RootStore } from "../../Redux/store"
import { getExactBrandName } from "../../utils/textHelpers"
import BrandCard from "../Components/BrandCard/BrandCard"
import LoadingOverlay from "../Components/LoadOverlay/LoadOverlay"
import Pagination from "../Components/Pagination/pagination"
import { useLayout } from "../Hooks/useLayoutContext"
import usePagination from "../Hooks/usePagination"
import {
  getBrandId,
  handleAlibabaIconClick,
  handleAliExpressIconClick,
  loadFilteredProducts,
  resetWindow,
} from "./actions"
import { getUserRole, UserRoles } from "../../utils/localStorage"

type BrandAutocompleteProps = {
  options: string[]
  loading: boolean
  brandSelect(event: React.ChangeEvent<unknown>, ...rest: any[]): void
}

function BrandAutocomplete({
  options,
  loading,
  brandSelect,
}: BrandAutocompleteProps) {
  return (
    <FormControl className="countryAutoComplete">
      <Autocomplete
        loading={loading}
        multiple
        sx={{
          width: "100%",
          minHeight: "40px",
          "& .MuiAutocomplete-option": {
            fontSize: 15,
            "& > span": {
              mr: 10,
              fontSize: 18,
            },
          },
        }}
        options={options}
        onChange={brandSelect}
        autoHighlight
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            label="All Brands"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </FormControl>
  )
}

export default function SearchResults(): React.ReactElement {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
  const { menuIsExpanded } = useLayout()
  const query = useQuery()
  const searchTerm = query.get("q")
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0)
  const [selectedButtonLabel, setSelectedButtonLabel] = useState("All results")
  const [hoveredProductIndex, setHoveredProductIndex] = useState<number | null>(
    null
  )
  const [brandList, setBrandList] = useState<string[]>([])
  const [selectedBrands, setSelectedBrands] = useState<string[]>([])
  const [bestSellers] = useState<boolean>(false)
  const [featuredProduct] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [optionsLoading, setOptionsLoading] = useState(false)
  const [productResults, setProductResults] = useState<ProductsSearch>()
  const [brandResults, setBrandResults] = useState<BrandsSearch[]>()

  const brandsData: BrandList[] = useSelector(
    (state: RootStore) => state.PageReduser["brandList"]
  )

  const {
    currentPage,
    pageSize,
    resultCount,
    setResultCount,
    setCurrentPage,
    setPageSize,
  } = usePagination()
  const userRole = getUserRole()
  const isAdminOrAnalyst = (userRole === UserRoles.ADMIN || userRole === UserRoles.BRANDANALYST)

  const buttonLabels = ["Brands", "Products"]

  useEffect(() => {
    const loadBrandNames = async () => {
      setOptionsLoading(true)
      const response = await fetchBrandNames(searchTerm)
      setOptionsLoading(false)
      if (response) {
        setBrandList(response)
      }
    }

    const loadBrands = async () => {
      setLoading(true)
      const response = await fetchBrandsResults(searchTerm as string)
      setLoading(false)
      if (response) {
        setBrandResults(response)
      }
    }

    loadBrands()
    loadBrandNames()
    resetWindow()
  }, [searchTerm])

  useEffect(() => {
    const loadProducts = async () => {
      setLoading(true)
      const response = await fetchProductsResults(
        searchTerm as string,
        selectedBrands,
        pageSize,
        currentPage,
        bestSellers
      )
      setLoading(false)
      if (response) {
        setProductResults(response)
        setResultCount(response.count)
      }
    }

    loadProducts()
    resetWindow()
  }, [
    bestSellers,
    currentPage,
    pageSize,
    searchTerm,
    selectedBrands,
    setResultCount,
  ])

  const handleMouseEnteredProduct = (index: number) => {
    setHoveredProductIndex(index)
  }

  const handleMouseLeftProduct = () => {
    setHoveredProductIndex(null)
  }

  const setSelectedButton = (index: number, label: string) => {
    setSelectedButtonIndex(index)
    setSelectedButtonLabel(label)
  }

  const getSelectedBrands = (
    _e: React.ChangeEvent<unknown>,
    ...rest: any[]
  ) => {
    const brands = rest[0] as string[]
    brands.length > 0 ? setSelectedBrands(brands) : setSelectedBrands([])
  }

  const productsList = productResults?.results &&
    productResults.results.length > 0 && (
      <>
        {loadFilteredProducts(
          selectedBrands,
          productResults.results,
          featuredProduct
        ).map((product, index) => (
          <Grid item xs={3} key={index} className="productBox proResult">
            <Paper
              variant={hoveredProductIndex == index ? "elevation" : "outlined"}
              elevation={4}
              onMouseEnter={() => handleMouseEnteredProduct(index)}
              onMouseLeave={handleMouseLeftProduct}
              sx={(theme) => ({
                pl: theme.spacing(2),
                pr: theme.spacing(2),
                pt: theme.spacing(1.25),
                pb: theme.spacing(1.25),
                display: "flex",
                height: "378px",
                flexDirection: "column",
                "& > *:not(last-child)": {
                  mb: theme.spacing(1),
                },
                "& .MuiAvatar-root": {
                  width: "100%",
                  height: "100%",
                },
              })}
            >
              {hoveredProductIndex === index && (
                <>
                  <Avatar
                    sx={{
                      position: "absolute !important",
                      width: "70px !important",
                      height: "45px !important",
                      right: "0px",
                      top: "-3px",
                      cursor: "pointer",
                      "& .MuiAvatar-img": {
                        position: "absolute",
                        right: "30px",
                        width: "40px !important",
                        height: "auto !important",
                      },
                    }}
                    src={AlibabaIcon}
                    onClick={() => handleAlibabaIconClick(product.name)}
                  />
                  <Avatar
                    sx={{
                      position: "absolute !important",
                      width: "45px !important",
                      height: "45px !important",
                      right: "0px",
                      top: "-3px",
                      cursor: "pointer",
                      "& .MuiAvatar-img": {
                        width: "28px !important",
                        right: "0px",
                        position: "absolute",
                        height: "auto !important",
                      },
                    }}
                    src={AliExpressIcon}
                    onClick={() => handleAliExpressIconClick(product.name)}
                  />
                </>
              )}
              <Typography variant="subtitle1">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "& a": {
                      lineHeight: 0,
                      ml: (theme) => theme.spacing(1),
                    },
                  }}
                >
                  <Link
                    sx={{
                      textDecoration: "none",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    href={`brand/${getExactBrandName(product.brandname)}/${getBrandId(product.brandname, brandsData) || ""
                      }`}
                    target="_blank"
                  >
                    <span>{product.brandname}</span>
                  </Link>
                  <Link href={product.url} referrerPolicy="no-referrer" target="_blank">
                    <LaunchIcon color="action" fontSize="small" />
                  </Link>
                </Box>
              </Typography>
              <Avatar variant="square" src={product.imageurl} />
              <Typography variant="subtitle2">{product.name}</Typography>
              <Typography
                sx={{
                  "& span": {
                    marginLeft: (theme) => theme.spacing(2),
                    color: (theme) => theme.palette.text.secondary,
                    textDecoration: "line-through",
                    fontSize: "90%",
                  },
                }}
                variant="subtitle1"
              >
                ${product.price}
              </Typography>
              {hoveredProductIndex === index && isAdminOrAnalyst && (
                <Button
                  sx={{
                    display: "block",
                    position: "relative",
                    top: "-20px !important",
                    float: "right",
                    width: "125px",
                    height: "23px !important",
                  }}
                >
                  Analyze
                </Button>
              )}
            </Paper>
          </Grid>
        ))}
        <Pagination
          currentPage={currentPage}
          resultCount={resultCount}
          pageSize={pageSize}
          onPageChange={setCurrentPage}
          onPageSizeChange={setPageSize}
          label={"Products"}
        />
      </>
    )

  return (
    <Grid
      className={menuIsExpanded === true ? "slider v1-trending" : "v1-trending"}
    >
      <LoadingOverlay loading={loading} />
      {brandResults ? (
        <Grid item xs={12} className="searchHead">
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-between",
              "& > h4:last-child, & > hr": {
                color: theme.palette.text.secondary,
              },
              mb: theme.spacing(1.5),
              mt: theme.spacing(0),
            })}
          >
            { }
            <Typography variant="h4">
              Search results for the query <strong>"{searchTerm}"</strong>{" "}
              <Link component={RouterLink} to="/search-brand">
                <small>advanced filters</small>
              </Link>
            </Typography>
            <Typography variant="h4">
              {selectedButtonLabel === "Products"
                ? productResults?.count
                : brandResults?.length}
            </Typography>
          </Box>
          <Divider />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-between",
              "& > h4:last-child, & > hr": {
                color: theme.palette.text.secondary,
              },
              mb: theme.spacing(1.5),
              mt: theme.spacing(0),
            })}
          >
            { }
            <Typography variant="h4">
              No Results for the query <strong>"{query.get("q")}"</strong>{" "}
              <Link component={RouterLink} to="/search-brand">
                <small>advanced filters</small>
              </Link>
            </Typography>
            <Typography variant="h4">0</Typography>
          </Box>
          <Divider />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={(theme) => ({
          mt: theme.spacing(2),
          "& > button": {
            mr: theme.spacing(1),
          },
        })}
      >
        {buttonLabels.map((label, index) => (
          <Button
            key={index}
            variant={selectedButtonIndex == index ? "contained" : "outlined"}
            color={selectedButtonIndex == index ? "primary" : "inherit"}
            size="small"
            className="searchTagBtn"
            onClick={() => setSelectedButton(index, label)}
          >
            {label}
          </Button>
        ))}
      </Grid>
      <Grid container item xs={12} className="v1-trendingItems" spacing={2}>
        {selectedButtonLabel === "Products" ? (
          <>
            <div className="sortedItems">
              <div className="sortProduct">
                <InputLabel>Show Products From </InputLabel>
                <BrandAutocomplete
                  options={brandList}
                  loading={optionsLoading}
                  brandSelect={getSelectedBrands}
                />
              </div>
            </div>
            <Grid container item xs={12} className="productTabResult">
              {productsList}
            </Grid>
          </>
        ) : (
          brandResults?.map((brand, index) => (
            <BrandCard key={index} indexNumber={index} brand={brand} />
          ))
        )}
      </Grid>
    </Grid>
  )
}
