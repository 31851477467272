import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import LockIcon from "@mui/icons-material/Lock"
import LoginIcon from "@mui/icons-material/Login"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import {
  Alert,
  Box,
  Button,
  Fade,
  FormControl,
  Link,
  Modal,
} from "@mui/material"
import Backdrop from "@mui/material/Backdrop"
import axios from "axios"
import React from "react"
import { useDispatch } from "react-redux"

import v1logo from "../../../assets/images/Logo/LogoFull.svg"
import Actions from "../../../pages/Actions/PageActions"
import { getAbsoluteURL } from "../../../utils"
import { User } from "../../brand/common"
import { useAuth } from "../../Hooks/usAuthContext"

interface Props {
  open: boolean
  closeModal: () => void
}

export default function LoginModal(props: Props): React.ReactElement {
  const dispatch = useDispatch()
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [error, setError] = React.useState(false)
  const [pwdVisible, setPwdVisible] = React.useState(false)
  const { setUser } = useAuth()

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const url = getAbsoluteURL("api/user/login/")
    const apiBody = { email: email, password: password }
    dispatch({ type: "Loading", payload: true })
    axios
      .post(url, apiBody, { headers: { "Content-type": "application/json" } })
      .then((response) => {
        const user = response.data as User
        localStorage.setItem("uid", user.id.toString())
        localStorage.setItem("token", user.token)
        if (user.is_superuser) {
          localStorage.setItem("is_superuser", "true")
        }
        dispatch(Actions.GetSubscriptionUsage())
        setUser(user)
        dispatch({ type: "Loading", payload: false })
        props.closeModal()
      })
      .catch(() => {
        dispatch({ type: "Loading", payload: false })
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 3000)
      })
  }

  const togglePwd = () => {
    setPwdVisible(!pwdVisible)
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      className="AccessLoginPopup"
      onClose={props.closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Box>
          <div className="v1-loginBox">
            <img src={v1logo} alt="MOAS" />
            <div className="v1-login">
              {error ? (
                <Alert severity="error">
                  Incorrect email or password! Please try again.
                </Alert>
              ) : (
                ""
              )}
              <form onSubmit={handleLogin}>
                <FormControl>
                  <span>
                    <AlternateEmailIcon fontSize="small" />
                  </span>
                  <input
                    required
                    id="email"
                    type="email"
                    aria-describedby="email-helper-text"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email address..."
                  />
                </FormControl>
                <FormControl>
                  <span>
                    <LockIcon fontSize="small" />
                  </span>
                  <input
                    required
                    id="password"
                    type={pwdVisible ? "text" : "password"}
                    aria-describedby="pwd-helper-text"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password..."
                  />
                  <span onClick={togglePwd}>
                    {pwdVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </span>
                </FormControl>
                <Button type="submit" fullWidth endIcon={<LoginIcon />}>
                  Log In
                </Button>
              </form>
              <Link href="/email-verify">Forgotten password?</Link>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  )
}
