import React from "react"

import { Avatar, Grid, Paper, Typography } from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"
import TickIcon from "@mui/icons-material/CheckCircle"
import { DateTime } from "luxon"
import "react-multi-carousel/lib/styles.css"

import { BrandItem, FbAdsDetail } from "../common"
import VideoPlayer from "../../Components/VideoPlayer/videoPlayer"
import { truncateText } from "../../../utils/textHelpers"

interface Props {
  brandAd: FbAdsDetail
  productAdsOnly: boolean | undefined
  brandItem: BrandItem
}

export default function BrandAdCard(props: Props): React.ReactElement {
  const { brandItem, brandAd } = props

  const renderPublisherPlatform = (data: string): string[] => {
    const filter = data.replace(/[[\]' ]+/g, "")
    return filter.split(",")
  }

  const renderDate = (date: string) => {
    if (props.productAdsOnly) {
      return DateTime.fromSeconds(parseInt(date)).toFormat("dd MMM yyyy")
    } else {
      return DateTime.fromISO(new Date(date).toISOString()).toFormat(
        "dd MMM yyyy"
      )
    }
  }

  return (
    <Grid item xs={12} className="adItems">
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& > *:not(last-child)": {},
          "& img": {
            width: "100%",
          },
        }}
        elevation={4}
        style={{ cursor: "pointer" }}
        onClick={() => window.open(brandAd.link_url, "_blank", "noreferrer")}
      >
        <div style={{ width: "100%" }}>
          <Avatar
            src={brandItem.profileimage}
            variant="circular"
            style={{ width: "28px", height: "28px" }}
          />
          <a href={brandItem.brandsite} target="_blank">
            {brandItem.brandname}
          </a>
          <p>{truncateText(brandAd.ad_content, 150)}</p>
          <div className="adsImg">
            {brandAd.video_url ? (
              <VideoPlayer sourceUrl={brandAd.video_url} />
            ) : (
              <img
                src={
                  brandAd.image === "N/A" || brandAd.image === null
                    ? "/images/brands/tommyhilfiger/tommy-faces.jpg"
                    : brandAd.image
                }
                alt="Ad-image"
              />
            )}
          </div>
        </div>

        <div className="adsheader">
          {brandAd.isactive === 1 ? (
            <span className="Active">
              <TickIcon /> Active{" "}
            </span>
          ) : (
            <span className="Inactive">
              <CancelIcon />
              Inactive
            </span>
          )}
          <a href={brandAd.link_url} target="_blank">
            {brandAd.cta_type.replace("_", " ")}
          </a>
          {brandAd.startdate && (
            <div className="sdate">
              <Typography variant="caption" component="span">
                Start :{" "}
              </Typography>
              <Typography variant="caption" component="span">
                {renderDate(brandAd.startdate)}
              </Typography>
            </div>
          )}
          {brandAd.enddate && (
            <div className="ldate">
              <Typography variant="caption" component="span">
                Last seen :{" "}
              </Typography>
              <Typography variant="caption" component="span">
                {renderDate(brandAd.enddate)}
              </Typography>
            </div>
          )}
          <p>ID: {brandAd.adarchiveid}</p>
          {renderPublisherPlatform(brandAd.publisherplatform).map(
            (publisher, index) => (
              <div key={index} className="sIcons">
                {publisher === "instagram" && (
                  <i className="fab fa-instagram"></i>
                )}
                {publisher === "facebook" && (
                  <i className="fab fa-facebook-f"></i>
                )}
                {publisher === "messenger" && (
                  <i className="fab fa-facebook-messenger"></i>
                )}
              </div>
            )
          )}
        </div>
      </Paper>
    </Grid>
  )
}
