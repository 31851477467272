export const AudienceNetworkMono = ({
  width,
  height,
  color,
}: {
  width?: string
  height?: string
  color?: string
}) => {
  return (
    <svg
      width={width || 15}
      height={height || 15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3125 7.5C10.3125 8.15039 10.2773 8.77734 10.2158 9.375H4.78418C4.71973 8.77734 4.6875 8.15039 4.6875 7.5C4.6875 6.84961 4.72266 6.22266 4.78418 5.625H10.2158C10.2803 6.22266 10.3125 6.84961 10.3125 7.5ZM11.1562 5.625H14.7627C14.918 6.22559 15 6.85254 15 7.5C15 8.14746 14.918 8.77441 14.7627 9.375H11.1562C11.2178 8.77148 11.25 8.14453 11.25 7.5C11.25 6.85547 11.2178 6.22852 11.1562 5.625ZM14.4551 4.6875H11.0361C10.7432 2.81543 10.1631 1.24805 9.41602 0.246094C11.71 0.852539 13.5762 2.5166 14.4521 4.6875H14.4551ZM10.0869 4.6875H4.91309C5.0918 3.62109 5.36719 2.67773 5.7041 1.91309C6.01172 1.22168 6.35449 0.720703 6.68555 0.404297C7.01367 0.09375 7.28613 0 7.5 0C7.71387 0 7.98633 0.09375 8.31445 0.404297C8.64551 0.720703 8.98828 1.22168 9.2959 1.91309C9.63574 2.6748 9.91113 3.61816 10.0869 4.6875ZM3.96387 4.6875H0.544922C1.42383 2.5166 3.28711 0.852539 5.58398 0.246094C4.83691 1.24805 4.25684 2.81543 3.96387 4.6875ZM0.237305 5.625H3.84375C3.78223 6.22852 3.75 6.85547 3.75 7.5C3.75 8.14453 3.78223 8.77148 3.84375 9.375H0.237305C0.0820313 8.77441 0 8.14746 0 7.5C0 6.85254 0.0820313 6.22559 0.237305 5.625ZM5.7041 13.084C5.36426 12.3223 5.0918 11.3789 4.91309 10.3125H10.0869C9.9082 11.3789 9.63281 12.3223 9.2959 13.084C8.98828 13.7754 8.64551 14.2764 8.31445 14.5928C7.98633 14.9062 7.71387 15 7.5 15C7.28613 15 7.01367 14.9062 6.68555 14.5957C6.35449 14.2793 6.01172 13.7783 5.7041 13.0869V13.084ZM3.96387 10.3125C4.25684 12.1846 4.83691 13.752 5.58398 14.7539C3.28711 14.1475 1.42383 12.4834 0.544922 10.3125H3.96387ZM14.4551 10.3125C13.5762 12.4834 11.7129 14.1475 9.41895 14.7539C10.166 13.752 10.7432 12.1846 11.0391 10.3125H14.4551Z"
        fill={color || "#646984"}
      />
    </svg>
  )
}
