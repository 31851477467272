import { fetchApiCall, postApiCall } from ".."
import { Category, MaxValues, QueryParams, ShopifyData } from "./types"

export const fetchCategories = async () => {
  const endpoint = "api/store-categories/"

  return fetchApiCall<Category[]>(endpoint)
}

export const fetchMaxValues = async () => {
  const endpoint = "search/shopifyfull/max_values"

  return fetchApiCall<MaxValues>(endpoint)
}

export const fetchShopifyBrands = async (
  params: QueryParams
) => {
  const endpoint = "search/shopifyfull/"

  return fetchApiCall<ShopifyData>(endpoint, {
    page: params.currentPage,
    page_size: params.pageSize,
    duration: params.duration,
    ordering: params.ordering,
    action: params.trackedOn,
    fb_ad_active: params.runningAds,
    report_published: params.reportPublished
  })
}

export const fetchShopifyBrandsWithFilter = async (
  payload: any,
  params: any
) => {
  const endpoint = "search/shopifyfull/"

  return postApiCall<ShopifyData>(endpoint, payload, params)
}