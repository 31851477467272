import { Box, Stack } from "@mui/material"

import VideoPlayer from "../../../Components/VideoPlayer/videoPlayer"
import { FbAd } from "../../../../api/ads"

const DEFAULT_BODY_HEIGHT = "258px"

export const AdBody = ({
  ad,
  bodyHeight,
}: {
  ad: FbAd
  bodyHeight?: string
}) => {
  return (
    <AdCardBody
      adId={parseInt(ad.ad_creative_id)}
      bodyHeight={bodyHeight}
      imageUrl={ad.image}
      videoUrl={ad.video_url}
    />
  )
}

const AdCardBody = ({
  adId,
  imageUrl,
  videoUrl,
  bodyHeight,
}: {
  adId: number
  imageUrl?: string | null
  videoUrl?: string | null
  bodyHeight?: string
}) => {
  if (imageUrl && imageUrl != "N/A") {
    return (
      <Stack spacing={2}>
        <AdImage
          adId={adId}
          imageUrlString={imageUrl}
          bodyHeight={bodyHeight}
        />
      </Stack>
    )
  }

  if (videoUrl) {
    return (
      <Stack spacing={2}>
        <AdVideo videoUrlString={videoUrl} bodyHeight={bodyHeight} />
      </Stack>
    )
  }

  return null
}

const AdImage = ({
  adId,
  imageUrlString,
  bodyHeight,
}: {
  adId: number
  imageUrlString?: string | null
  bodyHeight?: string
}) => {
  if (!imageUrlString || imageUrlString === "N/A") {
    return null
  }
  return (
    <img
      src={imageUrlString}
      alt={`image for ${adId}`}
      style={{
        backgroundColor: "#F2F2F2",
        marginTop: "12px",
        width: "100%",
        height: bodyHeight || DEFAULT_BODY_HEIGHT,
        display: "inline-block",
        objectFit: "contain",
      }}
    />
  )
}

const AdVideo = ({
  videoUrlString,
  bodyHeight,
}: {
  videoUrlString: string
  bodyHeight?: string
}) => {
  return (
    <Box
      sx={{
        marginTop: "12px",
        width: "100%",
        height: bodyHeight || DEFAULT_BODY_HEIGHT,
        display: "inline-block",
      }}
    >
      <VideoPlayer sourceUrl={videoUrlString} />
    </Box>
  )
}