import axios, { AxiosResponse } from "axios"
import { Dispatch } from "redux"

import { FilterData, filterResponse, getAbsoluteURL } from "../../utils"
import { BrandItem, SocialCharts, SocialData } from "../../V1/brand/common"

interface payloadData {
  type: string
  payload: unknown
}
interface loading {
  type: string
  payload: boolean
}

const GetSelectedBrandData = (value: number | undefined, duration?: string) => {
  const token = localStorage.getItem("token")
  if (value) {
    return (dispatch: Dispatch) => {
      const loading: loading = { type: "Loading", payload: true }
      dispatch(loading)

      void (async () => {
        const headers = token
          ? {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
              },
              body: JSON.stringify({
                brand_id: value,
                duration: duration || null,
              }),
            }
          : undefined

        const url = getAbsoluteURL("search/shopifyfull/")
        const response = await fetch(url, headers)
        const data: BrandItem = (await response.json()).results[0] as BrandItem
        const loading: loading = { type: "Loading", payload: false }
        const StatusCode: payloadData = {
          type: "StatusCode",
          payload: response.status,
        }
        dispatch(StatusCode)
        const payloadData: payloadData = { type: "BrandItem", payload: data }
        dispatch(payloadData)
        dispatch(loading)
        const payload: payloadData = {
          type: "SetTrackedOn",
          payload: data.trackstore === 0 ? false : true,
        }
        dispatch(payload)
      })()
    }
  }
}

const GetSocialData = (value: string, duration?: string) => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    const loading: loading = { type: "Loading", payload: true }
    dispatch(loading)

    void (async () => {
      const response = await fetch(
        getAbsoluteURL(`api/shopifysocial/${value}?duration=${duration || 60}`),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token as string}`,
          },
        }
      )
      const data: SocialData[] = (await response.json()) as SocialData[]
      const loading: loading = { type: "Loading", payload: false }
      if (data.length !== 0) {
        const chartData: SocialCharts = {
          fbfollowers: filterResponse(data, "fbfollowers"),
          fblikes: filterResponse(data, "fblikes"),
          fbreviews: filterResponse(data, "fbreviews"),
          instafollowers: filterResponse(data, "instafollowers"),
          reditmentions: filterResponse(data, "reditmentions"),
          redditgrowth: filterResponse(data, "redditgrowth"),
          numberfbads: filterResponse(data, "numberfbads"),
          numberreviews: filterResponse(data, "numberreviews"),
          amazonreviews: filterResponse(data, "amazonreviews"),
        }
        const chartPayload: payloadData = {
          type: "SocialCharts",
          payload: chartData,
        }
        dispatch(chartPayload)
        const dataCount = FilterData(data)
        const payloadDataCount: payloadData = {
          type: "SocialCount",
          payload: dataCount,
        }
        dispatch(payloadDataCount)
        const payloadData: payloadData = { type: "SocialData", payload: data }
        dispatch(payloadData)
      } else {
        const payloadData: payloadData = { type: "SocialData", payload: [] }
        dispatch(payloadData)
        const payloadDataCount: payloadData = {
          type: "SocialCount",
          payload: {},
        }
        dispatch(payloadDataCount)
      }
      dispatch(loading)
    })()
  }
}

const GetFbAds = (
  value: string,
  sortBy?: string,
  pageNumber?: number,
  count?: number
) => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    const loading: loading = { type: "Loading", payload: true }
    dispatch(loading)

    void (async () => {
      if (value) {
        const fetchUrl = getAbsoluteURL(
          `search/fb-ads/?page=${pageNumber || 1}&page_size=${
            count || 20
          }&page_id=${value}&ordering=${sortBy || "-startdate"}`
        )
        const response = await fetch(fetchUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token as string}`,
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        const data: any = await response.json()
        const loading: loading = { type: "Loading", payload: false }
        if (data) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const payloadData: payloadData = { type: "FbAds", payload: data.data }
          dispatch(payloadData)
        } else {
          const payloadData: payloadData = { type: "FbAds", payload: {} }
          dispatch(payloadData)
        }
        dispatch(loading)
      }
    })()
  }
}

const GetFbAdsCount = (value: string) => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    const loading: loading = { type: "Loading", payload: true }
    dispatch(loading)

    void (async () => {
      if (value) {
        const response = await fetch(
          getAbsoluteURL(`search/fb-ads/count?page_id=${value}`),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token as string}`,
            },
          }
        )
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res: any = await response.json()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data: unknown[] = res.data as unknown[]
        const loading: loading = { type: "Loading", payload: false }

        if (data.length !== 0) {
          const lastCount = data.pop()
          data.push(lastCount)
          const payloadData: payloadData = { type: "FbAdsCount", payload: data }
          dispatch(payloadData)
          const payloadDataCount: payloadData = {
            type: "FbAdsCountData",
            payload: lastCount,
          }
          dispatch(payloadDataCount)
        } else {
          const payloadData: payloadData = { type: "FbAdsCount", payload: [] }
          dispatch(payloadData)
          const payloadDataCount: payloadData = {
            type: "FbAdsCountData",
            payload: {},
          }
          dispatch(payloadDataCount)
        }
        dispatch(loading)
      }
    })()
  }
}

const GetFbAdsReview = (value: string) => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    void (async () => {
      if (value) {
        const response = await fetch(
          getAbsoluteURL(`search/fb-ads/review/?page_id=${value}`),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token as string}`,
            },
          }
        )
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res: any = await response.json()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data: Record<string, unknown> = res.data as Record<
          string,
          unknown
        >
        const payloadData: payloadData = { type: "FbAdsReview", payload: data }
        dispatch(payloadData)
      }
    })()
  }
}

const GetSerpStatKeyword = (value: string) => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    void (async () => {
      const response = await fetch(
        getAbsoluteURL(`api/keyword-data/${value}`),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token as string}`,
          },
        }
      )
      const data: unknown[] = (await response.json()) as unknown[]
      if (data.length > 0) {
        const payloadData: payloadData = {
          type: "SerpstatKeywords",
          payload: data,
        }
        dispatch(payloadData)
      } else {
        const payloadData: payloadData = {
          type: "SerpstatKeywords",
          payload: [],
        }
        dispatch(payloadData)
      }
    })()
  }
}

const GetTrafficData = (value: string) => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    const loading: loading = { type: "Loading", payload: true }
    dispatch(loading)

    void (async () => {
      const response = await fetch(
        getAbsoluteURL(`api/traffic-data/brief/${value}`),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token as string}`,
          },
        }
      )
      const data: unknown = (await response.json()) as unknown
      const payloadData: payloadData = {
        type: "TrafficBriefData",
        payload: data,
      }
      dispatch(payloadData)
      const loading: loading = { type: "Loading", payload: false }
      dispatch(loading)
    })()
  }
}

const GetTrustpilotData = (value: string) => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    void (async () => {
      const response = await fetch(getAbsoluteURL(`api/trustpilot/${value}`), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token as string}`,
        },
      })
      const data: unknown[] = (await response.json()) as unknown[]
      const payloadData: payloadData = { type: "TrustpilotData", payload: data }
      dispatch(payloadData)
    })()
  }
}

const GetStoreTabSummary = (value: string) => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    void (async () => {
      const response = await fetch(
        getAbsoluteURL(
          `search/shopify-products/product_summary?brandname__wildcard=${value}`
        ),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token as string}`,
          },
        }
      )
      const data: unknown = (await response.json()) as unknown
      const payloadData: payloadData = {
        type: "StoreTabSummary",
        payload: data,
      }
      dispatch(payloadData)
    })()
  }
}

const GetSerpstatAds = (value: string) => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    void (async () => {
      const formData = { brandname: value }
      const response = await fetch(getAbsoluteURL("api/serpstat/adkeywords/"), {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token as string}`,
        },
      })
      const data: unknown = (await response.json()) as unknown
      const payloadData: payloadData = { type: "SerpstatAds", payload: data }
      dispatch(payloadData)
    })()
  }
}

const GetOverviewCharts = (brandId: string, duration: number) => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    const loading: loading = { type: "Loading", payload: true }
    dispatch(loading)
    void (async () => {
      const response = await fetch(
        getAbsoluteURL(`api/brand/charts/${brandId}/?duration=${duration}`),
        {
          headers: { Authorization: `Token ${token as string}` },
        }
      )
      const data: unknown = (await response.json()) as unknown
      const payload: payloadData = { type: "OverviewCharts", payload: data }
      dispatch(payload)
      const loading: loading = { type: "Loading", payload: false }
      dispatch(loading)
    })()
  }
}

const GetBrandViewStatus = (brandId: string) => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    const loading: loading = { type: "Loading", payload: true }
    dispatch(loading)
    void (async () => {
      const response = await fetch(
        getAbsoluteURL(`api/subscription/brand-view-status/${brandId}/`),
        {
          headers: { Authorization: `Token ${token as string}` },
        }
      )
      const data = (await response.json()) as unknown
      const payload: payloadData = { type: "BrandViewStatus", payload: data }
      dispatch(payload)
      const loading: loading = { type: "Loading", payload: false }
      dispatch(loading)
    })()
  }
}

const GetSubscriptionUsage = () => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    const loading: loading = { type: "Loading", payload: true }
    dispatch(loading)
    if (token) {
      axios
        .get(getAbsoluteURL("api/subscription/usage/"), {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          const data: unknown = response.data
          const payload: payloadData = {
            type: "SubscriptionUsage",
            payload: data,
          }
          dispatch(payload)
          const subscriptionLoaded: payloadData = {
            type: "SubscriptionLoaded",
            payload: true,
          }
          dispatch(subscriptionLoaded)
          const loading: loading = { type: "Loading", payload: false }
          dispatch(loading)
        })
        .catch(() => {
          const subscriptionLoaded: payloadData = {
            type: "SubscriptionLoaded",
            payload: false,
          }
          dispatch(subscriptionLoaded)
          const loading: loading = { type: "Loading", payload: false }
          dispatch(loading)
        })
    } else {
      const payload: payloadData = { type: "SubscriptionUsage", payload: {} }
      dispatch(payload)
      const subscriptionLoaded: payloadData = {
        type: "SubscriptionLoaded",
        payload: false,
      }
      dispatch(subscriptionLoaded)
      const loading: loading = { type: "Loading", payload: false }
      dispatch(loading)
    }
  }
}

const GetReviewTracker = (brandId: string | number) => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    const loading: loading = { type: "Loading", payload: true }
    dispatch(loading)
    if (token) {
      axios
        .get(getAbsoluteURL(`/api/brand-reviews/${brandId}/`), {
          headers: {
            Authorization: `Token ${localStorage.getItem("token") as string}`,
          },
        })
        .then((response) => {
          const data: unknown = response.data
          const payload: payloadData = { type: "ReviewTracker", payload: data }
          dispatch(payload)
          const loading: loading = { type: "Loading", payload: false }
          dispatch(loading)
        })
        .catch((error) => console.log(error))
    }
  }
}

const GetYoutubeAnalytics = (brandId: string) => {
  const token = localStorage.getItem("token")
  return (dispatch: Dispatch) => {
    dispatch({ type: "Loading", payload: true })
    axios
      .get(getAbsoluteURL(`api/youtube/analytics/${brandId}/`), {
        headers: { Authorization: `Token ${token as string}` },
      })
      .then((response) => {
        const payloadData: payloadData = {
          type: "youtubeAnalytics",
          payload: response.data,
        }
        dispatch(payloadData)
        dispatch({ type: "Loading", payload: false })
      })
      .catch((error: { response: AxiosResponse }) => {
        const youtubeStatus: payloadData = {
          type: "youtubeStatus",
          payload: error.response.status,
        }
        dispatch(youtubeStatus)
        dispatch({ type: "Loading", payload: false })
      })
  }
}

const Actions = {
  GetSelectedBrandData,
  GetSocialData,
  GetFbAds,
  GetFbAdsCount,
  GetFbAdsReview,
  GetTrafficData,
  GetSerpStatKeyword,
  GetTrustpilotData,
  GetStoreTabSummary,
  GetSerpstatAds,
  GetOverviewCharts,
  GetBrandViewStatus,
  GetSubscriptionUsage,
  GetReviewTracker,
  GetYoutubeAnalytics,
}

export default Actions
