// hooks/useUser.ts
import * as React from "react"
import { ILayoutContext, LayoutContext } from "../../contexts/LayoutContext"

export const useLayout = (): ILayoutContext => {
  const layoutContext = React.useContext(LayoutContext)

  if (!layoutContext) {
    throw new Error("useLayout must be used within a LayoutContext")
  }

  return layoutContext
}
