import React from "react"
import axios from "axios"
import { useDispatch } from "react-redux"
import { Modal, Backdrop, Fade, Box, Typography, Link } from "@mui/material"

import { getAbsoluteURL } from "../../../utils"

interface Props {
  open: boolean
}

interface PaymentDetails {
  portal_url: string
}

export default function BrandCreditsMessage(props: Props): React.ReactElement {
  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  }
  const token = localStorage.getItem("token")

  const dispatch = useDispatch()

  const getPaymentDetails = () => {
    dispatch({ type: "Loading", payload: true })
    axios.post(getAbsoluteURL("api/subscription/create-portal-session/"), {},
      { headers: { "Content-type": "application/json", "Authorization": `Token ${token as string}` } })
      .then((response) => {
        const paymentDetails = response.data as PaymentDetails
        dispatch({ type: "Loading", payload: false })
        Object.assign(
          document.createElement("a"),
          {
            target: "_blank",
            href: paymentDetails.portal_url
          }
        ).click()
      })
      .catch((error) => console.log(error))
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      className="addBrandPopup"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            You've reached your brand view limit for this month. Please consider
            <Link onClick={() => getPaymentDetails()}> upgrading</Link>
          </Typography>
        </Box>
      </Fade>
    </Modal>
  )
}
