import { Box, Typography } from "@mui/material"
import React from "react"
import Modal from "../../../components/Modal"

type Props = {
  open: boolean
  close: () => void
  apiMessage: string
}

const ErrorMessage = (props: Props): React.ReactElement => {

  return (
    <Modal
      open={props.open}
      handleClose={props.close}
    >
      <Box>
        <Typography variant="h6">{props.apiMessage}</Typography>
      </Box>
    </Modal>
  )
}
export default ErrorMessage