import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import { Typography } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import React, { useEffect, useState } from "react"

import { Margin, Supplier } from "../../../types/productReportTypes"
import { fetchSupplierMargin } from "../../../api/report"

type Props = {
  productId: number
  productPrice: number
  supplier: Supplier
}

export default function CalculationTable(props: Props): React.ReactElement {
  const [margin, setMargin] = useState<Margin[]>([])

  useEffect(() => {
    const loadSupplierMargin = async () => {
      const params = {
        supplier_id: props.supplier.id as number,
        supplier_cost: props.supplier.price_per_unit as number,
        product_id: props.productId,
        product_price: props.productPrice,
      }
      const response = await fetchSupplierMargin(params)
      if (response) {
        setMargin(response)
      }
    }

    loadSupplierMargin()
  }, [props.productId, props.productPrice, props.supplier])

  return (
    <>
      <div className="shbox">
        <div className="Competitors">
          <Typography variant="h5">
            Profit = Price - (Tax*Price) - freight - courier - CAC
            <Tooltip
              title={
                <React.Fragment>
                  The table shows approximate profit for the product from this supplier taking in consideration:<br />
                  <b>Retail Price</b><br />
                  <b>Tax rate</b> at the destination<br />
                  <b>Cost of Goods</b> sold - from the current supplier cost per unit<br />
                  <b>Freight</b> - to this destination based on the cost per unit<br />
                  <b>Courier costs</b> - approximate for the destination<br />
                  <b>Customer Acquisition Cost</b> - approximate based on 30% from Retail Price
                </React.Fragment>
              }
              arrow
            >
              <InfoIcon color="disabled" />
            </Tooltip>
          </Typography>
          {margin.length > 0
            ? <TableContainer >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Country</TableCell>
                    <TableCell>20" Profit</TableCell>
                    <TableCell>40" Profit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {margin.map((marginData, index) => (
                    <TableRow key={index}>
                      <TableCell>{marginData.country}</TableCell>
                      <TableCell>${marginData["20_ft_profit"]}</TableCell>
                      <TableCell>${marginData["40_ft_profit"]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            : <Typography variant="h6">Margin hasn't been calculated yet for this supplier.</Typography>
          }
        </div>
      </div>

    </>
  )
}
