import { SelectChangeEvent } from "@mui/material"
import { useEffect, useState } from "react"

import Ads from "./Ads"
import { AdCategories } from "./Constants"
import TrendingAdsLayout from "./Layout"

export default function TrendingAds() {
  const [adCategory, setAdCategory] = useState(AdCategories.AllAds)
  const [searchValue, setSearchValue] = useState<string>("")
  const [searchCounter, setSearchCounter] = useState<number>(0)

  useEffect(() => {
    if (searchValue === "") {
      setSearchCounter(0)
    }
  }, [searchValue])

  const handleAdCategoryChange = (event: SelectChangeEvent<unknown>) => {
    setAdCategory(event.target.value as AdCategories)
  }

  const handleSearch = () => {
    setSearchCounter(searchCounter + 1)
  }

  return (
    <TrendingAdsLayout
      adCategory={adCategory}
      handleAdCategoryChange={handleAdCategoryChange}
      handleSearch={handleSearch}
      search={searchValue}
      setSearch={setSearchValue}
      ads={<Ads
        adCategory={adCategory}
        search={searchValue}
        searchCounter={searchCounter}
      />}
    />
  )
}