import dayjs from "dayjs"
import { CtaType } from "../../../api/trendingAds"

export const COUNTRIES_LIST = [
  { label: "All Countries", value: "all" },
  { label: "US", value: "US" },
  { label: "AU", value: "Australia" },
  { label: "CA", value: "Canada" },
  { label: "GB", value: "UK" },
  { label: "Other", value: "OTHER" },
]

export const MEDIA_TYPES = [
  { label: "All media types", value: "all" },
  { label: "Videos", value: "videos" },
  { label: "Images", value: "images" },
  { label: "No image or video", value: "none" },
]

export const PLATFORMS = [
  { label: "All platforms", value: "all" },
  { label: "Facebook", value: "fb" },
  { label: "Instagram", value: "ig" },
  { label: "Messenger", value: "messenger" },
  { label: "Audience Network", value: "an" },
]

export const ACTIVE_STATUS = [
  { label: "Active or Inactive", value: 2 },
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
]

export enum AdCategories {
  AllAds = "all",
  FeaturedAds = "featured_ads",
  Default = "default",
}

export const defaultFromDate = dayjs("2021-01-01")
export const defaultToDate = dayjs()

export const getCtaType = (type: string) => {
  if (type === CtaType.instagram) {
    return "VIEW IG"
  }
  return type.replaceAll("_", " ")
}
