import { Avatar, Box, TableCell } from "@mui/material"
import { openLinkInNewTab } from "../../../utils"
import { getBrandUrl } from "../../../utils/textHelpers"

type Props = {
  avatar: string
  label: string
  id: number
}

export default function AvatarTableCell({ avatar, label, id }: Props) {

  return (
    <TableCell
      sx={{
        fontSize: "14px",
        fontFamily: "Poppins,sans-serif !important",
        border: "none",
        height: "34px",
        pl: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          "& .MuiAvatar-root": {
            marginRight: (theme) => theme.spacing(1),
            width: "26px",
            height: "26px",
            border: "1px solid #e3e8ee",
            borderRadius: "50%",
          },
          "& strong": {
            fontFamily: "Poppins,sans-serif !important",
            fontWeight: 400,
            fontSize: "16px",
            minWidth: "160px"
          }
        }}
      >
        <Avatar style={{ cursor: "pointer" }} onClick={() => openLinkInNewTab(getBrandUrl(label, id))} variant="circular" src={avatar} />
        <strong style={{ cursor: "pointer" }} onClick={() => openLinkInNewTab(getBrandUrl(label, id))}>{label}</strong>
      </Box>
    </TableCell>
  )
}
