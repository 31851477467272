/* eslint-disable @typescript-eslint/no-floating-promises */
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import LockIcon from "@mui/icons-material/Lock"
import LoginIcon from "@mui/icons-material/Login"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { Alert, Button, FormControl, FormHelperText, Link } from "@mui/material"
import axios from "axios"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"

import { fetchSubscriptionStatus } from "../api/plans"
import v1logo from "../assets/images/Logo/LogoFull.svg"
import { RootStore } from "../Redux/store"
import { getAbsoluteURL } from "../utils"
import { User } from "./brand/common"
import { SidebarRoute } from "./Components/Appbar/appbarRoutes"
import LoadingOverlay from "./Components/LoadOverlay/LoadOverlay"
import SubscriptionMessage from "./Components/SubscriptionMessage/SubscriptionMessage"
import { useAuth } from "./Hooks/usAuthContext"

export default function V1Login(): React.ReactElement {
  const loading: boolean = useSelector(
    (state: RootStore) => state.PageReduser["loading"]
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [error, setError] = React.useState(false)
  const { setUser } = useAuth()
  const [pwdVisible, setPwdVisible] = React.useState(false)
  const [subscriptionModalOpen, setSubscriptionModalOpen] =
    React.useState<boolean>(false)

  const checkSubscription = async () => {
    const response = await fetchSubscriptionStatus()
    if (response?.active_subscription) {
      history.push(SidebarRoute.TrendingBrands)
    }
    if (response?.active_subscription === false) {
      setSubscriptionModalOpen(true)
    }
  }

  async function handleLogin(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const url = getAbsoluteURL("api/user/login/")
    const apiBody = { email: email, password: password }
    dispatch({ type: "Loading", payload: true })
    await axios
      .post(url, apiBody, { headers: { "Content-type": "application/json" } })
      .then((response) => {
        const user = response.data as User
        window.profitwell("user_email", email)
        localStorage.setItem("uid", user.id.toString())
        localStorage.setItem("token", user.token)
        localStorage.setItem("role", user.role)
        localStorage.setItem("username", user.username)
        localStorage.setItem("name", user.name)
        setUser(user)
        checkSubscription()
        if (user.is_superuser) {
          localStorage.setItem("is_superuser", "true")
        }
        if (user.is_beta_user) {
          localStorage.setItem("is_beta_user", "true")
        }
      })
      .catch(() => {
        dispatch({ type: "Loading", payload: false })
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 3000)
      })
  }

  function togglePwd() {
    setPwdVisible(!pwdVisible)
  }

  return (
    <div className="v1-loginBg">
      <div className="auth__left">
        <div className="auth__info">
          <ul>
            <li>Welcome back to TrendRocket.</li>
          </ul>
          <p className="mt-2"> Log in to view your account. </p>
        </div>
      </div>
      <LoadingOverlay loading={loading} />
      <div className="auth__right">
        <div className="createAcc">
          <Link
            sx={{
              textDecoration: "none",
              ":hover": {
                textDecoration: "underline",
              },
            }}
            href="https://www.trendrocket.io/#pricing"
          >
            Create New Account
            <span>
              <AccountCircleIcon fontSize="small" />
            </span>
          </Link>
        </div>
        <div className="v1-loginBox">
          <img src={v1logo} alt="MOAS" />
          <div className="v1-login">
            {error ? (
              <Alert severity="error">
                Incorrect email or password! Please try again.
              </Alert>
            ) : (
              ""
            )}
            <form onSubmit={handleLogin}>
              <FormControl>
                <span>
                  <AlternateEmailIcon fontSize="small" />
                </span>
                <input
                  required
                  id="email"
                  type="email"
                  aria-describedby="email-helper-text"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email address..."
                />
              </FormControl>
              <FormControl>
                <span>
                  <LockIcon fontSize="small" />
                </span>
                <input
                  required
                  id="password"
                  type={pwdVisible ? "text" : "password"}
                  aria-describedby="pwd-helper-text"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password..."
                />
                <span onClick={togglePwd}>
                  {pwdVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </span>
                <FormHelperText id="pwd-helper-text"></FormHelperText>
              </FormControl>
              <Button
                sx={{
                  textAlign: "left",
                  justifyContent: "space-between",
                  height: "36px !important",
                }}
                type="submit"
                fullWidth
                endIcon={<LoginIcon />}
              >
                Log In
              </Button>
            </form>
            <Link
              sx={{
                textDecoration: "none",
                ":hover": {
                  textDecoration: "underline",
                },
              }}
              href="/email-verify"
            >
              Forgotten password?
            </Link>
          </div>
        </div>
      </div>
      <SubscriptionMessage open={subscriptionModalOpen} />
    </div>
  )
}
