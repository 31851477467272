import { ThemeProvider as MThemeProvider, createTheme } from "@mui/material"
import { ReactNode } from "react"
import ComponentsOverrides from "./overrides"

export const colors = {
  Primary: "#3B4EDF",
  Primary500: "#6070E5",
  Primary400: "#8692EC",
  Primary300: "#ABB4F2",
  Primary200: "#D1D6F9",
  Primary100: "#F2F4FD",
  LightPrimary: "rgba(59, 78, 223, 0.1)",
  Secondary: "#3B4EDF",
  Secondary2: "#683BB7",
  Warning: "#FBA04B",
  Success: "#069D6D",
  Error: "#FD4E5D",
  White: "#FFFFFF",
  Text: "#3D4465",
  Background: "#f2f4fd",
  Icon: "#A6B2C1",
}

type Props = {
  children: ReactNode
}

export default function ThemeProvider({ children }: Props) {
  const theme = createTheme({
    components: ComponentsOverrides(),
  })

  return <MThemeProvider theme={theme}>{children}</MThemeProvider>
}
