import LoadingButton from "@mui/lab/LoadingButton/LoadingButton"
import { Box, TextField } from "@mui/material"
import { useState } from "react"

import Modal from "../../components/Modal"
import { editProduct } from "../../api/report"
import { UpdatedProduct } from "../../types/productReportTypes"
import { colors } from "../../utils/theme"

type Props = {
  open: boolean
  onClose: (open: boolean) => void
  name?: string
  body?: string
  productId?: number
  updateProduct: (product: UpdatedProduct) => void
}

export default function EditModal({
  open,
  onClose,
  name,
  body,
  productId,
  updateProduct,
}: Props) {
  const [formData, setFormData] = useState<any>()
  const [loading, setLoading] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, ...{ [e.target.name]: e.target.value } })
  }

  const handleSave = async () => {
    if (productId) {
      setLoading(true)
      const response = await editProduct(productId, formData)
      setLoading(false)
      if (response) {
        updateProduct(response)
      }
    }
  }

  return (
    <Modal
      title="Edit Product"
      open={open}
      handleClose={() => onClose(false)}
    >
      <Box>
        <TextField
          fullWidth
          name="name"
          label="Name"
          variant="outlined"
          defaultValue={name}
          onChange={handleChange}
        />
        <TextField
          sx={{
            mb: "20px",
            mt: "20px",
          }}
          fullWidth
          name="body"
          label="Description"
          variant="outlined"
          multiline
          maxRows={10}
          defaultValue={body}
          onChange={handleChange}
        />
        <LoadingButton
          sx={{
            right: "15px",
            position: "absolute",
            bottom: "10px",
            ".MuiLoadingButton-loadingIndicator": {
              color: colors.White,
            },
          }}
          loading={loading}
          loadingPosition="center"
          onClick={handleSave}
        >
          Save
        </LoadingButton>
      </Box>

    </Modal>
  )
}