import { styled } from "@mui/system"
import { useEffect } from "react"

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const Div = styled("div")({
  display: "none !important"
})

const SITE_KEY = "6LfFe3ckAAAAAKzy0fNeHxJ8-SLP22IApocijhzj"

export default function ReCaptcha({ action }: { action: string }) {
  useEffect(() => {
    const loadReCaptcha = () => {
      const script = document.createElement("script")
      script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${SITE_KEY}`
      document.body.appendChild(script)
    }

    loadReCaptcha()
  }, [action])

  return <Div className="g-recaptcha" data-sitekey={SITE_KEY} data-size="invisible" />
}

export const getToken = () =>
  new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    window.grecaptcha.ready(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      window.grecaptcha.execute(SITE_KEY, { action: "submit" }).then((token: string) => {
        resolve(token)
      })
    })
  })
