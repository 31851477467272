import { Grid, Typography } from "@mui/material"
import { styled } from "@mui/system"

type SpanProps = {
  color: string
}

export const Container = styled("div")({
  display: "inline-block",
  width: "calc(100% - 20px)",
  background: "rgba(59, 78, 223, 0.05)",
  borderRadius: "5px",
  padding: "10px",
  position: "relative",
  marginBottom: "10px"
})

export const ImageWrapper = styled("div")({
  width: "80px",
  height: "80px",
  borderRadius: "5px",
  overflow: "hidden",
  display: "inline-block",
  verticalAlign: "top",
  marginRight: "15px",
  float: "left",
  "& img": {
    width: "100% !important",
    height: "100% !important",
    objectFit: "cover"
  }
})

export const CountryName = styled(Typography)({
  display: "inline-block",
  fontFamily: "Poppins !important",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  width: "calc(100% - 100px)",
  letterSpacing: "-0.03em",
  color: "rgba(61, 68, 101, 0.5)",
  marginTop: "5px"
})

export const CountryTraffic = styled("p")({
  fontFamily: "Poppins !important",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "24px",
  lineHeight: "36px",
  color: "rgba(61, 68, 101, 0.5)",
  display: "inline-block",
  width: "calc(100% - 100px)",
  letterSpacing: "-0.03em",
  marginTop: "10px"
})

export const CountrySpan = styled("span")(({ color }: SpanProps) => ({
  position: "absolute",
  right: "20px",
  top: "40px",
  color: color,
  fontFamily: "Poppins !important",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
}))

export const CustomButton = styled("button")({
  background: "rgba(59, 78, 223, 0.05)",
  borderRadius: "5px",
  width: "100%",
  letterSpacing: "-0.03em",
  color: "#3B4EDF",
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "18px",
  lineHeight: "27px",
  height: "60px",
  border: "none",
  padding: "0px",
})

export const RegionsContainer = styled(Grid)({
  width: "100%",
  height: "540px",
  background: "#FFFFFF",
  borderRadius: "10px",
})

export const CountryHeader = styled(Typography)({
  fontFamily: "Poppins !important",
  fontWeight: 500,
  fontSize: "24px",
  lineHeight: "36px",
  color: "#3D4465",
  marginBottom: "20px",
})

export const CountryWrapper = styled(Grid)({
  background: "#FFFFFF",
  boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08)",
  borderRadius: "10px",
  padding: "30px",
})

export const Wrapper = styled(Grid)({
  background: "#FFFFFF",
  boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08)",
  borderRadius: "10px",
  padding: "30px",
  marginTop: "15px"
})

export const MainHeader = styled(Typography)({
  fontFamily: "Poppins !important",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "30px",
  lineHeight: "45px",
  color: "#3D4465",
  marginBottom: "5px"
})

export const Detail = styled("p")({
  fontFamily: "Poppins !important",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "-0.03em",
  margin: "0px",
  color: "rgba(61, 68, 101, 0.5)",
})