import { AxiosError } from "axios"
import { fetchApiCall, postApiCall } from "."
import { CollectionResult } from "./common"

export type SavedAd = {
  id: number,
  board_id: number,
  fb_ad_id: string
  created_on: string
  updated_on: string
  fb_ad: string
}

export type FbAd = {
  ad_content: string
  ad_country: string
  ad_creative_id: string
  adarchiveid: string
  agegenderdata: string
  collationcount: string
  collationid: string
  creation_time: string
  cta_type: string
  data_insert_timestamp: string
  display_format: string
  enddate: string
  html: string
  image: string
  impressions: string
  is_featured: boolean
  isactive: number
  link_description: string
  link_url: string
  locationdata: string
  page_categories: string
  page_id: string
  pinned_at: string
  publisherplatform: string
  reachestimate: string
  spend: string
  startdate: string
  title: string
  video_content_url: string
  video_preview_image_url: string
  video_url: string
}

export const saveAdToTrendRocket = async (
  payload: any,
  callBack: (response: AxiosError | null) => void
) => {
  const endpoint = "/api/creative/pin-tr-fb-ad/"

  return postApiCall<SavedAd>(endpoint, payload, {}, callBack)
}

export const fetchFbAds = async (page: number, page_size: number, page_id: string, ordering: string) => {
  const endpoint = "search/fb-ads/"
  const params = {
    page,
    page_size,
    page_id,
    ordering
  }

  return fetchApiCall<{ status: number, data: CollectionResult<FbAd> }>(endpoint, params)
}