import { Grid } from "@mui/material"
import { SelectChangeEvent } from "@mui/material/Select"
import { styled } from "@mui/system"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { LifetimeBrandAdCountChart } from "../LifetimeBrandAdCountChart"
import LineChartContainer from "../LineChartContainer"
import ReportsOverviewHeader from "../ReportsOverviewHeader"
import GetChartCount from "../../chartValues/GetChartCount"
import GetChartLabel from "../../chartValues/GetChartLabel"
import { FbAdsCount, SocialCharts, SocialData } from "../../common"
import LoadingOverlay from "../../../Components/LoadOverlay/LoadOverlay"
import { fetchOverviewCharts, OverviewCharts } from "../../../../api/brand"
import Actions from "../../../../pages/Actions/PageActions"
import { RootStore } from "../../../../Redux/store"
import { colors } from "../../../../utils/theme"
//-------------------------------------------------------------------------------------------------------------------------

type Params = {
  name: string
  brandId: string
}

const ChartSection = styled(Grid)(({ theme }) => ({
  background: colors.White,
  boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08)",
  borderRadius: "10px !important",
  minHeight: "330px",
  border: "none",
  marginRight: "30px",
  marginBottom: "30px",
  [theme.breakpoints.up("xs")]: {
    minWidth: "300px",
  },
  [theme.breakpoints.up("md")]: {
    minWidth: "380px",
  },
}))

export default function OverviewChartsSection() {
  const params: Params = useParams()
  const dispatch = useDispatch()
  const fbAdsCount: FbAdsCount[] | undefined = useSelector(
    (state: RootStore) => state.PageReduser["FbAdsCount"] as FbAdsCount[]
  )
  const socialChartsData: SocialCharts = useSelector(
    (state: RootStore) => state.PageReduser["socialCharts"]
  )
  const socialDataCount: SocialData = useSelector(
    (state: RootStore) => state.PageReduser["socialDataCount"]
  )
  const [loading, setLoading] = useState(false)
  const [dateChoice, setDateChoice] = useState("90")
  const [charts, setCharts] = useState<OverviewCharts>()

  const facebookLikes = {
    change: GetChartLabel("Facebook likes"),
    count: GetChartCount("Facebook likes", charts),
  }
  const instaFollowers = {
    change: GetChartLabel("Instagram followers"),
    count: GetChartCount("Instagram followers", charts),
  }
  const trustpilotReviews = {
    change: GetChartLabel("TrustPilot reviews"),
    count: socialDataCount?.numberreviews?.toString(),
  }
  const redditMentions = {
    change: GetChartLabel("Reddit mentions"),
    count: socialDataCount?.reditmentions?.toString(),
  }
  const websiteTraffic = {
    change: GetChartLabel("Website traffic"),
    count: GetChartCount("Website traffic", charts),
  }

  useEffect(() => {
    const loadCharts = async () => {
      setLoading(true)
      const response = await fetchOverviewCharts(params.brandId, dateChoice)
      setLoading(false)
      if (response) {
        setCharts(response)
      }
    }

    loadCharts()
    dispatch(Actions.GetSelectedBrandData(parseInt(params.brandId), dateChoice))
  }, [params.brandId, dateChoice, dispatch])

  const handleDateChoice = (e: SelectChangeEvent) => {
    setDateChoice(e.target.value)
  }

  return (
    <>
      <LoadingOverlay loading={loading} />
      <ReportsOverviewHeader
        dateChoice={dateChoice}
        handleDateChoice={handleDateChoice}
      />
      {charts && charts.facebook_likes?.length > 0 && (
        <ChartSection item xs={12} sm={6} md={3}>
          <LineChartContainer
            change={charts.percentage_change?.facebook_likes?.toString()}
            headerText="Facebook likes"
            headerNumber={facebookLikes.count}
            overviewCharts={charts}
          />
        </ChartSection>
      )}
      {charts && charts.instagram_followers?.length > 0 && (
        <ChartSection item xs={12} sm={6} md={3}>
          <LineChartContainer
            change={charts.percentage_change?.instagram_followers?.toString()}
            headerText="Instagram followers"
            headerNumber={instaFollowers.count}
            overviewCharts={charts}
          />
        </ChartSection>
      )}
      {charts && charts.trustpilot_reviews.length > 0 && (
        <ChartSection item xs={12} sm={6} md={3}>
          <LineChartContainer
            change={charts.percentage_change?.trustpilot_reviews?.toString()}
            headerText="TrustPilot reviews"
            headerNumber={trustpilotReviews.count}
            data={socialChartsData}
          />
        </ChartSection>
      )}
      {charts && charts.reddit_mentions.length > 0 && (
        <ChartSection item xs={12} sm={6} md={3}>
          <LineChartContainer
            change={charts.percentage_change?.reddit_mentions?.toString()}
            headerText="Reddit mentions"
            headerNumber={redditMentions.count}
            data={socialChartsData}
          />
        </ChartSection>
      )}
      {fbAdsCount?.length > 0 ? (
        <ChartSection item xs={12} sm={6} md={3}>
          <LifetimeBrandAdCountChart />
        </ChartSection>
      ) : (
        ""
      )}
      {charts && charts.website_traffic.length > 0 && (
        <ChartSection item xs={12} sm={6} md={3}>
          <LineChartContainer
            change={charts.percentage_change?.website_traffic?.toString()}
            headerText="Website traffic"
            headerNumber={websiteTraffic.count}
            overviewCharts={charts}
          />
        </ChartSection>
      )}
    </>
  )
}
