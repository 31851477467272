import TickIcon from "@mui/icons-material/CheckBox"
import CancelIcon from "@mui/icons-material/Close"
import TuneIcon from "@mui/icons-material/FilterAlt"
import {
  Box,
  Button,
  ClickAwayListener,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import FilterPopper from "../../../components/Popper/FilterPopper"
import { RootStore } from "../../../Redux/store"
import Authorization from "../../Authorization/Authorization"
import Pagination from "../../Components/Pagination/pagination"
import usePagination from "../../Hooks/usePagination"
import { BrandItem, FbAdsCount, FbAdsReview } from "../common"
import GeoMap from "../components/GeoMap/GeoMap"
import { LifetimeBrandAdCountChart } from "../components/LifetimeBrandAdCountChart"
import { LifetimeUniqueBrandAdCountChart } from "../components/LifetimeUniqueBrandAdCountChart"
import FacebookAdCard from "./FacebookAdCard"
import { FbAd, fetchFbAds } from "../../../api/ads"
import { CollectionResult } from "../../../api/common"
import { getToken } from "../../../utils/localStorage"
import LoadingOverlay from "../../Components/LoadOverlay/LoadOverlay"
import AdDetailPreviewModal from "../components/FbAdDetail"
import { colors } from "../../../utils/theme"
import { scrollToTop } from "../../../utils"

export default function AdsTab(): React.ReactElement {
  const brandItem: BrandItem = useSelector(
    (state: RootStore) => state.PageReduser["brandItem"]
  )
  const FbAdsCount: FbAdsCount[] = useSelector(
    (state: RootStore) => state.PageReduser["FbAdsCount"]
  )
  const FbAdsCountData: FbAdsCount = useSelector(
    (state: RootStore) => state.PageReduser["FbAdsCountData"]
  )
  const FbAdsReview: FbAdsReview = useSelector(
    (state: RootStore) => state.PageReduser["FbAdsReview"]
  )
  const token = getToken()

  const {
    currentPage,
    pageSize,
    resultCount,
    setResultCount,
    setCurrentPage,
    setPageSize,
  } = usePagination()

  const [sortAdsBy, setSortAdsBy] = useState("-startdate")
  const [filtersPopperOpened, setFiltersPopperOpened] = useState(false)
  const [filtersPopperAnchorEl, setFiltersPopperAnchorElem] =
    useState<HTMLButtonElement | null>(null)
  const [adCounts, setAdCounts] = useState<number[]>([])
  const [fbAds, setFbAds] = useState<CollectionResult<FbAd>>()
  const [selectedAd, setSelectedAd] = useState<FbAd>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (FbAdsReview.fb_ads_country) {
      const adCountList = FbAdsReview.fb_ads_country.map((country) =>
        country.ad_country !== "Others" ? country.ads_count : 0
      )
      adCountList.sort((a, b) => a - b).shift()
      setAdCounts(adCountList)
    }
  }, [FbAdsCount, FbAdsCountData, FbAdsReview.fb_ads_country])

  useEffect(() => {
    const loadFbAds = async () => {
      setLoading(true)
      const response = await fetchFbAds(
        currentPage,
        pageSize,
        brandItem.page_id,
        sortAdsBy
      )
      setLoading(false)
      if (response) {
        setFbAds(response.data)
        setResultCount(response.data.count)
      }
    }
    if (resultCount !== 0) {
      scrollToTop(1475)
    }

    loadFbAds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandItem.page_id, currentPage, pageSize, setResultCount, sortAdsBy])

  function handleFiltersButtonClicked(e: React.MouseEvent<HTMLButtonElement>) {
    setFiltersPopperAnchorElem(filtersPopperAnchorEl ? null : e.currentTarget)
    setFiltersPopperOpened(!filtersPopperOpened)
  }

  function closeFilterPopper() {
    setFiltersPopperAnchorElem(null)
    setFiltersPopperOpened(false)
  }

  function handleClickAwayFromFiltersPopper() {
    closeFilterPopper()
  }

  function handleSortAdsBySelectChanged(e: SelectChangeEvent<string>) {
    setSortAdsBy(e.target.value)
    setCurrentPage(1)
  }

  function handleFilterPopperDone() {
    closeFilterPopper()
  }

  function parseDateTime(dateTime: string): string {
    const [daysHours, mins] = dateTime.split(":")
    return `${daysHours} hours ${mins} minutes`
  }

  const viewNextAd = () => {
    const ads = fbAds?.results
    if (ads) {
      const index = ads.findIndex(
        (ad) => ad.adarchiveid === selectedAd?.adarchiveid
      )
      if (index === ads.length) {
        return
      }
      setSelectedAd(ads[index + 1])
    }
  }

  const viewPreviousAd = () => {
    const ads = fbAds?.results
    if (ads) {
      const index = ads.findIndex(
        (ad) => ad.adarchiveid === selectedAd?.adarchiveid
      )
      if (index < 1) {
        return
      }
      setSelectedAd(ads[index - 1])
    }
  }

  return (
    <Grid container className="adsTab">
      {!token ? (
        <Authorization />
      ) : (
        <>
          <LoadingOverlay loading={loading} />
          <Grid
            container
            sx={{
              gridGap: "30px",
              "& .MuiGrid-item": {
                boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08)",
                borderRadius: "10px",
                borderCollapse: "collapse",
                maxWidth: "calc(25% - 23px)",
              },
              "& > :first-child": {
                borderLeft: "none",
              },
              "& > :last-child": {
                borderRight: "none",
              },
            }}
          >
            {FbAdsCountData.total_ads && (
              <Grid item xs={3}>
                <LifetimeBrandAdCountChart />
              </Grid>
            )}
            {FbAdsCountData.unique_ads && (
              <Grid item xs={3}>
                <LifetimeUniqueBrandAdCountChart />
              </Grid>
            )}
            {FbAdsReview.active_ads && (
              <Grid item xs={3} className="avgRunningTime Active">
                <Typography variant="subtitle2" className="Active">
                  <TickIcon />
                  Active Ads
                </Typography>
                <p>
                  Average Ad Runtime{" "}
                  <span>
                    {FbAdsReview.active_ads.average_runtime
                      ? parseDateTime(FbAdsReview.active_ads.average_runtime)
                      : 0}{" "}
                  </span>
                </p>
                <p>
                  Ads Running More Than 30 Days{" "}
                  <span>
                    {FbAdsReview.active_ads.ads_running_above_30_days || 0}{" "}
                  </span>
                </p>
                <p>
                  Total Ads <span>{FbAdsReview.active_ads.ads_count}</span>
                </p>
              </Grid>
            )}
            {FbAdsReview.inactive_ads && (
              <Grid item xs={3} className="avgRunningTime Inactive">
                <Typography variant="subtitle2" className="Inactive">
                  <CancelIcon />
                  Inactive Ads
                </Typography>
                <p>
                  Average Ad Runtime{" "}
                  <span>
                    {FbAdsReview.inactive_ads.average_runtime
                      ? parseDateTime(FbAdsReview.inactive_ads.average_runtime)
                      : 0}
                  </span>
                </p>
                <p>
                  Ads Running More Than 30 Days{" "}
                  <span>
                    {FbAdsReview.inactive_ads.ads_running_above_30_days || 0}{" "}
                  </span>
                </p>
                <p>
                  Total Ads <span>{FbAdsReview.inactive_ads.ads_count}</span>
                </p>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{
              mt: (theme) => theme.spacing(3.75),
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                height: "475px",
                pl: "0px!important",
                pt: "0px!important",
                boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08)",
                borderRadius: "10px",
                position: "relative",
              }}
            >
              {FbAdsReview.fb_ads_country ? (
                <Paper
                  sx={{
                    padding: (theme) => theme.spacing(3.75),
                    backgroundColor: colors.White,
                    boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08)",
                    borderRadius: "10px",
                    position: "absolute",
                    width: "323px",
                    maxHeight: "240px",
                    bottom: "30px",
                    left: "30px",
                    mt: (theme) => theme.spacing(0),
                  }}
                >
                  <Typography variant="h6">Ads - Country Targeting</Typography>
                  {FbAdsReview.fb_ads_country.map((data, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "& > :last-child": {
                          color: (theme) => theme.palette.text.secondary,
                        },
                        "& > h6": {
                          fontSize: "16px",
                          lineHeight: "2",
                        },
                      }}
                    >
                      <Typography variant="h6">{data.ad_country}</Typography>
                      <Typography variant="h6">{data.ads_count}</Typography>
                    </Box>
                  ))}
                </Paper>
              ) : (
                ""
              )}
              {FbAdsReview.fb_ads_country ? (
                <GeoMap
                  adCounts={adCounts}
                  countries={FbAdsReview.fb_ads_country}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            className="adsFilterHead"
          >
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                "& > :first-child": {
                  marginRight: (theme) => theme.spacing(4),
                },
              }}
            >
              <Typography variant="h5">Ads list</Typography>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end">
              <ClickAwayListener onClickAway={handleClickAwayFromFiltersPopper}>
                <div>
                  <Button
                    variant="outlined"
                    startIcon={<TuneIcon />}
                    size="medium"
                    onClick={handleFiltersButtonClicked}
                    sx={{
                      boxShadow: "0 1px 1px 0 rgba(0,0,0,.08) !important",
                      color: `${colors.Text} !important`,
                      "&:hover": {
                        backgroundColor: "transparent !important",
                        color: `${colors.Text} !important`,
                      },
                    }}
                  >
                    Filters
                  </Button>
                  <FilterPopper
                    open={filtersPopperOpened}
                    anchorEl={filtersPopperAnchorEl}
                    onDone={handleFilterPopperDone}
                  />
                </div>
              </ClickAwayListener>
              <FormControl
                sx={{
                  ml: "30px",
                  boxShadow: "none",
                  display: "inline-block!important",
                  border: "1px solid #dedfe2!important",
                  borderRadius: "6px",
                  height: "40px",
                  lineHeight: "40px",
                  pl: "16px!important",
                  "& .MuiFormLabel-root": {
                    transform: "none!important",
                    position: "relative",
                    display: "inline-block",
                    top: "6px",
                  },
                  "& .MuiInputBase-root": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  },
                }}
                variant="outlined"
              >
                <InputLabel id="label-sort-by">Sort by : </InputLabel>
                <Select
                  labelId="label-sort-by"
                  label="Sort by"
                  id="dropdown-sort-by"
                  value={sortAdsBy}
                  onChange={handleSortAdsBySelectChanged}
                >
                  <MenuItem value="-startdate">Latest Ads</MenuItem>
                  <MenuItem value="startdate">Start date</MenuItem>
                  <MenuItem value="long_running_ads">Long running ads</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            {fbAds &&
              fbAds.results?.map((facebookAd: FbAd) => (
                <FacebookAdCard
                  facebookAd={facebookAd}
                  key={facebookAd.adarchiveid}
                  boards={[]}
                  boardsLoading={false}
                  viewDetail={setSelectedAd}
                />
              ))}
            <Pagination
              currentPage={currentPage}
              resultCount={resultCount}
              pageSize={pageSize}
              onPageChange={setCurrentPage}
              onPageSizeChange={setPageSize}
              label={"Ads"}
            />
          </Grid>
          <AdDetailPreviewModal
            handleClose={() => setSelectedAd(undefined)}
            ad={selectedAd}
            onNext={viewNextAd}
            onPrevious={viewPreviousAd}
          />
        </>
      )}
    </Grid>
  )
}
