
export enum Incoterms {
  exWorks = "EXW",
  freeOnBord = "FOB",
  deliveryDutyPaid = "DDP",
}

export type ComparisonReport = {
  id?: number
  amazon: boolean
  brand_name: string
  country: string
  description: string
  domain_age: string
  fb_likes: number
  fb_ads_active: boolean
  fb_growth: number
  fb_page_age: string
  google_ads_active: boolean
  ig_growth: number
  ig_followers: number
  web_traffic: number
  logo: string
  url: string
}

export type Product = {
  body: string
  brandname: string
  brandname_keyword: string
  code: string
  collection: string
  imageurl: string
  index: number
  instock: string
  name: string
  previousprice: number
  price: number
  price_usd: number
  productrank: number
  shopify_product_id: number
  timestamp: string
  timeupdated: string
  url: string
  variantname: string
  is_featured: boolean
}

export type UpdatedProduct = {
  name: string
  body: string
}

export type ShopifyProduct = {
  product_cost: string
  product_margin: string
  shopify_product_id: string
}

export type Supplier = {
  id?: number
  is_deleted?: boolean
  product_id: number
  product_name: string
  supplier_name: string
  price_per_unit: number | null
  alibaba_url: string
  drop_shipping: string
  wechat: string
  whatsapp: string
  agent_notes: string
  minimum_order_qty: number | null
  contact_person: string
  lead_time: number | null
  supplier_type: number
  sea_freight: boolean
  air_freight: boolean
  margin?: string
  incoterms: Incoterms
  master_carton_size: string
  master_carton_weight: string
  master_carton_units: number | null
  product_box_size: string
  weight: string
  skype: string
  is_verified: boolean
  [index: string]: any
}

export type Logistics = {
  id?: number
  product_id: number
  product_name: string
  country: number | undefined
  cost_per_unit_container_cost: number | undefined
  cost_per_unit_20ft_container_cost: number | undefined
  cost_per_unit_40ft_container_cost: number | undefined
  cost_per_unit_air_freight: number | undefined
  product_supplier?: number
  no_of_units_20_ft_container: number | null
  no_of_units_40_ft_container: number | null
  [index: string]: any
}

export type Containers = {
  country: string
  country_id: number
  cost_40ft_container: number
  cost_20ft_container: number
  cost_per_unit_20_ft: number
  cost_per_unit_40_ft: number
}

export type UnitsPerContainer = {
  "20_ft": number
  "40_ft": number
}

export type AutomatedLogistics = {
  container_rates: Containers[]
  units_per_container: UnitsPerContainer
}

export type Margin = {
  country: string
  using_cac: number
  "20_ft_profit": number
  "40_ft_profit": number
  freight_20_ft: number
  freight_40_ft: number
}