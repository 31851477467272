import { FormControl, MenuItem, Select } from "@mui/material"

export type PlanOption = {
  priceId: string
  title: string
  detail: string
  supportsTrial: boolean
}

export const PlanSelect = ({
  planOptions,
  selectedPriceId,
  onSelect,
}: {
  planOptions: PlanOption[]
  selectedPriceId?: string
  onSelect: (priceId: string) => void
}) => {
  return (
    <FormControl
      sx={{
        mb: "0px !important"
      }}
    >
      <label>Select a Plan</label>
      <Select
        required
        id="signup-plan-select"
        value={selectedPriceId}
        label="Select a plan"
        onChange={(event) => {
          onSelect(event.target.value)
        }}
        sx={{
          "& .MuiSelect-select": {
            pr: "18px !important",
            width: "90% !important",
          },
        }}
        inputProps={{
          sx: {
            paddingRight: "0px",
            paddingTop: "8px",
            paddingBottom: "0px",
            paddingLeft: "12px",
          },
        }}
      >
        {planOptions.map((planOption) => (
          <MenuItem value={planOption.priceId}>{planOption.title}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const generatePlanOptions = (): PlanOption[] => {
  const ENV = process.env.REACT_APP_ENVIRONMENT
  if (ENV?.toLowerCase() === "production") {
    return [
      {
        priceId: "price_1M5PFNF4ZwYqlhsbAwhqrMA0",
        title: "Launch: $39 USD a month",
        supportsTrial: true,
        detail:
          "Perfect for starters or those wanting to test the waters first!",
      },
      {
        priceId: "price_1M5POSF4ZwYqlhsbc7pNFVr5",
        title: "Grow: $99 USD a month",
        supportsTrial: true,
        detail: "Perfect for businesses ready to expand",
      },
      {
        priceId: "price_1M5PVmF4ZwYqlhsbbbLnIqAr",
        title: "Scale: $149 USD a month",
        supportsTrial: false,
        detail: "Perfect for those who already have traction",
      },
    ]
  } else {
    return [
      {
        priceId: "price_1KoRgIF4ZwYqlhsbzLyq3pzT",
        title: "Launch (Demo): $39 USD a month",
        supportsTrial: true,
        detail:
          "Perfect for starters or those wanting to test the waters first!",
      },
      {
        priceId: "price_1KoRigF4ZwYqlhsbrEkLAFs0",
        title: "Grow (Demo): $99 USD a month",
        supportsTrial: false,
        detail: "Perfect for businesses ready to expand",
      },
    ]
  }
}
