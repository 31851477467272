// @mui
import { Grid } from "@mui/material"

import { PinnedAd } from "../../../../../api/creativeCenter/pinnedAds"
import AdDetailView from "./AdDetailView"
import AdMetaView from "./AdMetaView"

// ----------------------------------------------------------------------

type PinnedAdContentProps = {
  pinnedAd: PinnedAd
  focusedCtaUrl?: string | null
  handleClose?: VoidFunction
  focusedIndexChanged: (newIndex: number) => void
  hideMoveBoard?: boolean
}

export default function PinnedAdContent({
  pinnedAd,
  focusedCtaUrl,
  hideMoveBoard,
  handleClose,
  focusedIndexChanged,
}: PinnedAdContentProps) {
  return (
    <Grid
      container
      sx={{
        borderBottomWidth: "1px",
        borderBottomColor: "rgba(61, 68, 101, 0.1)",
        borderBottomStyle: "solid",
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          borderRightWidth: { md: "1px" },
          borderRightColor: { md: "rgba(61, 68, 101, 0.1)" },
          borderRightStyle: { md: "solid" },
          pr: { md: 2 },
        }}
      >
        <AdDetailView
          pinnedAd={pinnedAd}
          focusedIndexChanged={focusedIndexChanged}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ pl: 2 }}>
        <AdMetaView
          pinnedAd={pinnedAd}
          handleClose={handleClose}
          focusedCtaUrl={focusedCtaUrl}
          hideMoveBoard={hideMoveBoard}
        />
      </Grid>
    </Grid>
  )
}
