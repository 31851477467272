// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

const ROOTS_BRAND = "/brand"
const PUBLIC = "/public"

export const PATH_BRANDS = {
  root: ROOTS_BRAND,
  home: {
    overview: (brandName: string, brandId: number) =>
      path(ROOTS_BRAND, `/${brandName}/${brandId}`),
  },
}

export const PATH_PUBLIC = {
  root: PUBLIC,
  ads: (token: string) => `ad/${token}`,
}
