export type CountryType = {
  code: string
  label: string
}

export type CountryRegion = {
  code: string
  label: string
  groupBy: string
}

export enum ScoreStatus {
  Good = "good",
  Warn = "warn",
  Bad = "bad"
}

export type ScoreColumn = {
  score: number
  percentage: number
  status: ScoreStatus
}

export type NetworkColumn = {
  value: number
  change: number
}

export type BrandTableRowData = {
  brand_id: number
  brandname: string
  page_id: string
  address: string
  description: string
  fburl: string
  instagramurl: string
  category_name: string
  country_code: string
  trustpilotsite: string
  flag: string
  brandsite: string
  igfollowers: number
  fblikes: number
  fbads_count: number
  aboutbrand: string
  brand_description: string
  revenue_score: number | null
  network: NetworkColumn
  trustPilot: number
  google: number
  timestamp: string
  profileimage: string
  coverimage: string
  shopifysite: string
  keywords: string[]
  serpstat_keywords: string[]
  scaling_score_percentage: number
  revenue_score_percentage: number
  latest_amazonreviews: number
  latest_shopifyreviews_count: number
  latest_fblikes: number
  latest_fbreviews: number
  latest_fbfollowers: number
  latest_instaposts: number
  latest_instafollowers: number
  latest_instafollowing: number
  latest_numberfbads: number
  latest_numberreviews: number
  latest_redditgrowth: number
  latest_reditmentions: number
  product_data: {
    avg_price: number
    count: number
    last_30_days: number
    max_price: number
    min_price: number
  }
  store_data: {
    estimated_monthly_orders: number
    estimated_monthly_revenue: number
    estimated_monthly_revenue_range: string
    estimated_monthly_traffic: number
  }
  web_traffic_growth: {
    last_3_months: string
    last_6_months: string
    last_month: string
    last_month_traffic: string
    avg_last_6_months: string
    avg_last_3_months: string
  }
  scaling_score_ranking: number
  revenue_score_ranking: number
  total_reviews_count: number
  [index: string]: any
}

export type AdditionalFilters = {
  trustpilotReviews: {
    latest_numberreviews: number
    latest_trustpilotrating: number
  }
  fbReviews: { latest_fbreviews__gte: number }
  reditMentions: { latest_reditmentions__gte: number }
  fbLikes: {
    latest_fblikes__gte: number
    scaling_score_percentage__gte: number
  }
  instaFollowers: { latest_instafollowers__gte: number }
  fbadsCount: { latest_numberfbads__gte: number; is_fb_ad_active: 0 | 1 }
}

export type FilterTypes = {
  Trustpilot_Reviews: boolean
  Trustpilot_Reviews_30_Days: boolean
  Facebook_Reviews: boolean
  Facebook_Reviews_30_Days: boolean
  Shopify_Reviews: boolean
  Shopify_Reviews_30_Days: boolean
  Reddit_Mentions: boolean
  Facebook_Likes: boolean
  Facebook_Likes_growth: boolean
  IG_Followers: boolean
  IG_Followers_Growth: boolean
  FB_Ads_Count: boolean
  FB_Ads_growth_30_days: boolean
  Active_ads: boolean
  Ads_running_over_30_days: boolean
  Estimated_monthly_traffic: boolean
  Estimated_monthly_orders: boolean
  Estimated_monthly_revenue: boolean
  Number_of_products: boolean
  Product_price_minimum: boolean
  Product_price_average: boolean
  Product_price_maximum: boolean
  New_products_last_30_days: boolean
  [index: string]: boolean
}

export type AdditionalFilterRangeTypes = {
  latest_numberreviews__range: number[]
  trustpilotreviews_diff__range: number[]
  latest_fbreviews__range: number[]
  fbreviews_diff__range: number[]
  latest_shopifyreviews_count__range: number[]
  shopifyreview_count_diff__range: number[]
  latest_fblikes__range: number[]
  latest_instafollowers__range: number[]
  instafollowers_diff__range: number[]
  fbads_count__range: number[]
  fb_ads_diff__range: number[]
  fbads_growth__range: number[]
  ads_running_over: number[]
  active_fbads_count__range: number[]
  estimated_monthly_traffic__range: number[]
  estimated_monthly_orders__range: number[]
  estimated_monthly_revenue__range: number[]
  product_data_count__range: number[]
  product_data_min_price__range: number[]
  product_data_avg_price__range: number[]
  product_data_max_price__range: number[]
  product_data_diff__range: number[]
  latest_reditmentions__range: number[]
  fblikes_diff__range: number[]
  [index: string]: number[]
}
