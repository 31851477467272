import { defaultInitialState } from "../Actions/PageActionsType"
interface actions {
  type: string,
  payload: any[]
}

const pageInitialstate: defaultInitialState = {
  user: {},
  brandDetails: {
    BrandName: [],
    FBLikes: [],
    FbFollowers: [],
    InstaPosts: [],
    Instafollowers: [],
    Instafollowing: [],
    NumberFBads: [],
    NumberReviews: [],
    ReditMentions: [],
    ReviewScore: [],
    ReviewSite: [],
    ReviewText: [],
    Timestamp: []
  },
  forceReportTab: false,
  forceOverviewTab: false,
  slider: true,
  loading: false,
  featureKey: 0,
  brandList: [],
  selectBrand: 0,
  searchtype: "",
  brandname: "",
  brandId: 0,
  brandItem: {},
  FbAds: {},
  FbAdsCount: [],
  FbAdsCountData: {},
  FbAdsReview: {},
  SocialData: [],
  socialDataCount: {},
  trackedOn: false,
  socialCharts: {},
  storeTabSummary: {},
  trafficBriefData: {},
  trustpilotData: [],
  serpstatKeywords: [],
  serpstatAds: [],
  overviewCharts: {},
  brandViewStatus: {},
  subscriptionLoaded: false,
  subscriptionUsage: {},
  notifications: [],
  ReviewTracker: {},
  StatusCode: {},
  youtubeAnalytics: {},
  youtubeStatus: {},
  isIconHidden: false,
}

export const PageReduser = (state: defaultInitialState = pageInitialstate, actions: actions) => {
  switch (actions.type) {
    case "UserLogged":
      return {
        ...state,
        user: actions.payload
      }
    case "BrandList":
      return {
        ...state,
        brandList: actions.payload
      }
    case "BrandId":
      return {
        ...state,
        brandId: actions.payload
      }
    case "BrandDetails":
      return {
        ...state,
        brandDetails: actions.payload,
        searchtype: "searchtype",
      }
    case "BrandItem":
      return {
        ...state,
        brandItem: actions.payload
      }
    case "brandName":
      return {
        ...state,
        brandname: actions.payload,
      }
    case "SocialData":
      return {
        ...state,
        SocialData: actions.payload,
      }
    case "SocialCount":
      return {
        ...state,
        socialDataCount: actions.payload,
      }
    case "FbAds":
      return {
        ...state,
        FbAds: actions.payload,
      }
    case "FbAdsCount":
      return {
        ...state,
        FbAdsCount: actions.payload,
      }
    case "FbAdsCountData":
      return {
        ...state,
        FbAdsCountData: actions.payload,
      }
    case "FbAdsReview":
      return {
        ...state,
        FbAdsReview: actions.payload
      }
    case "SocialCharts":
      return {
        ...state,
        socialCharts: actions.payload
      }
    case "SetTrackedOn":
      return {
        ...state,
        trackedOn: actions.payload
      }
    case "TrafficBriefData":
      return {
        ...state,
        trafficBriefData: actions.payload
      }
    case "TrustpilotData":
      return {
        ...state,
        trustpilotData: actions.payload
      }
    case "SerpstatKeywords":
      return {
        ...state,
        serpstatKeywords: actions.payload
      }
    case "StoreTabSummary":
      return {
        ...state,
        storeTabSummary: actions.payload
      }
    case "SerpstatAds":
      return {
        ...state,
        serpstatAds: actions.payload
      }
    case "DrawerAction":
      return {
        ...state,
        slider: actions.payload
      }
    case "ForceReportTab":
      return {
        ...state,
        forceReportTab: actions.payload
      }
    case "ForceOverviewTab":
      return {
        ...state,
        forceOverviewTab: actions.payload
      }
    case "Loading":
      return {
        ...state,
        loading: actions.payload
      }
    case "TriggerFeaturedKey":
      return {
        ...state,
        featureKey: actions.payload
      }
    case "OverviewCharts":
      return {
        ...state,
        overviewCharts: actions.payload
      }
    case "BrandViewStatus":
      return {
        ...state,
        brandViewStatus: actions.payload
      }
    case "SubscriptionLoaded":
      return {
        ...state,
        subscriptionLoaded: actions.payload
      }
    case "SubscriptionUsage":
      return {
        ...state,
        subscriptionUsage: actions.payload
      }
    case "Notifications":
      return {
        ...state,
        notification: actions.payload
      }
    case "ReviewTracker":
      return {
        ...state,
        ReviewTracker: actions.payload
      }
    case "StatusCode":
      return {
        ...state,
        StatusCode: actions.payload
      }
    case "youtubeAnalytics":
      return {
        ...state,
        youtubeAnalytics: actions.payload
      }
    case "youtubeStatus":
      return {
        ...state,
        youtubeStatus: actions.payload
      }
    case "HIDE_ICON":
      return {
        ...state,
        isIconHidden: actions.payload
      }
    default:
      return state
  }
}
