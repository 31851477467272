import { Box, Grid } from "@mui/material"
import { useEffect, useState } from "react"

import { getRegionsData } from "./actions"
import TopCountries from "./countries"
import Regions from "./regions"
import {
  CountryHeader,
  CountryWrapper,
  Detail,
  MainHeader,
  RegionsContainer,
  Wrapper
} from "./styles"
import LoadingOverlay from "../../../Components/LoadOverlay/LoadOverlay"
import useError from "../../../Hooks/errorHandler"
import { CountryTargets, getTrafficGrowth } from "../../../../api/countryTargets"
import { LockButton } from "../../../../components/Buttons/lockButton"
import EmptyComponent from "../../../../components/EmptyComponent/EmptyComponent"
import { DetailTypes, TitleTypes } from "../../../../components/EmptyComponent/types"
import { getExactBrandName } from "../../../../utils/textHelpers"

type CountryTargetProps = {
  brandName: string
}

export default function CountryTrafficGrowth({ brandName }: CountryTargetProps) {
  const brand = getExactBrandName(brandName)
  const [trafficDetail, setTrafficDetail] = useState<CountryTargets[]>([])
  const [loading, setLoading] = useState(false)
  const {
    statusCode,
    errorCallBack
  } = useError()

  useEffect(() => {
    const loadTrafficGrowth = async () => {
      setLoading(true)
      const response = await getTrafficGrowth(brand, errorCallBack)
      setLoading(false)
      if (response) {
        setTrafficDetail(response)
      }
    }

    loadTrafficGrowth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand])

  return (
    <Grid container>
      <LoadingOverlay loading={loading} />
      <Box alignItems="center" justifyContent="space-between" className="StoreHeading">
        <MainHeader>Geography & Country Targeting</MainHeader>
      </Box>
      {statusCode !== 200 &&
        <Detail>Which countries sent the most traffic to {brand} lately? Where is their core audience from?</Detail>
      }
      {trafficDetail?.length > 0 &&
        <Detail>Last month {trafficDetail[0]?.country} was the top country sending traffic to {brand}</Detail>
      }
      {trafficDetail?.length > 0 &&
        (
          <Wrapper container alignItems="center" justifyContent="space-between">
            <RegionsContainer item xs={8} justifyContent="space-between">
              <Regions regions={getRegionsData(trafficDetail)} />
            </RegionsContainer>
            <CountryWrapper item xs={4} justifyContent="space-between">
              <CountryHeader>Top Countries</CountryHeader>
              <TopCountries countries={trafficDetail} />
            </CountryWrapper>
          </Wrapper>
        )}
      {statusCode === 403 && (
        <LockButton
          name={"country targets"}
          sx={{
            position: "relative",
            verticalAlign: "sub",
            ml: "10px",
            mt: "-5px",
            "& svg": {
              position: "relative",
              verticalAlign: "inherit",
            }
          }}
        />
      )}
      {(statusCode === 400) || (statusCode === 404) && (
        <EmptyComponent title={TitleTypes.noResults} detail={DetailTypes.countryTraffic} />
      )}
    </Grid>
  )
}