import { fetchApiCall } from "."
import { getAbsoluteURL } from "../utils"

export type BrandList = {
  brandname: string
  brand_id: number
  brandsite: string
  shopifysite: string
  page_id: string
}

export const fetchBrandList = async () => {
  const endpoint = getAbsoluteURL("search/shopifyfull/brandname/")

  return fetchApiCall<BrandList[]>(endpoint)
}