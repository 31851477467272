// @mui
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CancelIcon from "@mui/icons-material/Close"
import InfoIcon from "@mui/icons-material/Info"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material"
import { DateTime } from "luxon"
import { useState } from "react"
// components
import { removeTrendingAd, TrendingAd } from "../../../api/trendingAds"
import Iconify from "../../../components/Iconify"
import { Icon } from "../../../components/Icons/adFooterIcon"
import { IconWrapper } from "../../../components/IconWrapper/adIconWrapper"
import { PATH_BRANDS } from "../../../paths"
import { colors } from "../../../utils/theme"
import VideoPlayer from "../../Components/VideoPlayer/videoPlayer"
import { getCtaType } from "./Constants"
import { useLayout } from "../../Hooks/useLayoutContext"
import { truncateText } from "../../../utils/textHelpers"
import { getUserRole, UserRoles } from "../../../utils/localStorage"
// ----------------------------------------------------------------------

type Props = {
  trendingAd: TrendingAd
  isAdmin: boolean
  viewDetail: (ad: TrendingAd) => void
}

enum AdContent {
  Empty = "N/A",
}

export default function TrendingAdCard({
  trendingAd,
  isAdmin,
  viewDetail,
}: Props) {
  const userRole = getUserRole()
  const isAdminOrTester =
    userRole === UserRoles.ADMIN || userRole === UserRoles.TESTER
  const { menuIsExpanded } = useLayout()
  const [loading, setLoading] = useState(false)

  const handleAdUnfeature = async () => {
    setLoading(true)
    await removeTrendingAd(trendingAd.id)
    setLoading(false)
  }

  const getImage = (adImage: string | null) => {
    if (adImage === AdContent.Empty || adImage === null) {
      return (
        <Iconify
          width={200}
          height={200}
          sx={{ mt: "50px" }}
          icon={"icons8:advertising"}
          color={colors.Icon}
        />
      )
    }

    return (
      <img
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
        src={adImage}
        alt="Ad-image"
      />
    )
  }

  return (
    <Grid
      item
      xs={12}
      md={menuIsExpanded ? 12 : 6}
      lg={menuIsExpanded ? 6 : 4}
      xl={menuIsExpanded ? 4 : 3}
      sx={{
        padding: "15px",
      }}
    >
      <Paper
        elevation={4}
        sx={{
          background: "#ffffff !important",
          boxShadow: "0px 5px 20px 3px rgba(23, 3, 89, 0.08) !important",
          borderRadius: "10px !important",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "15px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            "& .MuiAvatar-root": {
              display: "inline-block",
              verticalAlign: "middle",
              mr: "10px",
            },
            "& a": {
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "27px",
              color: colors.Text,
              fontFamily: "Poppins, sans-serif !important",
              textDecoration: "none",
            },
            "& p": {
              fontFamily: "Poppins, sans-serif !important",
              fontSize: "12px",
              lineHeight: "18px",
              color: colors.Text,
              height: "54px",
              mt: "10px",
              mb: "20px",
            },
          }}
        >
          <Avatar
            src={trendingAd.profileimage}
            variant="circular"
            style={{ width: "28px", height: "28px" }}
          />
          <a
            href={PATH_BRANDS.home.overview(
              trendingAd.brand_name,
              trendingAd.brand_id
            )}
            target="_blank"
          >
            {trendingAd.brand_name}
          </a>
          {isAdmin && (
            <IconButton
              onClick={handleAdUnfeature}
              disabled={loading}
              sx={{
                border: "none !important",
                padding: "15px !important",
                float: "right",
                top: "-9px",
                right: "-14px",
                borderRadius: "50% !important",
                width: "30px",
              }}
            >
              {!loading && (
                <StarBorderIcon
                  fontSize="small"
                  titleAccess="Unfeature this ad"
                />
              )}
              {loading && <CircularProgress />}
            </IconButton>
          )}
          {isAdminOrTester && (
            <IconButton
              sx={{
                border: "none !important",
                padding: "15px !important",
                float: "right",
                top: "-9px",
                right: "-14px",
                borderRadius: "50% !important",
                width: "30px",
              }}
              onClick={() => viewDetail(trendingAd)}
            >
              <InfoIcon
                sx={{ color: colors.Icon }}
                fontSize="small"
                titleAccess="View Details"
              />
            </IconButton>
          )}
          <p>
            {trendingAd.ad_content &&
              trendingAd.ad_content !== AdContent.Empty &&
              truncateText(trendingAd.ad_content, 150)}
          </p>
          <Box
            sx={{
              margin: "0px !important",
              width: "100% !important",
              height: "312px !important",
              background: "#d3d3d3",
              borderRadius: "5px",
              position: "relative",
              display: "inline-block",
              overflow: "hidden",
            }}
          >
            {trendingAd.ad_video && trendingAd.ad_video !== AdContent.Empty ? (
              <VideoPlayer sourceUrl={trendingAd.ad_video} />
            ) : (
              getImage(trendingAd.ad_image)
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "inline-block",
            width: "100%",
            pl: "0px",
            pr: "0px",
            pt: "25px",
            "& span": {
              width: "calc(100% - 118px)",
              display: "inline-block",
              verticalAlign: "top",
              fontWeight: "normal",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "Poppins, sans-serif !important",
              color: colors.Text,
            },
          }}
        >
          {trendingAd.is_active ? (
            <span>
              <CheckBoxIcon
                sx={{
                  fill: colors.Success,
                  borderRadius: "3px",
                  overflow: "hidden",
                  border: `1px solid ${colors.Success}`,
                  width: "16px",
                  height: "16px",
                  mr: "10px",
                  display: "inline-block",
                  verticalAlign: "middle",
                  position: "relative",
                }}
              />{" "}
              Active{" "}
            </span>
          ) : (
            <span>
              <CancelIcon
                sx={{
                  fill: colors.White,
                  borderRadius: "3px",
                  overflow: "hidden",
                  border: "1px solid rgba(59, 78, 223, 0.1)",
                  width: "16px",
                  height: "16px",
                  mr: "10px",
                  background: "rgba(59, 78, 223, 0.1)",
                  display: "inline-block",
                  verticalAlign: "middle",
                  position: "relative",
                }}
              />
              Inactive
            </span>
          )}
          <a
            style={{
              border: `1px solid ${colors.Primary}`,
              boxSizing: "border-box",
              borderRadius: "5px",
              height: "40px",
              minWidth: "118px",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "40px",
              fontFamily: "Poppins, sans-serif !important",
              display: "inline-block",
              textAlign: "center",
              textDecoration: "none",
            }}
            href={trendingAd.ad_link}
            target="_blank"
          >
            {getCtaType(trendingAd.cta)}
          </a>
          {trendingAd.start_date && (
            <Box
              sx={{
                mt: "10px",
                width: "100%",
                "& span": {
                  fontFamily: "Poppins, sans-serif !important",
                  fontSize: "16px",
                },
              }}
            >
              <Typography variant="caption" component="span">
                Start :{" "}
                {DateTime.fromISO(trendingAd.start_date).toFormat(
                  "dd MMM yyyy"
                )}
              </Typography>
            </Box>
          )}
          <p
            style={{
              fontSize: "14px",
              lineHeight: "24px",
              fontFamily: "Poppins, sans-serif !important",
              display: "inline-block",
              marginBottom: "0px",
            }}
          >
            ID: {trendingAd.id}
          </p>
          <IconWrapper>
            <Icon className="fab fa-facebook-f" />
          </IconWrapper>
        </Box>
      </Paper>
    </Grid>
  )
}
