import { SnackbarProvider } from "notistack"
import { Provider } from "react-redux"
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom"
import Store from "./Redux/store"
import V1AppBar from "./V1/Components/Appbar/Appbar"
import { SidebarRoute } from "./V1/Components/Appbar/appbarRoutes"
import PrivateRoute from "./V1/Components/PrivateRoute/PrivateRoute"
import PinnedAds from "./V1/CreativeCenter/PinnedAds"
import V1EmailVerify from "./V1/EmailVerify"
import TrendingProducts from "./V1/Featured-Products/FeaturedProducts"
import V1Login from "./V1/Login"
import V1PasswordReset from "./V1/PasswordReset"
import ProductDetail from "./V1/ProductReport/ProductReport"
import PublicAd from "./V1/Public/Ads"
import V1Settings from "./V1/Settings/Settings"
import V1Signup from "./V1/Signup/Signup"
import TrendingAds from "./V1/Trending/TrendingAds"
import V1TrendingPage from "./V1/Trending/TrendingBrands/TrendingPage"
import BrandPage from "./V1/brand"
import AddBrand from "./V1/brand/AddBrand"
import V1SearchBrand from "./V1/search-brand/SearchBrand"
import SearchResultsPage from "./V1/search-results"
import ErrorPage from "./pages/ErrorPage"
import PageNotFound from "./pages/PageNotFound"
import SuccessPage from "./pages/SuccessPage"
import WrapperBox from "./pages/WrapperBox/WrapperBox"

export default function V1App() {
  return (
    <Provider store={Store}>
      <SnackbarProvider>
        <Router>
          <V1AppBar />
          <WrapperBox>
            <Switch>
              <PrivateRoute exact path={SidebarRoute.Settings}>
                <V1Settings />
              </PrivateRoute>
              <Route exact path="/reset-password/verify/:uid/:token/">
                <V1PasswordReset />
              </Route>
              <PrivateRoute exact path={SidebarRoute.TrendingBrands}>
                <V1TrendingPage />
              </PrivateRoute>
              <PrivateRoute exact path="/add-brand">
                <AddBrand />
              </PrivateRoute>
              <Route exact path="/search-brand">
                <V1SearchBrand />
              </Route>
              <PrivateRoute exact path="/search">
                <SearchResultsPage />
              </PrivateRoute>
              <PrivateRoute exact path="/">
                <Redirect to={SidebarRoute.TrendingBrands} />
              </PrivateRoute>
              <Route exact path="/brand/:name/:brandId">
                <BrandPage />
              </Route>
              <PrivateRoute
                exact
                path="/brand/:name/:brandId/product/:productName/:productId/detail"
              >
                <ProductDetail />
              </PrivateRoute>
              <Route exact path="/checkout/success">
                <SuccessPage />
              </Route>
              <Route exact path="/error">
                <ErrorPage />
              </Route>
              <Route exact path="/page-not-found">
                <PageNotFound />
              </Route>
              <Route exact path="/login">
                <V1Login />
              </Route>
              <Route exact path="/email-verify">
                <V1EmailVerify />
              </Route>
              <Route exact path="/signup">
                <V1Signup />
              </Route>
              <PrivateRoute exact path={SidebarRoute.TrendingAds}>
                <TrendingAds />
              </PrivateRoute>
              <PrivateRoute exact path={SidebarRoute.FeaturedProducts}>
                <TrendingProducts />
              </PrivateRoute>
              <PrivateRoute exact path={SidebarRoute.MyBoards}>
                <PinnedAds />
              </PrivateRoute>
              <Route exact path="/public/ad/:token">
                <PublicAd />
              </Route>
            </Switch>
          </WrapperBox>
        </Router>
      </SnackbarProvider>
    </Provider>
  )
}
