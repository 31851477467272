// @mui
import { Box, Stack, Typography } from "@mui/material"

import * as DOMPurify from "dompurify"
import { format } from "timeago.js"
import { PinnedAd } from "../../../../../api/creativeCenter/pinnedAds"
import { PinnedAdBody } from "../../PinnedAdCard/PinnedAdBody"

// ----------------------------------------------------------------------
export default function AdDetailView({
  pinnedAd,
  focusedIndexChanged,
}: {
  pinnedAd: PinnedAd
  focusedIndexChanged: (newIndex: number) => void
}) {
  return (
    <Stack spacing={1} sx={{ mb: 2 }}>
      <PinnedAdHeader pinnedAd={pinnedAd} />
      <PinnedAdHtml pinnedAd={pinnedAd} />
      <PinnedAdBody
        pinnedAd={pinnedAd}
        bodyHeight={"425px"}
        focusedIndexChanged={focusedIndexChanged}
      />
    </Stack>
  )
}

type AdDetailViewProps = {
  pinnedAd: PinnedAd
}

const PinnedAdHeader = ({ pinnedAd }: AdDetailViewProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <img
        src={pinnedAd.fb_ad.brand.profileimage || ""}
        style={{
          width: "61px",
          height: "61px",
          display: "inline-block",
          marginRight: "11px",
          borderRadius: "50%",
        }}
      />
      <Stack>
        <Typography variant="h6">{pinnedAd.fb_ad.brand.brandname}</Typography>
        <Typography
          fontWeight="400"
          fontSize="16px"
          color="rgba(59, 78, 223, 0.7);
"
        >
          {`Saved ${format(pinnedAd.created_on)}`}
        </Typography>
      </Stack>
    </Box>
  )
}

const PinnedAdHtml = ({ pinnedAd }: AdDetailViewProps) => {
  const badHtml = pinnedAd.fb_ad.html?.includes("&#123;")
  if (pinnedAd.fb_ad.html && !badHtml) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(pinnedAd.fb_ad.html),
        }}
      />
    )
  } else {
    return <Typography variant="body2">{pinnedAd.fb_ad.ad_content}</Typography>
  }
}
