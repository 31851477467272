import {
  Backdrop,
  Box,
  Breakpoint,
  Fade,
  IconButton,
  Modal as MUIModal,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material"
import { ReactElement, ReactNode, useMemo } from "react"
import Iconify from "../Iconify"

import { headingStyle, modalStyle } from "./styles"
//-----------------------------------------------------------------------------------------------------

type Props = {
  open: boolean
  title?: string
  ariaLabelledBy?: string
  ariaDescribedBy?: string
  hideHeader?: boolean
  handleClose?: VoidFunction
  children: ReactElement
  maxWidth?: Breakpoint
  sx?: SxProps<Theme>
  dialogActionsContent?: ReactNode
}

export default function Modal({
  open,
  ariaLabelledBy,
  ariaDescribedBy,
  title,
  hideHeader,
  children,
  maxWidth,
  handleClose,
  sx,
}: Props) {
  const modalStyles = useMemo(() => modalStyle(maxWidth), [maxWidth])

  return (
    <MUIModal
      aria-labelledby={ariaLabelledBy || "transition-modal-title"}
      aria-describedby={ariaDescribedBy || "transition-modal-description"}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={sx}
    >
      <Fade in={open}>
        <Box sx={modalStyles}>
          {hideHeader !== true && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                mb: "24px",
              }}
            >
              {title ? (
                <Typography sx={headingStyle}>{title}</Typography>
              ) : (
                <Box />
              )}
              {handleClose && (
                <IconButton onClick={handleClose}>
                  <Iconify
                    icon={"material-symbols:close"}
                    width={28}
                    height={28}
                  />
                </IconButton>
              )}
            </Stack>
          )}
          <Box>{children}</Box>
        </Box>
      </Fade>
    </MUIModal>
  )
}
