import React from "react"
import { Grid, Table, TableHead, TableRow, TableCell, TableSortLabel } from "@mui/material"
import { styled } from "@mui/system"
import { ResponsiveBar } from "@nivo/bar"
import { useSelector } from "react-redux"

import { RootStore } from "../../../Redux/store"
import { StoreTabSummary } from "../common"

type PriceRangeProps = {
  isViabilityPage?: boolean
}

const Container = styled(Grid)((props: PriceRangeProps) => ({
  height: props.isViabilityPage ? "437px !important" : "478px !important",
  top: props.isViabilityPage ? "7px !important" : ""
}))


export default function PriceRange({ isViabilityPage }: PriceRangeProps): React.ReactElement {
  const storeTabSummary: StoreTabSummary = useSelector(((state: RootStore) => state.PageReduser["storeTabSummary"]))

  const getBarChartData = () => {

    return storeTabSummary?.price_range?.map((item) => ({ "Product Price": item.name, price: item.price }))
  }

  return (
    storeTabSummary.price_range && storeTabSummary.price_range.length > 0 ?
      <Container isViabilityPage={isViabilityPage} item xs={6} className="BarchartTable shbox">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel className="sortStyle">Price range</TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel className="sortStyle">Value</TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <>
          <ResponsiveBar
            data={getBarChartData()}
            keys={["Product Price"]}
            indexBy="price"
            margin={{ top: 16, right: 16, bottom: 32, left: 95 }}
            padding={0.3}
            layout="horizontal"
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors="#3C4FE0"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
            }}
            enableLabel={false}
            enableGridX={true}
            enableGridY={false}
          />
        </>
      </Container> : <div />
  )
}
