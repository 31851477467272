import { MaxValues } from "../../api/searchBrand/types"
import { AdditionalFilterRangeTypes, FilterTypes, ScoreColumn, ScoreStatus } from "./types"

export const getScore = (score: number, percentage: number): ScoreColumn => {
  let status: ScoreStatus = ScoreStatus.Good
  if (score > 70) {
    status = ScoreStatus.Good
  } else if (score > 29 && score < 70) {
    status = ScoreStatus.Warn
  } else {
    status = ScoreStatus.Bad
  }
  return { score, percentage, status }
}

export const stringTruncate = (str: string) => {
  if (str.length > 125) {
    return str.slice(0, 125) + "..."
  } else {
    return str
  }
}

export const getFilterRangeValues = (maxValues: MaxValues): AdditionalFilterRangeTypes => {

  return {
    latest_numberreviews__range: [1, maxValues.latest_numberreviews.value],
    trustpilotreviews_diff__range: [1, maxValues.diff_last_month_numberreviews.value],
    latest_fbreviews__range: [1, maxValues.latest_fbreviews.value],
    fbreviews_diff__range: [1, maxValues.diff_last_month_fbreviews.value],
    latest_fblikes__range: [1, maxValues.latest_fblikes.value],
    latest_instafollowers__range: [1, maxValues.latest_instafollowers.value],
    instafollowers_diff__range: [1, maxValues.diff_last_month_instafollowers.value],
    fbads_count__range: [1, maxValues.fbads_count.value],
    fbads_growth__range: [1, maxValues.diff_last_month_fb_ads.value],
    active_fbads_count__range: [1, maxValues.active_fbads_count.value],
    estimated_monthly_traffic__range: [1, maxValues.store_data_estimated_monthly_traffic.value],
    estimated_monthly_orders__range: [1, maxValues.store_data_estimated_monthly_orders.value],
    estimated_monthly_revenue__range: [1, maxValues.store_data_estimated_monthly_revenue.value],
    product_data_count__range: [1, maxValues.product_data_count.value],
    product_data_min_price__range: [1, maxValues.product_data_min_price.value],
    product_data_avg_price__range: [1, maxValues.product_data_avg_price.value],
    product_data_max_price__range: [1, maxValues.product_data_max_price.value],
    product_data_diff__range: [1, maxValues.product_data_last_30_days.value],
    latest_reditmentions__range: [1, maxValues.latest_reditmentions.value],
    fblikes_diff__range: [1, maxValues.diff_last_month_fblikes.value],
    fb_ads_diff__range: [1, maxValues.diff_last_month_fb_ads.value],
    ads_running_over: [1, maxValues.fbads_count_over_30_days.value],
    latest_shopifyreviews_count__range: [1, maxValues.latest_shopifyreviews_count.value],
    shopifyreview_count_diff__range: [1, maxValues.diff_last_month_shopifyreviews.value],
  }
}

export const getRangeAsString = (range: number[]): string => {
  // converting the range values to string format for matching the type of payload

  return range.join("__")
}

export const getAdditionalFilterValues = (choice: string, filterRangeValues: AdditionalFilterRangeTypes) => {
  switch (choice) {
    case "Trustpilot_Reviews":
      return {
        latest_numberreviews__range: getRangeAsString(
          filterRangeValues.latest_numberreviews__range
        ),
      }
    case "Trustpilot_Reviews_30_Days":
      return {
        trustpilotreviews_diff__range: getRangeAsString(
          filterRangeValues.trustpilotreviews_diff__range
        ),
      }
    case "Facebook_Reviews":
      return {
        latest_fbreviews__range: getRangeAsString(
          filterRangeValues.latest_fbreviews__range
        ),
      }
    case "Facebook_Reviews_30_Days":
      return {
        fbreviews_diff__range: getRangeAsString(
          filterRangeValues.fbreviews_diff__range
        ),
      }
    case "Shopify_Reviews":
      return {
        latest_shopifyreviews_count__range: getRangeAsString(
          filterRangeValues.latest_shopifyreviews_count__range
        ),
      }
    case "Shopify_Reviews_30_Days":
      return {
        shopifyreview_count_diff__range: getRangeAsString(
          filterRangeValues.shopifyreview_count_diff__range
        ),
      }
    case "Reddit_Mentions":
      return {
        latest_reditmentions__range: getRangeAsString(
          filterRangeValues.latest_reditmentions__range
        ),
      }
    case "Facebook_Likes":
      return {
        latest_fblikes__range: getRangeAsString(
          filterRangeValues.latest_fblikes__range
        ),
      }
    case "Facebook_Likes_growth":
      return {
        fblikes_diff__range: getRangeAsString(
          filterRangeValues.fblikes_diff__range
        ),
      }
    case "IG_Followers":
      return {
        latest_instafollowers__range: getRangeAsString(
          filterRangeValues.latest_instafollowers__range
        ),
      }
    case "IG_Followers_Growth":
      return {
        instafollowers_diff__range: getRangeAsString(
          filterRangeValues.instafollowers_diff__range
        ),
      }
    case "FB_Ads_Count":
      return {
        fbads_count__range: getRangeAsString(
          filterRangeValues.fbads_count__range
        ),
      }
    case "FB_Ads_growth_30_days":
      return {
        fb_ads_diff__range: getRangeAsString(
          filterRangeValues.fb_ads_diff__range
        ),
      }
    case "Active_ads":
      return {
        active_fbads_count__range: getRangeAsString(
          filterRangeValues.active_fbads_count__range
        ),
      }
    case "Ads_running_over_30_days":
      return {
        is_fb_ad_active: 1,
        fbads_count__range: getRangeAsString(
          filterRangeValues.fbads_count__range
        ),
      }
    case "Estimated_monthly_traffic":
      return {
        estimated_monthly_traffic__range: getRangeAsString(
          filterRangeValues.estimated_monthly_traffic__range
        ),
      }
    case "Estimated_monthly_orders":
      return {
        estimated_monthly_orders__range: getRangeAsString(
          filterRangeValues.estimated_monthly_orders__range
        ),
      }
    case "Estimated_monthly_revenue":
      return {
        estimated_monthly_revenue__range: getRangeAsString(
          filterRangeValues.estimated_monthly_revenue__range
        ),
      }
    case "Number_of_products":
      return {
        product_data_count__range: getRangeAsString(
          filterRangeValues.product_data_count__range
        ),
      }
    case "Product_price_minimum":
      return {
        product_data_min_price__range: getRangeAsString(
          filterRangeValues.product_data_min_price__range
        ),
      }
    case "Product_price_average":
      return {
        product_data_avg_price__range: getRangeAsString(
          filterRangeValues.product_data_avg_price__range
        ),
      }
    case "Product_price_maximum":
      return {
        product_data_max_price__range: getRangeAsString(
          filterRangeValues.product_data_max_price__range
        ),
      }
    case "New_products_last_30_days":
      return {
        product_data_diff__range: getRangeAsString(
          filterRangeValues.product_data_diff__range
        ),
      }
  }
}

export const getAdditionalFilterParams = (checkboxValues: FilterTypes, rangeValues: AdditionalFilterRangeTypes) => {
  const additionalFilterObject: Record<string, unknown> = {}

  Object.keys(checkboxValues).forEach((val) => {
    if (checkboxValues[val] === true) {
      Object.assign(
        additionalFilterObject,
        getAdditionalFilterValues(val, rangeValues) as unknown
      )
    }
  })

  return additionalFilterObject
}