// @mui
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"

import { Supplier } from "../../../../types/productReportTypes"

type Props = {
  suppliers: Supplier[]
  productImage: string
}

const imageStyle = {
  width: 35,
  height: 35
}

export default function SupplierOverview(props: Props) {
  return (
    <div className="shbox mb-30 supplyOverviewTable">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>Seller Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Margin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.suppliers.map((supplier, index) => (
              <TableRow key={index}>
                <TableCell>
                  <img
                    style={imageStyle} src={props.productImage || "/images/product_placeholder.jpg"}
                    alt="product-image"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null // prevents looping
                      currentTarget.src = "/images/product_placeholder.jpg"
                    }}
                  />
                  {supplier.product_name}
                </TableCell>
                <TableCell>{supplier.supplier_name}</TableCell>
                <TableCell>${supplier.price_per_unit}</TableCell>
                <TableCell>{supplier.margin}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
