import Link from "@mui/material/Link"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import React from "react"

import LoginModal from "./Components/LoginModal"

export default function Authorization(): React.ReactElement {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)

  const closeModal = () => {
    setModalOpen(false)
  }

  const getUserStatus = () => {
    return (
      <div>
        <button onClick={() => setModalOpen(true)}>
          <LockOutlinedIcon /> Login to see Brand
        </button>
        <p>
          Login with your account to get access to this Brand <br />
          Or <Link href="https://www.trendrocket.io/#pricing">sign up</Link> for
          TrendRocket if you still don't have an account with us
        </p>
      </div>
    )
  }

  return (
    <div className="accessPop">
      {getUserStatus()}
      <LoginModal open={modalOpen} closeModal={closeModal} />
    </div>
  )
}
