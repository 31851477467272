import { Box, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { useMemo } from "react"
import active from "../assets/images/Active/Active.png"
import inactive from "../assets/images/Active/Inactive.png"

const now = DateTime.now()
const ICON_SIZE = "20px"
export const PinnedAdActive = ({
  endDateString,
}: {
  endDateString: string
}) => {
  const isActive = useMemo(() => {
    const endDate = DateTime.fromSeconds(parseInt(endDateString))
    return now > endDate
  }, [endDateString])

  if (isActive) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={active}
          style={{
            width: ICON_SIZE,
            height: ICON_SIZE,
            marginRight: "10px",
          }}
        />
        <Typography variant="subtitle2">Active</Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <img
        src={inactive}
        style={{
          width: ICON_SIZE,
          height: ICON_SIZE,
          marginRight: "10px",
        }}
      />
      <Typography variant="subtitle2">Inactive</Typography>
    </Box>
  )
}
