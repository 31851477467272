import { AxiosError } from "axios"
import { fetchApiCall, getAbsoluteURL } from "."

export type CountryTargets = {
  country: string
  code: string
  traffic: string
  growth: string
  change: string
}

export const getTrafficGrowth = async (
  brandName: string,
  callBack: (response: AxiosError | null) => void
) => {
  const endpoint = getAbsoluteURL(`api/report/traffic-growth/?brand=${brandName}`)

  return await fetchApiCall<CountryTargets[]>(endpoint, {}, callBack)
}