import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight"
import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material"
import React from "react"

interface CheckboxDetailSectionProps {
  comparison: string
  handleComparisonChanged: (e: SelectChangeEvent<string>) => void
  compareByNumber: number | null
  handleCompareByNumberChanged: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}


function CheckboxDetailSection({ comparison, handleComparisonChanged, compareByNumber, handleCompareByNumberChanged }: CheckboxDetailSectionProps) {

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(1),
        backgroundColor: "#E4E7EC",
      }}
    >
      <FormGroup>
        <FormControl variant="outlined">
          <InputLabel id="comparison-label">Comparison</InputLabel>
          {/* eslint-disable @typescript-eslint/no-unsafe-assignment */}
          <Select
            labelId="comparison-label"
            label="Comparison"
            id="dropdown-comparison"
            value={comparison}
            onChange={handleComparisonChanged}
            MenuProps={{
              disablePortal: true,
              anchorEl: this
            }}>
            <MenuItem value="equal">Is equal to</MenuItem>
            <MenuItem value="greater">Is greater than</MenuItem>
            <MenuItem value="less">Is less than</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          sx={(theme) => ({
            mt: theme.spacing(1),
            "& .MuiInputLabel-formControl": {
              position: "relative"
            },
            "& .MuiSvgIcon-root": {
              mr: theme.spacing(1),
              mt: -theme.spacing(2)
            },
            "& .MuiTextField-root": {
              maxWidth: "80px"
            }
          })}
        >
          <div style={{ display: "table-row" }}>
            <InputLabel style={{ display: "table-cell" }}><SubdirectoryArrowRightIcon color="primary" /></InputLabel>
            <TextField style={{ display: "table-cell" }} variant="outlined" value={compareByNumber} onChange={handleCompareByNumberChanged} type="number" />
          </div>
        </FormControl>
      </FormGroup>
    </Box>
  )
}

interface FilterPopperProps {
  open: boolean
  anchorEl: HTMLButtonElement | null,
  onDone: () => void
}


export default function FilterPopper({ open, anchorEl, onDone }: FilterPopperProps) {
  const [checkState, setCheckState] = React.useState({
    likes: false, comments: false, views: false, shares: false,
    country: false, last_seen: false, started: false
  })
  function handleCheckboxChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const newCheckState = { ...checkState }
    for (const property in newCheckState) {
      newCheckState[property as CheckboxNames] = false
    }
    newCheckState[e.target.name as CheckboxNames] = e.target.checked
    setCheckState(newCheckState)
  }
  function handleClearButtonClicked() {
    const newCheckState = { ...checkState }
    for (const property in newCheckState) {
      newCheckState[property as CheckboxNames] = false
    }
    setCheckState(newCheckState)
  }
  type CheckboxNames = "likes" | "comments" | "views" | "shares" | "country" | "last_seen" | "started"
  const checkboxes: CheckboxNames[] = ["likes", "comments", "views", "shares", "country", "last_seen", "started"]
  const [comparison, setComparison] = React.useState("equal")
  const [compareByNumber, setCompareByNumber] = React.useState<number | null>(null)
  const handleComparisonChanged = (e: SelectChangeEvent<string>) => {
    setComparison(e.target.value)
  }

  const handleCompareNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCompareByNumber(parseInt(e.target.value))
  }

  return (
    <Popper open={open} anchorEl={anchorEl} placement="bottom" transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={150}>
          <Paper sx={{ minWidth: "180px" }}>
            <FormGroup>
              {checkboxes.map((name, index) => (
                <>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={checkState[name]} onChange={handleCheckboxChanged} name={name} />}
                    label={name[0].toUpperCase() + name.slice(1).replace("_", " ")}
                    key={index}
                    sx={(theme) => ({
                      pl: theme.spacing(1),
                      pr: theme.spacing(1)
                    })}
                  />
                  {checkState[name] ? <CheckboxDetailSection
                    comparison={comparison}
                    compareByNumber={compareByNumber}
                    handleComparisonChanged={handleComparisonChanged}
                    handleCompareByNumberChanged={handleCompareNumber} /> : null}
                </>
              ))}
            </FormGroup>unique_ads
            <Box
              sx={{
                backgroundColor: "#E4E7EC",
                padding: (theme) => theme.spacing(1),
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button size="small" variant="outlined" onClick={handleClearButtonClicked}>clear</Button>
              <Button size="small" variant="contained" color="primary" onClick={onDone}>Done</Button>
            </Box>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}
