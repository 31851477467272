import NotificationsIcon from "@mui/icons-material/Notifications"
import axios from "axios"
import React from "react"
import { useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { getAbsoluteURL } from "../../../utils"
import { SidebarRoute } from "../Appbar/appbarRoutes"

interface Params {
  name: string
  brandId: string
}

interface NotificationRequest {
  id: number
  created_on: string
  updated_on: string
  brand_id: number
  brand_name: string
  event_type:
  | "brand_added"
  | "brand_updated"
  | "report_published"
  | "report_summary"
}

export default function ViabilityRequest(): React.ReactElement {
  const dispatch = useDispatch()
  const params: Params = useParams()
  const token = localStorage.getItem("token")
  const [isNotified, setIsNotified] = React.useState<boolean>(false)

  React.useEffect(() => {
    const fetchNotificationRequest = () => {
      axios
        .get(`api/notifications/requests/?brand_id=${params.brandId}`, {
          headers: { Authorization: `Token ${token as string}` },
        })
        .then((response) => {
          const data = response.data as NotificationRequest[]
          if (data.length > 0) {
            setIsNotified(true)
          }
        })
        .catch((err) => console.log(err))
    }

    fetchNotificationRequest()
  }, [params.brandId, token])

  const forceOverviewTab = () => {
    dispatch({ type: "ForceOverviewTab", payload: true })
  }

  const handleNotifyMe = () => {
    const payload = {
      brand_id: params.brandId,
      brand_name: params.name,
      event_type: "report_published",
    }
    axios
      .post(getAbsoluteURL("api/notifications/notify-me/"), payload, {
        headers: { Authorization: `Token ${token as string}` },
      })
      .then(() => setIsNotified(true))
      .catch((err) => console.log(err))
  }

  return (
    <div className="accessPop">
      {!isNotified ? (
        <div>
          <p>
            This brand doesn't have a trend viability report yet, we will get
            working <br />
            on it and notify you as soon as the report is available.
          </p>
          <button onClick={handleNotifyMe} className="primary">
            <NotificationsIcon />
            <span> Notify me</span>
          </button>
        </div>
      ) : (
        <div>
          <p>
            We haven't quite completed this brand's trend viability report.
            <br />
            Our team has been notified you would like one and we will email you
            when it is complete.
          </p>
          <button onClick={forceOverviewTab} className="default">
            Back to Overview
          </button>
          <Link to={SidebarRoute.TrendingBrands}>View Brands with Report</Link>
        </div>
      )}
    </div>
  )
}
