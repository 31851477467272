import LoadingButton from "@mui/lab/LoadingButton"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import { ChangeEvent, FormEvent, useState } from "react"

import { AddBoardInput, Board } from "../../../../api/creativeCenter/boards"
import Modal from "../../../../components/Modal"
import { colors } from "../../../../utils/theme"
import { dropDownStyle, submitButtonStyle, textFieldStyle } from "./styles"
//-----------------------------------------------------------------------------------------------------

type Props = {
  open: boolean
  handleClose: VoidFunction
  onSubmit: (payload: AddBoardInput) => Promise<Board>
}

enum Status {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

const FEED_BACK = { show: false, status: Status.SUCCESS }

export default function AddBoardModal({ open, handleClose, onSubmit }: Props) {
  const [field, setField] = useState({ title: "", description: "" })
  const [loading, setLoading] = useState(false)
  const [feedBack, setFeedBack] = useState(FEED_BACK)

  const handleChange = (
    e: SelectChangeEvent | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setField({ ...field, ...{ [e.target.name]: e.target.value } })
  }

  const showFeedBack = (status: Status) => {
    setFeedBack({ show: true, status })
    setTimeout(() => setFeedBack({ ...feedBack, ...{ show: false } }), 3000)
  }

  const addBoard = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    const result = await onSubmit(field)
    if (result) {
      showFeedBack(Status.SUCCESS)
    } else {
      showFeedBack(Status.ERROR)
    }
    setLoading(false)
  }

  const message = (
    <Typography
      sx={{
        color:
          feedBack.status === Status.SUCCESS ? colors.Success : colors.Error,
        fontFamily: "Poppins !important",
        position: "relative",
      }}
    >
      {feedBack.status === Status.SUCCESS
        ? "Board has been successfully created."
        : "Something went wrong"}
    </Typography>
  )

  return (
    <Modal open={open} handleClose={handleClose} title={"Create a New Board"}>
      <form onSubmit={addBoard}>
        <TextField
          label="Title"
          variant="outlined"
          required
          name="title"
          onChange={handleChange}
          sx={textFieldStyle}
        />
        <FormControl
          sx={{
            width: "100%",
            ".MuiInputLabel-root": {
              background: colors.White,
            },
          }}
        >
          <InputLabel id="description-label">Description</InputLabel>
          <Select
            labelId="description-label"
            id="description-select"
            name="description"
            label="Description"
            sx={dropDownStyle}
            MenuProps={{ disablePortal: true }}
            value={"default"}
            onChange={handleChange}
          >
            <MenuItem value="default">Default Project</MenuItem>
          </Select>
        </FormControl>
        {feedBack.show === true && message}
        <LoadingButton
          type="submit"
          loading={loading}
          loadingPosition="center"
          sx={submitButtonStyle}
        >
          Create Board
        </LoadingButton>
      </form>
    </Modal>
  )
}
