import { OverviewCharts } from "../../../api/brand"

export default function GetChartCount(
  name: string,
  chart: OverviewCharts | undefined
): string | undefined {
  // function to return the total chart count.

  // returns respective chart count based on param.
switch (name) {
    case "Facebook likes":
      {
        if (chart?.facebook_likes && chart.facebook_likes.length > 0) {
          return chart.facebook_likes[
            chart.facebook_likes.length - 1
          ].data.toString()
        }
      }
      break
    case "Instagram followers":
      {
        if (chart?.instagram_followers && chart.instagram_followers.length > 0) {
          return chart.instagram_followers[
            chart.instagram_followers.length - 1
          ].data.toString()
        }
      }
      break
    case "Fb Ads":
      {
        if (chart?.facebook_ad_count && chart.facebook_ad_count.length > 0) {
          return chart.facebook_ad_count[
            chart.facebook_ad_count.length - 1
          ].data.toString()
        }
      }
      break
    case "Website traffic":
      {
        if (chart?.website_traffic && chart.website_traffic.length > 0) {
          return chart.website_traffic[chart.website_traffic.length - 1].data.toString()
        }
      }
      break
    default:
      console.error(`Unknown chart type: ${name}`)
      break
  }
}
