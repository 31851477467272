import { Login } from "@mui/icons-material"
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"
import { Button } from "@mui/material"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { alpha, styled, useTheme } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  NavLink as RouterLink,
  useHistory,
  useLocation,
} from "react-router-dom"

import { Box, ListItemButton, useMediaQuery } from "@mui/material"
import { fetchSubscriptionStatus } from "../../../api/plans"
import FullLogo from "../../../assets/images/Logo/LogoFull.svg"
import Iconlogo from "../../../assets/images/Logo/LogoIcon.png"
import ToggleBtn from "../../../assets/images/toggle.svg"
import Actions from "../../../pages/Actions/PageActions"
import { PATH_PUBLIC } from "../../../paths"
import { RootStore } from "../../../Redux/store"
import { MOBILE_SCREEN_SIZE } from "../../../utils"
import {
  ChromeExtensionEventType,
  sendChromeExtensionMessage,
} from "../../../utils/chromeExtension"
import { getUserRole, UserRoles } from "../../../utils/localStorage"
import { colors } from "../../../utils/theme"
import { useAuth } from "../../Hooks/usAuthContext"
import { useLayout } from "../../Hooks/useLayoutContext"
import MobileViewSearch from "../Search/MobileView/searchBar"
import SearchBar from "../Search/searchBar"
import SubscriptionCard from "../SubscriptionCard/SubscriptionCard"
import SubscriptionMessage from "../SubscriptionMessage/SubscriptionMessage"
import {
  appBarRoutes,
  iconForSidebarRoute,
  RouteItem,
  RouteSection,
  SidebarRoute,
} from "./appbarRoutes"
import { AvatarMenu } from "./AvatarMenu"

const drawerWidth = 300

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Search = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}))

const excludedUrls: string[] = [
  "/error",
  "/checkout/success",
  "/login",
  "/email-verify",
  "/page-not-found",
  "/signup",
  "/subscribe",
  "/reset-password",
  PATH_PUBLIC.root,
]

export default function MiniDrawer() {
  const userRole = getUserRole()
  const history = useHistory()
  const theme = useTheme()
  const location = useLocation()
  const dispatch = useDispatch()
  const [subscriptionModalOpen, setSubscriptionModalOpen] =
    React.useState<boolean>(false)
  const subscriptionLoaded: boolean = useSelector(
    (state: RootStore) => state.PageReduser["subscriptionLoaded"]
  )
  const { user } = useAuth()
  const { setMenuIsExpanded, menuIsExpanded } = useLayout()

  const hideAppBar = React.useMemo(() => {
    return excludedUrls.some((s) => location.pathname.startsWith(s))
  }, [location.pathname])

  const token = localStorage.getItem("token")
  const isMobileView = useMediaQuery(MOBILE_SCREEN_SIZE)
  const routes = React.useMemo(() => {
    return appBarRoutes(
      userRole === UserRoles.ADMIN || userRole === UserRoles.TESTER
    )
  }, [userRole])

  React.useEffect(() => {
    const logIntoExtension = async () => {
      await sendChromeExtensionMessage({
        event: ChromeExtensionEventType.LOG_IN,
        data: user,
      })
    }

    if (!subscriptionLoaded) {
      dispatch(Actions.GetSubscriptionUsage())
    }
    if (user) {
      logIntoExtension()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionLoaded, user])

  React.useEffect(() => {
    const logIntoExtension = async (loggedInUser: any) => {
      await sendChromeExtensionMessage({
        event: ChromeExtensionEventType.LOG_IN,
        data: loggedInUser,
      })
    }

    if (user && user.token) {
      logIntoExtension(user)
    }
  }, [user])

  const checkSubscription = async () => {
    const response = await fetchSubscriptionStatus()
    if (response?.active_subscription === false) {
      setSubscriptionModalOpen(true)
    }
  }

  const handleDrawerOpen = () => {
    setMenuIsExpanded(true)
  }

  const handleDrawerClose = () => {
    setMenuIsExpanded(false)
  }

  React.useEffect(() => {
    if (isMobileView) {
      handleDrawerClose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileView])

  React.useEffect(() => {
    if (token) {
      checkSubscription()
    }
  }, [token])

  const renderSubscriptionCard = () => {
    return (
      <div className="subscriptionIcon">
        {!menuIsExpanded ? (
          <IconButton title="Subscription Detail" onClick={handleDrawerOpen}>
            <AccountBalanceWalletIcon
              sx={{
                position: "absolute",
                left: "0px",
              }}
            />
          </IconButton>
        ) : (
          <SubscriptionCard />
        )}
      </div>
    )
  }

  if (hideAppBar) {
    return null
  }

  return (
    <div className={menuIsExpanded === true ? "slider navBar v1" : "navBar v1"}>
      <AppBar position="fixed" color="transparent" open={menuIsExpanded}>
        <Toolbar
          sx={{
            backgroundColor: colors.White,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(menuIsExpanded && { display: "none" }) }}
          >
            <img src={ToggleBtn} className="open" />
          </IconButton>
          <IconButton onClick={handleDrawerClose} aria-label="close drawer">
            {theme.direction === "rtl" ? (
              <img src={ToggleBtn} className="open" />
            ) : (
              <img src={ToggleBtn} className="open" />
            )}
          </IconButton>
          <img
            src={Iconlogo}
            onClick={() => history.push(SidebarRoute.TrendingBrands)}
            style={{ cursor: "pointer", width: "44px" }}
            alt="trend-rocket-logo"
            className="Iconlogo-sm"
          />
          <Search>{isMobileView ? <MobileViewSearch /> : <SearchBar />}</Search>
          <Box
            className="loginBtn"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {token ? (
              <AvatarMenu />
            ) : (
              <Button endIcon={<Login />} color="primary" href="/login">
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
        open={menuIsExpanded}
        className="v1-hmenu"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            borderBottom: `0.8px solid ${colors.Icon}`,
          }}
        >
          <img
            src={FullLogo}
            alt="trend-rocket-logo"
            onClick={() => history.push(SidebarRoute.TrendingBrands)}
            style={{
              width: "162px",
              cursor: "pointer",
            }}
          />
        </Box>

        {routes.map((section) => (
          <MenuSection
            routeSection={section}
            key={section.id}
            open={menuIsExpanded}
          />
        ))}
        {token && renderSubscriptionCard()}
        <SubscriptionMessage open={subscriptionModalOpen} />
      </Drawer>
    </div>
  )
}

type MenuItemSectionProps = {
  routeSection: RouteSection
  open: boolean
}

const MenuSection = ({ routeSection, open }: MenuItemSectionProps) => {
  return (
    <Box
      key={routeSection.id}
      sx={{
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {open && (
        <Typography
          fontSize="16px"
          fontWeight="500"
          fontFamily={"'Poppins', sans-serif !important;"}
          sx={{
            marginBottom: 1,
          }}
        >
          {routeSection.title}
        </Typography>
      )}
      {!open && <Box sx={{ height: 20 }} />}
      {routeSection.items.map((item) => (
        <MenuItem routeItem={item} key={item.route} open={open} />
      ))}
    </Box>
  )
}

type MenuItemProps = {
  routeItem: RouteItem
  open: boolean
}

const MenuItem = ({ routeItem, open }: MenuItemProps) => {
  const location = useLocation()
  const selected = location.pathname.includes(routeItem.route)

  return (
    <ListItemButton
      sx={{
        width: "85%",
        backgroundColor: selected ? colors.Primary : colors.White,
        borderRadius: "5px",
      }}
      component={RouterLink}
      target={routeItem.newTab ? "_blank" : undefined}
      to={{ pathname: routeItem.route }}
    >
      <ListItemIcon
        sx={{
          minWidth: "20px",
          paddingRight: "12px",
        }}
        title={routeItem.title}
      >
        {iconForSidebarRoute(routeItem.route, selected)}
      </ListItemIcon>
      {open && (
        <ListItemText
          disableTypography
          primary={
            <Typography
              fontSize="16px"
              fontWeight="500"
              fontFamily={"'Poppins', sans-serif !important;"}
              style={{
                color: selected ? colors.White : colors.Text,
              }}
            >
              {routeItem.title}
            </Typography>
          }
        />
      )}
    </ListItemButton>
  )
}
