import LaunchIcon from "@mui/icons-material/Launch"
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"

import { ProductItem, fetchTrendingProducts } from "../../api/trendingProducts"
import AlibabaIcon from "../../assets/images/icon_alibaba.png"
import AliExpressIcon from "../../assets/images/v1-icons/aliexpress.png"
import Ficon1 from "../../assets/images/v1-icons/ficon-1.svg"
import Ficon2 from "../../assets/images/v1-icons/ficon-2.svg"
import Ficon3 from "../../assets/images/v1-icons/ficon-3.svg"
import Ficon4 from "../../assets/images/v1-icons/ficon-4.svg"
import { getExactBrandName } from "../../utils/textHelpers"
import LoadingOverlay from "../Components/LoadOverlay/LoadOverlay"
// import Pagination from "../Components/Pagination/pagination"
import { useLayout } from "../Hooks/useLayoutContext"
import usePagination from "../Hooks/usePagination"
import {
  handleAliExpressIconClick,
  handleAlibabaIconClick,
} from "../search-results/actions"
import { scrollToTop } from "../../utils"

export default function FeaturedProducts(): React.ReactElement {
  const { menuIsExpanded } = useLayout()
  const {
    currentPage,
    pageSize,
    // resultCount,
    setResultCount,
    // setCurrentPage,
    // setPageSize,
  } = usePagination()

  const [productsData, setProductsData] = useState<ProductItem[]>([])
  const [hoveredProductIndex, setHoveredProductIndex] = useState<number | null>(
    null
  )
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const loadFeaturedProducts = async () => {
      setLoading(true)
      const response = await fetchTrendingProducts(currentPage, pageSize)
      setLoading(false)
      if (response) {
        setProductsData(response)
        setResultCount(response?.length)
      }
    }

    loadFeaturedProducts()
    scrollToTop()
  }, [currentPage, pageSize, setResultCount])

  const handleMouseEnteredProduct = (index: number) => {
    setHoveredProductIndex(index)
  }

  const handleMouseLeftProduct = () => {
    setHoveredProductIndex(null)
  }

  // passing the selected product as state to product detail page.
  const handleProductSelect = (selectedProduct: ProductItem) => {
    sessionStorage.setItem(
      "pid",
      selectedProduct.product.shopify_product_id.toString()
    )

    Object.assign(document.createElement("a"), {
      target: "_blank",
      href: `/brand/${encodeURIComponent(selectedProduct.brand_name)}/${
        selectedProduct.brand_id
      }/product/${encodeURIComponent(selectedProduct.product.name)}/${
        selectedProduct.product.shopify_product_id
      }/detail`,
    }).click()
  }

  const getProductCard = (product: ProductItem, index: number) => (
    <Grid item xs={12} sm={4} key={index} className="featuredProduct">
      <Paper
        variant={"elevation"}
        elevation={4}
        onMouseEnter={() => handleMouseEnteredProduct(index)}
        onMouseLeave={handleMouseLeftProduct}
        sx={(theme) => ({
          pl: theme.spacing(2),
          pr: theme.spacing(2),
          pt: theme.spacing(1.25),
          pb: theme.spacing(1.25),
          ml: theme.spacing(1.25),
          mr: theme.spacing(1.25),
          borderRadius: "15px",
          display: "flex",
          height: "400px",
          flexDirection: "column",
          "& > *:not(last-child)": {
            mb: (theme) => theme.spacing(1),
          },
        })}
      >
        {hoveredProductIndex === index && (
          <>
            <Avatar
              sx={{
                height: "25px",
                width: "25px",
                right: "75px",
                top: "7px",
                position: "absolute",
                cursor: "pointer",
              }}
              src={AliExpressIcon}
              onClick={() => handleAliExpressIconClick(product.product.name)}
            />
            <Avatar
              sx={{
                height: "30px",
                width: "30px",
                right: "40px",
                top: "5px",
                position: "absolute",
                cursor: "pointer",
              }}
              src={AlibabaIcon}
              onClick={() => handleAlibabaIconClick(product.product.name)}
            />
            <Link
              href={product?.product?.url}
              referrerPolicy="no-referrer"
              target="_blank"
              sx={{ float: "right" }}
            >
              <LaunchIcon fontSize="small" />
            </Link>
          </>
        )}
        <Typography variant="subtitle2">Featured Product</Typography>
        <img
          alt={`${product.brand_name} product ${product.product_name}`}
          src={product.product?.imageurl || "/images/product_placeholder.jpg"}
          style={{
            width: "100%",
            maxHeight: "220",
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = "/images/product_placeholder.jpg"
          }}
        />
        <div className="bstSellerDetails">
          {product.product?.name && (
            <Typography variant="subtitle2">{product.product?.name}</Typography>
          )}
          <span>
            <Link
              sx={{
                textDecoration: "none",
                ":hover": {
                  textDecoration: "underline",
                },
              }}
              href={`/brand/${getExactBrandName(product.brand_name)}/${
                product.brand_id
              }`}
              target="_blank"
            >
              {product.brand_name}
            </Link>
          </span>
        </div>
        {product.product?.price && (
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              "& span": {
                marginLeft: theme.spacing(2),
                color: theme.palette.text.secondary,
                textDecoration: "line-through",
                fontSize: "90%",
              },
            })}
          >
            ${product.product?.price}
          </Typography>
        )}
        <div className="feauredIcons">
          <button>
            <img src={Ficon1} />
          </button>
          <button>
            <img src={Ficon2} />
          </button>
          <button>
            <img src={Ficon3} />
          </button>
          <button>
            <img src={Ficon4} />
          </button>
        </div>
        <Button color="primary" onClick={() => handleProductSelect(product)}>
          View Product Details
        </Button>
      </Paper>
    </Grid>
  )

  return (
    <Paper
      className={menuIsExpanded === true ? "slider v1-trending" : "v1-trending"}
    >
      <LoadingOverlay loading={loading} />
      <div className="searchHead">
        <Typography variant="h4">Featured Products</Typography>
        <Divider />
      </div>
      <div className="featuredProducts">
        {productsData?.map(
          (product, index) => product && getProductCard(product, index)
        )}
        {/* pagination component */}
        {/* {productsData && productsData?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            resultCount={resultCount}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onPageSizeChange={setPageSize}
            label={"Products"}
          />
        )} */}
      </div>
    </Paper>
  )
}
