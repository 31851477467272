import { fetchApiCall, postApiCall } from "."

export type CheckoutSession = {
  payment_url: string
  session_id: string
}

export type CurrentSubscription = {
  plan: {
    active: boolean
    amount: number
    amount_decimal: string
    created: number
    currency: string
    id: string
    interval: string
    livemode: boolean
    metadata: Record<string, unknown>
    name: string
    product: string
    trial_period_days: number
  }

  product: {
    active: boolean
    created: number
    description: string
    id: string
    images: []
    live_mode: boolean
    metadata: Record<string, unknown>
    name: string
  }
}

export type PriceItem = {
  id: string
  object: string
  active: boolean
  billing_scheme: string
  created: number
  currency: string
  livemode: boolean
  lookup_key: null
  metadata: Record<string, unknown>
  nickname: string
  product: string
  recurring: {
    aggregate_usage: null
    interval: string
    interval_count: number
    trial_period_days: null
    usage_type: string
  }
  tax_behavior: string
  tiers_mode: null
  transform_quantity: null
  type: string
  unit_amount: number
  unit_amount_decimal: string
}

export type SubscriptionStatus = {
  active_subscription: boolean
  current_subscription: CurrentSubscription
  recent_registration: boolean
}

export type PlanDetails = {
  id: string
  name: string
  description: string
  features: string[]
  metadata: {
    pricing_offer: string
    product_desc: string
    [key: string]: string
  }
  image: []
  livemode: boolean
  active: boolean
  prices: PriceItem[]
}

export type PaymentDetails = {
  portal_url: string
}

export const createCheckout = async (payload: any) => {
  const endpoint = "api/subscription/create-checkout-session/"

  return postApiCall<CheckoutSession>(endpoint, payload)
}

export const createPortalSession = async () => {
  const endpoint = "api/subscription/create-portal-session/"

  return postApiCall<PaymentDetails>(endpoint, {})
}

export const fetchPlanDetails = async () => {
  const endpoint = "api/subscription/plan-prices/"

  return fetchApiCall<PlanDetails[]>(endpoint)
}

export const fetchSubscriptionStatus = async () => {
  const endpoint = "api/subscription/active/"

  return fetchApiCall<SubscriptionStatus>(endpoint)
}