// @mui
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import TrendingDownIcon from "@mui/icons-material/TrendingDown"
import TrendingUpIcon from "@mui/icons-material/TrendingUp"
import { Box, Chip, TableCell } from "@mui/material"

import { ScoreColumn } from "../../search-brand/types"
import {
  getScoreChipBgColor,
  getScoreChipColor,
  getScoreIconColor,
} from "../../../utils/statusHelpers"
//--------------------------------------------------------------------------------------------


interface ScoreCellProps {
  score: ScoreColumn
}

export default function ScoreTableCell(props: ScoreCellProps) {
  let icon
  if (props.score.percentage !== null) {
    if (props.score.percentage > 0) {
      icon = <TrendingUpIcon sx={{ color: getScoreIconColor(props.score) }} />
    } else if (props.score.percentage == 0) {
      icon = <ArrowRightAltIcon sx={{ color: getScoreIconColor(props.score) }} />
    } else {
      icon = <TrendingDownIcon sx={{ color: getScoreIconColor(props.score) }} />
    }
  }

  return (
    <TableCell>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Chip
          label={props.score.score}
          sx={{
            backgroundColor: getScoreChipBgColor(props.score),
            color: getScoreChipColor(props.score),
          }}
        />
        <span>{props.score.percentage ? `${props.score.percentage}%` : ""}</span> {icon}
      </Box>
    </TableCell>
  )
}
