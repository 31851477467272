import InfoIcon from "@mui/icons-material/Info"
import InstagramIcon from "@mui/icons-material/Instagram"
import FacebookIcon from "@mui/icons-material/Facebook"
import Shopify from "@mui/icons-material/ShoppingCart"
import StarsIcon from "@mui/icons-material/StarRate"
import AdsLibrary from "@mui/icons-material/DynamicFeed"
import { Grid, Paper, Rating } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"

import { RootStore } from "../../../Redux/store"
import { BrandItem } from "../common"


interface Props {
  isReport?: boolean
}

export default function BrandInfo({ isReport }: Props): React.ReactElement {
  const brandItem: BrandItem = useSelector(((state: RootStore) => state.PageReduser["brandItem"]))
  const [readMore, setReadMore] = React.useState(false)
  const [forceRead, setForceRead] = React.useState(false)

  function toggleReadMore() {
    setReadMore(!readMore)
    setForceRead(!forceRead)
  }

  function stringTruncate(str: string) {
    if (str.length > 125) {
      if (readMore === false) {
        setReadMore(true)
      }
      return str.slice(0, 125) + "..."
    } else {
      return str
    }
  }

  return (
    <Grid
      item
      xs={12}
      md={3}
      className="infoSocial shbox"
    >
      {Object.keys(brandItem).length !== 0 &&
        <Paper
          sx={(theme) => ({
            padding: "30px !important",
            borderRadius: "4px",
            height: "210px",
            boxShadow: "none",
            "& > :not(:last-child)": {
              marginBottom: theme.spacing(1)
            },
            "& .MuiSvgIcon-root": {
              marginRight: theme.spacing(1.5)
            }
          })}
        >
          {(!brandItem.brand_description || isReport) ? ""
            : <>
              <InfoIcon />
              <span>{forceRead ? brandItem.brand_description : stringTruncate(brandItem.brand_description)}</span>
              {brandItem.brand_description.length > 125 && (forceRead ?
                <a style={{ cursor: "pointer" }} onClick={() => toggleReadMore()} className="readmore">Read less...</a> :
                <a style={{ cursor: "pointer" }} onClick={() => toggleReadMore()} className="readmore">Read more...</a>)}
            </>}
          <br />
          <br />
          <div className="socialIcons">
            {!brandItem.fburl ? "" : <><FacebookIcon /><a target="_blank" href={brandItem.fburl}>{"Facebook"}</a></>}
            {!brandItem.fb_ads_library_url ? "" : <><AdsLibrary /><a target="_blank" href={brandItem.fb_ads_library_url}>Ads Library</a></>}

            {!brandItem.shopifysite ? "" : <><Shopify /><a target="_blank" href={brandItem.shopifysite}>Shopify</a></>}

            {!brandItem.instagramurl ? "" : <><InstagramIcon /><a target="_blank" href={brandItem.instagramurl}>Instagram</a></>}

            {!brandItem.trustpilotsite ? "" :
              <div className="starRate">
                <StarsIcon /><a target="_blank" href={brandItem.trustpilotsite}>Trustpilot</a>

                <p><Rating name="half-rating-read" readOnly precision={0.5} className="starRating" value={brandItem.latest_trustpilotrating || 0} /></p>
                {`Reviews ${brandItem.latest_numberreviews} | ${brandItem.latest_trustpilotratingtext || "No rating"}`} {brandItem.latest_trustpilotrating}

              </div>}
          </div>
        </Paper>
      }
    </Grid>
  )
}
