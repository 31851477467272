const EXTENSION_ID = process.env.REACT_APP_TREND_ROCKET_EXTENSION_ID

export enum ChromeExtensionEventType {
  LOG_IN = "LOG_IN",
  LOG_OUT = "LOG_OUT",
}

type ChromeExtensionMessage = {
  event: ChromeExtensionEventType
  data: any
}

export const sendChromeExtensionMessage = async (
  message: ChromeExtensionMessage
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
  if (!window.chrome) {
    return
  }
  // Working with extension code seems incredibly volatile. If there is no extension running at all then runtime is undefined and will crash chrome
  // Now catching all errors and returning
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    await window?.chrome?.runtime?.sendMessage(EXTENSION_ID, message)
  } catch (e: any) {
    return
  }
}
