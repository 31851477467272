import { deleteApiCall, fetchApiCall, patchApiCall } from ".."

import { CollectionResult } from "../common"
import { CtaType } from "../trendingAds"

export type PinnedAdsFilters = {
  boardIds?: number[]
  platforms?: string[] | string
  format?: string[] | string
  sort?: string[] | string
  brandIds?: string[]
}

export type PinnedAdCard = {
  id: number
  body: string
  image: string | null
  title: string
  caption: string
  cta_type: string
  cta_text: string
  link_description: string
  link_url: string
  video_url: string | null
  video_preview_image: string | null
}

export type PinnedAdBrand = {
  brandname: string
  coverimage: string
  profileimage?: string | null
  instagramurl?: string | null
  fburl: string
  brandsite?: string | null
  fblikes?: number
  brand_id: number
}

export type PinnedFBAd = {
  adarchiveid: string
  creation_time: string
  startdate: string
  enddate: string
  html?: string
  fb_ad_video_url?: string | null
  page_id: string
  ad_country?: string | null
  publisherplatform: string
  isactive: 1 | 0
  ad_creative_id?: string | null
  cta_type: CtaType
  collationcount?: string
  collationid?: string
  image?: string | null
  video_preview_image_url?: string | null
  video_content_url?: string | null
  ad_content?: string | null
  link_url: string | null
  reachestimate: string
  spend: string
  impressions: string
  agegenderdata?: string | null
  locationdata?: string | null
  data_insert_timestamp: string
  video_url: string | null
  pinned_at: string
  cards: PinnedAdCard[]
  page_categories: string
  brand: PinnedAdBrand
}

export type PinnedAd = {
  id: number
  board: number
  fb_ad: PinnedFBAd
  created_on: string
  updated_on: string
}

export const getPinnedAds = async (
  filters?: PinnedAdsFilters,
  page = 1,
  pageSize = 20
) => {
  const endpoint = "api/creative/board-ads/"

  return fetchApiCall<CollectionResult<PinnedAd>>(endpoint, {
    page,
    pageSize,
    board_ids: filters?.boardIds,
    platforms: filters?.platforms,
    display_formats: filters?.format,
    ordering: filters?.sort,
    page_ids: filters?.brandIds,
  })
}

export const deletePinnedAd = async (id: number) => {
  const endpoint = `api/creative/delete/board-ads/${id}`

  return deleteApiCall(endpoint)
}

export const movePinnedAd = async (pinnedAd: PinnedAd, toBoardId: number) => {
  const endpoint = `api/creative/move-pin/${pinnedAd.id}/`

  return patchApiCall(endpoint, {
    source_board_id: pinnedAd.board,
    dest_board_id: toBoardId,
  })
}

type ShareLinkResponse = {
  link: string
}
export const getShareableLink = async (pinnedAdId: number) => {
  const endpoint = `api/creative/pinned-ad/${pinnedAdId}/share/`
  return fetchApiCall<ShareLinkResponse>(endpoint)
}

export const getPublicAd = async (token: string) => {
  const endpoint = `api/creative/ad/${token}/`
  return fetchApiCall<PinnedAd>(endpoint)
}
