export const ProductsIcon = ({
  width,
  height,
  color,
}: {
  width?: string
  height?: string
  color?: string
}) => {
  return (
    <svg
      width={width || 15}
      height={height || 16}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1516 13.0169V10.7686L14.6509 9.30653C14.819 9.20632 14.922 9.02502 14.922 8.82929C14.922 8.63356 14.819 8.45226 14.6509 8.35205L12.1682 6.89826L14.6509 5.44446C14.8197 5.34541 14.9234 5.16433 14.9234 4.9686C14.9234 4.77287 14.8197 4.59179 14.6509 4.49274L11.0344 2.37136C10.8619 2.27205 10.6496 2.27205 10.4771 2.37136L8.28954 3.65412H6.63436L4.44678 2.37136C4.27431 2.27205 4.06201 2.27205 3.88954 2.37136L0.272987 4.48998C0.103853 4.58945 0 4.77101 0 4.96722C0 5.16344 0.103853 5.34499 0.272987 5.44446L2.75574 6.89826L0.272987 8.35205C0.104854 8.45226 0.00185924 8.63356 0.00185924 8.82929C0.00185924 9.02502 0.104854 9.20632 0.272987 9.30653L2.7723 10.7686V13.0169H12.1516Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.46211 9.65625V15.8135L7.44832 16.0011C7.35488 15.9985 7.26368 15.9719 7.18349 15.9238L3.04556 13.4907C2.87695 13.3921 2.77307 13.2116 2.77246 13.0162V10.768L3.8897 11.4245C4.06217 11.5238 4.27447 11.5238 4.44694 11.4245L7.46211 9.65625Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1517 10.768V13.0162C12.1511 13.2116 12.0472 13.3921 11.8786 13.4907L7.74066 15.9238C7.65671 15.9748 7.56026 16.0016 7.46204 16.0011L7.44824 16.0011L7.46204 15.8135V9.65625L10.4772 11.4245C10.6497 11.5238 10.862 11.5238 11.0344 11.4245L12.1517 10.768Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.168 6.8981L12.1515 6.90638L7.46182 9.65672L2.77217 6.90638L2.75562 6.8981L6.63423 4.625V6.34638H5.98872C5.87406 6.34654 5.77146 6.41761 5.731 6.52489C5.69054 6.63217 5.72066 6.7533 5.80665 6.82914L7.27975 8.11741C7.38389 8.2089 7.53975 8.2089 7.64389 8.11741L9.11699 6.82914C9.20298 6.7533 9.2331 6.63217 9.19264 6.52489C9.15218 6.41761 9.04958 6.34654 8.93492 6.34638H8.28941V4.625L12.168 6.8981Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.11713 6.82758L7.64403 8.11586C7.53989 8.20735 7.38403 8.20735 7.27989 8.11586L5.80679 6.82758C5.72079 6.75174 5.69068 6.63062 5.73114 6.52334C5.7716 6.41606 5.8742 6.34499 5.98886 6.34482H6.63437V0.275862C6.63437 0.123508 6.75788 0 6.91024 0H8.01368C8.16604 0 8.28954 0.123508 8.28954 0.275862V6.34482H8.93506C9.04972 6.34499 9.15232 6.41606 9.19278 6.52334C9.23324 6.63062 9.20312 6.75174 9.11713 6.82758Z"
        fill={color}
      />
    </svg>
  )
}
