import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import LockIcon from "@mui/icons-material/Lock"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { Alert, Button, FormControl } from "@mui/material"
import axios from "axios"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"

import v1logo from "../assets/images/Logo/LogoFull.svg"
import { RootStore } from "../Redux/store"
import { getAbsoluteURL } from "../utils"
import ReCaptcha, { getToken } from "../utils/Recaptcha"
import LoadingOverlay from "./Components/LoadOverlay/LoadOverlay"

export default function V1PasswordReset(): React.ReactElement {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const [email, setEmail] = React.useState<string>("")
  const [pwd, setPwd] = React.useState<string>("")
  const [confirmPwd, setConfirmPwd] = React.useState<string>("")
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [showIcon, setShowIcon] = React.useState<boolean>(false)
  const [pwdVisible, setPwdVisible] = React.useState<boolean>(false)
  const loading: boolean = useSelector(
    (state: RootStore) => state.PageReduser["loading"]
  )

  React.useEffect(() => {
    const cred = params as { uid: string; token: string }
    dispatch({ type: "Loading", payload: true })
    axios
      .get(
        getAbsoluteURL(`api/user/verify-reset-link/${cred.uid}/${cred.token}/`)
      )
      .then((response) => {
        const data = response.data as { email: string; message: string }
        setEmail(data.email)
        dispatch({ type: "Loading", payload: false })
      })
      .catch(() => {
        dispatch({ type: "Loading", payload: false })
        setError(true)
        setAlertOpen(true)
        setTimeout(() => {
          setAlertOpen(false)
          setError(false)
        }, 3000)
      })
  }, [params, dispatch])

  function handlePwdChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.id === "newPassword") {
      setPwd(e.target.value)
    } else {
      setConfirmPwd(e.target.value)
    }
    if (e.target.id === "confirmPassword" && e.target.value.length > 0) {
      setShowIcon(true)
    } else if (
      e.target.id === "confirmPassword" &&
      e.target.value.length === 0
    ) {
      setShowIcon(false)
    }
  }

  const handleReset = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const recaptchaToken = (await getToken()) as string
    const cred = params as { uid: string; token: string }
    const url = getAbsoluteURL(
      `api/user/confirm-reset-password/${cred.uid}/${cred.token}/`
    )
    const formData = {
      password: pwd,
      confirm_password: confirmPwd,
      recaptchaToken,
    }
    dispatch({ type: "Loading", payload: true })
    axios
      .post(url, formData, { headers: { "Content-type": "application/json" } })
      .then(() => {
        dispatch({ type: "Loading", payload: false })
        setAlertOpen(true)
        setTimeout(() => {
          setAlertOpen(false)
          history.push("/login")
        }, 4000)
      })
      .catch(() => {
        dispatch({ type: "Loading", payload: false })
        setError(true)
        setAlertOpen(true)
        setTimeout(() => {
          setAlertOpen(false)
          setError(false)
        }, 3000)
      })
  }

  const alert = error ? (
    <Alert severity="error">Error occured! Please try again.</Alert>
  ) : (
    <Alert severity="info">
      Your password has been successfully changed! Redirecting to login page.
      Please wait...
    </Alert>
  )

  const validationIcon =
    pwd === confirmPwd ? (
      <CheckCircleIcon color="success" />
    ) : (
      <CancelIcon color="error" />
    )

  return (
    <div className="v1-loginBg">
      <div className="auth__left">
        <div className="auth__info">
          <ul>
            <li>Forgotten password - no problem</li>
          </ul>
          <p className="mt-2">
            {" "}
            We'll get you back on board in no time, just fill in the details.{" "}
          </p>
        </div>
      </div>
      <LoadingOverlay loading={loading} />
      <div className="auth__right">
        <div className="v1-SignupBox">
          <img src={v1logo} alt="MOAS" />
          <div className="v1-Signup">
            {alertOpen ? alert : ""}
            <form onSubmit={handleReset}>
              <FormControl>
                <label>Email</label>
                <span>
                  <AlternateEmailIcon fontSize="small" />
                </span>
                <input
                  id="email"
                  type="email"
                  aria-describedby="email-helper-text"
                  readOnly
                  value={email}
                />
              </FormControl>
              <FormControl>
                <label>New password</label>
                <span>
                  <LockIcon fontSize="small" />
                </span>
                <input
                  required
                  id="newPassword"
                  type={pwdVisible ? "text" : "password"}
                  aria-describedby="pwd-helper-text"
                  onChange={handlePwdChange}
                />
                <span onClick={() => setPwdVisible(!pwdVisible)}>
                  {pwdVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </span>
              </FormControl>
              <FormControl>
                <label>Confirm password</label>
                <span>
                  <LockIcon fontSize="small" />
                </span>
                <input
                  required
                  id="confirmPassword"
                  type={"password"}
                  aria-describedby="pwd-helper-text"
                  onChange={handlePwdChange}
                />
                <span>{showIcon ? validationIcon : ""}</span>
              </FormControl>
              <Button
                sx={{
                  height: "36px !important",
                }}
                type="submit"
                fullWidth
              >
                Reset Password
              </Button>
              <ReCaptcha action="submit" />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
