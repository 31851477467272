import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded"
import { default as LockIcon } from "@mui/icons-material/Lock"
import LoginIcon from "@mui/icons-material/Login"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import {
  Alert,
  Button,
  FormControl,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material"
import axios from "axios"
import React, { useMemo, useState } from "react"
import { useLocation } from "react-router"
import { useHistory } from "react-router-dom"

import { createCheckout } from "../../api/plans"
import v1logo from "../../assets/images/Logo/LogoFull.svg"
import { MOBILE_SCREEN_SIZE, getAbsoluteURL, openLink } from "../../utils"

import ReCaptcha, { getToken } from "../../utils/Recaptcha"
import { UserRoles } from "../../utils/localStorage"
import { SidebarRoute } from "../Components/Appbar/appbarRoutes"
import LoadingOverlay from "../Components/LoadOverlay/LoadOverlay"
import { useAuth } from "../Hooks/usAuthContext"
import { User } from "../brand/common"
import { PlanSelect, generatePlanOptions } from "./PlanSelect"
//---------------------------------------------------------------------------------------------------------

const planOptions = generatePlanOptions()

export default function V1Signup(): React.ReactElement {
  const isMobileDevice = useMediaQuery(MOBILE_SCREEN_SIZE)
  const history = useHistory()
  const location = useLocation()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [priceId, setPriceId] = useState<string | undefined>(
    location.search.replace("?itemid=", "")
  )
  const [errors, setErrors] = useState<Record<string, unknown>>({})
  const [message] = useState("")
  const [pwdVisible, setPwdVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setUser } = useAuth()

  const selectedPlan = useMemo(
    () => planOptions.find((planOption) => planOption.priceId === priceId),
    [priceId]
  )

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+[?:/.[a-zA-Z0-9-]+]*$/
    if (regex.exec(e.target.value) !== null) {
      setEmail(e.target.value)
    } else {
      console.log("error")
    }
  }

  const handleSubscription = async () => {
    //@ts-ignore
    const rewardfulToken = window.Rewardful.referral
    const payload = {
      price_id: priceId,
      referral_code: rewardfulToken,
    }
    setLoading(true)
    const response = await createCheckout(payload)
    setLoading(false)
    if (response) {
      openLink(isMobileDevice, response.payment_url)
    }
  }

  const checkUserStatus = (userRole: string) => {
    if (userRole === UserRoles.BETAUSER) {
      history.push(SidebarRoute.TrendingBrands)
    } else {
      handleSubscription()
    }
  }

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const recaptchaToken = (await getToken()) as string
    const url = getAbsoluteURL("api/user/register/")

    const apiBody = {
      name,
      email,
      password,
      recaptchaToken,
    }
    setLoading(true)
    axios
      .post(url, apiBody, {
        headers: { "Content-type": "application/json" },
      })
      .then((response) => {
        const user = response.data as User
        localStorage.setItem("uid", user.id.toString())
        localStorage.setItem("token", user.token)
        checkUserStatus(user.role)
        setUser(user)
      })
      .catch((errorData: any) => {
        setLoading(false)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setErrors(errorData.response.data)
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 3000)
      })
  }

  function togglePwd() {
    setPwdVisible(!pwdVisible)
  }

  const renderErrors = (fieldName: string) => {
    let msgs: string[] = []
    let error = false
    Object.keys(errors).forEach((key) => {
      if (key === fieldName) {
        msgs = errors[key] as string[]
        error = true
      }
    })
    return { error, msg: msgs.join(".") }
  }

  return (
    <div className="v1-loginBg">
      <div className="auth__left">
        <div className="auth__info">
          <ul>
            <li>Skyrocket your product research</li>
          </ul>
          <p className="mt-2">
            We help you find great products and uncover your competitor strategy
            in minutes not hours with our powerful tools.
          </p>
        </div>
      </div>
      <LoadingOverlay loading={loading} />
      <div className="auth__right">
        <div className="createAcc">
          <Link
            sx={{
              textDecoration: "none",
              ":hover": {
                textDecoration: "underline",
              },
            }}
            href="/login"
          >
            Login to My Account
            <span>
              <LoginIcon fontSize="small" />
            </span>
          </Link>
        </div>
        <div className="v1-SignupBox">
          <img src={v1logo} alt="MOAS" />
          <div className="v1-Signup">
            {error ? <Alert severity="error">{message}</Alert> : ""}
            <br />
            <form onSubmit={handleSignUp}>
              <FormControl>
                <label>Name</label>
                <span>
                  <AccountCircleIcon fontSize="small" />
                </span>
                <input
                  required
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="John Doe"
                />
                {renderErrors("name").error ? (
                  <p style={{ color: "red" }}>{renderErrors("name").msg}</p>
                ) : (
                  ""
                )}
              </FormControl>
              <FormControl>
                <label>Email</label>
                <span>
                  <AlternateEmailIcon fontSize="small" />
                </span>
                <input
                  required
                  id="email"
                  type="email"
                  onChange={handleChange}
                  placeholder="john.doe@email.com"
                />
                {renderErrors("email").error ? (
                  <p style={{ color: "red" }}>{renderErrors("email").msg}</p>
                ) : (
                  ""
                )}
              </FormControl>
              <FormControl>
                <label>Create Password</label>
                <span>
                  <LockIcon fontSize="small" />
                </span>
                <input
                  required
                  id="password"
                  type={pwdVisible ? "text" : "password"}
                  aria-describedby="pwd-helper-text"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span onClick={togglePwd}>
                  {pwdVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </span>
                {renderErrors("password").error ? (
                  <p style={{ color: "red" }}>{renderErrors("password").msg}</p>
                ) : (
                  ""
                )}
              </FormControl>
              <PlanSelect
                planOptions={planOptions}
                onSelect={(newPriceId) => setPriceId(newPriceId)}
                selectedPriceId={priceId}
              />
              {selectedPlan?.supportsTrial === true && (
                <Typography variant="caption">7 day free trial</Typography>
              )}
              <Button
                sx={{
                  textAlign: "left",
                  background: "#00f",
                  justifyContent: "space-between",
                  height: "36px !important",
                  mt: "35px !important",
                }}
                type="submit"
                fullWidth
                endIcon={<CheckCircleOutlineRoundedIcon />}
              >
                Create Account
              </Button>
              <p>
                By creating a TrendRocket account you agree to our{" "}
                <a
                  href="https://www.trendrocket.io/terms-and-conditions"
                  target="_blank"
                >
                  <b>Terms and conditions</b>
                </a>{" "}
                and{" "}
                <a
                  href="https://www.trendrocket.io/privacy-policy"
                  target="_blank"
                >
                  <b>Privacy policy</b>
                </a>{" "}
                as well as to receive marketing emails from TrendRocket.
                <br />
                You can unsubscribe from these communications at any time.
              </p>
              <ReCaptcha action="submit" />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
