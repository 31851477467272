import SearchIcon from "@mui/icons-material/Search"
import { styled } from "@mui/system"
import { useState } from "react"
import { useDispatch } from "react-redux"

import Search from "../search"

const Wrapper = styled("div")({
  position: "absolute",
  width: "61%",
  background: "rgba(59, 78, 223, 0.05)",
  top: 30,
  borderRadius: 35,
  paddingLeft: 20,
  left: "17%",
  "& div ::before": {
    display: "none",
  },
  "& div input": {
    position: "relative",
    top: -23,
  },
})

const SearchButton = styled("button")({
  position: "absolute",
  padding: 0,
  right: 60,
  top: 41,
  border: "none",
  background: "transparent"
})

export default function MobileViewSearch() {
  const dispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false)

  const toggleSearchBar = () => {
    dispatch({ type: "HIDE_ICON", payload: !open })
    setOpen(!open)
  }

  return (
    <div>
      {open
        ? (
          <Wrapper>
            <Search onClose={toggleSearchBar} />
          </Wrapper>
        ) : (
          <SearchButton onClick={toggleSearchBar}>
            <SearchIcon />
          </SearchButton>
        )
      }
    </div>
  )
}