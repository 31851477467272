import { useEffect } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import BrandInformationSection from "./BrandInformationSection"
import BrandTabs from "./BrandTabs"

import { trackBrand, ViewType } from "../../api/brandTracking"
import Actions from "../../pages/Actions/PageActions"
import { RootStore } from "../../Redux/store"
import { getToken } from "../../utils/localStorage"
import Authorization from "../Authorization/Authorization"
import LoadingOverlay from "../Components/LoadOverlay/LoadOverlay"
import { useLayout } from "../Hooks/useLayoutContext"

interface Params {
  name: string
  brandId: string
}

export default function () {
  const token = getToken()
  const name = sessionStorage.getItem("name")
  const { menuIsExpanded } = useLayout()
  const loading: boolean = useSelector(
    (state: RootStore) => state.PageReduser["loading"]
  )
  const StatusCode: number = useSelector(
    (state: RootStore) => state.PageReduser["StatusCode"]
  )

  const dispatch = useDispatch()
  const params: Params = useParams()

  useEffect(() => {
    if (StatusCode === 404) {
      window.open("/page-not-found")
    }
  }, [StatusCode])

  useEffect(() => {
    const loadData = () => {
      const brandName = params.name
      if (brandName) {
        const actualName = brandName.replace(/-/g, " ").replace(/_/g, "-")
        sessionStorage.setItem("name", actualName)
      }
    }

    window.scrollTo(0, 0)
    loadData()
    const name = sessionStorage.getItem("name")
    if (name) {
      batch(() => {
        dispatch(Actions.GetSelectedBrandData(parseInt(params.brandId)))
        dispatch(Actions.GetSocialData(name))
        dispatch(Actions.GetOverviewCharts(params.brandId, 90))
        dispatch(Actions.GetTrafficData(name))
        dispatch(Actions.GetSerpStatKeyword(name))
        dispatch(Actions.GetStoreTabSummary(name))
        dispatch(Actions.GetSerpstatAds(name))
        dispatch(Actions.GetBrandViewStatus(params.brandId))
      })
    }
    window.history.pushState(null, window.location.pathname)
    window.addEventListener("popstate", loadData)
    return () => {
      window.removeEventListener("popstate", loadData)
    }
  }, [token, name, params.brandId, params.name, dispatch])

  useEffect(() => {
    const triggerBrandTracking = async () => {
      const brandId = parseInt(params.brandId)
      await trackBrand(brandId, ViewType.brandView)
    }

    triggerBrandTracking()
  }, [params.brandId])

  return (
    <div
      className={menuIsExpanded === true ? "slider v1-trending" : "v1-trending"}
    >
      {!localStorage.getItem("token") ? <Authorization /> : ""}
      <LoadingOverlay loading={loading} />
      <BrandInformationSection />
      <BrandTabs />
    </div>
  )
}
