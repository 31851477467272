import { Box, Typography, useMediaQuery } from "@mui/material"
import { styled } from "@mui/system"
import React from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { RootStore } from "../../../Redux/store"
import AppLogo from "../../../assets/images/Logo/LogoFull.svg"
import ApexBarChart from "../../../components/Chart/ApexBarChart"
import { MOBILE_SCREEN_SIZE } from "../../../utils"
import { TakeSnapshot } from "../../../utils/snapshot"
import { colors } from "../../../utils/theme"
import { TrafficBriefData } from "../common"
//------------------------------------------------------------------------------------------

enum TrafficElement {
  id = "traffic-revenue",
}

const ShareButton = styled("button")({
  height: "30px",
  paddingLeft: "5px",
  paddingRight: "10px",
  border: "none",
  borderLeft: "1px solid #ffff",
  background: "transparent",
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: "Poppins !important",
  color: colors.Text,
  verticalAlign: "top",
  cursor: "pointer",
})

export default function TrafficChart(): React.ReactElement {
  const trafficBriefData: TrafficBriefData = useSelector(
    (state: RootStore) => state.PageReduser["trafficBriefData"]
  )
  const isMobileDevice = useMediaQuery(MOBILE_SCREEN_SIZE)
  const params: { name: string } = useParams()

  const convertToNum = (str: string) => {
    let newStr = ""
    if (str.includes(".")) {
      newStr = str
        .replace("K", "00")
        .replace("k", "00")
        .replace("M", "00000")
        .replace("m", "00000")
        .replace("B", "00000000")
        .replace("b", "00000000")
        .replace(".", "")
    } else {
      newStr = str
        .replace("K", "000")
        .replace("k", "000")
        .replace("M", "000000")
        .replace("m", "000000")
        .replace("B", "000000000")
        .replace("b", "000000000")
    }
    return parseInt(newStr)
  }

  function getTrafficChartData() {
    const datalist: { x: string; y: number }[] = []
    if (trafficBriefData?.traffic_overview_data) {
      Object.keys(trafficBriefData.traffic_overview_data).map((key) =>
        datalist.push({
          x: key,
          y: convertToNum(
            trafficBriefData.traffic_overview_data[key].toString()
          ),
        })
      )
    }
    return {
      name: "Website Traffic",
      xAxis: datalist.map((value) => value.x),
      yAxis: datalist.map((value) => value.y),
    }
  }

  return (
    <Box
      sx={{
        height: "200px",
        position: "relative",
        pl: "10px",
        pr: "30px",
      }}
    >
      <div className="StoreHeader">
        <Typography
          variant="h5"
          sx={{
            fontSize: isMobileDevice ? "17px" : "24px",
            display: "inline-block",
            fontWeight: 500,
            color: colors.Text,
            fontFamily: "Poppins !important",
          }}
        >
          Monthly Visits
        </Typography>
        <Box
          sx={{
            display: "inline-block",
            background: "#EBEDFC",
            borderRadius: "5px",
            height: "30px",
            paddingLeft: "10px",
            paddingRight: "0px",
            marginLeft: "30px",
          }}
        >
          <img
            style={{
              marginTop: "6px",
              marginRight: "5px",
              height: "18px",
            }}
            src={AppLogo}
          />
          <ShareButton
            onClick={() => TakeSnapshot(TrafficElement.id, params.name)}
          >
            Share
          </ShareButton>
        </Box>
      </div>
      <ApexBarChart chartSeries={getTrafficChartData()} />
    </Box>
  )
}
