import { LicenseInfo } from "@mui/x-license-pro"
import "fontsource-roboto"
import React from "react"
import ReactDOM from "react-dom"
import { MUI_PRO_LICENSE_KEY } from "./Constants"
import V1App from "./V1App"
import "./assets/css/style.css"
import "./assets/css/v1-style.css"
import { AuthProvider } from "./contexts/AuthContext"
import { LayoutProvider } from "./contexts/LayoutContext"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import ThemeProvider from "./utils/theme"

// Sentry.init({
//   dsn: "https://214ec439390e455f8e35839d908e0ac8@o1308501.ingest.sentry.io/6553859",
//   integrations: [
//     new BrowserTracing({
//       tracePropagationTargets: [],
//     }),
//   ],
//   environment: process.env.NODE_ENV,
//   tracesSampleRate: 1.0,
// })

LicenseInfo.setLicenseKey(MUI_PRO_LICENSE_KEY)

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        <LayoutProvider>
          <V1App />
        </LayoutProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
serviceWorker.unregister()
