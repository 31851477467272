import { BrandTableRowData } from "../../V1/search-brand/types"

export enum TrackedStores {
  ON = "tracked_stores"
}

export type QueryParams = {
  currentPage: number
  pageSize: number
  duration: string
  ordering: string | null
  trackedOn: TrackedStores | null
  runningAds: true | null
  reportPublished: true | null
}

export type SubCategory = {
  id: number
  title: string
  category?: string
  categoryId?: number
}
export type Category = {
  id: number
  title: string
  sub_categories: SubCategory[]
}

type MaxValueData = {
  value: number
}

export type MaxValues = {
  active_fbads_count: MaxValueData
  diff_last_month_fblikes: MaxValueData
  diff_last_month_fb_ads: MaxValueData
  diff_last_month_fbreviews: MaxValueData
  diff_last_month_instafollowers: MaxValueData
  diff_last_month_numberreviews: MaxValueData
  diff_last_month_shopifyreviews: MaxValueData
  latest_shopifyreviews_count: MaxValueData
  fbads_count: MaxValueData
  fbads_count_over_30_days: MaxValueData
  latest_fblikes: MaxValueData
  latest_fbreviews: MaxValueData
  latest_instafollowers: MaxValueData
  latest_numberreviews: MaxValueData
  latest_reditmentions: MaxValueData
  product_data_avg_price: MaxValueData
  product_data_count: MaxValueData
  product_data_last_30_days: MaxValueData
  product_data_max_price: MaxValueData
  product_data_min_price: MaxValueData
  store_data_estimated_monthly_orders: MaxValueData
  store_data_estimated_monthly_revenue: MaxValueData
  store_data_estimated_monthly_traffic: MaxValueData
}

export type ShopifyData = {
  count: number
  next: string | null
  previous: string | null
  results: BrandTableRowData[]
}