import { Grid } from "@mui/material"
import axios from "axios"
import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { useSelector } from "react-redux"

import { RootStore } from "../../../Redux/store"
import { getAbsoluteURL } from "../../../utils"
import { BrandItem, FbAds, FbAdsDetail } from "../common"
import BrandAdCard from "./BrandAdCard"
import { getToken } from "../../../utils/localStorage"

type Props = {
  productAdsOnly?: boolean
  productId?: number
}

export default function FacebookAds(props: Props): React.ReactElement {
  const token = getToken()

  const brandItem: BrandItem = useSelector(
    (state: RootStore) => state.PageReduser["brandItem"]
  )
  const FbAds: FbAds = useSelector(
    (state: RootStore) => state.PageReduser["FbAds"]
  )

  const [currentlyShowingAds, setCurrentlyShowingAds] = React.useState<
    FbAdsDetail[]
  >([])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1600 },
      items: 4,
      slidesToSlide: 4,
    },
    desktop: {
      breakpoint: { max: 1599, min: 1024 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 640, min: 320 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  React.useEffect(() => {
    const getProductFbGoogleAds = () => {
      axios
        .get(
          getAbsoluteURL(
            `/api/report/product-fb-google-ads/${props.productId as number}/`
          ),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token as string}`,
            },
          }
        )
        .then((response) => {
          const fbAds = response.data[response.data.length - 1]
          setCurrentlyShowingAds(fbAds.fb_ads)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    if (props.productAdsOnly) {
      getProductFbGoogleAds()
    } else {
      setCurrentlyShowingAds(FbAds.results)
    }
  }, [FbAds.results, props.productAdsOnly, props.productId, token])

  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: "calc(100% + 32px)",
        flexBasis: "calc(100% + 32px)",
        mb: "14px",
        ml: "15px",
        display: "inline-block",
      }}
    >
      {currentlyShowingAds && (
        <Carousel
          swipeable={false}
          draggable={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["mobile"]}
          deviceType={"desktop"}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {currentlyShowingAds.map((brandAd, index) => (
            <BrandAdCard
              key={index}
              brandItem={brandItem}
              brandAd={brandAd}
              productAdsOnly={props.productAdsOnly}
            />
          ))}
        </Carousel>
      )}
    </Grid>
  )
}
