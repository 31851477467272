import React from "react"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { Avatar, Box, Button, Chip, Typography } from "@mui/material"

import Actions from "../../pages/Actions/PageActions"
import { RootStore } from "../../Redux/store"
import { BrandItem } from "./common"
import { useEffect } from "react"
import { getAbsoluteURL } from "../../utils"
import { checkIsSuperUser, getToken } from "../../utils/localStorage"

type payloadType = {
  type: string
  payload: any
}


export default function BrandInformationSection() {
  const dispatch = useDispatch()
  const brandItem: BrandItem = useSelector(((state: RootStore) => state.PageReduser["brandItem"]))
  const trackedStatus: boolean = useSelector(((state: RootStore) => state.PageReduser["trackedOn"]))
  const [isFeatured, setIsFeatured] = React.useState(brandItem.is_featured)

  const token = getToken()
  const isSuperUser = checkIsSuperUser()

  useEffect(() => {
    dispatch(Actions.GetFbAds(brandItem.page_id))
    dispatch(Actions.GetFbAdsCount(brandItem.page_id))
    dispatch(Actions.GetFbAdsReview(brandItem.page_id))
    setIsFeatured(brandItem.is_featured)
  }, [brandItem, dispatch])

  const forceReportTab = () => {
    dispatch({ type: "ForceReportTab", payload: true })
  }

  function setTrackedStatus(event: React.MouseEvent<unknown>) {
    event.preventDefault()
    const formData = { brand_id: brandItem.brand_id, is_track_store: trackedStatus ? 0 : 1 }
    void (async () => {
      await fetch(getAbsoluteURL("api/user/track-store/"), {
        method: "POST",
        body: JSON.stringify(formData),
        headers: { "Content-type": "application/json", "Authorization": `Token ${token as string}` }
      })
      const payloadData: payloadType = { type: "SetTrackedOn", payload: formData.is_track_store === 0 ? false : true }
      dispatch(payloadData)
    })()
  }

  function handleFeatureBrand() {
    const url = getAbsoluteURL("api/user/feature-brand/")
    const formData = { brand_id: brandItem.brand_id, is_featured: isFeatured === 0 ? 1 : 0 }
    axios.post(url, JSON.stringify(formData), { headers: { "Content-Type": "application/json", "Authorization": `Token ${token as string}` } })
      .then(() => setIsFeatured(isFeatured === 0 ? 1 : 0))
      .catch(() => alert("Error occured! Try again later"))
  }

  function renderTags(data: string) {
    const tags: string[] = data.split(/,/g)
    return tags.map((tag, index) => (
      <Chip label={tag} key={index} />
    ))
  }

  function openBrandPage() {
    if (brandItem.brandsite !== "NULL") {
      Object.assign(
        document.createElement("a"),
        {
          target: "_blank",
          href: brandItem.brandsite
        }).click()
    }

  }

  return (
    <>
      {Object.keys(brandItem).length !== 0 &&
        <>
          <div className="coverImg">
            {!brandItem.coverimage ? "" :
              <img src={brandItem.coverimage} alt="brand cover" style={{ width: "100%", height: "500px" }} />
            }
          </div>
          <Box className="brandTitle">
            <Avatar src={brandItem.profileimage} variant="circular" className="avatarImg" />
            <Box
              sx={{
                pl: (theme) => theme.spacing(0),
                textAlign: "center",
                mt: "-100px",
              }}
              alignItems="flex-start"
            >
              <Box>
                <Typography variant="h4" sx={{ pt: (theme) => theme.spacing(2) }}>{brandItem.brandname}</Typography>
              </Box>
              <Box
                sx={{
                  "& > *": {
                    margin: (theme) => theme.spacing(0.5),
                    borderRadius: 4
                  }
                }}
              >
                {!brandItem.category_name ? "" : renderTags(brandItem.category_name)}
              </Box>
            </Box>
            <Box
              className="BrandInfoBtn"
              sx={{
                mt: "-152px !important"
              }}
            >
              <Box className="text-right">
                {!brandItem.brandsite
                  ? ""
                  : <Button
                    className="visitBtn"
                    variant="outlined"
                    color="inherit"
                    size="small"
                    onClick={() => openBrandPage()}>
                    Visit Site
                  </Button>
                }
                <Button
                  className="visitBtn"
                  variant={trackedStatus ? "contained" : "outlined"}
                  color={trackedStatus ? "primary" : "inherit"}
                  size="small"
                  onClick={setTrackedStatus}>
                  {trackedStatus ? "Tracked" : "Track Store"}
                </Button>
                {isSuperUser ?
                  <Button
                    className="visitBtn"
                    variant={isFeatured === 1 ? "contained" : "outlined"}
                    color={isFeatured === 1 ? "primary" : "inherit"}
                    size="small"
                    onClick={handleFeatureBrand}>
                    {isFeatured === 1 ? "Featured" : "Feature Brand"}
                  </Button>
                  : ""}
                {(isSuperUser && !brandItem.report_published)
                  ? <Button
                    className="visitBtn"
                    variant="outlined"
                    color="inherit"
                    size="small"
                    onClick={forceReportTab}>
                    Create a Report
                  </Button>
                  : ""
                }
              </Box>
            </Box>
          </Box>
        </>
      }
    </>
  )
}