import { Box, Stack } from "@mui/material"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import {
  PinnedAd,
  PinnedAdCard,
} from "../../../../api/creativeCenter/pinnedAds"
import VideoPlayer from "../../../Components/VideoPlayer/videoPlayer"
import {
  AdCtaData,
  PinnedAdCTA,
} from "../PinnedAdModal/PinnedAdContent/PinnedAdCTA"
//-----------------------------------------------------------------------------------------------

const DEFAULT_BODY_HEIGHT = "258px"

export const PinnedAdBody = ({
  pinnedAd,
  bodyHeight,
  hideCTA,
  focusedIndexChanged,
}: {
  pinnedAd: PinnedAd
  bodyHeight?: string
  hideCTA?: boolean
  focusedIndexChanged?: (newIndex: number) => void
}) => {
  if (pinnedAd.fb_ad.cards.length > 0) {
    return (
      <PinnedAdCarousel
        cards={pinnedAd.fb_ad.cards}
        bodyHeight={bodyHeight}
        hideCTA={hideCTA}
        focusedIndexChanged={focusedIndexChanged}
      />
    )
  } else {
    return (
      <PinnedAdCardBody
        adId={pinnedAd.id}
        bodyHeight={bodyHeight}
        imageUrl={pinnedAd.fb_ad.image}
        videoUrl={pinnedAd.fb_ad.video_url}
        adCtaData={pinnedAd.fb_ad}
        hideCTA={hideCTA}
      />
    )
  }
}

const PinnedAdCardBody = ({
  adId,
  imageUrl,
  videoUrl,
  bodyHeight,
  adCtaData,
  hideCTA,
}: {
  adId: number
  imageUrl?: string | null
  videoUrl?: string | null
  bodyHeight?: string
  adCtaData: AdCtaData
  hideCTA?: boolean
}) => {
  if (imageUrl && imageUrl != "N/A") {
    return (
      <Stack spacing={2}>
        <PinnedAdImage
          adId={adId}
          imageUrlString={imageUrl}
          bodyHeight={bodyHeight}
        />
        {hideCTA !== true && <PinnedAdCTA adCtaData={adCtaData} />}
      </Stack>
    )
  }

  if (videoUrl) {
    return (
      <Stack spacing={2}>
        <PinnedAdVideo videoUrlString={videoUrl} bodyHeight={bodyHeight} />
        {hideCTA !== true && <PinnedAdCTA adCtaData={adCtaData} />}
      </Stack>
    )
  }

  return null
}

const PinnedAdCarousel = ({
  cards,
  bodyHeight,
  hideCTA,
  focusedIndexChanged,
}: {
  cards: PinnedAdCard[]
  bodyHeight?: string
  hideCTA?: boolean
  focusedIndexChanged?: (newIndex: number) => void
}) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1600 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1599, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 550, min: 400 },
      items: 1,
    },
  }

  return (
    <Carousel
      swipeable={true}
      draggable={false}
      responsive={responsive}
      keyBoardControl={true}
      removeArrowOnDeviceType={["mobile"]}
      beforeChange={(newIndex) => {
        if (focusedIndexChanged) {
          focusedIndexChanged(newIndex)
        }
      }}
    >
      {cards
        .filter((m) => m.image || m.video_url)
        .map((card) => {
          return (
            <PinnedAdCardBody
              key={card.id}
              bodyHeight={bodyHeight}
              adId={card.id}
              imageUrl={card.image}
              videoUrl={card.video_url}
              adCtaData={card}
              hideCTA={hideCTA}
            />
          )
        })}
    </Carousel>
  )
}

const PinnedAdImage = ({
  adId,
  imageUrlString,
  bodyHeight,
}: {
  adId: number
  imageUrlString?: string | null
  bodyHeight?: string
}) => {
  if (!imageUrlString || imageUrlString === "N/A") {
    return null
  }
  return (
    <img
      src={imageUrlString}
      alt={`image for ${adId}`}
      style={{
        backgroundColor: "#F2F2F2",
        marginTop: "12px",
        width: "100%",
        height: bodyHeight || DEFAULT_BODY_HEIGHT,
        display: "inline-block",
        objectFit: "contain",
      }}
    />
  )
}

const PinnedAdVideo = ({
  videoUrlString,
  bodyHeight,
}: {
  videoUrlString: string
  bodyHeight?: string
}) => {
  return (
    <Box
      sx={{
        marginTop: "12px",
        width: "100%",
        height: bodyHeight || DEFAULT_BODY_HEIGHT,
        display: "inline-block",
      }}
    >
      <VideoPlayer sourceUrl={videoUrlString} />
    </Box>
  )
}
