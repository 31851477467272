import { createTheme } from "@mui/material/styles"

export const IconTheme = createTheme({
  palette: {
    success: {
      main: "#00CB6A",
    },
  },
})

export const CountryTargetTheme = {
  success: "#00CB6A",
  error: "#d32f2f",
  primary: "rgba(61, 68, 101, 0.5)"
}
