import { Box, CircularProgress, Typography } from "@mui/material"
import { useState } from "react"

import {
  deletePinnedAd,
  PinnedAd,
} from "../../../../api/creativeCenter/pinnedAds"
import { truncateText } from "../../../../utils/textHelpers"
import { colors } from "../../../../utils/theme"
import { PinnedAdBody } from "./PinnedAdBody"

import { PinnedAdFooter } from "./PinnedAdFooter"
import { PinnedAdHeader } from "./PinnedAdHeader"

export const PinnedAdCard = ({
  pinnedAd,
  onDeleteSuccess,
  onSelect,
  onMove,
}: {
  pinnedAd: PinnedAd
  onDeleteSuccess: VoidFunction
  onSelect: VoidFunction
  onMove: VoidFunction
}) => {
  const [loading, setLoading] = useState(false)

  const handleDeleteClicked = async () => {
    setLoading(true)
    await deletePinnedAd(pinnedAd.id)
    onDeleteSuccess()
  }

  return (
    <Box
      bgcolor={colors.White}
      sx={{
        borderRadius: "10px",
        boxShadow: "0px 5px 20px 3px rgba(23, 3, 89, 0.08);",
        py: "21px",
        px: "17px",
        height: "496px",
      }}
    >
      <PinnedAdHeader
        pinnedAdBrand={pinnedAd.fb_ad.brand}
        onDeleteClicked={handleDeleteClicked}
        onSelect={onSelect}
        onMove={onMove}
      />
      {loading && <CircularProgress />}
      {!loading && (
        <Box>
          {pinnedAd.fb_ad.ad_content && (
            <Typography
              onClick={onSelect}
              sx={{
                cursor: "pointer",
                mt: "10px",
                fontSize: "12px",
                textOverflow: "ellipsis",
                lineHeight: "18px",
                overflow: "hidden",
                height: "54px",
              }}
            >
              {truncateText(pinnedAd.fb_ad.ad_content, 99)}
            </Typography>
          )}
          <PinnedAdBody pinnedAd={pinnedAd} hideCTA />
          <PinnedAdFooter pinnedAd={pinnedAd} onSelect={onSelect} />
        </Box>
      )}
    </Box>
  )
}
