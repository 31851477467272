export const BrandsIcon = ({
  width,
  height,
  color,
}: {
  width?: string
  height?: string
  color?: string
}) => {
  return (
    <svg
      width={width || 16}
      height={height || 14}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 1.33333H7.80469L6.56901 0.0976562C6.50651 0.0351562 6.42188 0 6.33333 0H1.33333C0.597982 0 0 0.597982 0 1.33333V3V11.3333C0 12.0687 0.597982 12.6667 1.33333 12.6667H2.66667V13C2.66667 13.1842 2.81576 13.3333 3 13.3333H5C5.18425 13.3333 5.33333 13.1842 5.33333 13V12.6667H10.6667V13C10.6667 13.1842 10.8158 13.3333 11 13.3333H13C13.1842 13.3333 13.3333 13.1842 13.3333 13V12.6667H14.6667C15.402 12.6667 16 12.0687 16 11.3333V2.66667C16 1.93132 15.402 1.33333 14.6667 1.33333ZM0.666667 1.33333C0.666667 0.96582 0.96582 0.666667 1.33333 0.666667H6.19531L7.19531 1.66667L6.19531 2.66667H0.666667V1.33333ZM12.6667 12.6667H11.3333V9C11.3333 8.81576 11.1842 8.66667 11 8.66667H5C4.81576 8.66667 4.66667 8.81576 4.66667 9V12.6667H3.33333V8C3.33333 7.63249 3.63249 7.33333 4 7.33333H12C12.3675 7.33333 12.6667 7.63249 12.6667 8V12.6667Z"
        fill={color}
      />
    </svg>
  )
}
