// @mui
import LanguageIcon from "@mui/icons-material/Language"
import { IconButton, TableCell } from "@mui/material"
import { styled } from "@mui/system"

import ReactCountryFlag from "react-country-flag"

import { openLinkInNewTab } from "../../../utils"
import { colors } from "../../../utils/theme"

type Props = {
  flag: string
  link: string
}

const FlagWrapper = styled("span")({
  width: "40px",
  height: "30px",
  borderRadius: "5px",
  background: colors.Primary100,
  display: "inline-block",
  textAlign: "center",
  marginTop: "10px",
  marginBottom: "10px",
  verticalAlign: "top",
  boxShadow: "none",
  "& img": {
    width: "21px !important",
    height: "13px !important",
    marginTop: "8px",
  }
})

export default function CountryLinkTableCell({ flag, link }: Props) {
  function handleBrandURLIconButtonClicked() {
    openLinkInNewTab(link)
  }
  return (
    <TableCell>
      <FlagWrapper>
        <ReactCountryFlag countryCode={flag} svg />
      </FlagWrapper>
      <IconButton
        sx={{
          padding: 0,
          height: "30px",
          width: "40px",
          background: colors.Primary100,
          borderRadius: "5px",
          marginTop: "10px",
          marginLeft: "10px",
          ":hover": {
            background: "rgba(59, 78, 223, 0.1)"
          },
          "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
            fill: "#8792a2",
          },
        }}
        onClick={handleBrandURLIconButtonClicked}
      >
        <LanguageIcon />
      </IconButton>
    </TableCell >
  )
}
