import React from "react"
import Chart from "react-apexcharts"
import { intToString } from "./actions"
import { ApexChartData, ChartType, CurveType, XaxisType } from "./types"

type Props = {
  chartSeries: ApexChartData
  type: ChartType
}

export default function ApexChart({ chartSeries, type }: Props): React.ReactElement {
  const chartOptions = {
    dataLabels: {
      enabled: type === "line" ? true : false,
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45
        }
      }
    },
    colors: ["#3b4edf"],
    grid: {
      show: true
    },
    stroke: {
      curve: type === "area" ? "smooth" as CurveType : "straight"
    },
    xaxis: {
      type: "datetime" as XaxisType,
      lines: {
        show: true,
      }
    },
    yaxis: {
      labels: {
        formatter: (value: number) => intToString(value).toString()
      }
    },
    chart: {
      id: "area",
      toolbar: {
        show: false
      },
      tooltip: {
        enabled: false,
        fixed: {
          enabled: true,
          position: "topLeft",
          offsetX: "-50px",
          offsetY: 0,
        },
      }
    }
  }
  const seriesData = [
    {
      name: chartSeries ? chartSeries.name : "",
      data: chartSeries ? chartSeries.series : []
    }
  ]

  return (
    <Chart
      options={chartOptions}
      series={seriesData}
      type={type}
      width="100%"
    />
  )
}
