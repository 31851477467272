import React from "react"

import SubscriptionPlans from "../SubscriptionPlans/plans"
import Modal from "../../../components/Modal"

type Props = {
  open: boolean
}

const SubscriptionMessage = (props: Props): React.ReactElement => {

  return (
    <Modal aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      maxWidth="lg"
      sx={{
        overflowX: "auto",
        overflowY: "auto",
      }}

    >
      <SubscriptionPlans isWarningPopup />
    </Modal>
  )
}
export default SubscriptionMessage