import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import axios from "axios"
import React from "react"
import { useSelector } from "react-redux"

import { RootStore } from "../../../Redux/store"
import { getAbsoluteURL } from "../../../utils"
import { SerpstatAds } from "../common"
import { getToken } from "../../../utils/localStorage"
//-------------------------------------------------------------------------------------------------

type Props = {
  productAdsOnly?: boolean
  productId?: number
}

export default function GoogleAds(props: Props): React.ReactElement {
  const serpstatAds: SerpstatAds[] = useSelector(
    (state: RootStore) => state.PageReduser["serpstatAds"]
  )

  const [openedAd, setOpenedAd] = React.useState<boolean>(false)
  const [openedEl, setOpenedEl] = React.useState<number | null>(null)
  const [CurrentlyShowingAds, setCurrentlyShowingAds] = React.useState<
    SerpstatAds[]
  >([])

  const token = getToken()

  function handleAdOpen(key: number) {
    setOpenedAd(!openedAd)
    setOpenedEl(key)
  }

  function stringTruncate(str: string) {
    if (str.length > 110) {
      return str.slice(0, 110) + "..."
    } else {
      return str
    }
  }

  const getProductFbGoogleAds = () => {
    axios
      .get(
        getAbsoluteURL(
          `/api/report/product-fb-google-ads/${props.productId as number}/`
        ),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token as string}`,
          },
        }
      )
      .then((response) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const googleAds = response.data[response.data.length - 1]
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setCurrentlyShowingAds(googleAds.google_ads)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  React.useEffect(() => {
    if (props.productAdsOnly) {
      getProductFbGoogleAds()
    } else {
      setCurrentlyShowingAds(serpstatAds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ads =
    CurrentlyShowingAds.length > 0 &&
    CurrentlyShowingAds.map((ad, index) => {
      const detailTable = (
        <div className="keywordTab">
          <Typography variant="h5">
            Keywords that trigger this ad to show
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Keywords</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Volume</TableCell>
                  <TableCell>CPC ($)</TableCell>
                  <TableCell>PPC competition, %</TableCell>
                  <TableCell>Results</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{ad.keyword}</TableCell>
                  <TableCell>{ad.position}</TableCell>
                  <TableCell>{ad.region_queries_count}</TableCell>
                  <TableCell>{ad.cost}</TableCell>
                  <TableCell>{ad.concurrency}</TableCell>
                  <TableCell>{ad.found_results}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )
      return (
        <div className="SerAds" key={index}>
          <div>
            <React.Fragment key={index}>
              <Typography variant="h4">
                <a href={ad.url} target="_blank">
                  {ad.title}
                </a>
              </Typography>
              <a href={ad.url} target="_blank">
                {ad.url}
              </a>
              <Typography variant="body2">{stringTruncate(ad.text)}</Typography>
              <Button
                onClick={() => handleAdOpen(index)}
                color={openedEl === index && openedAd ? "primary" : "inherit"}
                endIcon={
                  openedEl === index && openedAd ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
              >
                Keywords
              </Button>
            </React.Fragment>
          </div>
          {openedEl === index && openedAd ? detailTable : ""}
        </div>
      )
    })

  return (
    <Grid container className="GoogleAds">
      {CurrentlyShowingAds.length > 0 ? (
        <Grid
          item
          xs={12}
          alignItems="center"
          justifyContent="space-between"
          className="SerAdsHead"
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              fontSize: "28px",
              width: "100%",
              paddingTop: "0px",
              marginTop: "0px",
              "& span": {
                color: (theme) => theme.palette.text.secondary,
              },
              "& svg": {
                top: "2px",
                marginLeft: "3px",
                position: "relative",
                cursor: "pointer",
              },
            }}
          >
            Google Ad examples
          </Typography>
          <Grid item xs={12}>
            {ads}
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  )
}
