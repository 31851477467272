import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import InfoIcon from "@mui/icons-material/Info"
import Tooltip from "@mui/material/Tooltip"
import React, { useRef, useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { RootStore } from "../../../Redux/store"
import { BrandItem } from "../common"
import { PaidAdsResponse, fetchPaidAds, triggerWappalyzer } from "../../../api/paidAds"
import { checkIsSuperUser } from "../../../utils/localStorage"

export default function PaidAds(): React.ReactElement {
  const brandItem: BrandItem = useSelector(
    (state: RootStore) => state.PageReduser["brandItem"]
  )
  const platforms: string[] = [
    "Facebook",
    "Google",
    "Tiktok",
    "Pinterest",
    "Snapchat",
    "Reddit",
    "Native",
  ]

  const isSuperUser = checkIsSuperUser()

  const [paidAds, setPaidAds] = useState<PaidAdsResponse>()
  const [wappalyzerLookUpCounter, setWappalyzerLookUpCounter] = useState(0)

  useEffect(() => {
    const loadPaidAds = async () => {
      const response = await fetchPaidAds(brandItem.brand_id)
      if (response) {
        setPaidAds(response)
      }
    }

    loadPaidAds()
  }, [brandItem.brand_id, wappalyzerLookUpCounter])

  function getPixelSetup(index: number) {
    switch (index) {
      case 0:
        return paidAds?.pixel_setup?.facebook === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 1:
        return paidAds?.pixel_setup?.google === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 2:
        return paidAds?.pixel_setup?.tiktok === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 3:
        return paidAds?.pixel_setup?.pinterest === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 4:
        return paidAds?.pixel_setup?.snapchat === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 5:
        return paidAds?.pixel_setup?.reddit === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 6:
        return paidAds?.pixel_setup?.native === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
    }
  }

  function getRunAdsBefore(index: number) {
    switch (index) {
      case 0:
        return paidAds?.run_ads_before?.facebook === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 1:
        return paidAds?.run_ads_before?.google === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 2:
        return paidAds?.run_ads_before?.tiktok === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 3:
        return paidAds?.run_ads_before?.pinterest === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 4:
        return paidAds?.run_ads_before?.snapchat === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 5:
        return paidAds?.run_ads_before?.reddit === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 6:
        return paidAds?.run_ads_before?.native === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
    }
  }

  function getCurrently_running_ads(index: number) {
    switch (index) {
      case 0:
        return paidAds?.currently_running_ads?.facebook === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 1:
        return paidAds?.currently_running_ads?.google === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 2:
        return paidAds?.currently_running_ads?.tiktok === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 3:
        return paidAds?.currently_running_ads?.pinterest === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 4:
        return paidAds?.currently_running_ads?.snapchat === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 5:
        return paidAds?.currently_running_ads?.reddit === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
      case 6:
        return paidAds?.currently_running_ads?.native === true ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        )
    }
  }

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("brand_id", brandItem.brand_id.toString())

      const response = await triggerWappalyzer(formData)
      if (response) {
        setWappalyzerLookUpCounter(wappalyzerLookUpCounter + 1)
      }
    }
  }
    
  const triggerFileInput = () => {
    fileInputRef.current?.click()
  }

  return (
    <div className="tracker">
      <Typography variant="h6">
        Paid Advertising Profile
        <Tooltip
          title="A combination of all detected Tracking Pixels and known past/current ads"
          arrow
        >
          <InfoIcon color="disabled" />
        </Tooltip>
      </Typography>
      {isSuperUser && (
        <>
          <input
            ref={fileInputRef}
            type="file"
            accept=".json"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          <Button
            color="primary"
            className="triggerBtn"
            onClick={triggerFileInput}
          >
            Upload Wappalyzer File
          </Button>
        </>
      )}
      <TableContainer component={Paper} className="reportTable">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Platform</TableCell>
              <TableCell>Pixel Setup?</TableCell>
              <TableCell>Run ads before?</TableCell>
              <TableCell>Currently running Ads?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {new Array(7).fill(platforms).map((platform: string[], index) => (
              <TableRow key={index}>
                <TableCell>{platform[index]}</TableCell>
                <TableCell>{getPixelSetup(index) || ""}</TableCell>
                <TableCell>{getRunAdsBefore(index) || ""}</TableCell>
                <TableCell>{getCurrently_running_ads(index) || ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
