import { Grid, Paper } from "@mui/material"

import React, { useState } from "react"
import {
  AddBoardInput,
  addNewBoard,
  Board,
} from "../../../api/creativeCenter/boards"
import { PinnedAdsFilters } from "../../../api/creativeCenter/pinnedAds"

import { useLayout } from "../../Hooks/useLayoutContext"
import AddBoardModal from "./AddBoardModal"
import { Filters } from "./Filters"
import { PinnedAdsList } from "./PinnedAdsList"

export default function PinnedAds(): React.ReactElement {
  const { menuIsExpanded } = useLayout()

  const [filters, setFilters] = useState<PinnedAdsFilters>({})
  const [newBoard, setNewBoard] = useState<Board | undefined>()
  const [open, setOpen] = useState(false)

  const handleAddBoard = () => {
    setOpen(!open)
  }

  const handleBoardCreate = async (payload: AddBoardInput) => {
    const result = await addNewBoard(payload)
    setNewBoard(result)
    return result
  }

  return (
    <Paper
      className={menuIsExpanded === true ? "slider v1-trending" : "v1-trending"}
    >
      <Grid
        container
        sx={{
          mb: 2,
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={12}>
          <Filters
            filters={filters}
            setFilters={setFilters}
            board={newBoard}
            handleAddBoard={handleAddBoard}
          />
        </Grid>
      </Grid>
      <PinnedAdsList filters={filters} />
      <AddBoardModal
        open={open}
        handleClose={handleAddBoard}
        onSubmit={handleBoardCreate}
      />
    </Paper>
  )
}
