import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { styled } from "@mui/system"
import React from "react"

import { SerpstatKeywords } from "../common"

type SerpStatCardProps = {
  selectedKeywordIndex: number
  serpstatKeywords: SerpstatKeywords[]
}

type RowProps = {
  selected: boolean
}

const Row = styled(TableRow)((props: RowProps) => ({
  backgroundColor: props.selected ? "#ccccff !important" : "",
}))

export default function SerpStatCard({
  selectedKeywordIndex,
  serpstatKeywords,
}: SerpStatCardProps): React.ReactElement {
  const tableContents =
    serpstatKeywords &&
    serpstatKeywords.map((keywords, index) => (
      <Row key={index} selected={index === selectedKeywordIndex}>
        <TableCell>{keywords.keyword}</TableCell>
        <TableCell>
          {keywords.difficulty && keywords.difficulty.toFixed()}
        </TableCell>
        <TableCell>{keywords.position}</TableCell>
        <TableCell>{keywords.traff.toLocaleString()}</TableCell>
        <TableCell>{keywords.cost && keywords.cost.toPrecision(2)}</TableCell>
        <TableCell>{keywords.found_results.toLocaleString()}</TableCell>
        <TableCell>
          <Link
            target="_blank"
            referrerPolicy="no-referrer"
            href={keywords.url}
          >
            {keywords.url}
          </Link>
        </TableCell>
      </Row>
    ))

  return (
    <div className="tracker keywordTable">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Keywords</TableCell>
              <TableCell>KW.difficulty</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Traffic</TableCell>
              <TableCell>CPC($)</TableCell>
              <TableCell>Results</TableCell>
              <TableCell>URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableContents}</TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
