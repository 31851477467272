import { Tooltip } from "@mui/material"
import { ReactElement } from "react"

type ValueLabelProps = {
  children: ReactElement
  value: number
  open: boolean
}

export default function ValueLabel({ children, value }: ValueLabelProps) {
  return (
    <Tooltip
      open={true}
      title={value}
      placement="top"
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltipPlacementTop": {
            top: "36px !important",
            backgroundColor: "transparent",
            fontSize: "0.9em",
          },
        }
      }}
    >
      {children}
    </Tooltip>
  )
}