import FacebookIcon from "@mui/icons-material/Facebook"
import InfoIcon from "@mui/icons-material/Info"
import Instagram from "@mui/icons-material/Instagram"
import LanguageIcon from "@mui/icons-material/Language"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import StarsIcon from "@mui/icons-material/Stars"
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material"
import React from "react"

import { getBrandUrl, truncateText } from "../../../utils/textHelpers"
import { openLinkInNewTab } from "../../../utils"

interface V1Score {
  score: number
  color: string
}

interface Brand {
  address: string
  brand_id: number
  brandsite: string
  category_name: string
  coverimage: string
  description: string
  fburl: string
  instagramurl: string
  profileimage: string
  shopifysite: string | null
  timestamp: string
  trustpilotsite: string
  brandname: string
  latest_fbfollowers: number
  fblikes?: number
  igfollowers: number
  latest_numberfbads: number
  latest_numberreviews: number
  scaling_score_ranking: number
  revenue_score_ranking: number
  total_reviews_count: number
}

interface Props {
  indexNumber: number
  brand: Brand
}

const BrandCard: React.FC<Props> = (props) => {
  const { brand } = props

  const helpText = (name: string) => {
    if (name === "Scaling Score") {
      return "The Scaling Score tracks brand growth based on their Social channels month over month. Each brand receives a score between 1-100 in comparison with other tracked brands"
    } else {
      return "The Revenue Score uses Trend Rocket’s bespoke algorithm taking in consideration Traffic, Product metrics, Industry conversion data. Each brand receives a score between 1-100 in comparison with other tracked brands"
    }
  }

  function getScore(score: number): V1Score {
    let color: "#069D6D" | "#FBA04B" = "#069D6D"
    if (score >= 70) {
      color = "#069D6D"
    } else {
      color = "#FBA04B"
    }
    return { score, color }
  }

  const totalFollowersCount = () => {
    const { fblikes, igfollowers } = brand
    return (fblikes || 0) + (igfollowers || 0)
  }

  return (
    <Grid
      item
      xs={12}
      md={4}
    >
      <Paper
        key={props.indexNumber}
        sx={(theme) => ({
          backgroundColor: "#FBFDFE",
          padding: theme.spacing(2),
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "8px",
          minHeight: "540px",
          border: "1px solid #E3E8EE",
          boxShadow: "0 2px 5px 0 rgba(60,66,87,0.08)",
          boxSizing: "border-box",
          "& .MuiAvatar-root": {
            width: "72px",
            height: "72px",
            border: "1px solid #ccc",
          },
          "& .MuiTypography-body1": {
            color: theme.palette.text.secondary,
          },
          "& > *:not(:last-child)": {
            marginBottom: theme.spacing(1),
          },
        })}
      >
        <Avatar
          style={{ cursor: "pointer" }}
          onClick={() => openLinkInNewTab(getBrandUrl(brand.brandname, brand.brand_id))}
          src={brand.profileimage}
          variant="circular"
        />
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "24px",
            lineHeight: "36px",
            fontFamily: "Poppins, sans-serif !important",
            mt: "10px",
            cursor: "pointer",
          }}
          variant="h5"
          onClick={() => openLinkInNewTab(getBrandUrl(brand.brandname, brand.brand_id))}
        >
          {brand.brandname}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            "& > *": {
              marginRight: (theme) => theme.spacing(1),
              borderRadius: (theme) => theme.spacing(1),
            },
          }}
        >
          {brand.category_name === null
            ? ""
            : brand.category_name
              .split(",")
              .map((data, index) => <Chip label={data} key={index} />)}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            "& > *": {
              marginRight: (theme) => theme.spacing(1),
              borderRadius: (theme) => theme.spacing(1),
            },
          }}
        >
          {brand.description == null ? "" : truncateText(brand.description, 99)}
        </Box>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          {`${totalFollowersCount().toLocaleString()} followers`} &bull;
          {brand.total_reviews_count
            ? `${brand.total_reviews_count.toLocaleString()} Total reviews`
            : ""}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            "& > *": {
              marginRight: (theme) => theme.spacing(1),
              borderRadius: (theme) => theme.spacing(1),
            },
          }}
        >
          {brand.scaling_score_ranking ? (
            <Box
              sx={(theme) => ({
                textAlign: "center",
                marginRight: theme.spacing(2),
                marginLeft: theme.spacing(2),
                "& h6": {
                  color: theme.palette.text.secondary,
                  textTransform: "uppercase",
                },
              })}
            >
              <div className="svg-item">
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 40 40"
                  className="donut"
                >
                  <circle
                    className="donut-hole"
                    cx="20"
                    cy="20"
                    r="15.91549430918954"
                    fill="#fff"
                  ></circle>
                  <circle
                    className="donut-ring"
                    cx="20"
                    cy="20"
                    r="15.91549430918954"
                    fill="transparent"
                    stroke={`${getScore(brand.scaling_score_ranking).color}`}
                    strokeWidth="8"
                  ></circle>
                  <circle
                    className="donut-segment donut-segment-3"
                    stroke={`${getScore(brand.scaling_score_ranking).color}`}
                    cx="20"
                    cy="20"
                    r="15.91549430918954"
                    fill="transparent"
                    strokeWidth="8"
                    strokeDasharray={`${getScore(brand.scaling_score_ranking).score
                      } 100`}
                    strokeDashoffset="0"
                  ></circle>
                  <g
                    className="donut-text donut-text-2"
                    fill={`${getScore(brand.scaling_score_ranking).color}`}
                  >
                    <text y="50%" transform="translate(0, 4)">
                      <tspan
                        x="50%"
                        textAnchor="middle"
                        className="donut-percent"
                      >{`${getScore(brand.scaling_score_ranking).score
                        }`}</tspan>
                    </text>
                    <text y="60%" transform="translate(0, 2)"></text>
                  </g>
                </svg>
              </div>
              <Typography variant="subtitle1">
                scaling score
                <Tooltip title={helpText("Scaling Score")} arrow>
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Typography>
            </Box>
          ) : (
            ""
          )}
          {brand.revenue_score_ranking ? (
            <Box
              sx={(theme) => ({
                textAlign: "center",
                marginRight: theme.spacing(2),
                marginLeft: theme.spacing(2),
                "& h6": {
                  color: theme.palette.text.secondary,
                  textTransform: "uppercase",
                },
              })}
            >
              <div className="svg-item">
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 40 40"
                  className="donut"
                >
                  <circle
                    className="donut-hole"
                    cx="20"
                    cy="20"
                    r="15.91549430918954"
                    fill="#fff"
                  ></circle>
                  <circle
                    className="donut-ring"
                    cx="20"
                    cy="20"
                    r="15.91549430918954"
                    fill="transparent"
                    stroke={`${getScore(brand.revenue_score_ranking).color}`}
                    strokeWidth="8"
                  ></circle>
                  <circle
                    className="donut-segment donut-segment-3"
                    stroke={`${getScore(brand.revenue_score_ranking).color}`}
                    cx="20"
                    cy="20"
                    r="15.91549430918954"
                    fill="transparent"
                    strokeWidth="8"
                    strokeDasharray={`${getScore(brand.revenue_score_ranking).score
                      } 100`}
                    strokeDashoffset="0"
                  ></circle>
                  <g
                    className="donut-text donut-text-2"
                    fill={`${getScore(brand.revenue_score_ranking).color}`}
                  >
                    <text y="50%" transform="translate(0, 4)">
                      <tspan
                        x="50%"
                        textAnchor="middle"
                        className="donut-percent"
                      >{`${getScore(brand.revenue_score_ranking).score
                        }`}</tspan>
                    </text>
                    <text y="60%" transform="translate(0, 2)"></text>
                  </g>
                </svg>
              </div>
              <Typography variant="subtitle1">
                revenue score
                <Tooltip title={helpText("Revenue Score")} arrow>
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Typography>
              <br />
            </Box>
          ) : (
            ""
          )}
        </Box>
        <Button color="primary" onClick={() => openLinkInNewTab(getBrandUrl(brand.brandname, brand.brand_id))}>
          Learn more
        </Button>
        <div className="boxFooter">
          <IconButton size="small">
            {brand.brandsite === null ? (
              ""
            ) : (
              <a href={brand.brandsite} target="blank">
                <LanguageIcon />
              </a>
            )}
          </IconButton>
          <IconButton size="small">
            {brand.fburl === null ? (
              ""
            ) : (
              <a href={brand.fburl} target="blank">
                <FacebookIcon />
              </a>
            )}
          </IconButton>
          <IconButton size="small">
            {brand.shopifysite === null ? (
              ""
            ) : (
              <a href={brand.shopifysite} target="blank">
                <ShoppingCartIcon />
              </a>
            )}
          </IconButton>
          <IconButton size="small">
            {brand.instagramurl === null ? (
              ""
            ) : (
              <a href={brand.instagramurl} target="blank">
                <Instagram />
              </a>
            )}
          </IconButton>
          <IconButton size="small">
            {brand.trustpilotsite === null ? (
              ""
            ) : (
              <a href={brand.trustpilotsite} target="blank">
                <StarsIcon />
              </a>
            )}
          </IconButton>
        </div>
      </Paper>
    </Grid>
  )
}

export default BrandCard
