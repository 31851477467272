// @mui

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton"
import { Button } from "@mui/material"
import { Box, Stack } from "@mui/system"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import {
  PinnedAd,
  getShareableLink,
} from "../../../../api/creativeCenter/pinnedAds"
import Iconify from "../../../../components/Iconify"
import Modal from "../../../../components/Modal"
import PinnedAdContent from "./PinnedAdContent"

// ----------------------------------------------------------------------

type PinnedAdDetailModalProps = {
  handleClose: VoidFunction
  onNext: VoidFunction
  onPrevious: VoidFunction
  pinnedAd?: PinnedAd
}

export default function PinnedAdDetailModal({
  handleClose,
  onNext,
  onPrevious,
  pinnedAd,
}: PinnedAdDetailModalProps) {
  const { enqueueSnackbar } = useSnackbar()
  const [focusedAssetUrl, setFocusedAssetUrl] = useState<
    string | undefined | null
  >()
  const [generatingShareLink, setGeneratingShareLink] = useState(false)
  const [shareLink, setShareLink] = useState<string>()
  const [focusedCtaUrl, setFocusedUrl] = useState<string | undefined | null>()
  const [currentCardIndex, setCurrentCardIndex] = useState(0)

  useEffect(() => {
    setShareLink(undefined)
  }, [pinnedAd])

  useEffect(() => {
    if (!pinnedAd) {
      return
    }

    const hasCards = pinnedAd.fb_ad.cards?.length > 0

    const getAssetUrl = () => {
      const getImageUrl = (urlString?: string | null) => {
        return urlString && urlString !== "N/A" ? urlString : undefined
      }

      const fbImageUrlString = hasCards
        ? pinnedAd.fb_ad.cards[currentCardIndex].image
        : pinnedAd.fb_ad.image
      const imageUrlString = getImageUrl(fbImageUrlString)
      if (imageUrlString) {
        return imageUrlString
      }
      const fbVideoUrlString = hasCards
        ? pinnedAd.fb_ad.cards[currentCardIndex].video_url
        : pinnedAd.fb_ad.video_url
      return fbVideoUrlString
    }

    const getCtaUrl = () => {
      return hasCards
        ? pinnedAd.fb_ad.cards[currentCardIndex].link_url
        : pinnedAd.fb_ad.link_url
    }

    setFocusedUrl(getCtaUrl())
    setFocusedAssetUrl(getAssetUrl())
  }, [pinnedAd, currentCardIndex])

  if (!pinnedAd) {
    return null
  }

  const shareLinkClicked = async () => {
    if (shareLink) {
      navigator.clipboard.writeText(shareLink)
      enqueueSnackbar("Link copied to clipboard")
    }
    setGeneratingShareLink(true)
    const result = await getShareableLink(pinnedAd.id)
    setShareLink(result?.link)
    navigator.clipboard.writeText(result?.link || "")
    setGeneratingShareLink(false)
    enqueueSnackbar("Link copied to clipboard")
  }

  return (
    <Modal
      open={pinnedAd !== undefined}
      handleClose={handleClose}
      maxWidth="lg"
      hideHeader
    >
      <>
        <PinnedAdContent
          pinnedAd={pinnedAd}
          handleClose={handleClose}
          focusedCtaUrl={focusedCtaUrl}
          focusedIndexChanged={setCurrentCardIndex}
        />
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" spacing={2}>
            {focusedAssetUrl && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={
                  <Iconify icon={"material-symbols:download-rounded"} />
                }
                onClick={() => {
                  window.open(focusedAssetUrl, "_blank")
                }}
              >
                Download Asset
              </Button>
            )}
            <LoadingButton
              loading={generatingShareLink}
              variant="contained"
              color="secondary"
              onClick={shareLinkClicked}
              startIcon={<Iconify icon={"ph:link-simple-bold"} />}
            >
              {shareLink ? "Link copied to clipboard" : "Get shareable link"}
            </LoadingButton>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button onClick={onPrevious} variant="contained" color="secondary">
              {"<"}
            </Button>
            <Button onClick={onNext} variant="contained" color="secondary">
              {">"}
            </Button>
          </Stack>
        </Box>
      </>
    </Modal>
  )
}
