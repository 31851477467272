import { useEffect, useState } from "react"

import PlanCard from "./planCard"
import {
  Heading,
  PlanContainer,
  PlansWrapper,
  SubHeading,
  WarningHeader,
} from "./planStyles"
import LoadingOverlay from "../LoadOverlay/LoadOverlay"
import {
  fetchPlanDetails,
  fetchSubscriptionStatus,
  PlanDetails,
  SubscriptionStatus,
} from "../../../api/plans"
//--------------------------------------------------------------------------------

enum ButtonType {
  choose = "Choose",
  upgrade = "Upgrade"
}

type Props = {
  isWarningPopup?: boolean
}

export default function SubscriptionPlans({ isWarningPopup }: Props) {
  const [loading, setLoading] = useState<boolean>(false)
  const [plans, setPlans] = useState<PlanDetails[]>([])
  const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus>()

  useEffect(() => {
    const loadPlans = async () => {
      setLoading(true)
      const response = await fetchPlanDetails()
      setLoading(false)
      if (response) {
        setPlans(response.reverse())
      }
    }

    loadPlans()
  }, [])

  useEffect(() => {
    const loadSubscriptionStatus = async () => {
      setLoading(true)
      const response = await fetchSubscriptionStatus()
      setLoading(false)
      if (response) {
        setSubscriptionStatus(response)
      }
    }

    if (!isWarningPopup) {
      loadSubscriptionStatus()
    }
  }, [isWarningPopup])

  const renderHeader = () => (
    isWarningPopup ? (
      <div>
        <WarningHeader>
          You need an Active Subscription plan to use TrendRocket.
        </WarningHeader>
        <SubHeading>Choose a plan to continue.</SubHeading>
      </div>
    ) : (
      <div>
        <Heading>Change and update plan</Heading>
        <SubHeading>We have a plan to suit your needs.</SubHeading>
      </div>
    )
  )

  return (
    <PlanContainer>
      <LoadingOverlay loading={loading} />
      {renderHeader()}
      <PlansWrapper>
        {plans.map((plan, index) => plan.prices.length > 0 && (
          <PlanCard
            buttonType={isWarningPopup ? ButtonType.choose : ButtonType.upgrade}
            plan={plan}
            key={index}
            currentPlan={subscriptionStatus?.current_subscription?.product?.id as string}
          />
        ))}
      </PlansWrapper>
    </PlanContainer>
  )
}
