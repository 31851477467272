// context/userContext.tsx
import * as React from "react"
import { useLocalStorage } from "../V1/Hooks/useLocalStorage"
import { User } from "../V1/brand/common"

export interface IAuthContext {
  user: User | null
  setUser: (value: User | null) => void
}

export const AuthContext = React.createContext<IAuthContext | undefined>(
  undefined
)

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useLocalStorage<User | null>("user", null)

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}
