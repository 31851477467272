import {
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import {
  FeaturedBrandResult,
  fetchTrendingBrands,
} from "../../../api/trendingBrands"
import BrandCard from "../../Components/BrandCard/BrandCard"
import LoadingOverlay from "../../Components/LoadOverlay/LoadOverlay"
import usePagination from "../../Hooks/usePagination"
import Pagination from "../../Components/Pagination/pagination"
import { useLayout } from "../../Hooks/useLayoutContext"

export default function V1TrendingPage(): React.ReactElement {
  const {
    currentPage,
    pageSize,
    resultCount,
    setResultCount,
    setCurrentPage,
    setPageSize,
  } = usePagination()
  const { menuIsExpanded } = useLayout()
  const [brands, setBrands] = useState<FeaturedBrandResult | undefined>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const loadTrendingBrands = async () => {
      setLoading(true)
      const response = await fetchTrendingBrands(currentPage, pageSize)
      if (response) {
        setBrands(response)
        setResultCount(response.count)
      }
      setLoading(false)
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })

    loadTrendingBrands()
  }, [currentPage, pageSize, setResultCount])

  return (
    <Paper
      className={menuIsExpanded === true ? "slider v1-trending" : "v1-trending"}
    >
      <LoadingOverlay loading={loading} />
      <div className="searchHead">
        <Typography variant="h4">Trending Page</Typography>
        <Divider />
        <Typography variant="h5">Featured Brands</Typography>
      </div>
      <Typography variant="subtitle2">
        Brands that are rising quickly, with significant momentum or featuring a
        Trend Viability Report.
      </Typography>
      <Grid container spacing={2} className="v1-trendingItems">
        {brands &&
          brands.results.map((brand, index) => (
            <BrandCard key={index} indexNumber={index} brand={brand} />
          ))}
        {brands && brands.results.length > 0 && (
          <Pagination
            currentPage={currentPage}
            resultCount={resultCount}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onPageSizeChange={setPageSize}
            label={"Brands"}
          />
        )}
        <div className="trendingBtn">
          <Link to="/search-brand">View All</Link>
        </div>
      </Grid>
    </Paper>
  )
}
