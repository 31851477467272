import React, { useState } from "react"
import { Box, TextField } from "@mui/material"

import { AdditionalFilterRangeTypes } from "./types"


interface FilterInputProps {
  primaryLabel: string
  values: AdditionalFilterRangeTypes
  onInputChange(newValue: number[], name: string): void
}

export default function FilterInput({ primaryLabel, values, onInputChange }: FilterInputProps): React.ReactElement {
  const [trustpilotReviews, setTrustpilotReviews] = useState(values.latest_numberreviews__range)
  const [trustpilotDays, setTrustpilotDays] = useState(values.trustpilotreviews_diff__range)
  const [fbReviews, setFbReviews] = useState(values.latest_fbreviews__range)
  const [fbReviewsDays, setFbReviewsDays] = useState(values.fbreviews_diff__range)
  const [shopifyReviews, setShopifyReviews] = useState(values.latest_shopifyreviews_count__range)
  const [shopifyDays, setShopifyDays] = useState(values.shopifyreview_count_diff__range)
  const [reditMentions, setReditMentions] = useState(values.latest_reditmentions__range)
  const [fbLikes, setFbLikes] = useState(values.latest_fblikes__range)
  const [fbLikesGrowth, setFbLikesGrowth] = useState(values.fblikes_diff__range)
  const [iGFollowers, setIgFollowers] = useState(values.latest_instafollowers__range)
  const [iGFollowersGrowth, setIgFollowersGrowth] = useState(values.instafollowers_diff__range)
  const [fbAdsCount, setFbAdsCount] = useState(values.fbads_count__range)
  const [fbAdsGrowthDays, setFbAdsGrowthDays] = useState(values.fbads_growth__range)
  const [activeAds, setActiveAds] = useState(values.active_fbads_count__range)
  const [adsRunningDays, setAdsRunningDays] = useState(values.ads_running_over)
  const [monthlyTraffic, setMonthlyTraffic] = useState(values.estimated_monthly_traffic__range)
  const [monthlyOrders, setMonthlyOrders] = useState(values.estimated_monthly_orders__range)
  const [monthlyRevenue, setMonthlyRevenue] = useState(values.estimated_monthly_revenue__range)
  const [nofProducts, setNofProducts] = useState(values.product_data_count__range)
  const [priceMin, setPriceMin] = useState(values.product_data_min_price__range)
  const [priceAvg, setPriceAvg] = useState(values.product_data_avg_price__range)
  const [priceMax, setPriceMax] = useState(values.product_data_max_price__range)
  const [newProducts, setNewProducts] = useState(values.product_data_diff__range)

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string, label?: "Max" | "Min") {
    switch (name) {
      case "latest_numberreviews__range": {
        const values = trustpilotReviews
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setTrustpilotReviews(values)
        onInputChange(values, name)
      }
        break
      case "trustpilotreviews_diff__range": {
        const values = trustpilotDays
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setTrustpilotDays(values)
        onInputChange(values, name)
      }
        break
      case "latest_fbreviews__range": {
        const values = fbReviews
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setFbReviews(values)
        onInputChange(values, name)
      }
        break
      case "latest_reditmentions__range": {
        const values = reditMentions
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setReditMentions(values)
        onInputChange(values, name)
      }
        break
      case "fbreviews_diff__range": {
        const values = fbReviewsDays
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setFbReviewsDays(values)
        onInputChange(values, name)
      }
        break
      case "latest_shopifyreviews_count__range": {
        const values = shopifyReviews
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setShopifyReviews(values)
        onInputChange(values, name)
      }
        break
      case "shopifyreview_count_diff__range": {
        const values = shopifyDays
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setShopifyDays(values)
        onInputChange(values, name)
      }
        break
      case "latest_fblikes__range": {
        const values = fbLikes
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setFbLikes(values)
        onInputChange(values, name)
      }
        break
      case "fblikes_diff__range": {
        const values = fbLikesGrowth
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setFbLikesGrowth(values)
        onInputChange(values, name)
      }
        break
      case "latest_instafollowers__range": {
        const values = iGFollowers
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setIgFollowers(values)
        onInputChange(values, name)
      }
        break
      case "instafollowers_diff__range": {
        const values = iGFollowersGrowth
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setIgFollowersGrowth(values)
        onInputChange(values, name)
      }
        break
      case "fbads_count__range": {
        const values = fbAdsCount
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setFbAdsCount(values)
        onInputChange(values, name)
      }
        break
      case "fbads_growth__range": {
        const values = fbAdsGrowthDays
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setFbAdsGrowthDays(values)
        onInputChange(values, name)
      }
        break
      case "active_fbads_count__range": {
        const values = activeAds
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setActiveAds(values)
        onInputChange(values, name)
      }
        break
      case "is_fb_ad_active": {
        const values = adsRunningDays
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setAdsRunningDays(values)
        onInputChange(values, "latest_numberfbads__range")
      }
        break
      case "estimated_monthly_traffic__range": {
        const values = monthlyTraffic
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setMonthlyTraffic(values)
        onInputChange(values, name)
      }
        break
      case "estimated_monthly_orders__range": {
        const values = monthlyOrders
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setMonthlyOrders(values)
        onInputChange(values, name)
      }
        break
      case "estimated_monthly_revenue__range": {
        const values = monthlyRevenue
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setMonthlyRevenue(values)
        onInputChange(values, name)
      }
        break
      case "product_data_count__range": {
        {
          const values = nofProducts
          label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
          setNofProducts(values)
          onInputChange(values, name)
        }
      }
        break
      case "product_data_min_price__range": {
        const values = priceMin
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setPriceMin(values)
        onInputChange(values, name)
      }
        break
      case "product_data_avg_price__range": {
        const values = priceAvg
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setPriceAvg(values)
        onInputChange(values, name)
      }
        break
      case "product_data_max_price__range": {
        const values = priceMax
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setPriceMax(values)
        onInputChange(values, name)
      }
        break
      case "product_data_diff__range": {
        const values = newProducts
        label === "Max" ? values.splice(1, 1, parseInt(e.target.value)) : values.splice(0, 1, parseInt(e.target.value))
        setNewProducts(values)
        onInputChange(values, name)
      }
        break
    }
  }

  function renderInput() {
    switch (primaryLabel) {
      case "Trustpilot Reviews":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.latest_numberreviews__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "latest_numberreviews__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.latest_numberreviews__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "latest_numberreviews__range", "Max")} defaultValue={values.latest_numberreviews__range[1]} />
          </div>
        )
      case "Trustpilot Reviews 30 Days":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.trustpilotreviews_diff__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "trustpilotreviews_diff__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.trustpilotreviews_diff__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "trustpilotreviews_diff__range", "Max")} defaultValue={values.trustpilotreviews_diff__range[1]} />
          </div>
        )
      case "Facebook Reviews":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.latest_fbreviews__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "latest_fbreviews__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.latest_fbreviews__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "latest_fbreviews__range", "Max")} defaultValue={values.latest_fbreviews__range[1]} />
          </div>
        )
      case "Facebook Reviews 30 Days":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.fbreviews_diff__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "fbreviews_diff__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.fbreviews_diff__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "fbreviews_diff__range", "Max")} defaultValue={values.fbreviews_diff__range[1]} />
          </div>
        )
      case "Shopify Reviews":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.latest_shopifyreviews_count__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "latest_shopifyreviews_count__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.latest_shopifyreviews_count__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "latest_shopifyreviews_count__range", "Max")} defaultValue={values.latest_shopifyreviews_count__range[1]} />
          </div>
        )
      case "Shopify Reviews 30 Days":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.shopifyreview_count_diff__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "shopifyreview_count_diff__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.shopifyreview_count_diff__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "shopifyreview_count_diff__range", "Max")} defaultValue={values.shopifyreview_count_diff__range[1]} />
          </div>
        )
      case "Facebook Likes":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.latest_fblikes__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "latest_fblikes__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.latest_fblikes__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "latest_fblikes__range", "Max")} defaultValue={values.latest_fblikes__range[1]} />
          </div>
        )
      case "Facebook Likes growth":
        return (
          <div className="inputs">
            <TextField type="number" variant="outlined" InputProps={{ inputProps: { max: values.fblikes_diff__range[1], min: 1 } }} name={primaryLabel} label="Min value" onChange={e => handleChange(e, "fblikes_diff__range", "Min")} defaultValue={1} />
            <TextField type="number" variant="outlined" InputProps={{ inputProps: { max: values.fblikes_diff__range[1], min: 1 } }} name={primaryLabel} label="Max value" onChange={e => handleChange(e, "fblikes_diff__range", "Max")} defaultValue={values.fblikes_diff__range[1]} />
          </div>
        )
      case "IG Followers":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.latest_instafollowers__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "latest_instafollowers__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.latest_instafollowers__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "latest_instafollowers__range", "Max")} defaultValue={values.latest_instafollowers__range[1]} />
          </div>
        )
      case "IG Followers Growth":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.instafollowers_diff__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "instafollowers_diff__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.instafollowers_diff__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "instafollowers_diff__range", "Max")} defaultValue={values.instafollowers_diff__range[1]} />
          </div>
        )
      case "Reddit Mentions":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.latest_reditmentions__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "latest_reditmentions__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.latest_reditmentions__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "latest_reditmentions__range", "Max")} defaultValue={values.latest_reditmentions__range[1]} />
          </div>
        )
      case "FB Ads Count":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.fbads_count__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "fbads_count__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.fbads_count__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "fbads_count__range", "Max")} defaultValue={values.fbads_count__range[1]} />
          </div>
        )
      case "FB Ads growth 30 days":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.fbads_growth__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "fbads_growth__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.fbads_growth__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "fbads_growth__range", "Max")} defaultValue={values.fbads_growth__range[1]} />
          </div>
        )
      case "Active ads":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.active_fbads_count__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "active_fbads_count__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.active_fbads_count__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "active_fbads_count__range", "Max")} defaultValue={values.active_fbads_count__range[1]} />
          </div>
        )
      case "Ads running over 30 days":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.ads_running_over[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "is_fb_ad_active", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.ads_running_over[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "is_fb_ad_active", "Max")} defaultValue={values.ads_running_over[1]} />
          </div>
        )
      case "Estimated monthly traffic":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.estimated_monthly_traffic__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "estimated_monthly_traffic__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.estimated_monthly_traffic__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "estimated_monthly_traffic__range", "Max")} defaultValue={values.estimated_monthly_traffic__range[1]} />
          </div>
        )
      case "Estimated monthly orders":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.estimated_monthly_orders__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "estimated_monthly_orders__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.estimated_monthly_orders__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "estimated_monthly_orders__range", "Max")} defaultValue={values.estimated_monthly_orders__range[1]} />
          </div>
        )
      case "Estimated monthly revenue":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.estimated_monthly_revenue__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "estimated_monthly_revenue__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.estimated_monthly_revenue__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "estimated_monthly_revenue__range", "Max")} defaultValue={values.estimated_monthly_revenue__range[1]} />
          </div>
        )
      case "Number of products":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.product_data_count__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "product_data_count__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.product_data_count__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "product_data_count__range", "Max")} defaultValue={values.product_data_count__range[1]} />
          </div>
        )
      case "Product price minimum":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.product_data_min_price__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "product_data_min_price__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.product_data_min_price__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "product_data_min_price__range", "Max")} defaultValue={values.product_data_min_price__range[1]} />
          </div>
        )
      case "Product price average":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.product_data_avg_price__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "product_data_avg_price__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.product_data_avg_price__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "product_data_avg_price__range", "Max")} defaultValue={values.product_data_avg_price__range[1]} />
          </div>
        )
      case "Product price maximum":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.product_data_max_price__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "product_data_max_price__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.product_data_max_price__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "product_data_max_price__range", "Max")} defaultValue={values.product_data_max_price__range[1]} />
          </div>
        )
      case "New products last 30 days":
        return (
          <div className="inputs">
            <TextField type="number" InputProps={{ inputProps: { max: values.product_data_diff__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Min value" onChange={e => handleChange(e, "product_data_diff__range", "Min")} defaultValue={1} />
            <TextField type="number" InputProps={{ inputProps: { max: values.product_data_diff__range[1], min: 1 } }} variant="outlined" name={primaryLabel} label="Max value" onChange={e => handleChange(e, "product_data_diff__range", "Max")} defaultValue={values.product_data_diff__range[1]} />
          </div>
        )
      default:
        return ""
    }
  }
  return (

    <Box
      sx={{
        width: { xs: "91%", xl: "93%" },
        ml: { sm: "40px", md: "0px" }
      }}
      className="addFilter"
    >
      {renderInput()}
    </Box>
  )
}
