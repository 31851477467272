import { fetchApiCall, postApiCall } from "."
import { getAbsoluteURL } from "../utils"

export type BrandsSearch = {
  address: string
  brandsite: string
  brand_id: number
  category_name: string
  coverimage: string
  description: string
  fburl: string
  instagramurl: string
  profileimage: string
  shopifysite: string | null
  timestamp: string
  trustpilotsite: string
  brandname: string
  latest_fbfollowers: number
  igfollowers: number
  latest_numberreviews: number
  latest_numberfbads: number
  scaling_score_ranking: number
  revenue_score_ranking: number
  total_reviews_count: number
}

export type ProductsDetail = {
  code: string
  collection: string
  category: string
  name: string
  variantname: string
  price: number
  instock: string
  url: string
  imageurl: string
  body: string
  brandname: string
  timeupdated: string
  is_featured: boolean
}

export type ProductsSearch = {
  count: number
  next: string | null
  previous: string | null
  results: ProductsDetail[]
}

export const fetchBrandNames = async (searchValue: string | null) => {
  const endpoint = getAbsoluteURL("search/shopify-products/brands/")
  const payload = {
    search_value: searchValue,
  }

  return postApiCall<string[]>(endpoint, payload)
}

export const fetchBrandsResults = async (searchTerm: string) => {
  const endpoint = getAbsoluteURL(`api/brandmatch/${searchTerm}`)

  return fetchApiCall<BrandsSearch[]>(endpoint)
}

export const fetchProductsResults = async (
  searchTerm: string,
  selectedBrand?: string[],
  count = 20,
  pageNumber = 1,
  isBestSellers?: boolean
) => {
  const endpoint = isBestSellers
    ? getAbsoluteURL(
        `search/shopify-products/?page=${pageNumber}&page_size=${count}&best_seller=true`
      )
    : getAbsoluteURL(
        `search/shopify-products/?page=${pageNumber}&page_size=${count}`
      )
  let payload = {}
  if (selectedBrand && selectedBrand.length > 0) {
    payload = { brands: selectedBrand }
  } else {
    payload = { search_value: searchTerm }
  }

  return postApiCall<ProductsSearch>(endpoint, payload)
}
