import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { FormControl, Grid } from "@mui/material"
import React from "react"

import { getFullName, getUserName } from "../../../utils/localStorage"
//-------------------------------------------------------------------------------------------

export default function V1General(): React.ReactElement {
  const username = getUserName()
  const name = getFullName()

  return (
    <div className="general">
      <Grid container>
        <Grid item xs={6}>
          <FormControl>
            <label>First name</label>
            <span>
              <LockOutlinedIcon fontSize="small" />
            </span>
            <input
              readOnly
              name="first-name"
              placeholder={name?.split(" ")[0]}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <label>Last name</label>
            <span>
              <LockOutlinedIcon fontSize="small" />
            </span>
            <input
              readOnly
              name="last-name"
              placeholder={name?.split(" ")[1]}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ maxWidth: "49%" }}>
            <label>Email address</label>
            <span>
              <LockOutlinedIcon fontSize="small" />
            </span>
            <input
              readOnly
              id="email"
              type="email"
              aria-describedby="email-helper-text"
              placeholder={username as string}
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  )
}
