import EditIcon from "@mui/icons-material/Edit"
import InfoIcon from "@mui/icons-material/Info"
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import {
  deleteCompetitor,
  deleteFeatureProductData,
  fetchComparisonReport,
  fetchProductDetail,
  fetchProductMargin,
  fetchSuppliers,
  postFeatureProductData,
} from "../../api/report"
import {
  ComparisonReport,
  Product,
  ShopifyProduct,
  Supplier,
  UpdatedProduct,
} from "../../types/productReportTypes"
import {
  checkIsSuperUser,
  getUserRole,
  UserRoles,
} from "../../utils/localStorage"
import CalculationTable from "../brand/components/CalculationTable"
import LogisticsTable from "../brand/components/LogisticsTable"
import ProductAnalysisTable from "../brand/components/ProductAnalysisTable"
import AddCompetitors from "../brand/components/ProductCompetitors/AddCompetitors"
import AddSupplier from "../brand/components/ProductSuppliers/AddSupplier"
import SupplierOverview from "../brand/components/ProductSuppliers/SupplierOverview"
import CompetitorTable from "../Components/CompetitorTable/Table"
import { colors } from "../../utils/theme"
import { useLayout } from "../Hooks/useLayoutContext"
import EditModal from "./EditModal"

type Params = {
  name: string
  brandId: string
  productId: string
  productName: string
}

const PriceItem = styled("div")({
  borderRadius: "10px",
  height: "77px",
  width: "180px",
  display: "inline-block",
  padding: "24px",
  fontWeight: 500,
  fontSize: "16px",
  fontFamily: "Poppins,sans-serif !important",
  textAlign: "center",
  color: colors.Text,
  verticalAlign: "top",
  "& span": {
    display: "inline-block",
    width: "100%",
    fontWeight: 600,
    fontSize: "30px",
    marginBottom: "10px",
  }
})

export default function ProductDetail(): React.ReactElement {
  const params: Params = useParams()
  const { menuIsExpanded } = useLayout()

  const [isSuperUser] = useState(checkIsSuperUser())
  const [userRole] = useState(getUserRole())
  const [selectedBtnIndex, setSelectedBtnIndex] = useState<number>(0)
  const [supplierAddOpen, setSupplierAddOpen] = useState<boolean>(false)
  const [competitorAddOpen, setCompetitorAddOpen] = useState<boolean>(false)
  const [suppliers, setSuppliers] = useState<Supplier[]>([])
  const [comparisonReports, setComparisonReports] = useState<
    ComparisonReport[]
  >([])
  const [supplier, setSupplier] = useState<Supplier>()
  const [shopifyProduct, setShopifyProduct] = useState<ShopifyProduct>()
  const [chosenProduct, setChosenProduct] = useState<Product>()
  const [editOpen, setEditOpen] = useState(false)

  useEffect(() => {
    const loadComparisonReport = async () => {
      const response = await fetchComparisonReport(params.brandId)
      if (response) {
        setComparisonReports(response)
      }
    }

    loadComparisonReport()
  }, [params.brandId])

  useEffect(() => {
    const loadProductDetail = async () => {
      const response = await fetchProductDetail(params.productId)
      if (response) {
        setChosenProduct(response)
      }
    }

    loadProductDetail()
  }, [params.productId])

  useEffect(() => {
    const loadSuppliers = async () => {
      const response = await fetchSuppliers(params.productId)
      if (response) {
        setSuppliers(response.data.results)
      }
    }

    loadSuppliers()
  }, [params.productId])

  useEffect(() => {
    window.scrollTo(0, 0)
    const loadProductMargin = async () => {
      const response = await fetchProductMargin(params.productId)
      if (response) {
        setShopifyProduct(response)
      }
    }

    loadProductMargin()
  }, [params.productId])

  const useChosenProduct = (data: UpdatedProduct) => {
    if (chosenProduct) {
      setChosenProduct({
        ...chosenProduct,
        ...data,
      })
    }
  }

  const useSuppliers = (newSupplier: Supplier) => {
    setSuppliers([...suppliers, ...[newSupplier]])
  }

  const useComparisonReport = (reports: ComparisonReport[]) => {
    setComparisonReports(reports)
  }

  const updateSuppliers = (updatedSupplier: Supplier) => {
    const currentSuppliers = suppliers
    currentSuppliers.forEach((supplierData, index) => {
      if (supplierData.id === updatedSupplier.id) {
        currentSuppliers[index] = updatedSupplier
      }
    })
    setSuppliers(currentSuppliers)
  }

  const deleteSupplier = () => {
    const currentSuppliers = suppliers
    const newSuppliers = currentSuppliers.filter(
      (productSupplier) => productSupplier.id !== supplier?.id
    )
    setSuppliers(newSuppliers)
    setSelectedBtnIndex(0)
    setSupplier(undefined)
  }

  const handleSupplierBtnSelect = (supplier: Supplier) => {
    setSupplier(supplier)
    setSelectedBtnIndex(supplier.id as number)
  }

  const handleCompetitorDelete = async (id: number) => {
    const response = await deleteCompetitor(id)
    if (response) {
      const reports = comparisonReports
      const newReports = reports.filter((report) => report.id !== id)
      setComparisonReports(newReports)
    }
  }

  const handleFeature = async () => {
    const response = await postFeatureProductData(
      params.brandId,
      params.productId
    )
    if (response) {
      const data = { ...chosenProduct, ...{ is_featured: true } }
      setChosenProduct(data as Product)
    }
  }

  const handleUnfeature = async () => {
    const response = await deleteFeatureProductData(params.productId)
    if (response) {
      const data = { ...chosenProduct, ...{ is_featured: false } }
      setChosenProduct(data as Product)
    }
  }

  return (
    <>
      <Paper
        className={
          menuIsExpanded === true ? "slider v1-trending" : "v1-trending"
        }
      >
        {userRole === UserRoles.ADMIN && (
          <button
            className="featuredPdttBtn btn btn-primary"
            onClick={() =>
              chosenProduct?.is_featured ? handleUnfeature() : handleFeature()
            }
          >
            {chosenProduct?.is_featured
              ? "Featured Product"
              : "Feature this Product"}
          </button>
        )}
        <Box
          sx={{
            width: "95% !important",
            background: colors.White,
            boxShadow: "0 5px 20px 2px rgba(23,3,89,.08)",
            borderRadius: "10px",
            padding: "30px",
            display: "inline-block",
            mb: "30px",
            position: "relative",
          }}
          alignItems="center"
        >
          <Box
            sx={{
              display: "inline-block",
              width: "50%",
              verticalAlign: "top",
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                width: "100%",
                height: "401px",
                background: "rgba(59,78,223,.1)",
                borderRadius: "20px",
                textAlign: "center",
                mb: "20px",
                verticalAlign: "top",
              }}
            >
              <img
                style={{
                  objectPosition: "center center",
                  height: "80%",
                  marginTop: "3vh",
                  objectFit: "contain",
                  width: "90%",
                }}
                src={chosenProduct?.imageurl || "/images/product_placeholder.jpg"}
                alt="product-image"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = "/images/product_placeholder.jpg"
                }}
              />
            </Box>
            <Stack direction={"row"} spacing={2}>
              <PriceItem
                sx={{
                  backgroundColor: "rgba(61,68,101,.1)",
                  "& span": {
                    color: colors.Text
                  }
                }}
              >
                <span>${chosenProduct?.price_usd || chosenProduct?.price || "-"}</span>
                SELLING PRICE
              </PriceItem>
              <PriceItem
                sx={{
                  backgroundColor: "rgba(253,78,93,.1)",
                  "& span": {
                    color: colors.Error
                  }
                }}
              >
                <span>
                  {shopifyProduct?.product_cost
                    ? `$${shopifyProduct.product_cost}`
                    : "-"}
                </span>
                PRODUCT COST
              </PriceItem>
              <PriceItem
                sx={{
                  backgroundColor: "rgba(6,157,109,.1)",
                  "& span": {
                    color: colors.Success
                  }
                }}
              >
                <span>
                  {shopifyProduct?.product_margin
                    ? `$${parseFloat(shopifyProduct.product_margin).toFixed(2)}`
                    : "-"}
                </span>
                GROSS MARGIN
              </PriceItem>
            </Stack>
          </Box>
          <div className="ProductDesc">
            <Typography variant="h5">{chosenProduct?.name}</Typography>
            <Typography variant="body1">{chosenProduct?.body}</Typography>
            {(userRole === UserRoles.ADMIN ||
              userRole === UserRoles.BRANDANALYST) && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "25px",
                    right: "10px",
                    "& .MuiSvgIcon-root": {
                      color: colors.Primary
                    }
                  }}
                  onClick={() => setEditOpen(true)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            <EditModal
              open={editOpen}
              onClose={setEditOpen}
              name={chosenProduct?.name}
              body={chosenProduct?.body}
              productId={chosenProduct?.shopify_product_id}
              updateProduct={useChosenProduct}
            />
            <div className="learnMore">
              <Button
                href={`/brand/${params.name}/${params.brandId}`}
                target="_blank"
              >
                Learn More - Brand Details
              </Button>
              <p>
                Visit the Brand Detailed page to learn more or to Unlock the
                Trend Viability Report
              </p>
            </div>
          </div>
        </Box>

        <Box
          sx={{ width: "95% !important" }}
          alignItems="center"
          className="ProductAnalysis"
        >
          <Box alignItems="center">
            <Typography
              sx={(theme) => ({
                fontWeight: "bold",
                fontSize: "28px",
                "& span": {
                  color: theme.palette.text.secondary,
                },
              })}
              variant="h5"
            >
              Single Product - Detailed analysis
            </Typography>
          </Box>
          <button
            onClick={() => setSelectedBtnIndex(0)}
            className={selectedBtnIndex === 0 ? "selected" : ""}
          >
            Supplier Overview
          </button>
          {suppliers.map((supplier, index) => (
            <button
              key={index}
              onClick={() => handleSupplierBtnSelect(supplier)} // sets the supplier id as the button index for fetching logistic data.
              className={selectedBtnIndex === supplier.id ? "selected" : ""}
            >
              {`Supplier ${index + 1}`}
            </button>
          ))}
          {(userRole === UserRoles.ADMIN ||
            userRole === UserRoles.BRANDANALYST) && (
              <div>
                <button
                  onClick={() => setSupplierAddOpen(true)}
                  className="addSupplierBtn"
                >
                  + Add Supplier
                </button>
                <AddSupplier
                  modalOpen={supplierAddOpen}
                  onClose={() => setSupplierAddOpen(false)}
                  productId={parseInt(params.productId)}
                  productName={params.productName}
                  useSupplier={useSuppliers}
                />
              </div>
            )}

          <Box alignItems="center">
            <Box
              sx={{
                background: colors.Primary100,
                borderRadius: "10px",
                width: "calc(35% - 80px)",
                padding: "30px",
                display: "inline-block",
                mr: "20px",
                textAlign: "center",
                verticalAlign: "top",
                minHeight: "400px",
                "& img": {
                  minHeight: "400px",
                  objectFit: "cover",
                  width: "auto",
                  objectPosition: " center center",
                  maxHeight: "100%",
                  maxWidth: "100%",
                },
              }}
            >
              <img
                src={chosenProduct?.imageurl}
                alt="product-image"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = "/images/product_placeholder.jpg"
                }}
              />
            </Box>
            <div className="ProductAnalysisDetails">
              <Typography variant="h5">{chosenProduct?.name}</Typography>
              <Typography variant="body1">{chosenProduct?.body}</Typography>
            </div>
          </Box>
        </Box>
        {selectedBtnIndex > 0 ? (
          <div>
            {/* Detailed analysis section. */}
            <div className="shbox mb-30">
              <div className="Competitors">
                {/* Suppliers section with product analysis table. */}
                <ProductAnalysisTable
                  key={supplier?.id}
                  productId={parseInt(params.productId)}
                  productName={params.productName}
                  updateSupplier={updateSuppliers}
                  deleteSupplier={deleteSupplier}
                  supplier={supplier as Supplier}
                />
              </div>
            </div>
            {/* Logistics section with logistics data table. */}
            <div className="shbox mb-30">
              <div className="Competitors">
                <Typography
                  sx={(theme) => ({
                    fontWeight: "bold",
                    fontSize: "28px",
                    "& span": {
                      color: theme.palette.text.secondary,
                    },
                  })}
                  variant="h5"
                >
                  Logistics
                  <Tooltip
                    title="The table shows the approximate shipping cost to multiple global locations for Sea Freight 20” and 40” containers. The cost per unit is based on Global container index prices at the moment of report creation. Freight prices are very dynamic - always check and verify with your supplier before placing an order"
                    arrow
                  >
                    <InfoIcon color="disabled" />
                  </Tooltip>
                </Typography>
                <LogisticsTable
                  productId={parseInt(params.productId)}
                  productName={params.productName}
                  supplierId={supplier?.id as number}
                />
                {/* Margin section with calculation table. */}
              </div>
            </div>
            <div className="shbox mb-30">
              <div className="calTable">
                <CalculationTable
                  supplier={supplier as Supplier}
                  productId={parseInt(params.productId)}
                  productPrice={(chosenProduct?.price_usd || chosenProduct?.price) as number}
                />
              </div>
            </div>
          </div>
        ) : (
          <SupplierOverview
            suppliers={suppliers}
            productImage={chosenProduct?.imageurl as string}
          />
        )}
        {/* Competitors section with competitors table. */}
        <div className="shbox">
          <div className="Competitors">
            <Box alignItems="center">
              <Typography
                sx={(theme) => ({
                  fontWeight: "bold",
                  fontSize: "28px",
                  "& span": {
                    color: theme.palette.text.secondary,
                  },
                })}
                variant="h5"
              >
                Competitors
                <Tooltip
                  title="The table shows the current brand in comparison with similar stores selling an identical product"
                  arrow
                >
                  <InfoIcon color="disabled" />
                </Tooltip>
              </Typography>
              {isSuperUser && (
                <button
                  onClick={() => setCompetitorAddOpen(true)}
                  className="addSupplierBtn"
                >
                  + Add Competitors
                </button>
              )}
              <AddCompetitors
                brandId={parseInt(params.brandId)}
                modalOpen={competitorAddOpen}
                modalClose={() => setCompetitorAddOpen(false)}
                updateCompetitors={useComparisonReport}
              />
            </Box>
            <CompetitorTable
              reports={comparisonReports}
              handleDelete={handleCompetitorDelete}
            />
          </div>
        </div>
      </Paper>
    </>
  )
}
