import { Category, SubCategory } from "../../../api/searchBrand/types"
import { stringCapitalize } from "../../../utils"
import { COUNTRIES, groupBy } from "../constants"
import { CountryRegion } from "../types"

export const includeCategoryName = (category: Category) => {
  category.sub_categories.forEach((subCategory) => {
    subCategory.category = category.title
    subCategory.categoryId = category.id
    subCategory.title = stringCapitalize(subCategory.title)
  })

  return category
}

export const extractSubCategories = (categories: Category[]) => {
  let subCategories: SubCategory[] = []
  categories.forEach((category) => {
    includeCategoryName(category)
    subCategories = [...subCategories, ...category.sub_categories]
  })

  return subCategories
}

export const getCategoryIds = (subCategories: SubCategory[]) => {
  const categoryIds = subCategories.map((subCategory) => subCategory.categoryId) as number[]
  return [...new Set(categoryIds)] as number[]
}

export const getUniqueCategoryOptions = (subCategories: SubCategory[], prevOptions: SubCategory[]) => {
  const ids = subCategories.map((subCategory) => subCategory.id)
  const filtered = prevOptions.filter(({ id }) => !ids.includes(id))

  return filtered
}

export const getCountryRegionOptions = (regions: string[], countries: string[]) => {
  const countryOptions = COUNTRIES.filter((country) => countries.includes(country.code))

  return [
    ...regions.map((region) => ({ code: region, label: region, groupBy: groupBy.region })),
    ...countryOptions.map((country) => ({ code: country.code, label: country.label, groupBy: groupBy.country }))
  ] as CountryRegion[]
}