import {
  Box,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Actions from "../../../pages/Actions/PageActions"
import { RootStore } from "../../../Redux/store"
import Authorization from "../../Authorization/Authorization"
import { ReviewTracker } from "../common"
import LineChartContainer from "../components/LineChartContainer"
import { colors } from "../../../utils/theme"

interface ReviewTrackerProps {
  brandId: number
}

interface ApexChartData {
  name: string
  series: { x: string; y: number }[]
}

export default function ReviewTrackerTab({
  brandId,
}: ReviewTrackerProps): React.ReactElement {
  const dispatch = useDispatch()
  const token = localStorage.getItem("token")
  const ReviewTracker: ReviewTracker = useSelector(
    (state: RootStore) => state.PageReduser["ReviewTracker"]
  )
  const reviewTracker = ReviewTracker.velocity_tracker
  const charts = ReviewTracker.reviews

  const [fbCheckBox, setFbCheckBox] = useState<boolean>(true)
  const [trustpilotCheckBox, setTrustpilotCheckBox] = useState<boolean>(true)
  const [shopifyCheckBox, setShopifyCheckBox] = useState<boolean>(true)
  const [totalNrOfReviews, setTotalNrOfReviews] = useState<number>(0)
  const [sumSinceLastMonth, setSumSinceLastMonth] = useState<number>(0)

  useEffect(() => {
    dispatch(Actions.GetReviewTracker(brandId))
  }, [dispatch, brandId])

  function getCheckboxValue(name: string): boolean {
    if (name.startsWith("shopify")) {
      return shopifyCheckBox
    } else if (name === "facebook") {
      return fbCheckBox
    } else if (name === "trustpilot") {
      return trustpilotCheckBox
    } else {
      return false
    }
  }

  const getNewChartData = (chartName: string) => {
    let chartData: ApexChartData | undefined = undefined
    const datalist: { x: string; y: number }[] = []
    charts &&
      charts[chartName].forEach((datePair) => {
        Object.keys(datePair).forEach((date) => {
          datalist.push({
            x: new Date(date).toUTCString(),
            y: datePair[date],
          })
        })
      })
    chartData = {
      name: chartName.replace("_", " ").toUpperCase(),
      series: datalist,
    }
    return chartData
  }

  function handleCheckbox(
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) {
    if (name.startsWith("shopify")) {
      setShopifyCheckBox(event.target.checked)
    } else if (name === "facebook") {
      setFbCheckBox(event.target.checked)
    } else if (name === "trustpilot") {
      setTrustpilotCheckBox(event.target.checked)
    }
    if (reviewTracker) {
      if (event.target.checked === true) {
        setTotalNrOfReviews(
          totalNrOfReviews + (reviewTracker[name].review_count || 0)
        )
        setSumSinceLastMonth(
          sumSinceLastMonth + (reviewTracker[name].since_last_month || 0)
        )
      } else {
        setTotalNrOfReviews(
          totalNrOfReviews - (reviewTracker[name]?.review_count || 0)
        )
        setSumSinceLastMonth(
          sumSinceLastMonth - (reviewTracker[name]?.since_last_month || 0)
        )
      }
    }
  }

  return (
    <>
      {!token ? (
        <Authorization />
      ) : (
        <>
          <Box
            sx={{
              display: "inline-block",
              verticalAlign: "top",
              background: colors.White,
              boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08)",
              borderRadius: "10px",
              border: "none",
              width: "100%",
              minHeight: "366px",
              mb: "30px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins, sans-serif !important",
                color: colors.Text,
                fontWeight: 500,
                fontSize: "24px",
                mt: "30px !important",
                pl: "30px",
                lineHeight: "24px",
              }}
              variant="h6"
            >
              Review Velocity Tracker
            </Typography>
            <TableContainer
              sx={{
                mt: "0px",
                boxShadow: "none",
                border: "none",
              }}
              component={Paper}
            >
              <Table
                sx={{
                  ml: "30px",
                  mr: "30px",
                  width: "calc(100% - 60px)",
                  height: "219px",
                  borderR: "10px 10px 0px 0px",
                  overflow: "hidden",
                  mt: "20px",
                }}
                aria-aria-label="spanning table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& .MuiTableCell-head": {
                        background: "rgba(59,78,223,.1)",
                        color: colors.Text,
                        fontWeight: 500,
                        fontFamily: "Poppins,sans-serif!important",
                        fontSize: "16px",
                        lineHeight: "27px",
                        pl: "15px",
                        pr: "15px",
                        pt: "3px",
                        pb: "3px",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      },
                    }}
                  >
                    <TableCell>Show</TableCell>
                    <TableCell>Source</TableCell>
                    <TableCell>Rating</TableCell>
                    <TableCell># of Reviews</TableCell>
                    <TableCell>Change past 30 days</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {charts &&
                    Object.keys(charts).map(
                      (key, index) =>
                        charts[key].length > 0 && (
                          <TableRow
                            sx={{
                              "&:nth-of-type(even)": {
                                background: "rgba(59,78,223,.05)",
                              },
                              "& .MuiTableCell-root": {
                                fontSize: "14px",
                                fontFamily: "Poppins,sans-serif!important",
                                border: "none",
                                height: "34px",
                                padding: "3px 5px",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                                "& span": {
                                  padding: 0,
                                },
                              },
                            }}
                            key={index}
                          >
                            <TableCell>
                              <Checkbox
                                color="default"
                                inputProps={{
                                  "aria-label": "checkbox with default color",
                                }}
                                checked={getCheckboxValue(key)}
                                onChange={(e) => handleCheckbox(e, "fb")}
                              />
                            </TableCell>
                            <TableCell>
                              {key.replace("_", " ").toUpperCase()}
                            </TableCell>
                            <TableCell>
                              {(reviewTracker && reviewTracker[key]?.rating) ||
                                "-"}
                            </TableCell>
                            <TableCell>
                              {(reviewTracker &&
                                reviewTracker[key]?.review_count) ||
                                "-"}
                            </TableCell>
                            <TableCell>
                              {(reviewTracker &&
                                reviewTracker[key]?.since_last_month) ||
                                "-"}
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  <TableRow
                    sx={{
                      background: "rgba(59,78,223,.05)",
                      "& .MuiTableCell-root": {
                        fontWeight: 700,
                        fontSize: "14px",
                        fontFamily: "Poppins,sans-serif!important",
                        border: "none",
                        height: "34px",
                        padding: "3px 5px",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      },
                    }}
                  >
                    <TableCell rowSpan={3} />
                    <TableCell>Total</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{totalNrOfReviews}</TableCell>
                    <TableCell sx={{ color: colors.Success }}>
                      {sumSinceLastMonth}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Grid container>
            {charts &&
              Object.keys(charts).map(
                (key, index) =>
                  charts[key].length > 0 && (
                    <Grid
                      sx={{
                        mr: "20px",
                        maxWidth: "calc(33.3333% - 20px) !important",
                        mb: "30px",
                        background: colors.White,
                        boxShadow:
                          "0px 5px 20px 2px rgba(23, 3, 89, 0.08) !important",
                        borderRadius: "10px !important",
                        minHeight: "330px",
                        border: "none",
                        "& .MuiTypography-root": {
                          fontFamily: "Poppins, sans-serif !important",
                          mb: "10px",
                        },
                        "& .MuiChip-root": {
                          border: "none",
                          borderRadius: "0px",
                          height: "auto",
                          background: "transparent",
                          mr: "30px",
                          mt: "20px",
                          "& .MuiChip-label": {
                            padding: "0px",
                            fontFamily: "Poppins, sans-serif !important",
                            fontWeight: 500,
                            fontSize: "24px",
                            lineHeight: "36px",
                          },
                        },
                      }}
                      item
                      xs={12}
                      md={4}
                      key={index}
                    >
                      <LineChartContainer
                        reviewTracker
                        change={""}
                        headerText={key.replaceAll("_", " ").toUpperCase()}
                        headerNumber={
                          reviewTracker
                            ? reviewTracker[key]?.review_count?.toString()
                            : ""
                        }
                        reviewTrackerValue={getNewChartData(key)}
                      />
                    </Grid>
                  )
              )}
          </Grid>
        </>
      )}
    </>
  )
}
