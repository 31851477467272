import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import DeleteIcon from "@mui/icons-material/Delete"
import {
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow
} from "@mui/material"
import React from "react"

import { ComparisonReport } from "../../../types/productReportTypes"

type Props = {
  reports: ComparisonReport[]
  handleDelete: (id: number) => void
}

export default function CompetitorTable({ reports, handleDelete }: Props): React.ReactElement {

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            {reports.map((brand, index) => (
              <TableCell key={index}>
                <IconButton onClick={() => handleDelete(brand.id as number)}>
                  <DeleteIcon fontSize="small" color="error" />
                </IconButton>
                <img src={brand.logo} alt="logo" />
                <p>{brand.brand_name}</p>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>URL</TableCell>
            {reports.map((brand, index) => (
              <TableCell key={index}>{brand.url}</TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>IG Followers</TableCell>
            {reports.map((brand, index) => (
              <TableCell key={index}>{brand.ig_followers?.toLocaleString()}</TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>FB Likes</TableCell>
            {reports.map((brand, index) => (
              <TableCell key={index}>{brand.fb_likes?.toLocaleString()}</TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>FB Ads Active</TableCell>
            {reports.map((brand, index) => (
              <TableCell key={index}>
                {brand.fb_ads_active
                  ? <CheckCircleIcon color="success" fontSize="small" />
                  : <CancelIcon color="error" fontSize="small" />}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Google Ads Active</TableCell>
            {reports.map((brand, index) => (
              <TableCell key={index}>
                {brand.google_ads_active
                  ? <CheckCircleIcon color="success" fontSize="small" />
                  : <CancelIcon color="error" fontSize="small" />}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Web Traffic Approx</TableCell>
            {reports.map((brand, index) => (
              <TableCell key={index}>{brand.web_traffic?.toLocaleString()}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
