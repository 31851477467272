import React from "react"
import JoditEditor from "jodit-react"

interface Props {
  defaultValue: string
  fieldName: string
  getContent: (value: string, name: string) => void
}

export default function TextEditor(props: Props): React.ReactElement {
  const editor = React.useRef(null)

  const config = {
    readonly: false // all options from https://xdsoft.net/jodit/doc/
  }


  return (
    <JoditEditor
      ref={editor}
      value={props.defaultValue}
      config={config}
      onBlur={newContent => props.getContent(newContent, props.fieldName)} // preferred to use only this option to update the content for performance reasons
      onChange={newContent => newContent}
    />
  )
}
