import { styled } from "@mui/system"

export const CTALink = styled("a")({
  border: "1px solid #3b4edf",
  boxSizing: "border-box",
  borderRadius: 5,
  height: 40,
  minWidth: 118,
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "40px",
  fontFamily: "Poppins,sans-serif!important",
  display: "inline-block",
  textAlign: "center",
  textDecoration: "none",
  marginTop: -20,
})