import { ChartData, SocialData } from "../V1/brand/common"

const API_URL = process.env.REACT_APP_API_URL

if (!API_URL) {
  throw new Error("API URL environment variable not set")
}

export const MOBILE_SCREEN_SIZE = "(max-width:600px)"

export const openLinkInNewTab = (link: string) => {
  Object.assign(document.createElement("a"), {
    target: "_blank",
    href: link,
    rel: "noopener noreferrer",
  }).click()
}

export const openLink = (isMobileDevice: boolean, link: string) => {
  if (isMobileDevice) {
    window.location.href = link
  } else {
    openLinkInNewTab(link)
  }
}

export function filterResponse(
  data: SocialData[],
  chartName: string
): ChartData[] {
  const collectionList: ChartData[] = []
  data.forEach((val) => {
    Object.keys(val).forEach((key) => {
      if (chartName === key && val[key] !== -999) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        collectionList.push({ count: val[key], timestamp: val.timestamp })
      }
    })
  })
  return collectionList
}

export function FilterData(data: SocialData[]): SocialData | undefined {
  const newdata = data.pop()
  if (typeof newdata !== "undefined") {
    data.push(newdata)
    data.reverse()
    data.forEach((val) => {
      Object.keys(val).forEach((key) => {
        if (val[key] !== -999 && newdata[key] === -999) {
          newdata[key] = val[key] as SocialData
        }
      })
    })
    Object.keys(newdata).forEach((key) => {
      if (newdata[key] === -999) {
        newdata[key] = 0
      }
    })
    data.reverse()
  }
  return newdata
}

export function formatNumber(n: number) {
  return new Intl.NumberFormat().format(n)
}

export function getAbsoluteURL(relativePath: string): string {
  return new URL(relativePath, API_URL).toString()
}

export function postAbsoluteURL(relativePath: string): string {
  return new URL(relativePath, "http://161.97.176.189:7000").toString()
}

export const BRANDS_DOWNLOAD_URL = getAbsoluteURL(
  "search/shopifyfull/download/"
)

export const TRACKED_BRANDS_DOWNLOAD_URL = getAbsoluteURL(
  "search/shopifyfull/download?action=tracked_stores"
)

export const stringCapitalize = (sentence: string) => {
  const words = sentence.split(" ")
  const updatedWords = words.map((word) =>
    word !== "and" ? word[0].toUpperCase() + word.slice(1) : word
  )
  return updatedWords.join(" ")
}

export const scrollToTop = (top = 0) => {
  window.scrollTo({
    top,
    left: 0,
    behavior: "smooth",
  })
}
