import { fetchApiCall } from "."

export type Product = {
  shopify_product_id: number
  index: number
  code: string
  collection: string
  name: string
  variantname: string
  price: number
  instock: string
  url: string
  imageurl: string
  body: string
  brandname: string
  brandname_keyword: string
  timestamp: string
  previousprice: number
  productrank: number
  timeupdated: string
}

export type ProductItem = {
  id: number
  product: Product
  brand_id: number
  product_id: number
  brand_name: string
  product_name: string
}

export const fetchTrendingProducts = async (page: number, page_size: number) => {
  const endpoint = "api/brand/feature-product/"

  return fetchApiCall<ProductItem[]>(endpoint, {
    page,
    page_size
  })
}
