import Rootreduser from "./Reduser"

import { applyMiddleware, createStore } from "redux"

import thunkMiddleware from "redux-thunk"

const store = createStore(Rootreduser, applyMiddleware(thunkMiddleware))

export type RootStore = ReturnType<typeof Rootreduser>

export default store
