import CircularProgress from "@mui/material/CircularProgress"

import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"
import { useEffect, useState } from "react"
import {
  PinnedBrand,
  fetchMyPinnedBrands,
} from "../api/creativeCenter/pinnedBrands"

type Props = {
  value?: string[]
  error?: string
  label?: string
  onChange: (brandIds: string[]) => void
  placeholder?: string
  disabled?: boolean
}

export default function PinnedBrandsMultiSelect(props: Props) {
  const { value, onChange, label, placeholder, error } = props

  const [loading, setLoading] = useState(false)
  const [pinnedBrands, setPinnedBrands] = useState<PinnedBrand[]>([])

  useEffect(() => {
    const loadBoards = async () => {
      setLoading(true)
      const result = await fetchMyPinnedBrands()
      setPinnedBrands(result)
      setLoading(false)
    }
    loadBoards()
  }, [])

  if (loading) {
    return <CircularProgress />
  }

  const handleOnChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as unknown as string[]
    onChange(value)
  }

  const displayValue = () => {
    const foundItems = value?.map((v) => {
      const foundItem = pinnedBrands.find(
        (pinnedBrand) => pinnedBrand.page_id === v
      )
      return foundItem?.brandname || ""
    })
    return foundItems?.join(", ")
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="board-select">{label || "Boards"}</InputLabel>
      <Select
        labelId="board-select"
        name="boardSelectSelect"
        label={label || "Staff"}
        multiple
        // @ts-ignore
        value={value || []}
        error={!!error}
        placeholder={placeholder}
        onChange={handleOnChange}
        disabled={props.disabled}
        renderValue={() => <>{displayValue()}</>}
      >
        {pinnedBrands?.map((pinnedBrand) => (
          <MenuItem
            key={pinnedBrand.page_id}
            value={pinnedBrand?.page_id}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: "body2",
            }}
          >
            <Checkbox checked={value?.includes(pinnedBrand.page_id) || false} />
            <ListItemText primary={pinnedBrand.brandname} />
          </MenuItem>
        ))}
      </Select>
      {!!error && (
        <FormHelperText error sx={{ px: 2 }}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}
