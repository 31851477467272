import { deleteApiCall, fetchApiCall, patchApiCall, postApiCall } from "."
import {
  AutomatedLogistics,
  ComparisonReport,
  Logistics,
  Margin,
  Product,
  ShopifyProduct,
  Supplier,
  UpdatedProduct,
} from "../types/productReportTypes"

type SupplierResponse = {
  data: {
    results: Supplier[]
  }
}

export const deleteCompetitor = async (id: number) => {
  const endpoint = `api/report/brand-competitors/${id}/`

  return deleteApiCall(endpoint)
}

export const deleteFeatureProductData = async (productId: string) => {
  const endpoint = `api/brand/feature-product/${productId}/`

  return deleteApiCall(endpoint)
}

export const fetchComparisonReport = async (brandId: string) => {
  const endpoint = `/api/report/competitor-comparision-report/?brand_id=${brandId}`

  return fetchApiCall<ComparisonReport[]>(endpoint)
}

export const fetchProductDetail = async (productId: string) => {
  const endpoint = `search/shopify-products/${productId}`

  return fetchApiCall<Product>(endpoint)
}

export const fetchProductMargin = async (productId: string) => {
  const endpoint = `/api/report/product-margin-data/?shopify_product_id=${productId}`

  return fetchApiCall<ShopifyProduct>(endpoint)
}

export const fetchSuppliers = async (productId: string) => {
  const endpoint = `api/report/product-suppliers/?product_id=${productId}`

  return fetchApiCall<SupplierResponse>(endpoint)
}

export const postFeatureProductData = async (brandId: string, productId: string) => {
  const endpoint = "/api/brand/feature-product/"
  const payload = {
    brand_id: parseInt(brandId),
    product_id: productId,
  }

  return postApiCall(endpoint, payload)
}

export const fetchLogistics = async (product_id: number, product_supplier: number) => {
  const endpoint = "api/report/logistics/"

  return fetchApiCall<Logistics[]>(endpoint, {
    product_id,
    product_supplier
  })
}

export const fetchAutomatedLogisticsData = async (supplier_id: number, product_id: number) => {
  const endpoint = "api/brand/prefill-logistics/"
  const payload = {
    supplier_id,
    product_id
  }

  return postApiCall<AutomatedLogistics>(endpoint, payload)
}

export const editProduct = async (productId: number, payload: any) => {
  const endpoint = `api/update-product/${productId}/`

  return patchApiCall<UpdatedProduct>(endpoint, payload)
}

export const fetchSupplierMargin = async (params: any) => {
  const endpoint = "api/report/margin-calculator/"

  return fetchApiCall<Margin[]>(endpoint, params)
}
