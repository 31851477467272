import { Grid, Typography, Button } from "@mui/material"
import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { useSelector } from "react-redux"

import SerpStatCard from "../tabs/StoreTabCard"
import { RootStore } from "../../../Redux/store"
import { SerpstatKeywords } from "../common"

export default function Keywords(): React.ReactElement {
  const serpstatKeywords: SerpstatKeywords[] = useSelector(
    (state: RootStore) => state.PageReduser["serpstatKeywords"]
  )
  const [selectedButtonIndex, setSelectedButtonIndex] = React.useState(
    serpstatKeywords?.length || 0
  )

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1367 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1366, min: 1024 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 640, min: 320 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  return (
    <Grid item xs={12} className="KeywordBtns">
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
        className="KeywordChartHead"
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            fontSize: "28px",
            width: "100%",
            paddingTop: "0px",
            marginTop: "0px",
            "& span": {
              color: (theme) => theme.palette.text.secondary,
            },
            "& svg": {
              top: "2px",
              marginLeft: "3px",
              position: "relative",
              cursor: "pointer",
            },
          }}
        >
          Keywords
        </Typography>
      </Grid>
      {serpstatKeywords?.length > 0 && (
        <>
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={3000}
            pauseOnHover={true}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["mobile"]}
            deviceType={"desktop"}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {serpstatKeywords.map((keyword, index) => (
              <Button
                key={index}
                variant={
                  selectedButtonIndex == index ? "contained" : "outlined"
                }
                color={selectedButtonIndex == index ? "primary" : "inherit"}
                onClick={() => setSelectedButtonIndex(index)}
              >
                {keyword.keyword}
              </Button>
            ))}
          </Carousel>
          <SerpStatCard
            serpstatKeywords={serpstatKeywords}
            selectedKeywordIndex={selectedButtonIndex}
          />
        </>
      )}
    </Grid>
  )
}
