import { colors } from ".."

// ----------------------------------------------------------------------

export default function Button() {
  return {
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          background: colors.LightPrimary,
          boxShadow: "none",
          color: colors.Primary,
          "&:hover": {
            backgroundColor: colors.LightPrimary,
            boxShadow: "none",
          },
        },
        outlinedSecondary: {
          borderColor: colors.Primary,
          color: colors.Primary,
          "&:hover": {
            borderColor: colors.Primary,
            backgroundColor: colors.Primary,
            color: colors.White,
            boxShadow: "none",
          },
        },
      },
    },
  }
}
