/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from "react"
import axios from "axios"
import { Button, TextField, Typography } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Box from "@mui/material/Box"

import { getAbsoluteURL } from "../../../../utils"
import { Incoterms, Supplier } from "../../../../types/productReportTypes"
import Modal from "../../../../components/Modal"

interface Props {
  modalOpen: boolean
  onClose: () => void
  useSupplier: (value: Supplier) => void
  productId: number
  productName: string
}

const feedbackStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

export default function AddSupplier(props: Props) {
  const label = { inputProps: { "aria-label": "check box" } }

  const token = localStorage.getItem("token")

  const initialState: Supplier = {
    product_id: props.productId,
    product_name: props.productName,
    supplier_name: "",
    price_per_unit: null,
    alibaba_url: "",
    drop_shipping: "no",
    wechat: "",
    whatsapp: "",
    agent_notes: "",
    minimum_order_qty: null,
    supplier_type: 0,
    contact_person: "",
    lead_time: null,
    sea_freight: false,
    air_freight: false,
    incoterms: Incoterms.exWorks,
    master_carton_size: "",
    master_carton_weight: "",
    master_carton_units: null,
    product_box_size: "",
    weight: "",
    skype: "",
    is_verified: false
  }

  const [message, setMessage] = React.useState<string>("")
  const [feedbackMessageOpen, setFeedbackMessageOpen] = React.useState<boolean>(false)
  const [formData, setFormData] = React.useState<Supplier>(initialState)
  const [errors, setErrors] = React.useState<Record<string, unknown>>({})

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = formData
    data[e.target.name] = e.target.value
    setFormData(data)
  }

  const handleSelect = (e: SelectChangeEvent, fieldName: string) => {
    const data = formData
    setFormData({ ...data, ...{ [fieldName]: e.target.value } })
  }

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = formData
    setFormData({ ...data, ...{ [e.target.id]: e.target.checked as unknown as boolean } })
  }

  const addSupplier = () => {
    axios.post(getAbsoluteURL("api/report/product-suppliers/"), formData, { headers: { Authorization: `Token ${token as string}` } })
      .then((response) => {
        const data = response.data as { message: string, data: Supplier }
        setMessage(data.message)
        props.useSupplier(data.data)
        props.onClose()
        setFeedbackMessageOpen(true)
      })
      .catch((error: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setErrors(error.response.data)
        // setMessage("Please check the fields you have entered. Something went wrong.")
        // setFeedbackMessageOpen(true)
      })
  }

  const closeModal = () => {
    props.onClose()
    setFormData(initialState)
    setErrors({})
    setFeedbackMessageOpen(false)
  }

  const renderErrors = (fieldName: string) => {
    let msgs: string[] = []
    let error = false
    Object.keys(errors).forEach((key) => {
      if (key === fieldName) {
        msgs = errors[key] as string[]
        error = true
      }
    })
    return { error, msg: msgs.join(".") }
  }

  const feedbackContent = (
    <Box sx={feedbackStyle}>
      <Typography id="transition-modal-title" variant="h6" component="h2">{message}</Typography>
      <Button onClick={() => setFeedbackMessageOpen(false)} variant="contained" color="primary">Ok</Button>
    </Box>
  )

  return (
    <Modal
      open={props.modalOpen}
      title={"Add Supplier"}
      handleClose={closeModal}
      maxWidth="sm"
    >
      <>
        {!feedbackMessageOpen
          ? <Box
            sx={{
              "& .MuiFormControl-root": {
                display: "inline-block",
                width: "50%",
                borderRadius: "5px",
                pb: "8px",
                pt: "8px",
              },
              "& .MuiInputBase-root": {
                display: "inline-block",
                width: "83%",
                borderRadius: "5px",
                pb: "8px",
                pt: "8px",
              }
            }}
          >
            {Object.keys(formData).map((key, index) => (
              <>
                {!["incoterms", "is_verified", "supplier_type", "sea_freight", "air_freight", "product_id", "margin", "product_name", "drop_shipping"].includes(key)
                  ? <TextField
                    type={["price_per_unit", "minimum_order_qty", "master_carton_units"].includes(key) ? "number" : "text"}
                    key={index}
                    name={key}
                    label={key.replaceAll("_", " ")}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    defaultValue={formData[key]}
                    onChange={handleChange}
                    error={renderErrors(key).error}
                    helperText={renderErrors(key).msg} />
                  : ""
                }
                {key === "drop_shipping"
                  ? <FormControl>
                    <InputLabel id="drop-shipping">Drop Shipping</InputLabel>
                    <Select
                      labelId="drop-shipping-label"
                      id="drop-shipping"
                      value={formData.drop_shipping}
                      label="Drop Shipping"
                      onChange={(e) => handleSelect(e, key)}
                      error={renderErrors(key).error}
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                    <p style={{ color: "red" }}>{renderErrors(key).msg}</p>
                  </FormControl>
                  : ""
                }
                {key === "supplier_type"
                  ? <FormControl>
                    <InputLabel id="supplier-type">Supplier Type</InputLabel>
                    <Select
                      labelId="supplier-type-label"
                      id="supplier-type"
                      value={formData.supplier_type.toString()}
                      label="Supplier Type"
                      onChange={(e) => handleSelect(e, key)}
                      error={renderErrors(key).error}
                    >
                      <MenuItem value={0}>Manufacturer</MenuItem>
                      <MenuItem value={1}>Trading Company</MenuItem>
                      <MenuItem value={2}>Manufacturer/Trading Company</MenuItem>
                    </Select>
                    <p style={{ color: "red" }}>{renderErrors(key).msg}</p>
                  </FormControl>
                  : ""
                }
                {key === "incoterms"
                  ? <FormControl>
                    <InputLabel id="incoterms">Incoterms</InputLabel>
                    <Select
                      labelId="incoterms-label"
                      id="incoterms"
                      value={formData.incoterms}
                      label="Incoterms"
                      onChange={(e) => handleSelect(e, key)}
                      error={renderErrors(key).error}
                    >
                      <MenuItem value="EXW">EXW</MenuItem>
                      <MenuItem value="FOB">FOB</MenuItem>
                      <MenuItem value="DDP">DDP</MenuItem>
                    </Select>
                    <p style={{ color: "red" }}>{renderErrors(key).msg}</p>
                  </FormControl>
                  : ""
                }
                {(key === "sea_freight" || key === "air_freight" || key === "is_verified")
                  ? <FormControlLabel
                    control={<Checkbox id={key} {...label} checked={formData[key]} onChange={handleCheckbox} />}
                    label={key.replaceAll("_", " ")} />
                  : ""
                }
              </>
            ))}
            <Button
              sx={{
                position: "absolute",
                mt: "50px",
                right: "30px",
              }}
              color="primary"
              variant="contained"
              onClick={addSupplier}
              className="editSaveBtn"
            >
              Save
            </Button>
          </Box>
          : feedbackContent
        }
      </>
    </Modal>
  )
}