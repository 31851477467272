import { Box, Button, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { PinnedAd } from "../../../api/creativeCenter/pinnedAds"
import Iconify from "../../../components/Iconify"
import { colors } from "../../../utils/theme"
import PinnedAdContent from "../../CreativeCenter/PinnedAds/PinnedAdModal/PinnedAdContent"

export const MobilePinnedAdDetail = ({ pinnedAd }: { pinnedAd: PinnedAd }) => {
  const [focusedAssetUrl, setFocusedAssetUrl] = useState<
    string | undefined | null
  >()
  const [focusedCtaUrl, setFocusedUrl] = useState<string | undefined | null>()
  const [currentCardIndex, setCurrentCardIndex] = useState(0)

  useEffect(() => {
    if (!pinnedAd) {
      return
    }

    const hasCards = pinnedAd.fb_ad.cards?.length > 0

    const getAssetUrl = () => {
      const getImageUrl = (urlString?: string | null) => {
        return urlString && urlString !== "N/A" ? urlString : undefined
      }

      const fbImageUrlString = hasCards
        ? pinnedAd.fb_ad.cards[currentCardIndex].image
        : pinnedAd.fb_ad.image
      const imageUrlString = getImageUrl(fbImageUrlString)
      if (imageUrlString) {
        return imageUrlString
      }
      const fbVideoUrlString = hasCards
        ? pinnedAd.fb_ad.cards[currentCardIndex].video_url
        : pinnedAd.fb_ad.video_url
      return fbVideoUrlString
    }

    const getCtaUrl = () => {
      return hasCards
        ? pinnedAd.fb_ad.cards[currentCardIndex].link_url
        : pinnedAd.fb_ad.link_url
    }

    setFocusedUrl(getCtaUrl())
    setFocusedAssetUrl(getAssetUrl())
  }, [pinnedAd, currentCardIndex])

  return (
    <Box
      sx={{
        width: "100%",
        paddingX: "42px",
        paddingY: "32px",
        backgroundColor: colors.White,
        borderRadius: "10px",
        boxShadow: "0px 5px 20px 2px rgba(23, 3, 89, 0.08);",
      }}
    >
      <PinnedAdContent
        pinnedAd={pinnedAd}
        focusedCtaUrl={focusedCtaUrl}
        focusedIndexChanged={setCurrentCardIndex}
        hideMoveBoard
      />
      <Box
        sx={{
          mt: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" spacing={2}>
          {focusedAssetUrl && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Iconify icon={"material-symbols:download-rounded"} />}
              onClick={() => {
                window.open(focusedAssetUrl, "_blank")
              }}
            >
              Download Asset
            </Button>
          )}
        </Stack>
      </Box>
    </Box>
  )
}
