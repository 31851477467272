import {
  Box,
  Button,
  TextField as MUITextField,
} from "@mui/material"
import { styled } from "@mui/system"
import React from "react"

import TextEditor from "../TextEditor/TextEditor"
import Modal from "../../../components/Modal"

type ReportHeaders = {
  viability_description: string
  hot_market_trend: string
  trend_viability_title: string | null
  trend_viability_text: string | null
  [index: string]: string | null
}

type GoogleTrends = {
  brand_id: number
  brand_name: string
  interest_over_time: string
  interest_by_region: string
  related_topics: string
  related_queries: string
  [index: string]: string | number
}

type Props = {
  modalOpen: boolean
  heading: string
  defaultData: ReportHeaders | GoogleTrends
  modalClose: () => void
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleTextEditor: (content: string, name: string) => void
  handleSave: () => void
}

const TextField = styled(MUITextField)({
  marginBottom: "10px",
  marginTop: "10px",
  display: "flex"
})

const EditSection = (props: Props): React.ReactElement => {

  const getFieldName = () => {
    switch (props.heading) {
      case "About the Brand":
        return "viability_description"
      case "Trend Viability - Hot Market trends":
        return "hot_market_trend"
      case "Trend Viability -":
        return "trend_viability_text"
    }
    return ""
  }

  const googleTrendsInputFields = (
    <div>
      <TextField name="interest_over_time" label="Interest over time" onChange={props.handleChange} />
      <TextField name="interest_by_region" label="Interest by region" onChange={props.handleChange} />
      <TextField name="related_topics" label="Related topics" onChange={props.handleChange} />
      <TextField name="related_queries" label="Related Queries" onChange={props.handleChange} />
    </div>
  )

  return (
    <Modal
      open={props.modalOpen}
      title={props.heading}
      handleClose={props.modalClose}
    >
      <Box>
        {props.heading === "Trend Viability -"
          ? <div>
            <TextField
              name="trend_viability_title"
              placeholder="Add your custom title"
              defaultValue={props.defaultData["trend_viability_title"]}
              onChange={props.handleChange} />

            <TextEditor
              fieldName={getFieldName()}
              defaultValue={props.defaultData[getFieldName()] as string}
              getContent={props.handleTextEditor} />

            {googleTrendsInputFields}
          </div>
          : <TextEditor
            fieldName={getFieldName()}
            defaultValue={props.defaultData[getFieldName()] as string}
            getContent={props.handleTextEditor} />
        }
        <Button
          sx={{ mt: "30px" }}
          onClick={props.handleSave}
        >
          Save
        </Button>
      </Box>
    </Modal>
  )
}

export default EditSection