import { styled } from "@mui/system"

export const IconWrapper = styled("div")({
  width: 20,
  height: 20,
  borderRadius: "50%",
  border: "1px solid #3b4edf",
  textAlign: "center",
  lineHeight: 1.1,
  margin: 2,
  float: "right",
})