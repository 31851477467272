import React from "react"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"

import Authorization from "./Authorization"

interface Props {
  handleClick: () => void
}

export default function ViabilityAuthorization(props: Props): React.ReactElement {
  const token = localStorage.getItem("token")

  return (
    <>
      {!token
        ? <Authorization />
        : <div className="accessPop">
          <div >
            <button onClick={props.handleClick} className="primary"><LockOutlinedIcon /> Unlock Trend Viability</button>
            <p>Unlock this Trend Viability report to gain additional brand insights <br />Unlocking will consume one of your Report credits</p>
          </div>
        </div>
      }
    </>
  )
}
