import { styled } from "@mui/system"

import BlueTick from "../../../assets/images/v1-icons/blue-tick.svg"
//-------------------------------------------------------------------------

type MonthlyButtonProps = {
  currentplan?: string
}

export const HeadCap = styled("div")({
  background: "#3b4edf",
  position: "absolute",
  top: 0,
  width: "100%",
  left: 0,
  textAlign: "center",
  color: "#fff",
  fontWeight: 700,
  paddingTop: 5,
  paddingBottom: 5,
})

export const Heading = styled("h1")({
  display: "inline-block",
  width: "100%",
  textAlign: "center",
  fontFamily: "poppins,sans-serif!important",
  fontWeight: 400,
  marginBottom: 0,
})

export const MonthlyPlanButton = styled("button")<MonthlyButtonProps>((props) => ({
  borderRadius: 6,
  height: 30,
  paddingLeft: 18,
  paddingRight: 18,
  background: props.currentplan ? "#ffff" : "#3b4edf",
  borderColor: props.currentplan ? "#3b4edf" : "transparent",
  borderStyle: "solid",
  borderWidth: 1,
  fontSize: 13,
  fontFamily: "Poppins,sans-serif!important",
  color: props.currentplan ? "#3b4edf" : "#fff",
  cursor: "pointer",
}))

export const PlanBox = styled("div")({
  maxWidth: 255,
  padding: 10,
  height: 525,
  margin: 5,
  width: "100%",
  display: "inline-block",
  background: "#ffffff",
  border: "1px solid #e5e5e5",
  borderRadius: 14,
  textAlign: "left",
  verticalAlign: "top",
  position: "relative",
  overflow: "hidden",
})

export const PlanContainer = styled("div")({
  background: "#f3f4f7",
  paddingBottom: 30,
})

export const PlanDescription = styled("p")({
  fontWeight: 400,
  fontSize: 14,
  width: "100%",
  display: "inline-block",
  fontFamily: "poppins,sans-serif!important",
  marginTop: 0,
  textAlign: "left",
})

export const PlanFeature = styled("td")({
  fontFamily: "poppins,sans-serif!important",
  fontSize: "11px",
  color: "#80848f",
  paddingTop: 7,
  paddingBottom: 7,
})

export const PlanName = styled("h2")({
  color: "#3b4edf",
  fontWeight: 400,
  fontSize: 20,
  fontFamily: "poppins,sans-serif!important",
  marginTop: -160,
  marginBottom: -189,
  width: "100%",
  display: "inline-block",
  lineHeight: 20,
  textAlign: "left",
})

export const PlansWrapper = styled("div")({
  display: "inline-block",
  width: "100%",
  textAlign: "center",
})

export const PriceTag = styled("h5")({
  color: "#808f87",
  fontWeight: 400,
  fontSize: 16,
  fontFamily: "poppins,sans-serif!important",
  marginTop: 15,
  marginBottom: 10,
  width: "100%",
  display: "inline-block",
})

export const SubHeading = styled("p")({
  display: "inline-block",
  width: "100%",
  textAlign: "center",
  fontFamily: "poppins,sans-serif!important",
  fontWeight: 400,
})

export const TickIcon = styled("img")({
  marginRight: 10,
  display: "inline-block",
  verticalAlign: "middle",
})

TickIcon.defaultProps = {
  src: BlueTick,
}

export const WarningHeader = styled("h2")({
  fontSize: 25,
  marginTop: 40,
  display: "inline-block",
  width: "100%",
  textAlign: "center",
  fontFamily: "Poppins,sans-serif!important",
  fontWeight: 400,
  marginBottom: 0,
})

export const YearlyPlanButton = styled("span")({
  color: "#a2a8a5",
  fontWeight: 400,
  fontSize: 12,
  fontFamily: "poppins,sans-serif!important",
  marginTop: 0,
  marginBottom: 0,
  width: "auto",
  display: "inline-block",
  border: "1px solid #ddd",
  borderRadius: 5,
  cursor: "pointer",
  padding: "3px 10px",
})