import {
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material"
import { styled } from "@mui/system"

import adCategory from "../../../assets/images/v1-icons/adCategory.svg"
import allAds from "../../../assets/images/v1-icons/allAds.svg"
import featuredAds from "../../../assets/images/v1-icons/featuredAds.svg"
import searchIcon from "../../../assets/images/searchIcon.svg"

type MenuItemProps = {
  isFeaturedAd?: boolean
}

export const AdCategoryMenuItem = styled(MenuItem)((props: MenuItemProps) => ({
  paddingLeft: 40,
  "::after": {
    position: "absolute",
    content: "''",
    backgroundImage: props.isFeaturedAd ? `url(${featuredAds})` : `url(${allAds})`,
    backgroundRepeat: "no-repeat",
    width: 25,
    height: 25,
    left: 15,
    top: 10,
  }
}))

export const AdCategorySelect = styled(Select)({
  position: "relative",
  paddingLeft: 30,
  height: 50,
  "::after": {
    position: "absolute",
    content: "''",
    backgroundImage: `url(${adCategory})`,
    backgroundRepeat: "no-repeat",
    width: 25,
    height: 25,
    left: 8,
    top: 16,
  }
})

export const AdFilterSelect = styled(Select)({
  height: 50,
})

export const AutoCompleteStyles = {
  width: "100%",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: "5px",
  marginBottom: "10px",
  "& div": {
    padding: "0px 6px",
    color: "rgba(61, 68, 101, 0.5)",
    fontFamily: "Poppins !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    "& ::placeholder": {
      color: "black",
    },
  },
  "& div ::before": {
    display: "none"
  },
}

export const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: 20,
})

export const FilterButton = styled(Button)({
  width: 170,
  height: "46px !important",
})

export const ClearButton = styled(Button)({
  backgroundColor: "rgba(59, 78, 223, 0.1)",
  color: "rgba(59, 78, 223, 1)",
  width: 130,
  height: 46,
})

export const Container = styled("div")({
  padding: "0px !important",
  "::after": {
    background: "transparent !important",
    boxShadow: "none !important",
  },
})

export const Count = styled("p")({
  left: 100,
  top: 326,
  fontFamily: "Poppins,Sans-serif !important",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 24,
  letterSpacing: "-0.03em",
  color: "#3D4465",
})

export const CustomFormControl = styled(FormControl)({
  minWidth: 177,
  background: "#fff",
  marginRight: 20,
  height: 50,
})

export const Description = styled("p")({
  left: 100,
  top: 110,
  fontFamily: "Poppins,Sans-serif !important",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 16,
  letterSpacing: "-0.03em",
  color: "rgba(61, 68, 101, 0.7)",
})

export const Header = styled("h2")({
  fontFamily: "Poppins,sans-serif !important",
  fontSize: 30,
  fontWeight: 500,
  letterSpacing: "0em",
  textAlign: "left",
  color: "#3D4465",
})

export const SearchBar = styled("input")({
  boxSizing: "border-box",
  width: "100%",
  height: 50,
  left: 359,
  top: 218,
  backgroundImage: `url(${searchIcon})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "10px center",
  paddingLeft: 35,
  backgroundColor: "#FFFFFF",
  border: "1px solid rgba(61, 68, 101, 0.7)",
  borderRadius: 5,
})

export const SearchWrapper = styled("div")({
  display: "flex",
  paddingBottom: 28,
  borderBottom: "1px solid rgba(61, 68, 101, 0.7)",
})

export const Wrapper = styled("div")({
  background: "#F2F4FD",
  position: "relative",
  width: "100%",
  height: "auto",
  zIndex: 99999,
})

export const Popup = styled(Modal)({
  position: "absolute",
})

export const ModalHeader = styled("div")({
  fontFamily: "Poppins !important",
  position: "relative",
  top: -33,
  fontSize: 18,
  fontWeight: 500,
  letterSpacing: "-0.03em",
  color: "rgba(61, 68, 101, 0.6)",
  "& svg": {
    position: "relative",
    top: 6,
  }
})

export const Hr = styled("hr")({
  marginTop: 0,
  position: "relative",
  top: -22,
  left: -30,
  width: "calc(100% + 60px)",
})

export const Label = styled("label")({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 16,
  letterSpacing: "-0.03em",
  color: "rgba(61, 68, 101, 0.6)",
  marginBottom: 10,
  display: "inline-block",
})

export const DateLabel = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: 18,
  fontWeight: 500,
  letterSpacing: "-0.03em",
  color: "rgba(61, 68, 101, 0.6)",
})

export const DateRange = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 16,
  letterSpacing: "-0.03em",
  color: "rgba(61, 68, 101, 0.6)",
  marginBottom: 10,
})

export const DateInfo = styled("p")({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 12,
  letterSpacing: "-0.03em",
  color: "rgba(61, 68, 101, 0.5)",
})

export const CustomSelect = styled(Select)({
  width: "100%",
  marginBottom: 20,
  "& div": {
    padding: "5px 14px",
    color: "rgba(61, 68, 101, 0.5)",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
  },
})

export const CloseButton = styled("button")({
  background: "none",
  border: "none",
  color: "rgba(61, 68, 101, 0.4)",
  marginLeft: "97%",
  cursor: "pointer",
})

export const BoxStyle = {
  position: "absolute" as const,
  top: "47%",
  left: "calc(50% - 0px)",
  transform: "translate(-50%, -50%)",
  width: "600px",
  backgroundColor: "#fff",
  border: "3px solid #e5e5e5",
  borderRadius: "9px",
  boxShadow: 24,
  p: 4,
}

export const DateContainer = styled("div")({
  display: "inline-block",
  width: "calc(50% - 48px)",
  position: "relative",
  "& input": {
    textAlign: "left",
    color: "rgba(61, 68, 101, 0.5)",
    inset: "-5px 0px 0px",
    margin: "0px",
    padding: "0px 8px",
    cursor: "pointer",
    borderRadius: 4,
    borderStyle: "solid",
    borderWidth: 1,
    overflow: "hidden",
    minWidth: "0%",
    borderColor: "rgba(0, 0, 0, 0.23)",
    height: 40,
    width: "calc(100% - 16px)",
  },
  "& svg": {
    position: "absolute",
    right: "10px",
    top: "46px",
    opacity: 0.5,
  },
})

export const DateGrid = {
  marginTop: 0,
  marginLeft: 0,
  display: "inline-block",
  width: "100%",
  "& div": {
    marginBottom: "2px",
  }
}
