import { Box, Tab, Tabs, useMediaQuery } from "@mui/material"
import axios from "axios"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { trackBrand, ViewType } from "../../api/brandTracking"
import { RootStore } from "../../Redux/store"
import { getAbsoluteURL, MOBILE_SCREEN_SIZE } from "../../utils"
import ViabilityAuthorization from "../Authorization/TrendViabilityAuth"
import DropDownMenu from "../Components/MobileScreenComponents/DropDownMenu"
import ViabilityRequest from "../Components/Notification/ViabilityRequest"
import BrandCreditsMessage from "../Components/SubscriptionMessage/BrandViewCreditsMessage"
import { BrandItem, BrandViewStatus } from "./common"
import AdsTab from "./tabs/AdsTab"
import GoogleAdsTab from "./tabs/GoogleAdsTab"
import OverviewTab from "./tabs/OverviewTab"
import ProductsTab from "./tabs/ProductsTab"
// import ReviewTrackerTab from "./tabs/ReviewTrackerTab"
import SocialTab from "./tabs/SocialTab"
import StoreTab from "./tabs/StoreTab"
import TabPanel from "./tabs/TabPanel"
import ReportsTab from "./tabs/TrendViability"
import { checkIsSuperUser, getToken } from "../../utils/localStorage"
import { colors } from "../../utils/theme"

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

type Params = {
  name: string
  brandId: string
}

export default function BrandTabs(): React.ReactElement {
  const dispatch = useDispatch()
  const params: Params = useParams()
  const isSuperUser = checkIsSuperUser()
  const token = getToken()
  const isMobileView = useMediaQuery(MOBILE_SCREEN_SIZE)

  const [tabIndex, setTabIndex] = React.useState(0)

  const brandItem: BrandItem = useSelector(
    (state: RootStore) => state.PageReduser["brandItem"]
  )
  const brandViewStatus: BrandViewStatus = useSelector(
    (state: RootStore) => state.PageReduser["brandViewStatus"]
  )
  const forcingOverviewTab: boolean = useSelector(
    (state: RootStore) => state.PageReduser["forceOverviewTab"]
  )
  const forcingReportTab: boolean = useSelector(
    (state: RootStore) => state.PageReduser["forceReportTab"]
  )

  React.useEffect(() => {
    if (forcingReportTab) {
      setTabIndex(1)
    }
    if (forcingOverviewTab) {
      setTabIndex(0)
      dispatch({ type: "ForceOverviewTab", payload: false })
    }
  }, [forcingReportTab, forcingOverviewTab, dispatch])

  const triggerBrandTracking = async () => {
    const brandId = parseInt(params.brandId)
    await trackBrand(brandId, ViewType.reportView)
  }

  const unlockViability = () => {
    dispatch({ type: "Loading", payload: true })
    axios
      .post(
        getAbsoluteURL(`api/subscription/unlock-viability/${params.brandId}/`),
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token as string}`,
          },
        }
      )
      .then(() => {
        dispatch({
          type: "BrandViewStatus",
          payload: {
            view_viability_report: true,
            view_brand_detail: brandViewStatus?.view_brand_detail,
          },
        })
        triggerBrandTracking()
        dispatch({ type: "Loading", payload: false })
      })
      .catch((error) => {
        console.log(error)
        dispatch({ type: "Loading", payload: false })
      })
  }

  function handleTabChanged(
    event: React.ChangeEvent<unknown>,
    newTabIndex: number
  ) {
    setTabIndex(newTabIndex)
  }

  const renderViabilityTabScreen = () => {
    if (
      !isSuperUser &&
      brandItem.report_published &&
      !brandViewStatus?.view_viability_report
    ) {
      return <ViabilityAuthorization handleClick={unlockViability} />
    } else if (!isSuperUser && !brandItem.report_published) {
      return <ViabilityRequest />
    } else {
      return <ReportsTab />
    }
  }

  return (
    <Box className="productTab">
      {!isSuperUser && token && Object.keys(brandViewStatus).length > 1 ? (
        <BrandCreditsMessage open={!brandViewStatus.view_brand_detail} />
      ) : (
        ""
      )}
      {isMobileView ? (
        <DropDownMenu renderViabilityTabScreen={renderViabilityTabScreen} />
      ) : (
        <>
          <Tabs
            textColor="inherit"
            variant="scrollable"
            value={tabIndex}
            onChange={handleTabChanged}
            aria-label="Brand tabs"
            sx={{
              textAlign: "right",
              "& .Mui-selected": {
                color: `${colors.White} !important`,
              },
              "& .MuiTab-root": {
                opacity: 1,
              },
            }}
          >
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Trend Viability" {...a11yProps(1)} />
            {/* <Tab label="Review Tracker" {...a11yProps(2)} /> */}
            <Tab label="Social" {...a11yProps(3)} />
            <Tab label="FB Ads" {...a11yProps(4)} />
            <Tab label="Google Ads" {...a11yProps(5)} />
            <Tab label="Traffic and Revenue" {...a11yProps(6)} />
            <Tab label="Products" {...a11yProps(7)} />
          </Tabs>
          <Box>
            <TabPanel value={tabIndex} index={0}>
              <OverviewTab />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              {renderViabilityTabScreen()}
            </TabPanel>
            {/* <TabPanel value={tabIndex} index={2}>
              <ReviewTrackerTab brandId={brandItem.brand_id} />
            </TabPanel> */}
            <TabPanel value={tabIndex} index={2}>
              <SocialTab />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <AdsTab />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              <GoogleAdsTab />
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
              <StoreTab />
            </TabPanel>
            <TabPanel value={tabIndex} index={6}>
              <ProductsTab />
            </TabPanel>
          </Box>
        </>
      )}
    </Box>
  )
}
