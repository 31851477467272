import { useEffect, useState } from "react"

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton"
import { Button, DialogActions } from "@mui/material"
import { useSnackbar } from "notistack"
import {
  PinnedAd,
  movePinnedAd,
} from "../../../../api/creativeCenter/pinnedAds"
import { Board, fetchMyBoards } from "../../../../api/creativeCenter/boards"
import BoardSelect from "../../../../components/BoardSelect"
import Modal from "../../../../components/Modal"
//-----------------------------------------------------------------------------------------------------

type Props = {
  handleClose: VoidFunction
  onMoveSuccess: (pinnedAd: PinnedAd, newBoardId: number) => void
  pinnedAd?: PinnedAd
}

export default function MoveBoardModal({
  handleClose,
  onMoveSuccess,
  pinnedAd,
}: Props) {
  const [loading, setLoading] = useState(false)
  const [selectedBoardId, setSelectedBoardId] = useState<number>()
  const [boards, setBoards] = useState<Board[]>([])
  const [boardsLoading, setBoardsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const loadBoards = async () => {
      setBoardsLoading(true)
      const result = await fetchMyBoards()
      setBoards(result.results)
      setBoardsLoading(false)
    }
    loadBoards()
  }, [])

  if (!pinnedAd) {
    return null
  }

  const moveBoard = async () => {
    if (!selectedBoardId) {
      return
    }
    setLoading(true)

    await movePinnedAd(pinnedAd, selectedBoardId)
    onMoveSuccess(pinnedAd, selectedBoardId)

    enqueueSnackbar("Board Changed")
    setLoading(false)
    handleClose()
  }

  return (
    <Modal
      open={pinnedAd !== undefined}
      handleClose={handleClose}
      title={"Move to board"}
    >
      <>
        <BoardSelect
          name="board"
          label="Move to Board"
          boards={boards}
          onChange={setSelectedBoardId}
          loading={boardsLoading}
        />
        <DialogActions sx={{ marginTop: 2 }}>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>

          <LoadingButton
            variant="contained"
            color="secondary"
            onClick={moveBoard}
            loading={loading}
            disabled={!selectedBoardId}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </>
    </Modal>
  )
}
