import LoadingButton from "@mui/lab/LoadingButton"
import { Alert, Box, SxProps, Theme } from "@mui/material"
import { useEffect, useState } from "react"

import BoardSelect from "./BoardSelect"
import { colors } from "../utils/theme"
import { saveAdToTrendRocket } from "../api/ads"
import useError from "../V1/Hooks/errorHandler"
import { Board } from "../api/creativeCenter/boards"

type Props = {
  adId: string
  boards: Board[]
  boardsLoading: boolean
  sx?: SxProps<Theme>
}

export default function SaveAdToTrendRocket(props: Props) {
  const { errorMessage, setErrorMessage, errorCallBack } = useError()
  const [loading, setLoading] = useState(false)
  const [successAlertOpen, setSuccessAlertOpen] = useState(false)
  const [errorAlertOpen, setErrorAlertOpen] = useState(false)
  const [boardId, setBoardId] = useState<number>()

  useEffect(() => {
    if (errorMessage) {
      setErrorAlertOpen(true)
    }
  }, [errorMessage])

  const saveAd = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    const response = await saveAdToTrendRocket(
      {
        board_id: boardId,
        fb_ad_id: props.adId,
      },
      errorCallBack
    )
    setLoading(false)
    if (response) {
      setSuccessAlertOpen(true)
    }
  }

  const closeErrorAlert = () => {
    setErrorAlertOpen(false)
    setErrorMessage("")
  }

  return (
    <form onSubmit={saveAd}>
      <Box
        sx={{
          display: "flex !important",
          mt: "10px",
          "& .MuiFormControl-root": {
            width: "50%",
            mr: "10px",
          },
          ...props.sx,
        }}
      >
        <BoardSelect
          name="Board"
          boards={props.boards}
          onChange={setBoardId}
          loading={props.boardsLoading}
          required
        />
        <LoadingButton
          sx={{
            width: "50%",
            mr: "0px !important",
            background: `${colors.Primary} !important`,
            ".MuiLoadingButton-loadingIndicator": {
              color: `${colors.White} !important`,
            },
          }}
          loading={loading}
          loadingPosition="end"
          type="submit"
        >
          Save Ad
        </LoadingButton>
      </Box>
      {successAlertOpen && (
        <Alert
          sx={{
            mt: "10px",
            "& .MuiAlert-action": {
              "& .MuiButtonBase-root": {
                border: "none !important",
                backgroundColor: "transparent !important",
              },
            },
          }}
          severity="success"
          onClose={() => setSuccessAlertOpen(false)}
        >
          Ad pinned to this board!
        </Alert>
      )}
      {errorAlertOpen && (
        <Alert
          sx={{
            mt: "10px",
            "& .MuiAlert-action": {
              "& .MuiButtonBase-root": {
                border: "none !important",
                backgroundColor: "transparent !important",
              },
            },
          }}
          severity="error"
          onClose={closeErrorAlert}
        >
          {errorMessage}
        </Alert>
      )}
    </form>
  )
}
