import { fetchApiCall } from ".."

export type PinnedBrand = {
  page_id: string
  brandname: string
}

export const fetchMyPinnedBrands = async (page = 1, pageSize = 20) => {
  const endpoint = `api/creative/pinned-brands?page=${page}&pageSize=${pageSize}`

  const result = await fetchApiCall(endpoint)
  return result as PinnedBrand[]
}
