import { Grid } from "@mui/material"
import { Board } from "../../../api/creativeCenter/boards"
import { PinnedAdsFilters } from "../../../api/creativeCenter/pinnedAds"
import BoardFilter from "../../../components/BoardFilter"
import MultiBoardSelect from "../../../components/MultiBoardSelect"
import PinnedBrandsMultiSelect from "../../../components/PinnedBrandsMultiSelect"
import { FORMAT, PLATFORMS, SORT_OPTIONS } from "./FilterChoices"

type FiltersProps = {
  filters: PinnedAdsFilters
  setFilters: (filters: PinnedAdsFilters) => void
  handleAddBoard: VoidFunction
  board: Board | undefined
}

export const Filters = ({
  setFilters,
  filters,
  board,
  handleAddBoard,
}: FiltersProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <MultiBoardSelect
          value={filters.boardIds}
          onNewBoard={handleAddBoard}
          onChange={(boardIds?: number[]) => {
            setFilters({
              ...filters,
              boardIds,
            })
          }}
          label="Boards"
          newBoard={board}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <BoardFilter
          id="platforms"
          label="Platforms"
          value={filters.platforms}
          options={PLATFORMS}
          multiple
          onChange={(platforms?: string[] | string) => {
            setFilters({
              ...filters,
              platforms,
            })
          }}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <PinnedBrandsMultiSelect
          value={filters.brandIds}
          onChange={(brandIds?: string[]) => {
            setFilters({
              ...filters,
              brandIds,
            })
          }}
          label="Brands"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <BoardFilter
          id="format"
          label="Format"
          value={filters.format}
          options={FORMAT}
          multiple
          onChange={(format?: string[] | string) => {
            setFilters({
              ...filters,
              format,
            })
          }}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <BoardFilter
          id="sort"
          label="Sort"
          value={filters.sort}
          options={SORT_OPTIONS}
          onChange={(sort?: string[] | string) => {
            setFilters({
              ...filters,
              sort,
            })
          }}
        />
      </Grid>
    </Grid>
  )
}
