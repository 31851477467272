import AddIcon from "@mui/icons-material/Add"
import InfoIcon from "@mui/icons-material/Info"
import CloudDownloadIcon from "@mui/icons-material/FileDownload"
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react"
import Select from "react-select"

import {
  fetchMaxValues,
  fetchShopifyBrands,
  fetchShopifyBrandsWithFilter,
} from "../../api/searchBrand/searchBrand"
import {
  MaxValues,
  QueryParams,
  TrackedStores,
} from "../../api/searchBrand/types"
import SettingsIcon from "../../assets/images/settings.svg"
import {
  BRANDS_DOWNLOAD_URL,
  MOBILE_SCREEN_SIZE,
  TRACKED_BRANDS_DOWNLOAD_URL,
} from "../../utils"
import { checkIsSuperUser } from "../../utils/localStorage"
import AvatarTableCell from "../Components/AvatarTableCell"
import CountryLinkTableCell from "../Components/CountryLinkTableCell"
import LoadingOverlay from "../Components/LoadOverlay/LoadOverlay"
import Pagination from "../Components/Pagination/pagination"
import ScoreTableCell from "../Components/ScoreTableCell"
import { useLayout } from "../Hooks/useLayoutContext"
import usePagination from "../Hooks/usePagination"
import {
  getAdditionalFilterParams,
  getFilterRangeValues,
  getScore,
  stringTruncate,
} from "./actions"
import FilterInput from "./AdditionalFilterInput"
import FilterChoices from "./AdditionalFilterPopUp"
import { showTableHead } from "./components/brandTableHead"
import { showBrandTableRows } from "./components/brandTableRows"
import Categories from "./components/categories"
import CountryRegions from "./components/countryRegions"
import RangeSlider from "./components/customSlider"
import FilterRow from "./components/filterRow"
import {
  BrandFields,
  initialCheckboxValues,
  initialFilterRangeValues,
  SortType,
} from "./constants"
import TableMobileView from "./MobileScreen/tableComponent"
import {
  AdditionalFilterRangeTypes,
  BrandTableRowData,
  CountryRegion,
  FilterTypes,
} from "./types"
import { colors } from "../../utils/theme"
//--------------------------------------------------------------------------------------------------------------------

export default function V1SearchBrand() {
  const theme = useTheme()
  const isSuperUser = checkIsSuperUser()
  const isMobileView = useMediaQuery(MOBILE_SCREEN_SIZE)
  const {
    currentPage,
    pageSize,
    resultCount,
    setResultCount,
    setCurrentPage,
    setPageSize,
  } = usePagination()

  const [trackedOn, setTrackedON] = useState<TrackedStores | null>(null)
  const [runningAds, setRunningAds] = useState<true | null>(null)
  const [reportPublished, setReportPublished] = useState<true | null>(null)
  const [brandList, setbrandList] = useState<BrandTableRowData[]>([])
  const [scalingRange, setScalingRange] = useState<number[]>([0, 1000])
  const [revenueRange, setRevenueRange] = useState<number[]>([0, 100])
  const [countryCode, setCountryCode] = useState<CountryRegion[]>([])
  const [category, setCategory] = useState<number[]>([])
  const [subCategory, setSubCategory] = useState<number[]>([])
  const [filterCounter, setFilterCounter] = useState<number>(0)
  const [resetCounter, setResetCounter] = useState(0)
  const [sortType, setSortType] = useState<SortType | null>(null)
  const [sortBy, setSortBy] = useState<string | null>(null)
  const [countryReset, setCountryReset] = useState("resetOnCancel")
  const [isAddingFilter, setIsAddingFilter] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [checkBoxValues, setCheckBoxValues] = useState<FilterTypes>(
    initialCheckboxValues
  )
  const [maxValues, setMaxValues] = useState<MaxValues>()
  const [filterRangeValues, setFilterRangeValues] =
    useState<AdditionalFilterRangeTypes>(initialFilterRangeValues)
  const [tabFilter, setTabFilter] = useState<{
    value: string
    label: string
  } | null>({ value: "Social", label: "FB likes" })
  const [durationFilter, setDurationFilter] = useState<{
    value: string
    label: string
  } | null>({ value: "30", label: "Last 30 days" })
  const [filters, setFilters] = useState({
    country: true,
    category: true,
    scalingScore: true,
    revenueScore: true,
  })
  const { menuIsExpanded } = useLayout()

  const createSortHandler =
    (sort: SortType, column: keyof BrandTableRowData) => () => {
      setSortType(sort)
      setSortBy(column as string)
    }

  const durationOptions: Array<{ value: string; label: string }> = [
    { value: "30", label: "Last 30 days" },
    { value: "60", label: "Last 60 days" },
    { value: "90", label: "Last 90 days" },
  ]

  const options: Array<{ value: string; label: string }> = isSuperUser
    ? [
      { value: "", label: "NETWORK" },
      { value: "Social", label: "Social" },
      { value: "Reviews", label: "Reviews" },
      { value: "Reddit", label: "Reddit" },
      { value: "Store", label: "Store Metrics" },
      { value: "Product", label: "Product Data" },
      { value: "Traffic", label: "Website Traffic" },
    ]
    : [
      { value: "", label: "NETWORK" },
      { value: "Social", label: "Social" },
      { value: "Reviews", label: "Reviews" },
      { value: "Reddit", label: "Reddit" },
      { value: "Store", label: "Store Metrics" },
      { value: "Product", label: "Product Data" },
    ]

  useEffect(() => {
    const loadMaxRangeValues = async () => {
      const response = await fetchMaxValues()
      if (response) {
        setMaxValues(response)
      }
    }

    loadMaxRangeValues()
  }, [])

  useEffect(() => {
    if (maxValues) {
      const values = getFilterRangeValues(maxValues)
      setFilterRangeValues(values)
    }
  }, [maxValues, resetCounter])

  useEffect(() => {
    const loadDefaultShopifyBrands = async () => {
      const params: QueryParams = {
        currentPage,
        pageSize,
        duration: durationFilter?.value as string,
        ordering: sortType === "desc" ? `-${sortBy as string}` : sortBy,
        trackedOn,
        runningAds,
        reportPublished,
      }
      setLoading(true)
      const response = await fetchShopifyBrands(params)
      setLoading(false)
      if (response) {
        setbrandList(response.results)
        setResultCount(response.count)
      }
    }

    if (filterCounter === 0) {
      loadDefaultShopifyBrands()
    }
  }, [
    filterCounter,
    trackedOn,
    runningAds,
    reportPublished,
    sortBy,
    sortType,
    currentPage,
    pageSize,
    durationFilter,
    setResultCount,
  ])

  useEffect(() => {
    const loadFilteredShopifyBrands = async () => {
      const additionalFilterParams = getAdditionalFilterParams(
        checkBoxValues,
        filterRangeValues
      )

      const params = {
        page: currentPage,
        page_size: pageSize,
        duration: durationFilter?.value as string,
        ordering: sortType === "desc" ? `-${sortBy as string}` : sortBy,
        action: trackedOn,
        fb_ad_active: runningAds,
        report_published: reportPublished,
        categories: category,
        sub_categories: subCategory,
        country_codes: countryCode.map((country) => country.code),
        ...additionalFilterParams,
      }
      const payload = {
        scaling_score_ranking: {
          filter_value: { gte: scalingRange[0], lte: scalingRange[1] },
        },
        revenue_score_ranking: {
          filter_value: { gte: revenueRange[0], lte: revenueRange[1] },
        },
      }

      setLoading(true)
      const response = await fetchShopifyBrandsWithFilter(payload, params)
      setLoading(false)
      if (response) {
        setbrandList(response.results)
        setResultCount(response.count)
      }
    }

    if (filterCounter !== 0) {
      loadFilteredShopifyBrands()
    }

    if (window.scrollY !== 0) {
      window.scrollTo(0, 530)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterCounter,
    trackedOn,
    runningAds,
    reportPublished,
    sortBy,
    sortType,
    currentPage,
    pageSize,
    durationFilter,
  ])

  const applyFilter = () => {
    setFilterCounter(filterCounter + 1)
    setCurrentPage(1)
  }

  const handleCheckbox = (
    event: ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    setCheckBoxValues((prevState) => ({
      ...prevState,
      ...{ [name]: event.target.checked },
    }))
  }

  const onCountryRegionSelect = (_e: ChangeEvent<unknown>, ...rest: any[]) => {
    const choices = rest[0] as CountryRegion[]
    choices.length > 0 ? setCountryCode(choices) : setCountryCode([])
  }

  const handleScalingRangeChanged = (_e: any, newValue: number | number[]) => {
    setScalingRange(newValue as number[])
  }

  const handleRevenueRangeChanged = (_e: any, newValue: number | number[]) => {
    setRevenueRange(newValue as number[])
  }

  const countrySelectReset = () => {
    countryReset === "resetOnCancel"
      ? setCountryReset("forceReset")
      : setCountryReset("resetOnCancel")
    setCountryCode([])
  }

  const confirmAdditionalFilter = () => {
    setIsAddingFilter(!isAddingFilter)
  }

  const cancelFilter = (event: React.MouseEvent<unknown>) => {
    event.preventDefault()
    setFilters({
      country: true,
      category: true,
      scalingScore: true,
      revenueScore: true,
    })
    setScalingRange([0, 100])
    setRevenueRange([0, 100])
    countrySelectReset()
    setCheckBoxValues(initialCheckboxValues)
    setFilterCounter(0)
    setCategory([])
    setSubCategory([])
    setResetCounter(resetCounter + 1)
  }

  const handlePageChange = (
    _e: MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setCurrentPage(page)
  }

  const handleDuration = (
    selectedOption: { value: string; label: string } | null
  ) => {
    setDurationFilter(selectedOption)
  }

  const handleTableSettings = (
    selectedOption: { value: string; label: string } | null
  ) => {
    if (selectedOption?.label === "Social") {
      setTabFilter({ value: "Social", label: "Fb likes" })
    } else if (selectedOption?.label === "Product Data") {
      setTabFilter({ value: "Product", label: "Nr of Products" })
    } else if (selectedOption?.label === "Store Metrics") {
      setTabFilter({ value: "Store", label: "Traffic" })
    } else if (selectedOption?.label === "Website Traffic") {
      setTabFilter({ value: "Traffic", label: "Last Month" })
    } else {
      setTabFilter(selectedOption)
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCurrentPage(1)
    setPageSize(parseInt(event.target.value))
  }

  const toggleReportPublished = () => {
    setRunningAds(null)
    setTrackedON(null)
    setReportPublished(reportPublished ? null : true)
    setCurrentPage(1)
  }

  const toggleTrackedStores = () => {
    setRunningAds(null)
    setReportPublished(null)
    setTrackedON(trackedOn ? null : TrackedStores.ON)
    setCurrentPage(1)
  }

  const toggleRunningAds = () => {
    setRunningAds(runningAds ? null : true)
    setReportPublished(null)
    setTrackedON(null)
    setCurrentPage(1)
  }

  const handleAdditionalFiltersInput = (newValue: number[], name: string) => {
    const data = filterRangeValues
    Object.keys(data).forEach((key) => {
      if (key === name) {
        data[key] = newValue
      }
    })
    setFilterRangeValues(data)
  }

  const handleFilterCancelIcon = (name: string) => {
    if (name === "Scaling Score") {
      setScalingRange([0, 100])
      setFilters({ ...filters, ...{ scalingScore: false } })
    } else if (name === "Revenue Score") {
      setRevenueRange([0, 100])
      setFilters({ ...filters, ...{ revenueScore: false } })
    } else if (name === "Category") {
      setFilters({ ...filters, ...{ category: false } })
    } else {
      countrySelectReset()
      setFilters({ ...filters, ...{ country: false } })
    }
  }

  const handleAdditionalFiltersCancelIcon = (choice: string) => {
    setCheckBoxValues((prevState) => ({ ...prevState, ...{ [choice]: false } }))
  }

  const helpText = (name: string) => {
    if (name === "Scaling Score") {
      return "The Scaling Score tracks brand growth based on their Social channels month over month. Each brand receives a score between 1-100 in comparison with other tracked brands"
    } else {
      return "The Revenue Score uses Trend Rocket’s bespoke algorithm taking in consideration Traffic, Product metrics, Industry conversion data. Each brand receives a score between 1-100 in comparison with other tracked brands"
    }
  }

  const getSelectedMenuAttr = (menu: string | undefined) => {
    switch (menu) {
      case "Social":
        return BrandFields.FbLikes
      case "Product":
        return BrandFields.Count
      case "Store":
        return BrandFields.MonthlyTraffic
      case "Traffic":
        return BrandFields.LastMonthTraffic
      case "Reddit":
        return BrandFields.RedditGrowth
      case "Reviews":
        return BrandFields.ShopifyReviews
      default:
        return ""
    }
  }

  const BrandList =
    brandList &&
    brandList.map((data, index) => {
      return (
        <TableRow key={index}>
          <AvatarTableCell
            avatar={data.profileimage}
            label={data.brandname}
            id={data.brand_id}
          />
          <CountryLinkTableCell
            flag={data.country_code}
            link={data.brandsite}
          />
          {data.scaling_score_ranking !== null ? (
            <ScoreTableCell
              score={getScore(
                data.scaling_score_ranking,
                data.scaling_score_percentage
              )}
            />
          ) : (
            <TableCell>-</TableCell>
          )}
          {data.revenue_score_ranking !== null ? (
            <ScoreTableCell
              score={getScore(
                data.revenue_score_ranking,
                data.revenue_score_percentage
              )}
            />
          ) : (
            <TableCell>-</TableCell>
          )}
          <TableCell>
            {data.brand_description
              ? stringTruncate(data.brand_description)
              : "-"}
          </TableCell>
          {tabFilter?.value && showBrandTableRows(data, tabFilter.value)}
        </TableRow>
      )
    })

  const additionalFilterElements = Object.keys(checkBoxValues).map(
    (choice, index) =>
      checkBoxValues[choice] === true && (
        <FilterRow
          key={index}
          header={choice.replaceAll("_", " ")}
          onCancelIconClick={() => handleAdditionalFiltersCancelIcon(choice)}
          thirdColumn={
            <FilterInput
              primaryLabel={choice.replaceAll("_", " ")}
              onInputChange={handleAdditionalFiltersInput}
              values={filterRangeValues}
            />
          }
        />
      )
  )

  return (
    <>
      <Grid
        className={
          menuIsExpanded === true ? "slider v1-trending" : "v1-trending"
        }
      >
        <LoadingOverlay loading={loading} />
        <Grid
          item
          xs={12}
          sx={{
            mt: (theme) => theme.spacing(0),
            mb: "40px !important"
          }}
        >
          <Paper
            variant="elevation"
            elevation={1}
            className="searchBrandPaper shbox"
          >
            {filters.country && (
              <FilterRow
                header="Country"
                onCancelIconClick={() => handleFilterCancelIcon("Country")}
                thirdColumn={
                  <CountryRegions
                    value={countryCode}
                    reset={countryReset}
                    countrySelect={onCountryRegionSelect}
                  />
                }
              />
            )}
            {filters.category && (
              <FilterRow
                header="Category"
                onCancelIconClick={() => handleFilterCancelIcon("Category")}
                thirdColumn={
                  <Categories
                    resetCounter={resetCounter}
                    updateCategory={setCategory}
                    updateSubCategory={setSubCategory}
                  />
                }
              />
            )}
            {filters.scalingScore && (
              <FilterRow
                header="Scaling Score"
                onCancelIconClick={() =>
                  handleFilterCancelIcon("Scaling Score")
                }
                thirdColumn={
                  <RangeSlider
                    values={scalingRange}
                    onRangeChange={handleScalingRangeChanged}
                  />
                }
              />
            )}
            {filters.revenueScore && (
              <FilterRow
                header="Revenue Score"
                onCancelIconClick={() =>
                  handleFilterCancelIcon("Revenue Score")
                }
                thirdColumn={
                  <RangeSlider
                    values={revenueRange}
                    onRangeChange={handleRevenueRangeChanged}
                  />
                }
              />
            )}

            {!isAddingFilter && additionalFilterElements}
            {isAddingFilter ? (
              <FilterChoices
                onConfirm={confirmAdditionalFilter}
                open={isAddingFilter}
                choices={checkBoxValues}
                handleCheckbox={handleCheckbox}
                onClose={() => setIsAddingFilter(false)}
              />
            ) : (
              <Button
                variant="text"
                startIcon={<AddIcon />}
                color="inherit"
                onClick={() => setIsAddingFilter(!isAddingFilter)}
              >
                {Object.values(checkBoxValues).filter((value) => value === true)
                  .length > 0
                  ? "Add/Remove Filter"
                  : "Add Filter"}
              </Button>
            )}
            <Divider
              sx={{
                mt: "40px !important",
                mb: "40px !important",
                ml: "-24px",
                width: "calc(100% + 48px)",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div />
              <div>
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  onClick={cancelFilter}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={applyFilter}
                  style={{ marginLeft: theme.spacing(2) }}
                >
                  Apply
                </Button>
              </div>
            </Box>
          </Paper>
        </Grid>
        <Grid
          container
          id="brandTable"
          sx={{
            mt: (theme) => theme.spacing(5),
          }}
        >
          <Grid item xs={12} sx={{ display: "inherit" }} justifyContent="space-between">
            <Typography
              variant="h4"
              sx={{
                fontSize: "32px",
                fontWeight: "bold",
                "& span": {
                  color: (theme) => theme.palette.text.secondary,
                  fontSize: "smaller",
                },
              }}>
              Brands <span>{resultCount}</span>
            </Typography>
            <div>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                className={reportPublished ? "selectedBtn" : ""}
                onClick={toggleReportPublished}
              >
                Trend Viability
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                className={trackedOn ? "selectedBtn" : ""}
                onClick={toggleTrackedStores}
              >
                Tracked Stores
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                className={runningAds ? "selectedBtn" : ""}
                onClick={toggleRunningAds}
              >
                Running Ads
              </Button>
              {isSuperUser ? (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  href={
                    trackedOn
                      ? TRACKED_BRANDS_DOWNLOAD_URL
                      : BRANDS_DOWNLOAD_URL
                  }
                  download
                >
                  Download <CloudDownloadIcon />
                </Button>
              ) : (
                ""
              )}
              <div className="durationFilter">
                <Select
                  options={durationOptions}
                  value={durationFilter}
                  onChange={handleDuration}
                />
              </div>
            </div>
          </Grid>
          {isMobileView ? (
            <TableMobileView brandList={brandList} />
          ) : (
            <div className="SearchBrandList">
              <Grid item xs={12}>
                <TableContainer className="tracker">
                  <Table className="brands">
                    <TableHead>
                      <TableRow>
                        <TableCell className="sortHead">
                          <Box
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                              "& .MuiSvgIcon-root": {
                                mr: (theme) => theme.spacing(1),
                              },
                            }}
                          >
                            <label className="sortStyle">Brand Name</label>
                            <span
                              className="sortUp"
                              onClick={createSortHandler(
                                SortType.Descending,
                                BrandFields.Name
                              )}
                            />
                            <span
                              className="sortDown"
                              onClick={createSortHandler(
                                SortType.Ascending,
                                BrandFields.Name
                              )}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 100 }}
                          className="sortHead"
                        ></TableCell>
                        <TableCell
                          className="sortHead"
                          style={{ minWidth: 150 }}
                        >
                          <Box
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                              "& .MuiSvgIcon-root": {
                                mr: (theme) => theme.spacing(1),
                              },
                            }}
                          >
                            <Tooltip title={helpText("Scaling Score")} arrow>
                              <InfoIcon color="disabled" fontSize="small" />
                            </Tooltip>
                            <label className="sortStyle">Scaling Score</label>
                            <span
                              className="sortUp"
                              onClick={createSortHandler(
                                SortType.Descending,
                                BrandFields.ScalingScore
                              )}
                            />
                            <span
                              className="sortDown"
                              onClick={createSortHandler(
                                SortType.Ascending,
                                BrandFields.ScalingScore
                              )}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          className="sortHead"
                          style={{ minWidth: 160 }}
                        >
                          <Box
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                              "& .MuiSvgIcon-root": {
                                mr: (theme) => theme.spacing(1),
                              },
                            }}
                          >
                            <Tooltip title={helpText("Revenue Score")} arrow>
                              <InfoIcon color="disabled" fontSize="small" />
                            </Tooltip>
                            <label className="sortStyle">Revenue Score</label>
                            <span
                              className="sortUp"
                              onClick={createSortHandler(
                                SortType.Descending,
                                BrandFields.RevenueScore
                              )}
                            />
                            <span
                              className="sortDown"
                              onClick={createSortHandler(
                                SortType.Ascending,
                                BrandFields.RevenueScore
                              )}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>Brand Description</TableCell>
                        <TableCell
                          className="sortHead"
                          style={{ maxWidth: 420 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "95%",
                              cursor: "pointer",
                              "& .MuiSvgIcon-root": {
                                mr: (theme) => theme.spacing(1),
                              },
                            }}
                          >
                            <img src={SettingsIcon} alt="Settings" />
                            <label>
                              <Select
                                className="settingsPop"
                                options={options}
                                value={tabFilter}
                                onChange={handleTableSettings}
                              />
                            </label>
                            <span
                              className="sortUp"
                              onClick={createSortHandler(
                                SortType.Descending,
                                getSelectedMenuAttr(tabFilter?.value)
                              )}
                            />
                            <span
                              className="sortDown"
                              onClick={createSortHandler(
                                SortType.Ascending,
                                getSelectedMenuAttr(tabFilter?.value)
                              )}
                            />
                          </Box>
                        </TableCell>
                        {tabFilter?.value &&
                          showTableHead(
                            tabFilter.value,
                            createSortHandler,
                          )}
                      </TableRow>
                    </TableHead>
                    <TableBody>{BrandList}</TableBody>
                    {brandList && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            page={currentPage - 1}
                            onPageChange={handlePageChange}
                            rowsPerPage={pageSize}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            count={resultCount}
                            rowsPerPageOptions={[20, 50, 100, 150]}
                            sx={{
                              "& .MuiInputBase-root": {
                                left: "220px",
                                top: "1px",
                                background: colors.White,
                                borderRadius: "4px",
                              },
                              "& .MuiTablePagination-displayedRows": {
                                position: "absolute",
                                fontSize: "14px",
                                pl: "10px",
                              }
                            }}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
      <Pagination
        isSearchBrandPage
        currentPage={currentPage}
        resultCount={resultCount}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
        onPageSizeChange={setPageSize}
        label={"Brands"}
      />
    </>
  )
}
