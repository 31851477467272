import { ResponsiveChoropleth } from "@nivo/geo"
import { useMediaQuery } from "@mui/material"

import countriesFeatures from "../../../../static/geo/world_countries.json"
import { MOBILE_SCREEN_SIZE } from "../../../../utils"

type RegionProps = {
  regions: { id: string, value: number }[]
}

export default function Regions({ regions }: RegionProps) {
  const isMobileView = useMediaQuery(MOBILE_SCREEN_SIZE)

  return (
    <ResponsiveChoropleth
      data={regions}
      features={countriesFeatures.features}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      colors="blues"
      domain={[0, 100]}
      unknownColor="#eaeef3"
      label="properties.name"
      valueFormat=".2s"
      projectionScale={isMobileView ? 50 : 120}
      projectionRotation={[0, 0, 0]}
      enableGraticule={false}
      borderWidth={0.5}
      borderColor="#3b4edf"
    />
  )
}