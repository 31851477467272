import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import Modal from "../../../../components/Modal"
import AdContent from "./AdContent"
import { Box, Button, Stack } from "@mui/material"
import Iconify from "../../../../components/Iconify"
import landingPageIcon from "../../../../assets/images/v1-icons/landingPageIcon.png"
import { openLinkInNewTab } from "../../../../utils"
import { FbAd } from "../../../../api/ads"
//-----------------------------------------------------------------------------------------------------

type PinnedAdDetailModalProps = {
  handleClose: VoidFunction
  onNext: VoidFunction
  onPrevious: VoidFunction
  ad?: FbAd
}

export default function AdDetailPreviewModal({
  handleClose,
  onNext,
  onPrevious,
  ad,
}: PinnedAdDetailModalProps) {
  const { enqueueSnackbar } = useSnackbar()
  const [focusedAssetUrl, setFocusedAssetUrl] = useState<
    string | undefined | null
  >()
  const [shareLink, setShareLink] = useState<string>()
  const [focusedCtaUrl, setFocusedUrl] = useState<string | undefined | null>()

  useEffect(() => {
    if (!ad) {
      return
    }

    const getAssetUrl = () => {
      const getImageUrl = (urlString?: string | null) => {
        return urlString && urlString !== "N/A" ? urlString : undefined
      }
      const imageUrlString = getImageUrl(ad.image)
      if (imageUrlString) {
        return imageUrlString
      }
      return ad.video_url
    }

    setFocusedUrl(ad.link_url)
    setFocusedAssetUrl(getAssetUrl())
  }, [ad])

  if (!ad) {
    return null
  }

  const shareLinkClicked = () => {
    if (ad.link_url) {
      navigator.clipboard.writeText(ad.link_url)
      setShareLink(ad.link_url)
      enqueueSnackbar("Link copied to clipboard")
    }
  }

  return (
    <Modal
      open={ad !== undefined}
      handleClose={handleClose}
      maxWidth="lg"
      hideHeader
    >
      <>
        <AdContent
          ad={ad}
          handleClose={handleClose}
          focusedCtaUrl={focusedCtaUrl}
        />
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" spacing={2}>
            {focusedAssetUrl && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={
                  <Iconify icon={"material-symbols:download-rounded"} />
                }
                onClick={() => {
                  window.open(focusedAssetUrl, "_blank")
                }}
              >
                Download Asset
              </Button>
            )}
            {focusedCtaUrl && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => openLinkInNewTab(focusedCtaUrl)}
                startIcon={<img
                  src={landingPageIcon}
                  style={{
                    width: "1em",
                    height: "1em",
                  }}
                  alt="landing-icon"
                />
                }
              >
                Landing Page
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={shareLinkClicked}
              startIcon={<Iconify icon={"ph:link-simple-bold"} />}
            >
              {shareLink ? "Link copied to clipboard" : "Copy ad link"}
            </Button>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button onClick={onPrevious} variant="contained" color="secondary">
              {"<"}
            </Button>
            <Button onClick={onNext} variant="contained" color="secondary">
              {">"}
            </Button>
          </Stack>
        </Box>
      </>
    </Modal>
  )
}